import { Component, OnInit,ElementRef } from '@angular/core';
import { ReservaService } from '../../services/reservas.services'

import { AuthService} from '../../services/auth.service'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
//import reservar from './reservar.json';
@Component({
  selector: 'app-reserva-vinculacion',
  templateUrl: './reserva-vinculacion.component.html',
  styleUrls: ['./reserva-vinculacion.component.css']
})
export class ReservaVinculacionComponent implements OnInit {
id;
user;
  reserva: any;
  lleno: boolean;
  constructor(private UserService:AuthService, 
    private elRef:ElementRef,
    private usersService: AuthService,
    private router: Router, private route: ActivatedRoute, private api: ReservaService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.paramMap.get("id");
    
    this.loadReserva();
  }

  vincularme(){
    if(!this.reserva.registers.includes(this.user._id)){
      this.api.addToReserva(this.id,this.user._id)
      .then((r:any)=>{
        if (r.estado == "reserva") {

          Swal.fire("Ya tiene una reserva para este día")
            .then(() => {
  
            })
        }
        else if(r.estado == "lleno"){
          Swal.fire("Ya se completo el cupo para la reserva")
          .then(() => {

          })
        }
        else{
          Swal.fire("Vinculación exitosa");
          this.loadReserva()
        }
   
      })
    }
    else{
      Swal.fire("Ya estas vinculado a esta reserva")
    }

  
  }


  loaduser(){
    let user = localStorage.getItem('userInfo')
    console.log(user)
    this.user= JSON.parse(user).user;
    this.usersService.validatep(this.user.id)
    .then((response) => {
      console.log(response);

      this.usersService.setUserInfo({'user' : response['user']});
    
      let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;



    })
    .catch((err)=>{
      location.href="/login"
    })
    
  
     
         
  }

  loadReserva(){
    this.api.getreserva(this.id)
    .then((r)=>{
      console.log(r)
      this.reserva=r
      console.log(this.reserva)
      if(this.reserva.registers.length>3){
        this.lleno=true;
      }
      this.loaduser();
    })
  }
  
}
