import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-olvido',
  templateUrl: './olvido.component.html',
  styleUrls: ['./olvido.component.css']
})
export class OlvidoComponent implements OnInit {
id;
empresa;
  constructor(    private authService: AuthService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.paramMap.get("id");
    this.loadempresa();
  }

  loadempresa(){

    this.authService.getempresa(this.id)
    .then((response)=>{
      console.log(response);
      this.empresa=response["empresa"];
      this.olvido()
      
    })
    
       } 
olvido(){
  this.authService.olvidoclave(this.empresa)
  .then(()=>{
    Swal.fire("Se ha enviado un correo a la direccion: "+this.empresa.responsable.correo+" con las instrucciones de recuperación")
    .then(()=>{
      location.href="/"
    })
  })
}
}
