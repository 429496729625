<img src="../assets/logomovi.png" alt="" style="position: absolute; bottom: 10px; left: 5px; width: 25%; height: auto; background-color: white;border-radius: 15px; padding: 10px; ">

<div class="row mt-4 justify-content-center" >
<div class="col-md-6" *ngIf="!vis" >
<form  [formGroup]="ipersonForm" (ngSubmit)="ingresop()">
    <div class="form-group">
        <h1>Validar salida</h1>
        <input class="form-control" type="text" (change)="changeInput($event)" placeholder="número de identificación" formControlName="id" [ngClass]="{ 'is-invalid': submitted && f4.id.errors }">
        <div *ngIf="submitted && f4.id.errors" class="invalid-feedback ">
            <div *ngIf="f4.id.errors.required">ingrese su documento</div>
           
        </div> 
    </div>
    <input type="submit" value="Ingresar" class="btn btn-info">

</form>
</div>
<div class="row mt-4 justify-content-center" *ngIf="vis && !yaingreso" >


<div class="col-md-5"   >
   
    </div>
    <div class="col-md-6" *ngIf="!finish" >
        <div class="container  bg-dark  p-2 rounded-top shadow text-white" *ngIf="!fotop"  >
            <video  playsinline   playsinline   playsinline width="90%" height="225" autoplay #video></video> <br>
           
            <canvas #canvas style="display:none; "></canvas>
            </div>
            <br>
            <h2>Al ingresar y tomarse la fotografía esta aceptando nuestra política de tratamiento de datos </h2>
<button   class="btn btn-primary"  (click)="tomarregistro()" >Validar</button>
    </div>
    <div class="col-md-6" *ngIf="finish" >
<h1>
    ingreso validado
</h1>
<img class="card-img-top" src={{foto}} width="25%" style="border-radius:20% 20% 0 0 ;  " alt="">
   <button  class="btn btn-primary" (click)="terminar()"  >Terminar</button>
    </div>
</div>
<div class="row mt-4 justify-content-center" *ngIf="yaingreso" >
    <!-- <div class="col-md-4"   >

    
    </div> -->
    <div class="col-md-6"  > 
<h1 style="color: white;" >
    Ya valido su salida
</h1>
<img src="{{user.photo1}}" width="50%" alt=""> <br>
<h2 *ngIf="visita!='funcionario'" >Nivel de acceso: {{visita.acceso}}</h2>
<h2 *ngIf="visita!='funcionario'" >Descripción: {{visita.descripcion}}</h2>
<h2  *ngIf="visita!='funcionario'">Evento: {{visita.eventname}}</h2> 
<h2>Hora de salida: {{horasalida}}
</h2>
<button  class="btn btn-primary" (click)="terminar()"  >Terminar</button>
  
    </div>
</div>


</div>