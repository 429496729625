import { Component, OnInit,ElementRef, Renderer2  } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators , FormArray} from '@angular/forms';
import { interval } from 'rxjs';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 


@Component({
  selector: 'app-resumenevento',
  templateUrl: './resumenevento.component.html',
  styleUrls: ['./resumenevento.component.css']
})
export class ResumeneventoComponent implements OnInit {
id;
visitas:any;
ingresos:any;
  yabusco: boolean;
  notaForm: FormGroup;
 nPeople=0;
  newNota: any;
  empresas: any[];
  downReady: boolean;
  event: any;
  user;
  constructor(
    private authService : AuthService, 
    private eventService : EventsService, 
    private route: ActivatedRoute, private router : Router, private elRef:ElementRef,
    
  ) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    this.id=this.route.snapshot.paramMap.get("id");
    this.getvisitas();
    this.getIngresos();
    this.getevent();
    this.notaForm = new FormGroup({
      "id": new FormControl('', ),  
      'question': new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),              
      'time': new FormControl('', ),    
    });

  }
  compare( a, b ) {
    if ( a.fecha < b.fecha ){
      
      return -1;
    }
    if ( a.fecha > b.fecha ){
      return 1;
    }
    return 0;
  }
  getevent(){
    this.eventService.getEvent(this.id)
    .then((response)=>{
      this.event=response["results"][0]
    })
  }
  getvisitas(){
    this.eventService.getvisitasEvento(this.id)
    .then((result)=>{
      console.log(result)
      this.visitas=result["visitas"];
      this.visitas.sort( this.compare );
      this.cargarhoras(this.visitas);
    })
  }

  getIngresos(){
    this.eventService.getingresosEvento(this.id)
    .then((result)=>{
      console.log(result)
      this.ingresos=result["ingresos"];
      this.horaingreso(this.ingresos)
    })
  }
  horaingreso(arr){
    var n=0;
    arr.forEach(solicitud => {
     // console.log("visita: ",solicitud);
     n+=1;
      var date= new Date(solicitud.fecha);
       var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
       solicitud.fechat=fecha;  
       if(solicitud.salida){
        var date2= new Date(solicitud.fechaSalida);
        var fecha2= date2.getDate()+"/"+(date2.getMonth()+1)+"/"+date2.getUTCFullYear()+" hora: "+date2.getHours()+":"+(date2.getMinutes() < 10 ? '0' : '') + date2.getMinutes();
        solicitud.fechas=fecha2;  
       }
       if(solicitud.user.empresa!="independiente" && solicitud.user.empresa.length==24 ){
        this.authService.getempresa(solicitud.user.empresa)
        .then((response)=>{
          //console.log(response);        
          solicitud.empresa=response["empresa"];
        })
        .catch((err)=>{
          solicitud.empresa=solicitud.user.empresa;
        })
       }else{
        solicitud.empresa=solicitud.user.empresa;
       }
  if(n==arr.length){
    this.downReady=true;
  }
    }); 
  }

downIngreso(){
  let diasCsv= "data:text/csv;charset=utf-8,";
  var user;
  this.ingresos.forEach(element => {
   
      var salida;
      var empresa;
      if  (element.empresa && element.empresa.name  && element.empresa.name!=null){
        empresa=element.empresa.name
      }
      else{
        empresa=element.empresa
      }
      if(element.fechas){
         salida=element.fechas
      }
      else{
        salida="no registra salida"
      }
      user=[element.user.name+" "+element.user.apellido,element.user.id,empresa,element.fechat,salida];
      let row = user.join(",");
      diasCsv+= row + "\r\n";
    
  });
  saveAs(diasCsv,"ingresosevento-"+this.event.nombre+".csv");
 
}

  buscar(){
    var buscado=this.elRef.nativeElement.querySelector('#buscado').value;
    console.log("buscando",buscado);
    this.visitas=this.visitas.filter((usr)=>{ return usr.empresa._id== buscado});
    this.yabusco=true;
  }
  limpiar(){
    this.getvisitas();
    this.yabusco=false;
  }

  newnota(id){
    this.newNota=true;
    this.notaForm.patchValue({
    id:id  
    });
  
  }
  closenota(){
     if(this.newNota){
       this.newNota=false;
       this.notaForm.reset();
     }
   
  }

  cargarhoras(arr){
    this.nPeople=0;
    this.empresas=[];
    arr.forEach(solicitud => {
    //  console.log("visita: ",solicitud);
      var date= new Date(solicitud.fecha);
       var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
       solicitud.fechat=fecha;
      solicitud.open=false; 
      var aprobada=0; 
      solicitud.personas.forEach(element => {
        this.nPeople+=1;
      if(element.aprobe3){
        aprobada+=1;
      }  
      });
      console.log(solicitud.empresa);
      if(solicitud.empresa){
        this.authService.getempresa(solicitud.empresa)
.then((response)=>{
  console.log(response);
  this.empresas.push(response["empresa"])
  solicitud.empresa=response["empresa"];
})
      }
  solicitud.aprobada=aprobada;
    }); 
  }
  
}
