<div class="row justify-content-center">
 <div class="col-md-8 col-11 bg-dark" >
    <div id="viewer" ></div>
 </div>

</div>


<div class="row justify-content-center" >
    <div class="col-md-8 col-10">

  
<h1>Albums</h1>
<div  class="row justify-content-center">
<div *ngFor="let file of files" class="col-3" >
<button (click)="viewAlbum(file)" class="btn btn-primary" >{{file}}</button>
</div>
</div>
</div>
</div>
 

<div class="row justify-content-center" >
    <div class="col-md-8 col-10">

  
<h1>Videos</h1>
<div  class="row justify-content-center">


<div *ngFor="let vid of vids"  class="col-6" >


<video  playsinline  [src]="vid | safe" width="100%" style="height: auto;" controls ></video>
<!-- <a href="{{vid}}" target="_blank" download><button class="btn btn-primary" >Descargar</button></a> -->
</div>
</div>
</div>
</div>