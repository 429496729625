import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import {SocketioService} from "../../services/socketio.service";
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import  Swal from 'sweetalert2';  

import {OpenviduSessionComponent, StreamEvent, Session, UserModel, OpenViduLayout, OvSettings, OpenViduLayoutOptions, SessionDisconnectedEvent, Publisher} from 'openvidu-angular';

import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-porteria',
  templateUrl: './porteria.component.html',
  styleUrls: ['./porteria.component.css']
})
export class PorteriaComponent implements OnInit {
solicitudes;
intervalid;
hoy=[];
visitapop=false;
anteriores=[];
proximas=[];
porteria;
bita;
vanteriores;
vhoy;
fechaForm2;
vproximas;
user;
  bitaForm: any;
  notaForm: any;
  questions: any;
  newNota: boolean;
  selectedid: any;
  fecha: string;
  yabusco: boolean;
  empresas: any[];
  tempresas: any[];
  selecanfi: boolean;
  visitasol: any;
//   correosAnfitrion=["luis.quintero@movistararena.co","karina.rivera@movistararena.co","adriana.losada@movistararena.co",
// "juan.partarrieu@movistararena.co","gabriel.sanchez@movistararena.co","alexander.moreno@movistararena.co",
// "diego.castro@movistararena.co","sebastian.pulido@movistararena.co","luis.castaneda@movistararena.co",
// "tadeo.suarez@movistararena.co","nelson.beltran@movistararena.co","flor.parra@movistararena.co",
// "adriana.sanclemente@movistararena.co","carlos.yepes@movistararena.co","lina.galindo@movistararena.co",
// "andres.gomez@movistararena.co","stiven.sierra@movistararena.co","javier.garcia@movistararena.co",
// "oscar.parra@venues-snacks.com","francisco.guerra@venues-snacks.com","scortes@bizarro.com.co",
// "ingenieria@ipservices.com.co","jbaracaldo@ipservices.com.co","flor.ramirez@movistararena.co"]
correosAnfitrion=[];
  añadir: boolean;
  anadir: boolean;
  idrperson: FormGroup;
  nuser: boolean;
  luser: any;
  validcionPersonas: boolean;
  agregados: any[];
  submitted: boolean;
  visitaForm: FormGroup;
  eventos: any;
  constructor(
    private authService : AuthService, 
    private eventService : EventsService, 
    private route: ActivatedRoute, private router : Router,
    private socketservice: SocketioService,
    private renderer: Renderer2,
    private elRef:ElementRef,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {

    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;

    this.idrperson= new FormGroup({
      'id': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
    })
    this.notaForm = new FormGroup({
      "id": new FormControl('', ),  
      'question': new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),              
      'time': new FormControl('', ),    
    });
    this.bitaForm = new FormGroup({
 
      'question': new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),              
      'time': new FormControl('', ),    
    });
    this.fechaForm2= new FormGroup({
      'fecha':new FormControl("",[Validators.required]),
    })
    var href = this.router.url;
    if(href.includes("porteria")){
     
      this.porteria=true;
    }
    this.solicitudes=[];
    this.hoy=[];
    this.anteriores=[];
    this.proximas=[];
  if(this.user.rol && this.user.rol!="usuario"){
    this.getsolicitudeshoy();
    this.vhoy=true;
    this.getCorreosAnfitrion();
    this.loadquestions();
  }
  else{
    location.href="/login"
  }

  }

getCorreosAnfitrion(){
  this.authService.getFuncnioarios()
  .then((result:[any])=>{
  console.log(result);
    this.correosAnfitrion=result["usuarios"].map((el)=>{return el.correo})
  })

}
  sendNotify(){
    var correo=this.elRef.nativeElement.querySelector('#anfitrionSelect').value;
    console.log(correo);
    this.eventService.sendNotify(this.visitasol,correo)
    .then((result)=>{
      this.selecanfi=false;
      this.getsolicitudeshoy()
    })
  }


  aprbationSol(visita){
    //abro recuadro con lista de correos y botone enviar.
    this.selecanfi=true;
    this.visitasol=visita;
  }

  verhoy(){
    this.getsolicitudeshoy();
    this.vhoy=true
    this.vanteriores=false;
    this.vproximas=false
  }
  verproxi(){
    this.getsolicitudesproxima();
    this.vproximas=true;
    this.vhoy=false;
    this.vanteriores=false;

  }
  newFecha2(){
    console.log(this.fechaForm2.controls)
    var date=new Date(this.fechaForm2.controls["fecha"].value.toString());
    this.fecha= date.getUTCDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear();
  
    var dia=date.getUTCDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    console.log(dia,mes,ano)
    this.authService.getvisitasanterior(dia,mes,ano)
    .then((response)=>{
  console.log(response);
  this.anteriores.sort( this.compare );
    this.anteriores=response["visitas"];
    this.anteriores.sort( this.compare );
    this.anteriores.reverse()
    this.cargarhoras(this.anteriores);
    })
  }
verantes(){
  //this.getsolicitudesanterior();
  this.vanteriores=true;
  this.vhoy=false;

  this.vproximas=false
}
compare( a, b ) {
  if ( a.fecha < b.fecha ){
    
    return -1;
  }
  if ( a.fecha > b.fecha ){
    return 1;
  }
  return 0;
}
  getsolicitudeshoy(){
    var date=new Date();
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    this.authService.getvisitashoy(dia,mes,ano)
    .then((response)=>{
  console.log(response);
  
      this.solicitudes=response["visitas"];
    this.hoy=response["visitas"];
    this.hoy.sort( this.compare );
    this.cargarhoras(this.hoy);
    })
  }
  getsolicitudesproxima(){
    var date=new Date();
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    this.authService.getvisitasproxima(dia,mes,ano)
    .then((response)=>{
  console.log(response);
  
      this.solicitudes=response["visitas"];
    this.proximas =response["visitas"];
    this.proximas.sort( this.compare );
    this.cargarhoras(this.proximas);
    })
  }
  getsolicitudesanterior(){
    var date=new Date();
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    this.authService.getvisitasanterior(dia,mes,ano)
    .then((response)=>{
  console.log(response);
  
      this.solicitudes=response["visitas"];
    this.anteriores=response["visitas"];
    this.anteriores.sort( this.compare );
    this.cargarhoras(this.anteriores);
    })
  }
getsolicitudes(){
 
  this.authService.getallvisitas()
  .then((response)=>{
console.log(response);

    this.solicitudes=response["visitas"];
    this.ordenar();
  })
}
cargarhoras(arr){
  this.empresas=[];
  var empresas=[];
  arr.forEach(solicitud => {
    console.log("visita: ",solicitud);
    var date= new Date(solicitud.fecha);
     var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
     solicitud.fechat=fecha;
    solicitud.open=false; 
    var aprobada=0; 
    solicitud.personas.forEach(element => {
    if(element.aprobe3){
      aprobada+=1;
    }  
    });
    console.log(solicitud.empresa);
    if(solicitud.empresa){
      this.authService.getempresa(solicitud.empresa)
.then((response)=>{
  console.log(response);
  if(!empresas.includes(solicitud.empresa)){
    empresas.push(solicitud.empresa)
    this.empresas.push(response["empresa"])
  }
  
  solicitud.empresa=response["empresa"];
})
    }
solicitud.aprobada=aprobada;
  }); 
}
ordenar(){
  //this.hoy=[];
  //this.anteriores=[];
  //this.proximas=[];
  var now =new Date();
  this.solicitudes.forEach(solicitud => {

    var date= new Date(solicitud.fecha);
     var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
     solicitud.fechat=fecha;
    solicitud.open=false;
    if(now.getDate()==date.getDate() && now.getMonth()==date.getMonth()){

     if(this.hoy.some(e=> e._id===solicitud._id)){

     }
     else{
      this.hoy.push(solicitud);
     }


    }
    else if(date>now){
        
     if(this.proximas.some(e=> e._id===solicitud._id)){

    }
    else{
      this.proximas.push(solicitud);
    }


   
    }
    else if(date<now){
    
      if(this.anteriores.some(e=> e._id===solicitud._id)){

      }
      else{
        this.anteriores.push(solicitud);
      }
    }

  });

}
eliminar(id){
  console.log(id);
  this.authService.eliminarVisita(id)
  .then((response) => {
    this.ngOnInit();
})
}
newnota(id){
  this.newNota=true;
  this.notaForm.patchValue({
  id:id  
  });

}
closenota(){
   if(this.newNota){
     this.newNota=false;
     this.notaForm.reset();
   }
 
}
nopro(event){
  event.stopPropagation();
}
sendnota(){
  if (this.notaForm.invalid) {
    this.newNota=false;
    this.ngOnInit();
}
  var now= new Date();
  var n = now.getUTCHours();
  if(n<5){
    n=24+n; 
  } 
 now.setHours(n-5);  
  this.notaForm.patchValue({
    time: now  
  });

  this.authService.newnota(this.notaForm.value,this.user)
  .then((response)=>{
    this.newNota=false;
  this.ngOnInit();
  })
.catch((err)=>{
  this.newNota=false;
})

}


loadquestions(){ 
  var date=new Date();
 
  var dia=date.getDate();
  var mes=date.getMonth()+1;
  var ano=date.getFullYear();
  this.eventService.preguntas(dia,mes,ano)
  .then((response) => {
    //console.log(response);
    var preguntas=response["results"]
    preguntas.forEach(element => {
      //console.log(element);
      var date= new Date(element.time);
      element.fecha=element.time;
var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
element.time=fecha;
    })
 this.questions=preguntas
 this.questions.sort( this.compare );
  })
  .catch((err)=>{
 
  })
}

newitem(){
  var now= new Date();
  var n = now.getUTCHours();
  if(n<5){
    n=24+n; 
  } 
 now.setHours(n-5);  
  this.bitaForm.patchValue({
    time: now  
  });

  this.authService.newitem(this.bitaForm.value,this.user)
  .then((response)=>{
    this.loadquestions();
    this.bitaForm.patchValue({
      question:""  
    });
  })


}

logout(){
  localStorage.removeItem('userInfo');
  location.href="/login"
}
buscar(){
  var buscado=this.elRef.nativeElement.querySelector('#buscado').value;
  console.log("buscando",buscado);
  this.hoy=this.hoy.filter((usr)=>{ if( usr.empresa &&  usr.empresa._id){
    return  usr.empresa._id== buscado}  
  else{
return false}
  });
  this.yabusco=true;
}
limpiar(){
  this.verhoy();
  this.yabusco=false;
}


aprobar(visita){
  Swal.fire({
    title:"Por favor ingrese la nota correspondiente a la aprobación",
    input:"text",
    showCancelButton: true,
  confirmButtonText: 'Aprobar',
  showLoaderOnConfirm: true,
  })
  .then((result:any)=>{
    
    
    if(result.value !=null && result.value.length>0 ){
     
      //agregar la nota y enviar aprobacion
      this.authService.aprobar(visita._id,4)
      .then(()=>{
        //agregar nota
        var now= new Date();
        var n = now.getUTCHours();
        if(n<5){
          n=24+n; 
        } 
       now.setHours(n-5);  
        this.notaForm.patchValue({
          time: now,
          id:visita._id,
          question:result.value
        });
      
        this.authService.newnota(this.notaForm.value,this.user)
        .then((response)=>{
          this.getsolicitudeshoy()
        })
      .catch((err)=>{
        
      })
  
      })
    }
   
  })
}

anadirPersona(visita){
  this.anadir=true;
  this.nuser=false;
  this.luser=null;
  this.visitasol=visita;
}
lookPerson(){
  var id=this.idrperson.controls["id"].value;
  if(id){
    this.authService.validatep(id)
    .then((response)=>{
   console.log(response);
   this.luser=response["user"];
   this.nuser=true;
    })
    .catch((err)=>{
      console.log(err);
      Swal.fire("No se encuentra el usuario")
    })

    
  }
}

confirmAddPerson(){
this.authService.addPersonToVisit(this.visitasol,this.luser)
.then((response)=>{
  console.log(response);
  this.anadir=false;
  this.getsolicitudeshoy();
})
}

marcaruno(){
  var uno=this.elRef.nativeElement.querySelector('#marcauno').value;
 // console.log(uno)
 var uno=uno.split("\n")
uno.forEach((el)=>{
  el =el.replace(/[,.-\s]/g, '');
  if (this.visitaForm.controls.personasc.value.indexOf(el)==-1 && el!="" ) this.visitaForm.controls.personasc.value.push(el);
  
})

 //console.log(this.visitaForm.controls.personasc.value);
  this.calcularPersonas();
  this.elRef.nativeElement.querySelector('#marcauno').value="";
  if(this.validcionPersonas){
    this.validarPersonas();
  }

}

calcularPersonas(){
  this.agregados=this.visitaForm.controls.personasc.value
  

}
get f() { return this.visitaForm.controls; }
getempresas(){
  this.authService.getempresas()
  .then((response)=>{
   // console.log(response);
    this.tempresas=response["empresas"];
  })
}
createVisita(){
  this.getEvents();
  this.getempresas();
this.validcionPersonas=false;
this.visitaForm= new FormGroup({
  "personasc": new FormControl([], [
    Validators.required,
   
  ]),
  'eventid': new FormControl("", [
    Validators.required,
   
  ]),
  'eventname': new FormControl("", [
    Validators.required,
   
  ]),
  'tipo': new FormControl('prestacion de servicios', [
    Validators.required,
   
  ]),

  'descripcion': new FormControl('', [
     
  ]),
  'nota': new FormControl('', [
    Validators.required,
  ]),
  'acceso': new FormControl('', [
    Validators.required,   
  ]),
  "area":new FormControl("625d88fb682fa724f21274e6", [
    Validators.required,   
  ]),
 
  'date': new FormControl('', [
    Validators.required,
    Validators.minLength(5),
  ]),
  'personas': new FormControl([], [
     
  ]),
  "empresa": new FormControl('', [
      
     
  ]),
  'dia': new FormControl('', [   ]),
  'mes': new FormControl('', [   ]),
  'ano': new FormControl('', [   ]),

 
})

this.visitapop=true;
this.agregados=[];
}

getEvents(){
  this.eventService.getAllEvents()
  .then((response)=>{
    console.log(response);
    this.eventos=response["results"];
    this.eventos=this.eventos.filter(el=>{return !el.archivado})
  })

}

async newVisita(){
// console.log(this.visitaForm.controls.personas['controls']);
this.submitted=true;
console.log("controls:",this.f)


if(this.agregados.length==0){
Swal.fire("No ha agregado personas a esta visita")
.then(()=>{
  return
 })
}
else{
//var marcas=await this.getSelected(this.visitaForm.controls.personasc['controls'],this.personasc); 
//var datos=await this.getArea(this.visitaForm.controls['eventid'].value); 

console.log("date ",this.visitaForm.controls["date"].value)
var date= new Date(this.visitaForm.controls["date"].value);
//console.log(now.getDate()==date.getDate() && now.getMonth()==date.getMonth());
var dia=date.getDate();
var mes=date.getMonth()+1;
var ano=date.getFullYear();
var ename=await this.getArea(this.visitaForm.controls['eventid'].value); 
 

this.visitaForm.patchValue({
  eventname:ename,
ano:ano,
mes:mes,
dia:dia
 })
// console.log(this.visitaForm.value);
// console.log("resultados: ",this.visitaForm.value);
// return

if(this.visitaForm.invalid){
  console.log("invalido",this.visitaForm.value);
  return
}

this.authService.newvisitaporteria(this.visitaForm.value)
  .then((response)=>{
   this.visitapop=false;
    console.log(response);

    //enviar nota
    var now= new Date();
    var n = now.getUTCHours();
    if(n<5){
      n=24+n; 
    } 
   now.setHours(n-5);  
    this.notaForm.patchValue({
      time: now,
      id:response["visita"]._id,
      question:this.visitaForm.controls["nota"].value
    });
  
    this.authService.newnota(this.notaForm.value,this.user)
    .then((response)=>{
      this.getsolicitudeshoy()
    })
  .catch((err)=>{
    
  })

    //this.loadvisitas();
  })
    .catch((err)=>{
   console.error(err);
  })
}
  

 }

 getArea(event){
  if(event=="general"){
    return "general"
  }
  var select=this.eventos.filter((evento)=>{return evento._id==event})
  //console.log("select: ",personas);

var event=select[0].nombre;

return event
}

 validarPersonas(){
 
   var length=this.visitaForm.controls.personasc.value.length;
   //console.log(this.visitaForm.controls.personasc.value);
   //console.log(this.visitaForm.controls.personas.value);
   var c=0;
   var arr=this.visitaForm.controls.personasc.value;
   const arr_=this.visitaForm.controls.personasc.value;
  this.visitaForm.controls.personasc.value.forEach((element,i) => {     
  
      this.authService.validatep(element)
      .then((response) => {
        c+=1;
        var user=response["user"]; 

        arr[i]={
          "id":element,
          "name":user.name+ " "+user.apellido,
       "ingreso":null,
       "aprobe1":true,
       "aprobe2":true,
       "aprobe3":true,
       
        }
        if(c==length){
          
          this.validcionPersonas=true;
    console.log(arr);
    console.log(arr_);
    this.visitaForm.patchValue({
      personas :arr,
      personasc:arr_
       })
        }
      })
      .catch((err)=>{
        c+=1;
        arr[i]={
          "id":element,
          "name":null,
       "ingreso":null,
       "aprobe1":true,
       "aprobe2":true,
       "aprobe3":true,
       
        }
        if(c==length){
          console.log(arr);
          console.log(arr_);
          this.validcionPersonas=true;
    
    this.visitaForm.patchValue({
      personas :arr,
      personasc:arr_
       })
        }
      })

 
  });
 }

}
