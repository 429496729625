<div class="m-1">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div  class="caja-form2 m-2" *ngFor="let canje of canjes" >  
                Usuario: {{canje.user.name}} {{canje.user.apellido}}
                <br>
                Documento: {{canje.user.id}}
                <br>
                Club: {{canje.club}}
                <br>
                Inicio:  {{canje.inicio| date:"mediumDate"}} 
                <br>
                Fin:  {{canje.fin| date:"mediumDate"}} 
            </div>
        </div>
    </div>


</div>