import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { HomeComponent } from './components/home/home.component';
import { ConferenciaComponent } from './components/conferencia/conferencia.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { SafePipe } from './safe.pipe';
import { ChatComponent } from './components/chat/chat.component';
import { ElemdisplayDirective } from './components/elemdisplay.directive';
//import { PreguntasComponent } from './components/preguntas/preguntas.component';
import { VotacionesComponent } from './components/votaciones/votaciones.component';
import { RegistroPagoComponent } from './components/registro-pago/registro-pago.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { OpenviduSessionModule } from 'openvidu-angular';
import {MatTabsModule} from '@angular/material/tabs';
import 'hammerjs';
import { TallerComponent } from './components/taller/taller.component';
import { MuestraComponent } from './components/muestra/muestra.component';
import { StandComponent } from './components/stand/stand.component';
import { LandingComponent } from './components/landing/landing.component';

import { CuentaComponent } from './components/cuenta/cuenta.component';

import { ConferencistasComponent } from './components/conferencistas/conferencistas.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
//import { MonitorComponent } from './components/monitor/monitor.component';
import { AgendaComponent } from './components/agenda/agenda.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MemoriasComponent } from './components/memorias/memorias.component';
//import { MiagendaComponent } from './components/miagenda/miagenda.component';
import { VizualizadorComponent } from './components/vizualizador/vizualizador.component';
import { BitacoraComponent } from './components/bitacora/bitacora.component';
import { ChatPublicoComponent } from './components/chat-publico/chat-publico.component';
import { NotasComponent } from './components/notas/notas.component';
import { SalanetComponent } from './components/salanet/salanet.component';
import { SalaComponent } from './components/sala/sala.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { AprobacionComponent } from './components/aprobacion/aprobacion.component';
import { PorteriaComponent } from './components/porteria/porteria.component';
import { VisitaComponent } from './components/visita/visita.component';
import { IngresoComponent } from './components/ingreso/ingreso.component';
import { PanelComponent } from './components/panel/panel.component';
import { PersonaComponent } from './components/persona/persona.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { PanelempresaComponent } from './components/panelempresa/panelempresa.component';
import { ValidacionComponent } from './components/validacion/validacion.component';
import { AdministracionComponent } from './components/administracion/administracion.component';
import { AreasComponent } from './components/areas/areas.component';
import { EventoComponent } from './components/evento/evento.component';
import { AreaComponent } from './components/area/area.component';
import { Aprobacion2Component } from './components/aprobacion2/aprobacion2.component';
import { Aprobacion3Component } from './components/aprobacion3/aprobacion3.component';
import { Aprobar2Component } from './components/aprobar2/aprobar2.component';
import { Aprobar3Component } from './components/aprobar3/aprobar3.component';
import { UseruploaderComponent } from './components/useruploader/useruploader.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { Aprobar4Component } from './components/aprobar4/aprobar4.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { SalidaComponent } from './components/salida/salida.component';
import { MensajesComponent } from './components/mensajes/mensajes.component';
import { AddmanillaComponent } from './components/addmanilla/addmanilla.component';
import { ManillaLectorComponent } from './components/manilla-lector/manilla-lector.component';
import { IngresosempresaComponent } from './components/ingresosempresa/ingresosempresa.component';
import { RecuperarClaveComponent } from './components/recuperar-clave/recuperar-clave.component';
import { OlvidoComponent } from './components/olvido/olvido.component';
import { ResumeneventoComponent } from './components/resumenevento/resumenevento.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { PerfilempresaComponent } from './components/perfilempresa/perfilempresa.component';
import { JwtModule } from '@auth0/angular-jwt';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ManillasComponent } from './components/manillas/manillas.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from '@angular/material/core';
import { UseruploaderCopiarComponent } from './components/useruploader-copiar/useruploader-copiar.component';
import { ReportesIngresoComponent } from './components/reportes-ingreso/reportes-ingreso.component';
import { Reportes2Component } from './components/reportes2/reportes2.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { RegisterBeneficiarioComponent } from './components/register-beneficiario/register-beneficiario.component'; 

import {DatePipe} from '@angular/common';
import { ReservasComponent } from './components/reservas/reservas.component';
import { ReservaGolfComponent } from './components/reserva-golf/reserva-golf.component';
import { LectorReservasComponent } from './components/lector-reservas/lector-reservas.component';
import { AccesoComponent } from './components/acceso/acceso.component';
import { LectorComponent } from './components/lector/lector.component';
import { ReservaTennisComponent } from './components/reserva-tennis/reserva-tennis.component';
import { VinculacionComponent } from './components/vinculacion/vinculacion.component';
import { ReservaBolosComponent } from './components/reserva-bolos/reserva-bolos.component';
import { ReservaSkyComponent } from './components/reserva-sky/reserva-sky.component';
import { RegisterCanjesComponent } from './components/register-canjes/register-canjes.component';
import { ReservarComponent } from './components/reservar/reservar.component';
import { RegisterNineraComponent } from './components/register-ninera/register-ninera.component';
import { ReservaAgendaComponent } from './components/reserva-agenda/reserva-agenda.component';
import { RegistroSelectComponent } from './components/registro-select/registro-select.component';
import { RegisterBeneficiarioLinkComponent } from './components/register-beneficiario-link/register-beneficiario-link.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SolcitudCanjeComponent } from './components/solcitud-canje/solcitud-canje.component';
import { PqrComponent } from './components/pqr/pqr.component';
import { ReservaVinculacionComponent } from './components/reserva-vinculacion/reserva-vinculacion.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
export function tokenGetter() {
  return localStorage.getItem('access_token');
} 

@NgModule({
  declarations: [
    LectorReservasComponent,
    ReservasComponent,
    ReservaGolfComponent,
    AppComponent,
   // HomeComponent,
    ConferenciaComponent,
    LoginComponent,
    RegisterComponent,
    SafePipe,
    ChatComponent,
    ElemdisplayDirective,
    //PreguntasComponent,
    VotacionesComponent,
    RegistroPagoComponent,
    TallerComponent,
    MuestraComponent,
    StandComponent,
    LandingComponent,

    CuentaComponent,

    ConferencistasComponent,

    UsuariosComponent,

//    MonitorComponent,

    AgendaComponent,

    MemoriasComponent,

    //MiagendaComponent,

    VizualizadorComponent,

    BitacoraComponent,

    ChatPublicoComponent,

    NotasComponent,

    SalanetComponent,

    SalaComponent,
      AprobacionComponent,
      PorteriaComponent,
      VisitaComponent,
      IngresoComponent,
      PanelComponent,
      PersonaComponent,
      EmpresaComponent,
      PanelempresaComponent,
      ValidacionComponent,
      AdministracionComponent,
      AreasComponent,
      EventoComponent,
      AreaComponent,
      Aprobacion2Component,
      Aprobacion3Component,
      Aprobar2Component,
      Aprobar3Component,
      UseruploaderComponent,
      Aprobar4Component,
      TutorialComponent,
      SalidaComponent,
      MensajesComponent,
      AddmanillaComponent,
      ManillaLectorComponent,
      IngresosempresaComponent,
      RecuperarClaveComponent,
      OlvidoComponent,
      ResumeneventoComponent,
      PerfilComponent,
      PerfilempresaComponent,
      ManillasComponent,
      UseruploaderCopiarComponent,
      ReportesIngresoComponent,
      Reportes2Component,
      RegisterBeneficiarioComponent,
      LectorReservasComponent,
      AccesoComponent,
      LectorComponent,
      ReservaTennisComponent,
      VinculacionComponent,
      ReservaBolosComponent,
      ReservaSkyComponent,
      RegisterCanjesComponent,
      ReservarComponent,
      RegisterNineraComponent,
      ReservaAgendaComponent,
      RegistroSelectComponent,
      RegisterBeneficiarioLinkComponent,
      SolcitudCanjeComponent,
      PqrComponent,
      ReservaVinculacionComponent,
  

  ],
  imports: [
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ScrollingModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    RecaptchaV3Module,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    GoogleChartsModule,
    OpenviduSessionModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
    MatTabsModule,
    NgxQRCodeModule,
    NgxCsvParserModule,
    ZXingScannerModule,
    MatCheckboxModule,
    NgxPaginationModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["selesoluciona.com:8223","selesoluciona.com"],
        disallowedRoutes: [],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [DatePipe, LectorReservasComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
