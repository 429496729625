
<div class="row" style="padding: 30px;">
    <div class="col-md-12" style="text-align: center;">
        <select #listcameras (change)="selectedCamera(listcameras.value)">
            <option value="">-</option>
            <option *ngFor="let camera of cameras" value="{{camera.id}}">{{camera.label}}</option>
        </select>
        <br><br>
        <video  playsinline  #video1  width="80%" height="auto" ></video>
    <br><br>
    <input (change)="getcedula($event)" type="number" placeholder="cédula" class="form-control" />
    <br>
    <button (click)="validarcedula()" class="btn btn-primary">Enviar</button>
    </div>
    
</div>




<div *ngIf="infouserpop" style="z-index: 999999999999; position:absolute; width: 100%; height: 100%; top:0px; left: 0px; background-color: red;">
<div style="float: right;" (click)="infouserpop = false">X</div>
<div style="text-align: center;">
    <br><br><br>
    <h2>{{infouser.name}} {{infouser.apellido}}</h2>
    <br>
    {{infouser.categoria}}
    <br>
    {{infouser.correo}}
    <br><br>
    <label>Comentario:</label>
    <div class="d-flex justify-content-center">
        <input (keyup)="onComentario($event)" type="text" id="comentario" class="form-control" style="width: 60%;" />
    </div>
    
    <br><br>
    <button class="btn btn-primary" (click)="ingreso()" >Validar</button>
</div>
</div>