import { Component, OnInit } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import {  ViewChild } from '@angular/core';
@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {
  finvideo = false;
  images = [1,2,3,4,5,6].map((n) => `/assets/carrusel/${n}.png`);
  
paused = false;
unpauseOnArrow = false;
pauseOnIndicator = false;
pauseOnHover = true; 
user;
@ViewChild('carousel', {static : true}) carousel: NgbCarousel;
  constructor() { }

  
  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(this.user);
  }


sub(){
  console.log("enviado");
}

  ocultar_video(){
    document.getElementById("caja_video").style.display = "none";
    document.getElementById("caja_bienvenidos").style.display = "block";
    
  } 
}