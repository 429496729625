<div class="lienzo">
  <br>
  <div class="contenido_centrado_hv">
    <div class="row w-100 contenido_centrado_hv">
      <br><br>
      <div class="col-12 col-md-11">
        <div style="box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.42);">


          <mat-tab-group [selectedIndex]="0" color="warn" backgroundColor="warn" style="border-radius: 10px;">




            <mat-tab label="Bienvenidos">

              <div style="position: relative;">





                <div id="caja_bienvenidos">
                  <div class="row bienv">
                    <div class="col-md-5" style="text-align: center;">
                      <img src="../../../assets/logo_claro.png" style="margin: auto;" width="70%" alt="">

                    </div>
                    <div class="col-md-7 justify-content-center mt-5" style="text-align: center;">



                      <a class="m-1" href="/salas">
                        <button class="btn m-1  mr-5 btn-orange btn-p" style="background-color:rgb(244,67,54);">Sala
                          Networking </button> </a>


                      <a class="m-1" href="https://encuentrojca2020.congresovirtual.com.co/salon" target="_blank"
                        rel="noopener noreferrer">
                        <button class="btn m-1 mr-5 btn-orange btn-p " style="background-color:rgb(244,67,54);">Muestra
                          comercial</button> </a>

                      <a class="m-1" href="https://enelxdigitalday.eventovirtual.info/tienda" target="_blank"
                        rel="noopener noreferrer">
                        <button class="btn m-1 mr-5 btn-orange btn-p " style="background-color:rgb(244,67,54);">Tienda
                          virtual</button> </a>

                      <a class="m-1" href="https://enelxdigitalday.eventovirtual.info/var" target="_blank"
                        rel="noopener noreferrer">
                        <button class="btn m-1 mr-5 btn-orange btn-p " style="background-color:rgb(244,67,54);">Cabina
                          fotografica</button> </a>


                      <br><br><br><br>
                      <div class="row justify-content-start">
                        <div>
                          <h2
                            style="color:white;font-family: clanot_book !important;    font-size: 35px !important;  font-weight: bold">
                            Faltan:</h2>
                        </div>

                      </div>

                      <div style="color: white; " class="row justify-content-center">



                        <div class="col-2 caja-conta">
                          <div style="position: relative; ">
                            <div class="numero_contador" id="dias"></div>
                            <div class="texto_contador">Días</div>

                          </div>

                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div class="col-2  caja-conta">
                          <div style="position: relative; ">
                            <div class="numero_contador" id="horas"></div>
                            <div class="texto_contador">Horas</div>
                          </div>

                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div class="col-2 caja-conta ">
                          <div style="position: relative; ">
                            <div class="numero_contador" id="minutos"></div>
                            <div class="texto_contador">Minutos</div>
                          </div>

                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div class="col-2  caja-conta">
                          <div style="position: relative; ">
                            <div class="numero_contador" id="segundos"></div>
                            <div class="texto_contador">Segundos</div>
                          </div>

                        </div>


                      </div>


                    </div>
                  </div>

                </div>






              </div>



            </mat-tab>




            <mat-tab label="Agenda">


              <div class="caja_agenda" style="text-align: center;">
                <img width="80%" style="margin: auto;" src="../../../assets/agn.jpg" alt="">
              </div>

            </mat-tab>

            <mat-tab label="Invitados">


              <div class="caja_invitado justify-content-center contenido_centrado_hv">
                <div class="row contenido_centrado_hv">


                  <ngb-carousel #carousel [interval]="1500" [pauseOnHover]="pauseOnHover" class="col-md-5 col-12"
                    (slide)="onSlide($event)">
                    <ng-template ngbSlide *ngFor="let img of images; index as i">

                      <div class="picsum-img-wrapper justify-content-center" style="text-align: center;">
                        <img [src]="img" style="max-height: 95%; max-width: 80%; margin:0 auto; display: block;">
                      </div>

                    </ng-template>
                  </ngb-carousel>
                </div>
              </div>