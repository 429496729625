import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import {SocketioService} from "../../services/socketio.service";
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import  Swal from 'sweetalert2';  

import {OpenviduSessionComponent, StreamEvent, Session, UserModel, OpenViduLayout, OvSettings, OpenViduLayoutOptions, SessionDisconnectedEvent, Publisher} from 'openvidu-angular';

import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-aprobar3',
  templateUrl: './aprobar3.component.html',
  styleUrls: ['./aprobar3.component.css']
})
export class Aprobar3Component implements OnInit {

 
  vmensaje=false;
visita;
personasc=[];
empresa: any;
porteria;
getacceso;
id;
user;
mensajeForm;
  submitted: boolean;
  constructor(
    private authService : AuthService, 
    private eventService : EventsService, 
    private route: ActivatedRoute, private router : Router,
    private socketservice: SocketioService,
    private renderer: Renderer2,
    private elRef:ElementRef,
    private httpClient: HttpClient

  ) { }
 
  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(this.user);
    this.id=this.route.snapshot.paramMap.get("id");
    this.getSolicitud(this.id);
    this.personasc=[];
    this.mensajeForm=new FormGroup({
      'time': new FormControl('', ),    
      'from': new FormControl('', ),     
      'to': new FormControl('', ), 
      'txt': new FormControl('',[    Validators.required,] ),        
      'visita': new FormControl('', ),    
    })
  }

  get f2() { return this.mensajeForm.controls; }

newMensaje(){
  this.submitted = true;
  console.log(this.mensajeForm.controls);
  if (this.mensajeForm.invalid) {
    console.log("no enviado ");
    return;
}
  var now= new Date();
  var n = now.getUTCHours();
  if(n<5){
    n=24+n; 
  } 
  var to; //leer responsable
  if(this.visita.empresa!="independiente"){
    to=this.empresa.nit;
  }
  else{
    this.visita.personas[0]
  }
 now.setHours(n-5);  
  this.mensajeForm.patchValue({
    time: now,
    from:this.user.id,
to:to,
visita:this.id
  });

this.authService.newMesnaje(this.mensajeForm.value)
.then((response)=>{
Swal.fire("Mensaje enviado")
})
.catch((err)=>{
  console.log(err);
})

}

getSolicitud(id){
  this.eventService.getsolicitud(id)
  .then((response) => {
    console.log(response);
  this.visita=response["visita"]
  var date= new Date(this.visita.fecha);
  var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  this.visita.fechat=fecha;
  if(this.visita.acceso!=null){
    this.getacceso=true
 
  }
  else{
    this.getacceso=false
  }
  this.loadpersonas();
  this.loadempresa();


  })
  .catch((err)=>{
    console.log(err);
  })

}
loadpersonas(){
  this.personasc=[]
  this.visita.personas.forEach(id => {
    console.log(id);
    
    this.authService.validatep(id.id)
    .then((response)=>{
      if(id.ingreso){
        var date= new Date(id.ingreso.hora);
        var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        response["user"].ingreso=fecha;
       // console.log(response["user"])
      }
      var person=response["user"];
      person.aprobe1=id.aprobe1;
      person.aprobe2=id.aprobe2;
      person.aprobe3=id.aprobe3;
      
  this.personasc.push(person);
    })
    .catch((err)=>{
  
    })
    });
  
  }
  loadempresa(){
    if(this.visita.empresa){
    if(this.visita.empresa!="independiente"){
  this.authService.getempresa(this.visita.empresa)
  .then((response)=>{
    console.log(response);
    this.empresa=response["empresa"];
  })
  
     } }
  }
aprobar(){
  this.authService.aprobar(this.visita._id,3)
  .then(()=>{
    this.ngOnInit();
  })
}

aprobarpersona(id){
  this.authService.aprobarpersona(this.visita._id,id,3)
  .then(()=>{
    this.ngOnInit();
  })}

  setAcceso(id){
   var acceso= this.elRef.nativeElement.querySelector('#sacceso').value;
   if(acceso!=""){
     this.authService.setacceso(id,acceso)
     .then(()=>{
       this.getSolicitud(this.id);
     })
   }
   else{
     Swal.fire("Por favor seleccione una opción")}
  }

}

