import { Component, OnInit,ViewChild , ElementRef, Renderer2,Input,Output,EventEmitter } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 

@Component({
  selector: 'app-register-beneficiario',
  templateUrl: './register-beneficiario.component.html',
  styleUrls: ['./register-beneficiario.component.css']
})
export class RegisterBeneficiarioComponent implements OnInit {
  
  fotop;
  @Input() socio;
  @Output() done = new EventEmitter<boolean>();
  submitted = false;
  registerForm: FormGroup;
  foto1Url="nofoto";
  fotovalidate: boolean;

@ViewChild('video', { static: false }) videoElement: ElementRef;
@ViewChild('canvas', { static: false }) canvas: ElementRef;
  stream: any;
  
  camera = 0;
  cameras:any;
  videoWidth = 0;
  videoHeight = 0;
  loading: boolean = false;
  constructor(
    private authService : AuthService,
     private eventService : EventsService,
      private router: Router,
       private cd: ChangeDetectorRef,
       private renderer: Renderer2,
       private elRef:ElementRef, 

  ) { }

  ngOnInit(): void {
   
    this.registerForm = new FormGroup({
      'name': new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      'apellido': new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      'correo': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'tipoid': new FormControl('', [
        Validators.required,
     
      ]),
      'nacimiento': new FormControl('', [
        Validators.required,     
      ]),
      'sexo': new FormControl('', [
        Validators.required,     
      ]),
      'id': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
      'empresa': new FormControl('', [
        
  
      ]),
      'cargo': new FormControl('', [
      
  
      ]),

      'telefono': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
       'arl': new FormControl('', [
     
  
      ]),
      'eps': new FormControl('', [
  
  
      ]),
   
      'rol': new FormControl('usuario', [
        Validators.required,
    
      ]),
      'tipo': new FormControl('persona', [
        Validators.required,
    
      ]),
    
 
      'socio': new FormControl(this.socio, [
        Validators.required,
    
      ]),
   
      'categoria': new FormControl('beneficiario', [
        Validators.required,
    
      ]),
      'aprobe': new FormControl(false, [
        Validators.required,
    
      ]),
    
    });

    this.cameras=[];
    this.getcameras();
   
  }

  getcameras(){
    console.log("leyendo camaras");
    navigator.mediaDevices.enumerateDevices().then(this.gotDevices.bind(this)).catch(this.handleError);
    this.activeVideo();
  }
  gotDevices(deviceInfos) {
    console.log("ordenando camaras");
        for (let i = 0; i !== deviceInfos.length; ++i) {
          var name="";
     
          var  deviceInfo = deviceInfos[i];
          if (deviceInfo.kind == 'videoinput') {
            console.log(deviceInfo);
            name = deviceInfo.deviceId;
            this.cameras.push(name);
            console.log("cameras",this.cameras)
           
          } else {
            console.log('Some other kind of source/device ');
          }
        }
        
        }
  rotar(){
    var video = this.elRef.nativeElement.querySelector('#video');
    video.pause();
    if(this.camera<this.cameras.length-1){
        this.camera=this.camera+1;
        this.activeVideo();
    }
    else{
        this.camera=0;
       
        this.activeVideo();
    }
    console.log("camera",this.camera)
    
}

/* tomar(){
  this.loading = true;
  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
  this.authService.sendfoto(data)
  .then((response)=>{
    console.log(response["url"]);
    this.foto1Url=response["url"];
    this.loading=false;
    this.fotop=true;
  })
  .catch((err)=>{
alert("no encontramos tu rostro en la foto");
this.loading=false;

  })

} */
  get f() { return this.registerForm.controls; }
  register() {

    // console.log(this.registerForm.value)
 
      this.submitted = true;
      this.registerForm.patchValue({
        rol:"usuario",
        tipo:"persona",
        categoria:"beneficiario",
        aprobe:false,
        socio:this.socio
      })
 
     console.log(this.registerForm.value)
    
     if (this.registerForm.invalid) {
       return;
     }
     else{
     
        this.authService.createPerson(this.registerForm.value,this.foto1Url)
        .then((r)=>{
          console.log(r);
          Swal.fire("Registro exitoso ")
          this.done.emit(true)
        })
        .catch((err)=>{
          Swal.fire(     {
            title: "El registro no fue exitoso",
            text:"Por favor verifica tus datos, si ya te encuentras registrado pulsa el boton ingresar. Recomendamos el uso del navegador web Chrome. Si estás conectado por VPN por favor desconéctate y vuelve a intentarlo.",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ingresar ',
            cancelButtonText: "Reintentar"
            
        }).then((result)=>{
        if(result.value){
        
          this.done.emit(false)
        }
        else{
          //this.router.navigate(['']); 
        }
        });
        })
   
      
     }
 
    
   }
 


   
//control camara
handleError(error) {
  console.log('Error: ', error);
}

attachVideo(stream) {
  this.stream=stream;
  this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
  this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
    this.videoHeight = this.videoElement.nativeElement.videoHeight;
    this.videoWidth = this.videoElement.nativeElement.videoWidth;
});

}

activeVideo(){
     
      var  constraints = {
        video: {
            
            width: { ideal: 1280 },
            height: { ideal: 720 }
        }
    };
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
      navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
         } else {
             alert('Sorry, camera not available.');
         }



}

tomar(){
  this.loading = true;
  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
  this.authService.sendfoto(data)
  .then((response)=>{
    console.log(response["url"]);
    this.foto1Url=response["url"];
    this.loading=false;
    this.fotop=true;
  })
  .catch((err)=>{
alert("no encontramos tu rostro en la foto");
this.loading=false;

  })

}




}
