<div class="row justify-content-center mt-4" >
<div class="col-md-6 " *ngIf="visita" style="text-align: center; color: white;" >
<h1  style="color: white;" >Solicitud de visita</h1>

<button (click)="aprobar()" class="btn btn-primary" >Aprobar para todos</button>
<div class="card cajainfo" style="background-color: transparent;font-family:Arial, Helvetica, sans-serif;" >
    <div class="card-body" style=" padding: 0px; height:auto; " >
        <div style=" width: 100%; padding: 10px; overflow: hidden;">
       
         <span class="text" > Fecha: {{visita.fechat}}</span>
 <br>
 <span class="text" > Descripción: {{visita.descripcion}}</span>
 <br>

         <!-- <h4  *ngIf="visita.aprobe=='true'" >Aprobado</h4>
         <button  *ngIf="visita.aprobe!='true'"  class="btn-primary"  (click)="aprobar()" >  Aprobar visita</button>
        -->
     </div>
        
     <h2 style="color: black;"   >Número de personas: {{personasc.length}} </h2>
     <div class="row " >
       <div class="col-md-12" *ngFor="let persona of personasc">
         
         <span *ngIf="persona.aprobe1" > Aprobado  </span> <br>
         <span *ngIf="!persona.aprobe1" > Sin aprobación  <button class="btn btn-primary" (click)="aprobarpersona(persona.id)" >Aprobar</button>  </span>
         <app-persona [user]="persona" [show]="true" ></app-persona>
       
       </div> 
     </div>
 
   <div  *ngIf="empresa" >  
 {{empresa.name}}
 <span class="text" > NIT: {{empresa.nit}}</span> 
 
 <br>
 
 <span class="text" *ngIf="visita.placa" > Vehiculo: {{visita.placa}}</span> 
 <br>
 <h2>Responsable:</h2>
 <span class="text"> {{empresa.responsable.name}}</span>
 <br>
 <span class="text"> {{empresa.responsable.correo}}</span>
 <br>
 <span class="text"> {{empresa.responsable.telefono}}</span>
 
 
 <h2   >Documentos: </h2>
 <div *ngFor="let link of empresa.documentos">
     <a   href="{{link}}" target="_blank" rel="noopener noreferrer">
     <iframe  [src]="link | safe"  width="90%" height="200px" frameborder="0"></iframe> {{link}}</a>
     
 </div>
 
 
   </div>
   
     </div>
     </div>
     




<!-- <app-visita   [visita]="visita" ></app-visita> -->

<!-- <div *ngIf="visita.aprobe==true" >
<h3>Visita aprobada</h3>
</div> -->
<!-- <div *ngIf="visita.aprobe!=true" >
   <button class="btn btn-primary" (click)="aprobar()"  >Aprobar visita</button>
    </div> -->
 
</div>



</div>