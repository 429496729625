import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {EventsService} from "../../services/events.service";
import {SocketioService} from "../../services/socketio.service";
import Swal from 'sweetalert2';
import { first } from 'rxjs/operators';
import { log } from 'console';

@Component({
  selector: 'app-acceso',
  templateUrl: './acceso.component.html',
  styleUrls: ['./acceso.component.css']
})
export class AccesoComponent implements OnInit {
  vhoy: boolean = false;
  empresas;
  hoy;
  newNota: boolean;
  notaForm: any;
  proximas=[];

  vproximas: boolean = false;
  ingresoForm: FormGroup;
  personAgregar: any;
submitted;
datos = [];
invitados=[];
dato=false;
pop;
ver="ingresos";
  suser: any;
  sub!: Subscription;
  ingresos: any;
  registerForm: FormGroup;
  visita: any;
  usertoAddGuest: any;
  beneficiarios: any;
  newRegister=false;
  guest: any;
  user;
  showGuest: boolean;
  constructor(private usersService: AuthService,
    private cd :ChangeDetectorRef,
    private EventService:EventsService,
    private socketservice: SocketioService,
    ) { }
    ngOnDestroy() {
      this.socketservice.close();
      }
  ngOnInit(): void {

    const manage=this.manage;
    this.sub= this.socketservice.setupSocketConnection()
   .subscribe({
    next(_mesage:any) {
      console.log('Mensage recibido: ', _mesage);
     manage(_mesage);  
    }
   });
   
  
  
this.loaduser() 
    this.ingresoForm = new FormGroup({
   
      'doc': new FormControl('', [
        Validators.required,

      ]),

        });

        this.registerForm = new FormGroup({
          'name': new FormControl('', [
            Validators.required,
            Validators.minLength(3)
          ]),
         
          'correo': new FormControl('', [
            Validators.required,
            Validators.email
          ]),
          'id': new FormControl("", [
            Validators.required,
          ]),
          'empresa': new FormControl("independiente", [
            Validators.required,
        
          ]),
        
          'rol': new FormControl('usuario', [
            Validators.required,
          ]),
       
          'categoria': new FormControl('invitado', [
            Validators.required,
        
          ]),
          'aprobe': new FormControl(false, [
            Validators.required,
        
          ]),
    
           
        });

  }

  getBenefits() {
    this.usersService.getBenefits(this.ingresos._id)
      .then((r) => {
        this.beneficiarios = r;
        console.log(this.beneficiarios)
      })
  }

  dataAgregar(data:any){
    this.datos.push(data);
    this.personAgregar = this.datos;
    console.log(this.datos);
    
   }

   verhoy(){
    this.getsolicitudeshoy();
    this.vhoy=true
    this.vproximas=false;

  }

  getsolicitudeshoy(){
    var date=new Date();
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    this.usersService.getvisitashoyaprobar(dia,mes,ano)
    .then((response)=>{
  console.log(response);
  
      
    this.hoy=response["visitas"];
    this.hoy.sort( this.compare );
    this.cargarhoras(this.hoy);
    })
  }
newnota(id){
    this.newNota=true;
    this.notaForm.patchValue({
    id:id  
    });
  
  }

  verproxi(){
    this.getsolicitudesproxima();
    this.vproximas=true;
    this.vhoy=false;
   
  }
  getsolicitudesproxima(){
    var date=new Date();
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    
    this.usersService.getvisitasproxima(dia,mes,ano)
    .then((response)=>{
  console.log(response);
     
    this.proximas =response["visitas"];
    this.proximas.sort( this.compare );
    this.cargarhoras(this.proximas);
    })
  }
  eliminar(id){
    console.log(id);
    this.usersService.eliminarVisita(id)
    .then((response) => {
     this.verhoy()
  })
  }  
compare( a, b ) {
  if ( a.fecha < b.fecha ){
    
    return -1;
  }
  if ( a.fecha > b.fecha ){
    return 1;
  }
  return 0;
}

  cargarhoras(arr){
    this.empresas=[];
    var empresas=[];
    arr.forEach(solicitud => {
    //  console.log("visita: ",solicitud);
      var date= new Date(solicitud.fecha);
       var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
       solicitud.fechat=fecha;
      solicitud.open=false; 
      var aprobada=0; 
      var ingresosp=solicitud.ingresos.map((el)=>{return el.persona})
   solicitud.personasObj.forEach(p => {
    if(ingresosp.includes(p._id)){
      p.ingreso=solicitud.ingresos[ingresosp.indexOf(p._id)]

    }
   });
     
    
  solicitud.aprobada=aprobada;
    }); 
  }
  loaduser(){
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    this.usersService.validatep(this.user.id)
    .then((response) => {
      console.log(response);

      this.usersService.setUserInfo({'user' : response['user']});
    
      let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    if(this.user.rol=="admin" || this.user.rol=="porteria"){
      this.verIngresos();
      this.joinRoom(this.user,"1");
     }
  else{
    location.href="/login"
  }


    })
    .catch((err)=>{
      location.href="/login"
    })
    
  
     
         
  }
  

  joinRoom(usr:any,event:any){
    console.log("joinning room")
  this.socketservice.joinRoom(usr,"1");
  }

  manage=(msg:any)=>{
       
      var p_msg=JSON.parse(msg);
      console.log(p_msg);
    switch (p_msg.id){
            case "newIngreso":  
        this.loadIngresos();
        break;
        case "reconnect":
          this.joinRoom(this.user,1);
          break;
   
    
    }
    
    
      
    
    }
    newIngre(){
      this.ver='lector';
      this.vhoy=false; 
    this.vproximas= false
    }
    salidaIn(){
      this.ver='salidas';
      this.vhoy=false; 
    this.vproximas= false
    }

  verIngresos(){
    this.vhoy=false; 
    this.vproximas= false
    this.invitados=[];
    this.ver="ingresos";
    this.loadIngresos();

   // this.verifyVisita();
  }

loadIngresos(){
  function compare( a, b ) {
    var datea=Date.parse(a.fecha);
    var dateb= Date.parse(b.fecha);
    
    // console.log("fecha a",);
    // console.log("fecha b",dateb);
    if ( datea > dateb ){      
      return 1;   }
    if ( datea < dateb ){
            return -1;  }
    return 0; 
  }

    this.EventService.getIngresos()
    .then((r)=>{
      console.log("ingreso",r)
      this.ingresos=r["results"]
      this.ingresos.sort(compare);
      this.horaingreso(this.ingresos);
    })

}

newCodeSalida(e){
  if(this.dato==false){
    console.log(e)
    this.dato=true;
    this.cd.detectChanges();
    this.loadUserById(e);
  }
}

get f2() { return this.registerForm.controls; }
addGuest(userid){
  this.showGuest=false;
  this.guest=null;
  this.ingresoForm.reset()
  this.pop=true;
  this.usertoAddGuest=userid;
  this.usersService.getBenefits(this.usertoAddGuest)
  .then((r)=>{
    this.beneficiarios=r;
    this.beneficiarios=this.beneficiarios.filter((el)=>{return el.aprobe})
    console.log(this.beneficiarios,this.usertoAddGuest)
  })

}

filterNoAcompanante(){
  return this.ingresos.filter((el)=>{return !el.acompanante})
}
filterNoAcompananteFun(){
  return this.ingresos.filter((el)=>{return !el.acompanante && el.user.categoria == "funcionario"})
}
filterNoAcompananteSocio(){
  return this.ingresos.filter((el)=>{return !el.acompanante && el.user.categoria== "socio"})
}
filterAcompanante(id){
  return this.ingresos.filter((el)=>{return el.acompanante && el.responsable==id})
}
empleados() {

  return this.ingresos.filter((item) => {
    return item.user.categoria == "Invitado";
  });
}
agregar(){
  var acompanante=false
  var responsable=null;
  console.log(this.personAgregar);
  
  if(this.personAgregar.categoria!= "socio" && this.personAgregar.categoria!= "beneficiario"){
    acompanante=true;
    responsable=this.usertoAddGuest
  }
  this.personAgregar.map((item:any)=>{
    this.ingresoSocio(item,acompanante,responsable);
  })
   
  this.pop=false;
}

agregar2(){
  var acompanante=false
  var responsable=null;

  
  if(this.guest.categoria!= "socio" && this.guest.categoria!= "beneficiario"){
    acompanante=true;
    responsable=this.usertoAddGuest
  }
  
    this.ingresoSocio(this.guest,acompanante,responsable);

   
  this.pop=false;
}
register(){
  
  this.submitted = true;
  this.registerForm.patchValue({
    rol:"usuario",
    tipo:"persona",
    categoria:"invitado",
    aprobe:true,
    empresa:"independiente"
 
  })
  console.log(this.registerForm.value)
  if (this.registerForm.invalid) {
    return;
  }
  else{
  
     this.usersService.createPerson(this.registerForm.value,"nofoto")
     .then((res)=>{
      console.log(res);
      Swal.fire("Registro exitoso ")
     this.pop=false;
    // this.invitados.push(res["user"])
      this.ingresoSocio(res["user"],true,this.usertoAddGuest)
    })
    .catch((err)=>{
      Swal.fire(     {
        title: "El registro no fue exitoso",
        text:"Por favor verifica tus datos, si ya te encuentras registrado pulsa el boton ingresar. Recomendamos el uso del navegador web Chrome. Si estás conectado por VPN por favor desconéctate y vuelve a intentarlo.",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ingresar ',
        cancelButtonText: "Reintentar"
        
    }).then((result)=>{
    if(result.value){
    
     this.pop=false;
    }
    else{
      //this.router.navigate(['']); 
    }
    });
     })

  }


}


newSRespon(){
  if(this.ingresoForm.invalid){
    return
  }

  this.loadUserByDoc(this.ingresoForm.controls["doc"].value)


}



listaSalida=[];

newSalida(){
  if(this.ingresoForm.invalid){
    return
  }

  this.loadUserByDoc(this.ingresoForm.controls["doc"].value)
  this.ingresoForm.reset();

}


  newCode(e){

    if(this.dato==false){
      console.log(e)
      this.dato=true;
      this.cd.detectChanges();
      this.loadUserById(e);
    }
  

  }

  findPerson(){
    if(this.ingresoForm.invalid){
      return
    }
    this.usersService.validatep(this.ingresoForm.controls["doc"].value)
    .then((r:any)=>{
      console.log("invi",r)
      this.showGuest=true;
      this.guest=r.user
    })
    .catch((err)=>{
      console.log("invi",err)
      this.newRegister=true;
      this.registerForm.patchValue({id:this.ingresoForm.controls["doc"].value})
      this.ingresoForm.reset();
    })
  }
  newIngreso(){
    if(this.ingresoForm.invalid){
      return
    }
    this.loadUserByDoc(this.ingresoForm.controls["doc"].value)
  }
  loadUserById(id){
    this.usersService.loadUserById(id)
    .then((r)=>{
      this.dato=true;
      console.log("user",r);
      this.suser=r
      this.cd.detectChanges();
      if(this.ver=='lector' && this.suser.categoria=="invitado"){
        this.verifyVisita();
      }
      if(this.ver=='lector' &&  this.suser.aprobe && (  this.suser.categoria=="canje" ) ){
        const d = new Date();
      
        let time = d.getTime();
        if(this.suser.habilitado.fin>time){
          this.suser.canjeHabil=true;
      
        }
        else{
          this.suser.canjeHabil=false;
          Swal.fire("El usuario por canje no esta habilidato para el dia de hoy ")
        }
      
      
      }

    })
  }
  dataAgregarSalida(user: any) {
    if (!user.selected) {
      user.selected = true;
      this.listaSalida.push(user);
      console.log(this.listaSalida);
    } else {
      user.selected = false;
      this.listaSalida = this.listaSalida.filter(item => item !== user);
      console.log(this.listaSalida);
    }
  }
  
  acompanante:any;
  loadUserByDoc(id){
    this.usersService.validatep(id)
    .then((r:any)=>{
      this.dato=true;
      console.log("user",r);
      this.acompanante = this.filterAcompanante(r.user._id);
    
      
      if (!this.listaSalida.some(item => item.id === r.user.id)) {
        this.listaSalida.push(r.user);
        console.log("lista de usuarioscerrar",this.listaSalida);
        
        this.suser=r.user
        this.cd.detectChanges();
        console.log(this.ver,this.suser);
        
        if(this.ver=='lector' && this.suser.categoria=="invitado"){
          this.verifyVisita();
        }
  if(this.ver=='lector' &&  this.suser.aprobe && (  this.suser.categoria=="canje" ) ){
    const d = new Date();
  
    let time = d.getTime();
    if(this.suser.habilitado.fin>time){
      this.suser.canjeHabil=true;
  
    }
    else{
      this.suser.canjeHabil=false;
      Swal.fire("El usuario por canje no esta habilidato para el dia de hoy ")
    }
  
  
  }
      } else {
        Swal.fire("Ya agregaste este usuario a la lista de salida")
        return 
      }
     

    })
    .catch((err)=>{
      Swal.fire("El usuario no se encuentra registrado")
    })
  }

  verifyVisita(){
    //verificar si hay visita para hoy del usuario
    //this.visita=true;
  //   console.log("verificando");
    
    var now= new Date();
    var dia=now.getDate();
    var mes=now.getMonth()+1;
    var ano=now.getFullYear();
   // console.log(dia,mes,ano);
    this.usersService.getvisitasIdFecha(this.suser._id,dia,mes,ano)
    .then((r:any)=>{
      console.log("visitas",r.visitas);
      var visitas=r.visitas.filter((el)=>{return el.dia==dia && el.mes==mes && el.ano==ano})
      if(visitas && visitas.length>0){
      
        this.visita=visitas[0];
        console.log(this.visita,this.suser)
        this.cd.detectChanges();
      }
      else{
        Swal.fire("El usuario no tiene visitas para hoy")
      }
    })
    

  }

  validateSalida2(data: any) {
    this.usersService.getIngresosUser(data.id)
      .then((result) => {
        var ingreso = result["result"];
        var now = new Date();
        var n = now.getUTCHours();
        if (n < 5) {
          n = 24 + n;
        }
        now.setHours(n - 5);
        return this.usersService.newsalidaFuncionario(now, data, ingreso._id);
      })
      .then(() => {
        Swal.fire("Se registró la salida")
          .then(() => {
            this.dato = false;
            this.listaSalida = []; // Limpiar listaSalida
            this.loadIngresos();
          });
      })
      .catch((err) => {
        console.log(err);
  
        if (err.error.statusCode == 100) {
          Swal.fire("No se ha registrado su ingreso en la plataforma")
            .then(() => {
              this.dato = false;
            });
        }
      });
  }
  
  
  validateSalida() {
    Promise.all(
      this.listaSalida.map((element) => {
        return this.usersService.getIngresosUser(element.id)
          .then((result) => {
            var ingreso = result["result"];
            var now = new Date();
            var n = now.getUTCHours();
            if (n < 5) {
              n = 24 + n;
            }
            now.setHours(n - 5);
            return this.usersService.newsalidaFuncionario(now, element, ingreso._id);
          })
          .catch((err) => {
            console.log(err);
    
            if (err.error.statusCode == 100) {
              Swal.fire("No se ha registrado su ingreso en la plataforma")
                .then(() => {
                  this.dato = false;
                });
            }
            // Rechazar la promesa para mantenerla en Promise.all
            throw err;
          });
      })
    )
      .then((responses) => {
        // Todas las promesas se resolvieron correctamente
        Swal.fire("Se registró la salida")
          .then(() => {
            this.dato = false;
            this.listaSalida = []; // Limpiar listaSalida
          });
      })
      .catch((err) => {
        console.log(err);
        // location.reload();
      });
  }
  
  

  validateIngreso(){

      if( this.suser.aprobe && (  this.suser.categoria=="funcnionario" ||this.suser.categoria=="funcionario" || this.suser.categoria=="socio" ||this.suser.categoria=="beneficiario"||this.suser.categoria=="ninera"||this.suser.categoria=="caddie") ){
        this.ingresoSocio(this.suser);
      }
   //hacer ingreso invitado 
if(this.suser.categoria=="invitado"){
  this.ingresoInvitado(this.suser);
 
}
if( this.suser.aprobe && (  this.suser.categoria=="canje" ) ){
  const d = new Date();

  let time = d.getTime();
  if(this.suser.habilitado.fin>time){
    this.suser.canjeHabil=true;
    this.ingresoSocio(this.suser);
  }
  else{
    this.suser.canjeHabil=false;
    Swal.fire("El usuario por canje no esta habilidato para el dia de hoy ")
  }


}

    
    this.dato=null;
    this.suser=null;

  }


  get f() { return this.ingresoForm.controls; }

 




//funciones creadas
  ingresoSocio(user,acompanante=false,responsable=null){
    this.usersService.getIngresosUser(user.id)
    .then((result)=>{
       //console.log(result["result"]);
       
       var ingreso=result["result"];
               Swal.fire("No se ha registrado salida de su anterior ingreso.")
              .then(()=>{
                //si hay ingreso, marcar salida
               //this.salidaFuncionario();
              })
    
    })
    .catch((err)=>{
      console.log(err)
      //validar cod error no ha ingresado.
      if(err.error.statusCode==100){
        var now= new Date();
                        var n = now.getUTCHours();
                        if(n<5){
                          n=24+n;  
                        } 
                       now.setHours(n-5);
                       
                        this.usersService.newingresoFuncionario("socio",user.photo1,now,user,acompanante,responsable)
                        .then((response)=>{
                          Swal.fire("Se registro el ingreso",)
                          .then(()=>{
                           // this.finish=true;
                            // this.yaingreso=true;
                            // this.visita="funcionario";
                            // this.vis=true;
                           // this.terminar()
                           this.ver="ingresos"
                           this.loadIngresos();
                          })
                        }) 
                        .catch((err)=>{
                       
                            //location.reload();
                        })
    
      }
    
    })
  }

  ingresoInvitado(user,acompanante=false,responsable=null){
    console.log("validadno",user)
    this.usersService.getIngresosUser(user.id)
    .then((result)=>{
       //console.log(result["result"]);
       
       var ingreso=result["result"];
               Swal.fire("No se ha registrado salida de su anterior ingreso.")
              .then(()=>{
                //si hay ingreso, marcar salida
               //this.salidaFuncionario();
              })
    
    })
    .catch((err)=>{
      console.log(err)
      //validar cod error no ha ingresado.
      if(err.error.statusCode==100){
        var now= new Date();
                        var n = now.getUTCHours();
                        if(n<5){
                          n=24+n;  
                        } 
                       now.setHours(n-5);
                       
                        this.usersService.newingreso(this.visita._id,user.photo1,now,user,acompanante,responsable)
                        .then((response)=>{
                          Swal.fire("Se registro el ingreso",)
                          .then(()=>{
                           // this.finish=true;
                            // this.yaingreso=true;
                            // this.visita="funcionario";
                            // this.vis=true;
                           // this.terminar()
                           this.ver="ingresos"
                           this.loadIngresos();
                          })
                        }) 
                        .catch((err)=>{
                       console.log(err)
                            //location.reload();
                        })
    
      }
    
    })
  }

  horaingreso(arr){
  
    arr.forEach(solicitud => {
     // console.log("visita: ",solicitud);
      var date= new Date(solicitud.fecha);
       var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
       solicitud.fechat=fecha;  
       if(solicitud.salida){
        var date2= new Date(solicitud.fechaSalida);
        var fecha2= date2.getDate()+"/"+(date2.getMonth()+1)+"/"+date2.getUTCFullYear()+" hora: "+date2.getHours()+":"+(date2.getMinutes() < 10 ? '0' : '') + date2.getMinutes();
        solicitud.fechas=fecha2;  
       }
     
    }); 
  }
  AgregarOtro(){
    this.ver='salidas';
    this.vhoy=false; 
    this.vproximas= false
    this.dato = false
  }

}
