<div class="lienzo">
    <div class="row mt-4 justify-content-center" >
        <div class="col-md-10">
            <div class="row m-1 " >
                <div class="col-md-2">
                    <button class="btn btn-primary" (click)="changeVer(0)" >Pendientes</button>
                  
                    
                </div>
                <div class="col-md-2">
                    <button class="btn btn-primary" (click)="changeVer(1)" >Aprobadas</button>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-primary" (click)="changeVer(2)" >Rechazadas</button>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-primary" (click)="changeVer(3)" >Reportes</button>
                </div>
            </div>
            <div  *ngIf="ver!=3" >
                <div class="row">
                    <div class="col-12">
                        <div class="col-11 col-md-6" >
                            <div class="form-group">
                                <h3>SEDE:</h3>
                                <!--[(ngModel)]="selecionar" -->
                                <select class="form-select" aria-label="Default select example" >
                                    <option value="">Seleccione</option>
                                    <object data="GDA-Pompeya" >GDA-Pompeya</object>
                                    <option value="GDT-Castilla la nueva">GDT-Castilla la nueva</option>
                                    <option value="CTO-9-Acacias">CTO-9-Acacias</option>
                                    <option value="GCH-Acacias">GCH-Acacias</option>
                                    <option value="Remoto">Remoto</option>
                                </select>
                    
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="caja-form2" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; background-color: rgb(255, 255, 255);  padding:15px  ;" >
                    <h1>{{title}}</h1>
                    <table class="table table-striped table-bordered" >
                        
                        <thead>
                            <tr>
                               <th>
                                USUARIO
                               </th>
                               <TH>
                                FECHA
                               </TH>
                               <th>
                                SEDE
                               </th>
                               <TH>
                                ACTIVIDAD
                               </TH>
                             
                               <th>
                                HED
                               </th>
                               <TH>
                                HDF
                               </TH>
                               <TH>
                                RN
                               </TH>
                               <TH>
                                HNF
                               </TH>
                               <TH>
                                HEN
                               </TH>
                               <TH>
                                HEDF
                               </TH>
                               <TH>
                                HENF
                               </TH>
                               <TH >APROBAR</TH>
                               <TH>
                                RECHAZAR
                               </TH>
                            </tr>
                        </thead>
                        <!-- [ngClass]="{ 'rojo':  !solicitud.aprobe, 'verde':solicitud.aprobe, 'otro': !solicitud.archive &&  !solicitud.aprobe}" -->
                        <tbody>
                            <tr *ngFor="let solicitud of verSol()" >
                                <td>
                                    {{solicitud.user.name}} {{solicitud.user.apellido}}
                                </td>
                                <td>
                                    {{solicitud.dia}}/{{solicitud.mes}}/{{solicitud.ano}}
                                </td>
                               
                                    <td>
                                        {{solicitud.sede}}
                                    </td>
                               
                                <td>
                                    {{solicitud.actividad}}
                                </td>
                               
                                <td>
                                   {{ solicitud.hed}}
                                   </td>
                                   <Td>
                                    {{solicitud.hdf}}
                                   </Td>
                                   <Td>
                                    {{solicitud.rn}}
                                   </Td>
                                   <Td>
                                    {{solicitud.hnf}}
                                   </Td>
                                   <Td>
                                    {{solicitud.hen}}
                                   </Td>
                                   <Td>
                                   {{ solicitud.hedf}}
                                   </Td>
                                   <Td>
                                    {{solicitud.henf}}
                                   </Td>
                                <td >
                                    <button class="btn btn-primary" *ngIf="!solicitud.aprobe && !solicitud.archive"  (click)="aprobar(solicitud._id)" >Aprobar</button>
                                </td>
                                <td>
                                    <button class="btn btn-danger" *ngIf="!solicitud.archive && !solicitud.aprobe" (click)="rechazar(solicitud._id)" >Rechazar</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
           
            <div  *ngIf="ver==3" >
                <div class="row justify-content-center">
                    <div class="col-md-9">
                        <h1>Extras aprobadas en un rango de fechas</h1>
                       <!--  <div class="m-2 " style="text-align: center;"  >
                            <mat-form-field appearance="fill" style="z-index: 99999999999;"  >
                                <mat-label   >Seleccione el rango de fechas</mat-label>
                                <mat-date-range-input  [formGroup]="range" [rangePicker]="picker">
                                  <input (dateInput)="addEvent('input', $event)" matStartDate formControlName="start" placeholder="Start date">
                                  <input (dateInput)="addEvent('input', $event)" matEndDate formControlName="end" placeholder="End date">
                                </mat-date-range-input>
                                <mat-hint >MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                              
                                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> 
                              </mat-form-field>
                        </div> -->
                        <br>
                        <!-- <p>Selected range: {{range.value | json}}</p> -->
                        <div *ngIf="initdate && !reporte"  >
                            <span  style="font-size: 21px;" >Rango seleccionado: {{initdate}} - {{enddate}}</span>
                            <br>
                            <button class="btn btn-primary" (click)="newFechaRange()" >Buscar reportes en este rango</button>
                            <br>
                       
                      
                        </div>
                        <div *ngIf="reporte" >
                            <div class="row">
                                <div class="col-12">
                                    <div class="caja-form2" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; background-color: rgb(255, 255, 255);  padding:15px  ;" >
                                        <table class="table table-striped table-bordered" >
                                            <thead>
                                                <tr>
                                                   <th>
                                                    CC
                                                   </th>
                                                   <th>
                                                    NOMBRE
                                                   </th>
                                                   <th>
                                                    TIPO DE HORA
                                                   </th>
                                                   <th>
                                                    CANTIDAD
                                                   </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let sol of rSolicitudes" >
                                                    <td>
                                                        {{sol.id}}
                                                    </td>
                                                    <td>
                                                        {{sol.nombre}}
                                                    </td>
                                                    <td>
                                                        {{sol.tipo}}
                                                    </td>
                                                    <td>
                                                        {{sol.cantidad}}
                                                    </td>
                                                </tr>
    
                                            </tbody>
                                        </table>
                                        <br>
                                        <button class="btn btn-primary" (click)="generarExcel()" >Descargar</button>
                                        <br>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




