
<div class="row justify-content-center mt-3">
    <div class="col-md-4 py-1">
        <a class="m-2" href="/tutorial" target="_blank" rel="noopener noreferrer">
            <button class="btn btn-boton2">Tutoriales</button></a>
    </div>
    
    <div class="col-md-6 col-12 text-center" >
<!--         <img src="../assets/FondosClub/LOGO-SERREZUELA-VERTICAL.png" alt="" width="40%" >
 -->    </div>
    <div class="col-md-2 justify-content-end">
        <!-- <img src="../assets/FondosClub/logo-serrrezuela-ico.png" alt="" width="45%" > -->

    </div>

</div>


<div class="row mt-2 justify-content-center m-2" *ngIf="!rperson && !rcompany" style="
padding: 10px; ;">
<br><br>
 
    <div class="col-md-4 col-11  cardLogin"  *ngIf="cargaRegistroMo">
       <!--  <div class="row justify-content-center" >
            <h2 class="text-center fst-italic"  >
                <strong>Ingresar como Persona </strong>  
            </h2>
        </div> -->

        <br>
        <div class="mt-1 w-100">
            <form [formGroup]="ipersonForm" (ngSubmit)="ingresop()">
                <div class="text-center">
                    <img src="../assets/FondosClub/LOGO-SERREZUELA-VERTICAL.png" alt="" width="40%" >

                </div>
                <div class="form-group">
                    <h2 class="text-center"><strong style="color: black;">Ingresar como persona</strong> </h2>
                    <label for="" style="color: rgb(103, 103, 103);">Numero de identificación</label>
                    <input class="form-control" type="text" placeholder="número de identificación" formControlName="id"
                        [ngClass]="{ 'is-invalid': submitted && f4.id.errors }">
                    <div *ngIf="submitted && f4.id.errors" class="invalid-feedback ">
                        <div *ngIf="f4.id.errors.required">ingrese su documento</div>

                    </div>
                </div>
                <br>
               
                <div class="text-center" style="padding:10px">
                    <button type="submit" class="btn btn-orange col-md-6">Ingresar</button>
                </div>
                <br>
                <div class="text-center">
                     <label for="" style="color: rgb(103, 103, 103);">¿ Tienes una Cuenta ? <strong style="cursor: pointer" routerLink="/register" >Registrate</strong></label>

<!--                     <button  class="btn btn-orange" style="font-size: 12px;"  (click)="registerperson()">Registrarme</button>
 -->                </div>
                <br>
            </form>
        </div>
    </div> 
    <div class="col-md-3 col-9 cardLogin" *ngIf="!cargaRegistroMo">
       <!--  <div class="row justify-content-center" style="background-image: linear-gradient(to right, #55E2F6 0%, #A59DFD 51%, #F993EB 100%);">
            <h2 class=" text-center fst-italic" >
                <strong>  Registrarme como empresa</strong>  
            </h2>
        </div> -->
        
        <br>
        <div class="mt-3">    
            <form [formGroup]="icompanyForm" (ngSubmit)="ingresoc()">
                <div class="text-center">
                    <img src="../assets/FondosClub/LOGO-SERREZUELA-VERTICAL.png" alt="" width="40%" >

                </div>
                <div class="form-group">
                    <label for="">Escribe Numero de Nit Empresa</label>
                    <input class="form-control" type="text" placeholder="NIT" formControlName="nit"
                        [ngClass]="{ 'is-invalid': submitted && f3.nit.errors }">
                    <div *ngIf="submitted && f3.nit.errors" class="invalid-feedback ">
                        <div *ngIf="f3.nit.errors.required">ingrese su documento</div>
    
                    </div>
                </div>
                <br>
                <div class="text-center">
                    <button class="btn btn-orange " style="font-size: 12px;"  (click)="registercompany()">Registrarme como Empresa</button>
                </div>

                <br>
                <div class="text-center" style="padding:10px">
                    <button type="submit" class="btn btn-orange col-md-6">Ingresar</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- <div class="row m-1" >
    <div class="col-12">
        <div class="text-center mt-1">
            <br> <br> <br>
            <div class="ingreso" *ngIf="estadoRegistro">
                <button type="button" class="btn btn-orange  mt-5" *ngIf="cargaRegistroMo && !rperson" (click)="cargaRegistroMo = false" >Ingresar como Empresa</button>
                <button type="button" class="btn btn-orange  mt-5" *ngIf="!cargaRegistroMo" (click)="cargaRegistroMo = true"  >Ingresar como Persona</button>
        
            </div>
        </div>
    </div>
</div> -->

    <div class="container d-flex justify-content-end py-2" *ngIf="!estadoRegistro && fotop || rperson">
        <button type="button" class="btn btn-success"  (click)="regresarLogin()" >Regresar</button>

    </div>
<div class="row justify-content-center m-4" *ngIf="rperson">
    <div class="col-md-6">

        <div class="container  cajaFoto" *ngIf="!fotop">
            <video  playsinline  width="90%" height="225" autoplay #video style="margin-left: 20px; transform: rotateY(180deg);
            -webkit-transform:rotateY(180deg); /* Safari and Chrome */
            -moz-transform:rotateY(180deg); /* Firefox */" ></video> <br> 
            <h3 style="color:black" class="fst-italic">Al ingresar y tomarse la fotografía esta aceptando nuestra política de tratamiento de datos </h3>
            <div class="d-flex justify-content-center" style="padding:10px">
                <div  class="text-center py-3" *ngIf="!loading">
                    <button  (click)="tomar()" *ngIf="!fotovalidate" class="btn btn-info ">Tomar foto</button>
                    <button  (click)="tomarregistro()" *ngIf="fotovalidate" class="btn btn-info ">Tomar foto</button>
                </div>
               
                <div *ngIf="loading"  class="text-center py-3">
                    <div   class="spinner-border text-success " role="status">
                        <span class="sr-only">Loading...</span>
                      </div>  
                </div>
            </div>
           

            <canvas #canvas style="display:none; "></canvas>
        </div>
        <div class="container caja-form2">
            <form [formGroup]="rpersonForm" (ngSubmit)="registerp()" *ngIf="fotop" class="">
                <div class="row ">
                    <div class="col-11 col-md-6 ">
                        <div class="form-group">
                            <h3>Nombres:*</h3>
                            <input type="text" class="form-control" placeholder="Nombre" formControlName="name"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Ingrese su nombre</div>
                                <div *ngIf="f.name.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Apellidos:*</h3>
                            <input type="text" class="form-control" placeholder="Apellido" formControlName="apellido"
                                [ngClass]="{ 'is-invalid': submitted && f.apellido.errors }">
                            <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
                                <div *ngIf="f.apellido.errors.required">Ingrese sus apellidos</div>
                                <div *ngIf="f.apellido.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Tipo de documento:*</h3>
                         <select class="form-control" formControlName="tipoid" [ngClass]="{ 'is-invalid': submitted && f.tipoid.errors }"  >
                            <option value="">Selecione</option>
                            <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
                            <option value="Cédula de Extranjería Colombiana">Cédula de Extranjería Colombiana</option>
                            <option value="Cédula Extranjera">Cédula Extranjera</option>
                            <option value="Pasaporte">Pasaporte</option>
                            <option value="Registro Civil">Registro Civil</option>
                            <option value="Tarjeta de Identidad">Tarjeta de Identidad</option>

                         </select>
                            <div *ngIf="submitted && f.tipoid.errors" class="invalid-feedback ">
                                <div *ngIf="f.tipoid.errors.required">seleccione</div>
                             
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Documento de identidad:*</h3>
                            <input class="form-control" type="number" placeholder="Documento de identidad"
                                formControlName="id" [ngClass]="{ 'is-invalid': submitted && f.id.errors }">
                            <div *ngIf="submitted && f.id.errors" class="invalid-feedback ">
                                <div *ngIf="f.id.errors.required">ingrese su documento</div>
                                <div *ngIf="f.id.errors.min">ingrese un documento valido</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Celular:*</h3>
                            <input class="form-control" type="number" placeholder="celular" formControlName="telefono"
                                [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }">
                            <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback ">
                                <div *ngIf="f.telefono.errors.required">ingrese su telefono</div>
                                <div *ngIf="f.telefono.errors.min">ingrese un telefono valido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Correo electronico:*</h3>
                            <input class="form-control" type="email" placeholder="Correo electrónico"
                                formControlName="correo" [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                            <div *ngIf="submitted && f.correo.errors" class="invalid-feedback ">
                                <div *ngIf="f.correo.errors.required">ingrese su email</div>
                                <div *ngIf="f.correo.errors.email">ingrese un correo valido</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Fecha de nacimiento:*</h3>
                            <input class="form-control" type="date" placeholder="Fecha" formControlName="nacimiento"
                            min="1920-01-01" max="2028-12-31" 
                            [ngClass]="{ 'is-invalid': submitted && f.nacimiento.errors }">
                            <div *ngIf="submitted && f.nacimiento.errors" class="invalid-feedback">
                                <div *ngIf="f.nacimiento.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Sexo:*</h3>
                         <select class="form-control" formControlName="sexo" [ngClass]="{ 'is-invalid': submitted && f.sexo.errors }"  >
                            <option value="">Selecione</option>
                            <option value="Femenino">Femenino</option>
                            <option value="Masculino">Masculino</option>

                         </select>
                            <div *ngIf="submitted && f.sexo.errors" class="invalid-feedback ">
                                <div *ngIf="f.sexo.errors.required">seleccione</div>
                             
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Empresa:</h3>
                            <input type="text" class="form-control" placeholder="Empresa" formControlName="empresa"
                                [ngClass]="{ 'is-invalid': submitted && f.empresa.errors }">
                            <div *ngIf="submitted && f.empresa.errors" class="invalid-feedback">
                                <div *ngIf="f.empresa.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Cargo:</h3>
                            <input type="text" class="form-control" placeholder="Cargo" formControlName="cargo"
                                [ngClass]="{ 'is-invalid': submitted && f.cargo.errors }">
                            <div *ngIf="submitted && f.cargo.errors" class="invalid-feedback">
                                <div *ngIf="f.cargo.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                  
                </div>
    
                <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>ARL</h3>
                            <input type="text" class="form-control" placeholder="ARL" formControlName="arl"
                                [ngClass]="{ 'is-invalid': submitted && f.arl.errors }">
                            <div *ngIf="submitted && f.arl.errors" class="invalid-feedback">
                                <div *ngIf="f.arl.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>EPS</h3>
                            <input type="text" class="form-control" placeholder="EPS" formControlName="eps"
                                [ngClass]="{ 'is-invalid': submitted && f.eps.errors }">
                            <div *ngIf="submitted && f.eps.errors" class="invalid-feedback">
                                <div *ngIf="f.eps.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    
                    <div class="col-11 col-md-6">
    
                    </div>
                </div>
    
                
                <div class="text-center mt-2">
                    <input type="submit" value="Ingresar" class="btn btn-orange">
                </div>
            </form>
        </div>
        
    </div>

</div>



<div class="row" *ngIf="rcompany ">
    <div class="container caja-form2 col-11">
        <form [formGroup]="rcompanyForm" class="row py-3" (ngSubmit)="registerc()">
            <div class="field col-md-6">
                    <h3>Ingrese el nombre de la empresa</h3>
                    <input type="text" class="form-control" placeholder="Nombre" formControlName="name"
                        [ngClass]="{ 'is-invalid': submitted && f2.name.errors }">
                    <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
                        <div *ngIf="f2.name.errors.required">Ingrese su nombre</div>
                        <div *ngIf="f2.name.errors.minlength">El nombre debe tener al menos 4 caracteres</div>
                    </div>
            </div>
            <div class="field col-md-6" >
                <h3>Ingrese el NIT de la empresa</h3>
                <input class="form-control" type="text" placeholder="NIT" formControlName="nit"
                    [ngClass]="{ 'is-invalid': submitted && f2.nit.errors }">
                <div *ngIf="submitted && f2.nit.errors" class="invalid-feedback ">
                    <div *ngIf="f2.nit.errors.required">ingrese su documento</div>
    
                </div>
            </div>
            <div class="field col-md-6">
                    <h3>Ingrese clave de ingreso</h3>
                    <input class="form-control" type="text" formControlName="clave"
                        [ngClass]="{ 'is-invalid': submitted && f2.clave.errors }">
                    <div *ngIf="submitted && f2.clave.errors" class="invalid-feedback ">
                        <div *ngIf="f2.clave.errors.required">ingrese su clave</div>
                    </div>
            </div>
                <div class="field col-md-12 m-3">
                    <h3 class="text-center" >Datos de contacto de la empresa (sus datos)</h3>
                 </div>
                <div class="field col-md-6">
                    <h3>Ingrese su nombre</h3>
                    <input type="text" class="form-control" placeholder="Nombre" formControlName="rname"
                        [ngClass]="{ 'is-invalid': submitted && f2.rname.errors }">
                    <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
                        <div *ngIf="f2.rname.errors.required">Ingrese su nombre</div>
                        <div *ngIf="f2.rname.errors.minlength">El nombre debe tener al menos 4 caracteres</div>
                    </div>
                </div>
            <div class="field col-md-6">
                    <h3>Ingrese su correo electronico</h3>
                    <input class="form-control" type="email" placeholder="Correo electrónico" formControlName="rcorreo"
                        [ngClass]="{ 'is-invalid': submitted && f2.rcorreo.errors }">
                    <div *ngIf="submitted && f2.rcorreo.errors" class="invalid-feedback ">
                        <div *ngIf="f2.rcorreo.errors.required">ingrese su email</div>
                        <div *ngIf="f2.rcorreo.errors.email">ingrese un correo valido</div>
                    </div>                
            </div>
            <div class="field col-md-6">
                <h3>Ingrese su número de teléfono</h3>
                <input class="form-control" type="number" placeholder="teléfono" formControlName="rtelefono"
                    [ngClass]="{ 'is-invalid': submitted && f2.rtelefono.errors }">
                <div *ngIf="submitted && f2.rtelefono.errors" class="invalid-feedback ">
                    <div *ngIf="f2.rtelefono.errors.required">ingrese su telefono</div>
                    <div *ngIf="f2.rtelefono.errors.min">ingrese un telefono valido</div>
                </div>
            </div>
            <div class="col-12 text-center mt-3"> 
                <input type="submit" value="Ingresar" class="btn btn-orange">
            </div>
        </form>
    </div>
       
</div>

<div class="floating-button cl" *ngIf="showAddToHomeScreenButton">
      <button class="btn btn-orange" (click)="addToHomeScreen()">Instalar aplicación</button>
  
  </div>