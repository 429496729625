import { Component, OnInit,ElementRef, Renderer2,ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-registro-select',
  templateUrl: './registro-select.component.html',
  styleUrls: ['./registro-select.component.css']
})
export class RegistroSelectComponent implements OnInit {
  


user;
submitted
foto1Url: any;
fotovalidate: boolean;
fotop: boolean;
videoWidth = 0;
videoHeight = 0;
ingresoForm;
loading: boolean = false;

@ViewChild('video', { static: false }) videoElement: ElementRef;
@ViewChild('canvas', { static: false }) canvas: ElementRef;
newRegister: boolean;
registerForm: any;
stream: any;
habilitando: boolean;
constructor(
  private authService: AuthService,
  private router:Router,
  private renderer: Renderer2,
  private usersService: AuthService,
  private route: ActivatedRoute
) { }

ngOnInit(): void {

  this.ingresoForm = new FormGroup({
 
    'doc': new FormControl('', [
      Validators.required,

    ]),

      });

  this.registerForm = new FormGroup({

    'name': new FormControl('', [
      Validators.required,
      Validators.minLength(1)
    ]),
    'apellido': new FormControl('', [
      Validators.required,
      Validators.minLength(1)
    ]),
    'correo': new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    'tipoid': new FormControl('', [
      Validators.required,
   
    ]),
    'nacimiento': new FormControl('', [
      Validators.required,     
    ]),
    'sexo': new FormControl('', [
      Validators.required,     
    ]),
    'id': new FormControl('', [
      Validators.required,
      Validators.min(1111)
    ]),
    'empresa': new FormControl('', [
      

    ]),
    'cargo': new FormControl('', [
    

    ]),

    'telefono': new FormControl('', [
      Validators.required,
      Validators.min(1111)
    ]),
     'arl': new FormControl('', [
   

    ]),
    'eps': new FormControl('', [


    ]),
 
    'rol': new FormControl('usuario', [
      Validators.required,
  
    ]),
    'tipo': new FormControl('persona', [
      Validators.required,
  
    ]),
  

 
    'categoria': new FormControl('', [
      Validators.required,
  
    ]),
    'aprobe': new FormControl(false, [
      Validators.required,
  
    ]),
    'terminos': new FormControl(false, [
      Validators.requiredTrue,
  
    ]),
  

  });
  this.activeVideo();
}





get f4() { return this.ingresoForm.controls; }
get f() { return this.registerForm.controls; }





registerp(){
  this.submitted = true;

 
  console.log(this.registerForm.controls);
 
  if (this.registerForm.invalid) {
    console.log("no enviado ");
    return;
} 
console.log("enviado ");
  this.authService.createPerson(this.registerForm.value,this.foto1Url)
  .then((response) => {
    console.log(response);
if(response["user"].aprobe){
this.authService.setUserInfo({'user' : response['user']});
this.router.navigate(['agenda']);
}

else{
Swal.fire("Usuario cargado correctamente")
this.router.navigate(['login']);
}



   // this.completo=true;
  })
  .catch((err)=>{
    // this.router.navigate(['registerp']);
    Swal.fire("Error registradno usuario")
})

}

//control camara
handleError(error) {
console.log('Error: ', error);
}

attachVideo(stream) {
this.stream=stream;
this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
  this.videoHeight = this.videoElement.nativeElement.videoHeight;
  this.videoWidth = this.videoElement.nativeElement.videoWidth;
});

}

activeVideo(){
   
    var  constraints = {
      video: {
          
          width: { ideal: 1280 },
          height: { ideal: 720 }
      }
  };
  if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
    navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
       } else {
           alert('Sorry, camera not available.');
       }



}

tomar(){
this.loading = true;
this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
this.authService.sendfoto(data)
.then((response)=>{
  console.log(response["url"]);
  this.foto1Url=response["url"];
  this.loading=false;
  this.fotop=true;
})
.catch((err)=>{
alert("no encontramos tu rostro en la foto");
this.loading=false;

})

}
}
