<div class="row justify-content-center">

<div class="col-md-3 caja-form2 m-3" >
    <form [formGroup]="fechaForm" class="" (ngSubmit)="newFecha()">
        <div >
            <h3 style="color:black">Ingrese la fecha:</h3>
            <input class="form-control" type="date"  formControlName="fecha"
                min="2021-06-01" max="2023-12-31" required>
        </div>
        <div class="d-flex justify-content-center mt-3 padding:10px">
            <button class="btn btn-info" >Buscar</button>

        </div>
    </form>
</div>
<div class="col-md-3 caja-form2 m-3" >
    <form [formGroup]="bitaForm" (ngSubmit)="newitem()" novalidate="">
       <h3 style="color:black">Nueva nota:</h3>
        <input type="text " class="form-control"  formControlName="question" >
        <div class="d-flex justify-content-center mt-3">
            <input type="submit" value="Guardar"  class="btn btn-info"  >
        </div>
        </form>
</div>
</div>

<div  style="color: black; max-height: 450px; overflow-y: scroll;padding: auto; border-radius: 10px; padding:14px; " class="caja-form2 container" >
      <div class="d-flex justify-content-center  ">
        <h3 class="text-dark">Lista de Bitacoras</h3>
      </div>  
    <div  *ngFor="let question of questions" class="mb-2" >
        - {{question.question}}  -  {{question.time}}  <div *ngIf="question.user" style="font-size: 12px; font-weight: lighter; " > (Añadido por: {{question.user.name}} {{question.user.apelido}}) </div> <span *ngIf="question.visitaid"  style="font-size: 12px;" > <button class="btn btn-primary" (click)="openpop(question.visitaid)" >Ver visita</button></span>
    </div>
</div>

<div class="pop" *ngIf="pop" >  
    <div class="row justify-content-center" >
<div class="col-9" >
    <button (click)="pop=false" class="btn btn-primary" style="position: fixed; left: 0;top: 0;" >Cerrar</button>
    <app-visita [visita]="visita"  ></app-visita>
</div>
    </div>
  
</div>