import { Component, Input, OnInit } from '@angular/core';
import {ReservaService} from '../../services/reservas.services'

@Component({
  selector: 'app-reserva-agenda',
  templateUrl: './reserva-agenda.component.html',
  styleUrls: ['./reserva-agenda.component.css']
})
export class ReservaAgendaComponent implements OnInit {

  @Input() iduser;
  agenda:any

  constructor(private Reservas: ReservaService) { }

  ngOnInit(): void {
    this.Reservas.getReservasField(this.iduser).then((r)=>{
      console.log("AGENDA", r)
      this.agenda = r
    })
  }

}
