import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service"
@Component({
  selector: 'app-aprobacion3',
  templateUrl: './aprobacion3.component.html',
  styleUrls: ['./aprobacion3.component.css']
})
export class Aprobacion3Component implements OnInit {

user;
  visitas;
  constructor( private authService : AuthService, 
    private eventService : EventsService, ) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(user);
    if(this.user.rol=="admin"){
      this.getvisitasporAprobar()
    }
    
  }

  getvisitasporAprobar(){
    this.eventService.getVisitasaprobacion3()
    .then((response)=>{
      console.log(response);
      this.visitas=response["visitas"]
    })
  }

}
