<div class="row mt-4 justify-content-center " >
    <div style="text-align: left;" class="col-4 col-md-5" *ngFor="let area of areas" >
        
<div class="card" style="cursor: pointer;" (click)="irArea(area)" >
<h2 class="card-title" style="color: black; "  > Administrar {{area.nombre}} </h2>

</div>
    </div>    

</div>
<div class="row mt-4 justify-content-center " >
 
    <div style="text-align: left;" class="col-md-2 col-6" *ngFor="let event of eventos" >
<app-evento [event]="event" ></app-evento>
    </div>    

</div>

<!-- <img src="../assets/logomovi.png" alt="" style="position: absolute; bottom: 10px; left: 5px; width: 25%; height: auto;">
 -->