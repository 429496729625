<div class="m-1">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div  class="caja-form2 m-2" *ngFor="let canje of canjes" >  
                Usuario: {{canje.user.name}} {{canje.user.apellido}}
                <br>
                Documento: {{canje.user.id}}
                <br>
                Mensaje: {{canje.mensaje}}
                <br>
                Fecha: {{canje.createddate| date:"mediumDate"}} 
                <button class="btn btn-primary"  (click)="canje.open=true; loadMsg(canje._id)" >Abrir</button>
                <div *ngIf="canje.open">
                        <div *ngFor="let msg of msgs" >
                            {{msg.mensaje}}
                            <br>
                            <small>{{msg.user.name}} {{msg.user.apellido}} -  Fecha: {{msg.createddate| date:"mediumDate"}} </small>
                        </div>
                        <div>
                            <form [formGroup]="msgForm" (ngSubmit)="newMsg()">
                                <div class="col-11 col-md-6">
                                    <div class="form-group">
                                        <h3>Escriba su comentario</h3>
                                        <textarea class="form-control" formControlName="mensaje" cols="30" rows="3"></textarea>
                                        <button>Enviar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                </div>
            </div>
        </div>
    </div>


</div>