<div class="container bg-white" style="min-width:250px ; max-height: 500px; overflow-y: auto; " >
<div class="row "> 
<div class="col-12" style="justify-content: start; padding-left: 0px; padding-right: 0px;"  >
<div (click)="seeChat(conver,i)" class="mensaje" *ngFor="let conver of conversations;let i = index" [ngClass]="seeNew(conver)"   >
<span style="font-weight: bolder;" > {{conver.ultimo.fromName}} </span> <br>
<span>{{conver.ultimo.txt}}</span>
</div>
</div>
</div>
</div>

<div id="caja-respuesta"  style="z-index: 999;   position: fixed; bottom: 0px; right: 5px; width: 320px;height: 400px;  background-color: white;" *ngIf="vchat"  >
    <div style="text-align: end; background-color: gray;" >
<button class="btn " (click)="vchat=false" style="background-color: red;" >X</button>
    </div>
    <div style="max-height: 250px; overflow-y: scroll;" id="caja-chat">
        <div  class="chat" *ngFor="let mensaje of mensajes" [ngClass]="seeOwner(mensaje.from)"    >
            <span style="font-weight: bolder;" > {{mensaje.fromName}}: </span>
            <span>{{mensaje.txt}}</span> <br>
             <span style="font-size: smaller;" >{{mensaje.fecha}}</span> 
             <!-- <a href="http://" target="_blank" rel="noopener noreferrer"></a><span>ver visita</span> -->
            </div>
    </div>
  

        <div  style=" position: absolute; bottom: 1px;; left: 1px; width: 99%; "   >
            
            <form [formGroup]="mensajeForm" (ngSubmit)="newMensaje()"   >  
              <div class="form-group">
                
                <input type="text" class="form-control" placeholder="mensaje .." formControlName="txt"  [ngClass]="{ 'is-invalid': submitted && f2.txt.errors }">
                <div *ngIf="submitted && f2.txt.errors" class="invalid-feedback">
                    <div *ngIf="f2.txt.errors.required">Ingrese su mensaje</div>
        
                </div>
            </div> 
            <div style="text-align: start;" >
                <input type="submit" value="Enviar" class="btn btn-info">
            </div>
           
            </form>
         
          </div>  
</div>