<div class="row justify-content-center mt-4" >
    <div class="col-md-6 " *ngIf="visita" style="text-align: center; color: white;" >
    <h1  style="color: white;" >Solicitud de visita</h1>
    
    <button  *ngIf="getacceso" (click)="aprobar()" class="btn btn-primary" >Aprobar para todos</button>
    <div >
      <h2 *ngIf="!getacceso">Por favor seleccione el nivel de acceso antes de realizar la aprobación</h2>
      <br>
      <span  style="color: white;">Nivel de acceso: </span>
      <select  id="sacceso">
        <option value="">Seleccione</option>
        <option value="General personal">General personal</option>
        <option value="General sin manilla">General sin manilla</option>
        <option value="Protocolo">Protocolo</option>
        <option value="Servicio Platea">Servicio Platea</option>
        <option value="VIP-Platea-Boxes-Suites">VIP-Platea-Boxes-Suites</option>
        <option value="All access">All access</option>
      </select>
      <button  (click)="setAcceso(visita._id)" class="btn btn-primary" >Fijar</button>
    </div>
    <button class="btn btn-info" (click)="vmensaje=true" >Enviar mensaje sobre esta visita</button>
    <div class="card cajainfo" style="background-color: transparent;font-family:Arial, Helvetica, sans-serif;" >
        <div class="card-body" style=" padding: 0px; height:auto; " >
            <div style=" width: 100%; padding: 10px; overflow: hidden;">
           
             <span class="text" > Fecha: {{visita.fechat}}</span>
     <br>
     <span class="text" > Descripción: {{visita.descripcion}}</span>
     <br>
     <br>
     <span class="text" > Nivel de acceso: {{visita.acceso}}</span>
     <br>
             <!-- <h4  *ngIf="visita.aprobe=='true'" >Aprobado</h4>
             <button  *ngIf="visita.aprobe!='true'"  class="btn-primary"  (click)="aprobar()" >  Aprobar visita</button>
            -->
         </div>
            
         <h2 style="color: black;"  >Número de personas: {{personasc.length}} </h2>
         <div class="row " >
           <div class="col-md-12" *ngFor="let persona of personasc">
             
             <span *ngIf="persona.aprobe3 && getacceso" > Aprobado  </span> <br>
             <span *ngIf="!persona.aprobe3 && getacceso" > Sin aprobación  <button class="btn btn-primary" (click)="aprobarpersona(persona.id)" >Aprobar</button>  </span>
             <span style="color: white;" >Aprobación nivel 1: <span style="color: white;" *ngIf="persona.aprobe1" >Aprobado</span> <span style="color: white;" *ngIf="!persona.aprobe1" >No aprobado</span>  </span> <br>
             <span style="color: white;" >Aprobación nivel 2: <span style="color: white;" *ngIf="persona.aprobe2" >Aprobado</span> <span style="color: white;" *ngIf="!persona.aprobe2" >No aprobado</span>  </span> <br>
        
             <app-persona [user]="persona" [show]="true" ></app-persona>
          
           
           </div> 
         </div>
     
       <div style="color: black !important;" *ngIf="empresa" >  
     {{empresa.name}}
     <span class="text" > NIT: {{empresa.nit}}</span> 
     
     <br>
     
     <span class="text" *ngIf="visita.placa" > Vehiculo: {{visita.placa}}</span> 
     <br>
     <h2 style="color: black;" >Responsable:</h2>
     <span class="text"> {{empresa.responsable.name}}</span>
     <br>
     <span class="text"> {{empresa.responsable.correo}}</span>
     <br>
     <span class="text"> {{empresa.responsable.telefono}}</span>
     
     
     <h2   >Documentos: </h2>
     <div *ngFor="let link of empresa.documentos">
         <a   href="{{link}}" target="_blank" rel="noopener noreferrer">
         <iframe  [src]="link | safe"  width="90%" height="200px" frameborder="0"></iframe> {{link}}</a>
         
     </div>
     
     
       </div>
       
         </div>
         </div>
         
    
    
    
    
    <!-- <app-visita   [visita]="visita" ></app-visita> -->
    
    <!-- <div *ngIf="visita.aprobe==true" >
    <h3>Visita aprobada</h3>
    </div> -->
    <!-- <div *ngIf="visita.aprobe!=true" >
       <button class="btn btn-primary" (click)="aprobar()"  >Aprobar visita</button>
        </div> -->
     
    </div>
    
    
    
    </div>

    
    <div *ngIf="vmensaje" style="z-index: 99; position: fixed; top: 5%; left: 15%; width: 60%; " class="caja-form"  >
      <h2>Enviar mensaje</h2>
      <form [formGroup]="mensajeForm" (ngSubmit)="newMensaje()"  class="caja-form"  >  
        <div class="form-group">
          <h3>Mensaje:</h3>
          <input type="text" class="form-control"  formControlName="txt"  [ngClass]="{ 'is-invalid': submitted && f2.txt.errors }">
          <div *ngIf="submitted && f2.txt.errors" class="invalid-feedback">
              <div *ngIf="f2.txt.errors.required">Ingrese su mensaje</div>
  
          </div>
      </div> 
      <input type="submit" value="Enviar" class="btn btn-info">
      </form>
      <button class="btn btn-danger ml-2" (click)="vmensaje=false" >Cancelar</button>
    </div>