import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  url="https://selesoluciona.com:8223";
  constructor(private http : HttpClient) { }

//borrar evento
public deleteEvent(id){
  return this.http.post(this.url+'/deleteevent2', {"id":id}).toPromise()
}
public deleteArea(id){
  return this.http.post(this.url+'/deletearea', {"id":id}).toPromise()
}


//visitas
public getVisitasAdmin(){
  return this.http.post(this.url+'/visitasadmin', {}).toPromise()
}
public getVisitasArea(id){
  return this.http.post(this.url+'/visitasaprobararea', {"id":id}).toPromise()
}
public getVisitasaprobacion2(){
  return this.http.post(this.url+'/visitasaprobar2', {}).toPromise()
}
public getVisitasaprobacion3(){
  return this.http.post(this.url+'/visitasaprobar3', {}).toPromise()
}
//controlacceso
public getsolicitud(id){
  return this.http.post(this.url+"/visitaap",{"id":id}).toPromise()
}

public getvinculacion(empresa,evento){
  return this.http.post(this.url+'/getvinculacion',{empresa,evento}).toPromise()
}

  public getAllEvents(){
    return this.http.get(this.url+'/eventos').toPromise()
  }
  public deletep(id){
    return this.http.post(this.url+'/deletepevent', {"id":id}).toPromise()
  }
  public getAllAreas(){
    return this.http.get(this.url+'/areas').toPromise()
  }
  public getArea(id){
    return this.http.post(this.url+'/area', {"id":id}).toPromise()
  }
  public getEventosArea(id){
    return this.http.post(this.url+'/eventosarea', {"id":id}).toPromise()
  }
  public getEvent(id){
    return this.http.post(this.url+'/evento', {"id":id}).toPromise()
  }
  
public sendNotify(visita,correo){
  return this.http.post(this.url+'/sendnotify', {visita,correo}).toPromise()
}
  public getEventosPersona(id){
    return this.http.post(this.url+'/eventospersona', {"id":id}).toPromise()
  }
  public getEventosEmpresaa(id){
    return this.http.post(this.url+'/eventosempresa', {"id":id}).toPromise()
  }
  public addPersonToEvent(event,person,areaid){
    return this.http.post(this.url+'/addpersontoevent', {"event":event,"person":person,"area":areaid}).toPromise()
  }
  public addempresatoevent(event,empresa,areaid,capacidad,nit){
    return this.http.post(this.url+'/addempresatoevent', {"event":event,"empresa":empresa,"area":areaid,"capacidad":capacidad,"nit":nit}).toPromise()
  }

  public removePersonToEvent(event,person){
    return this.http.post(this.url+'/removepersontoevent', {"event":event,"person":person}).toPromise()
  }
  public removeempresatoevent(event,empresa){
    return this.http.post(this.url+'/removeempresatoevent', {"event":event,"empresa":empresa}).toPromise()
  }

  public createSalas(event){
    return this.http.post(this.url+'/newsalas', {event}).toPromise()
  }
  public getAllSalas(){
    return this.http.get(this.url+'/salas').toPromise()
  }
  public getIngresos(){
    return this.http.get(this.url+'/ingresos').toPromise()
  }
  public deleteIngresos(){
    return this.http.get(this.url+'/limpiaringresos').toPromise()
  }
  public deleteIngresosPasados(dia,mes,ano){
    return this.http.post(this.url+'/limpiaringresospasados',{"dia":dia,"mes":mes,"ano":ano}).toPromise()
  }

  public loadSala(id){
    return this.http.post(this.url+'/loadsala', {"id":id}).toPromise()
  }

  public createEvent(event){
    return this.http.post(this.url+'/newevent', {event}).toPromise()
  }
  public createArea(area){
    return this.http.post(this.url+'/newarea', {area}).toPromise()
  }
  public createStand(stand){
    return this.http.post(this.url+'/newstand', {stand}).toPromise()
  }
  public getStands(){
    return this.http.get(this.url+'/stands').toPromise()
  }
  public newNota(nota){
    return this.http.post(this.url+'/newnota', {nota}).toPromise()
  }
  public loadNota(email){
    return this.http.post(this.url+'/loadnota', {"email":email}).toPromise()
  }

  public bitacora(item){
    console.log(item)
    return this.http.post(this.url+'/newbitacoraitem', {"item":item}).toPromise()
  }


  public getBitacora(){
    return this.http.get(this.url+'/items').toPromise()
  }
  public deleteBitacora(id){
    return this.http.post(this.url+'/deletebitacora', {"id":id}).toPromise()
  }
  public downloadImage(imgUrl){
    return this.http.get(imgUrl, {responseType: 'blob' as 'json'}).toPromise()
  }



  public loadStand(id){
    return this.http.post(this.url+'/loadstand', {"id":id}).toPromise()
  }
  public load(id){
    return this.http.post(this.url+'/loadevent', {"id":id}).toPromise()
  }
  public delete(id){
    return this.http.post(this.url+'/deleteevent', {"id":id}).toPromise()
  }
  public deletestand(id){
    return this.http.post(this.url+'/deletestand', {"id":id}).toPromise()
  }
  public registrados(id){
    return this.http.post(this.url+'/registers', {"id":id}).toPromise()
  }

  public register(event,user){
    return this.http.post(this.url+'/registerevent', {"event":event,"user":user}).toPromise()
  }


  public asistentes(id){
    return this.http.post(this.url+'/asistentes', {"id":id}).toPromise()
  }

  public ingreso(event,user){
    return this.http.post(this.url+'/ingresoevent', {"event":event,"user":user}).toPromise()
  }

  public preguntas(dia,mes,ano){
    return this.http.post(this.url+'/bitacora',{"dia":dia,"mes":mes,"ano":ano}).toPromise()
  }
  public pulls(id){
    return this.http.post(this.url+'/pulls', {"id":id}).toPromise()
  }
  public getvisitasEvento(evento){
    return this.http.post(this.url+"/visitasevento",{evento}).toPromise()
  }
  public getingresosEvento(evento){
    return this.http.post(this.url+"/ingresosevento",{evento}).toPromise()
  }
}
