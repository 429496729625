import { Component, OnInit } from '@angular/core';
import {ChangeDetectorRef} from '@angular/core'
declare var AWS:any;

@Component({
  selector: 'app-vizualizador',
  templateUrl: './vizualizador.component.html',
  styleUrls: ['./vizualizador.component.css'],
  
})
export class VizualizadorComponent implements OnInit {
s3;
files=[];
vids=[];
albumBucketName;
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.albumBucketName = 'procolombia';

   AWS.config.region = 'us-east-1'; // Región
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:1df579aa-4d54-4465-b7ad-e42f2c800bea',
    });
    
    // Create a new service object
  this.s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: {Bucket: this.albumBucketName}
    });
   this.listAlbums();
// setTimeout(()=>{console.log(this.files),2000})

  
  }

  listAlbums() {
    this.s3.listObjects({Delimiter: '/'}, (err, data)=> {
      if (err) {
        console.log('There was an error listing your albums: ' + err.message);
      } else {
              var albums=data.CommonPrefixes;
         albums= albums.map((x)=>{return x["Prefix"].replace("/","");})
        this.files=albums;
        this.cd.detectChanges();
        
       
      }
    });
  }

  viewAlbum(albumName) {
    var cd = this.cd;   
    var buketname=this.albumBucketName;
    var albumPhotosKey = encodeURIComponent(albumName) + '/';
    this.s3.listObjects({Prefix: albumPhotosKey}, function(err, data) {
   
      if (err) {
        console.log('There was an error viewing your album: ' + err.message);
      }
      // 'this' references the AWS.Response instance that represents the response
      var href = this.request.httpRequest.endpoint.href;
      var bucketUrl = href + buketname + '/';
  
      var photos = data.Contents.filter(function(photo){
        var photoKey = photo.Key;
        var photoUrl = bucketUrl + encodeURIComponent(photoKey);
        if (photoUrl.includes(".mp4")){
return true;
        }else{return false}
       
      } ).map(function(photo) {
        var photoKey = photo.Key;
        var photoUrl = bucketUrl + encodeURIComponent(photoKey);
        if (photoUrl.includes(".mp4")){
return photoUrl;
        }
       
        
       } ) 
    act(photos);
     
      }
    )
    
    const act=(photos)=>{
      this.vids=photos;
      console.log(this.vids);
      cd.detectChanges();

    }
   
  }
 
}
