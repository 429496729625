<header class="p-3  text-white">
    <div class="row">
        <div class="row">
            <div class="d-flex">
                <a href="/" class="d-flex align-items-between mb-2 mb-lg-0 text-white text-decoration-none">
                    <svg class="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap">
                        <use xlink:href="#bootstrap"></use>
                    </svg>
                </a>
                <div class="col-md-10">
                    <ul class="nav col-md-12 col-lg-auto me-lg-auto mb-2 justify-content-between mb-md-0 ">
                                               <li><button (click)="funcionario()" style="border-color: #04453b !important;" class="btn-header">Ver Funcionarios</button></li>
                        <li><button (click)="visitas()" style="border-color: #04453b !important;" class="btn-header">Ver Visitantes</button></li>
                        <li><button (click)="filtroN=0" style="border-color: #04453b !important;" class="btn-header">Ver Todos</button></li>
<!--                         <li><a (click)="verEmpresas()" style="color: #04453b; " class="nav-link px-3 btn btn-header">Ver empresas</a></li>
 -->                        <li><button (click)="VerBloqueado()"  style="border-color: #04453b !important;"   class="btn-header">Ver bloqueados</button></li>
                        <li><button (click)="porAprobar()" style="border-color: #04453b !important;" class="btn-header">Por Aprobar</button></li>
                        <li><button (click)="descargar2()" style="border-color: #04453b !important;" class="btn-header">Descargar</button></li>
                        <li><button  (click)="CargarTodos()" style="border-color: #04453b !important;" class=" btn-header">Cargar a todos</button></li>

                    
                    </ul>

                </div>
                <div class="col-md-6" style="margin-left: 20%;">
                    <strong style="color: #04453b; ">Bienvenido {{user.name}} {{user.apellido}}</strong>
                </div>
            </div>

        </div>
    </div>
</header>
<div *ngIf="vPersona" class="pop">
    <div class="row mt-5 justify-content-center ">
        <div class="col-md-6 col-10">
            <app-persona [user]="persona" [show]="false"></app-persona>
            <button class="btn btn-primary" (click)="vPersona=false">Cerrar</button>
        </div>


    </div>


</div>



<div *ngIf="popCategoria"
  style="background-color:rgba(1, 1, 1, 0.687); position:fixed; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 999999;"
  class="border d-flex align-items-center justify-content-center">


  <div class="card" style="width: 70%;">
    <div class="card-header">
      <strong>Categoría</strong> 
      <span style="float:right; cursor: pointer;" (click)="popCategoria = false">X</span>
    </div>
    <div class="card-body">

        <form [formGroup]="categoriaForm" (ngSubmit)="setCategoria()" novalidate="">

            <div class="form-group">
                Categoria
                <select class="form-control" formControlName="categoria">
                    <option value="">-seleccionar-</option>
                    <option value="ninera">Niñera</option>
                    <option value="masajista">Masajista</option>
                    <option value="socio">Socio</option>
                    <option value="invitado">Invitado</option>
                    <option value="funcionario">Funcionario</option>
                    <option value="caddie">Caddie</option>
                    <option value="profesortenis">Profesor de tenis</option>
                    <option value="profesorgolf">Profesor de golf</option>
                    <option value="saladebelleza">Sala de belleza</option>
                    
                
                </select>
                <div>
                    <button type="submit" class="btn btn-primary">Cambiar</button>
                </div>
    
            </div>


        </form>

        </div>
        </div>



  </div>



<div *ngIf="seting" class="bg-dark text-white w-50 container mt-5"
    style="position: fixed; top: 20%; left:25%; z-index: 9999;">
    {{c_user.name}}, cc: {{c_user.id}}, email: {{c_user.correo}}
    <button (click)="setFuncionario()" *ngIf="!c_user.funcionario">Habilitar ingreso como funcionario</button>
    <button (click)="setNoFuncionario()" *ngIf="c_user.funcionario">deshabilitar ingreso como funcionario</button>
    <form [formGroup]="rolForm" (ngSubmit)="setRols()" novalidate="">
        <div class="form-group">
            Rol
            <select formControlName="rol">
                <option value="usuario">usuario</option>
                <option value="admin">admin</option>
                <option value="areas">areas</option>
                <option value="ocupacional">ocupacional</option>
                <option value="porteria">porteria</option>
            </select>

        </div>

        <input type="submit" value="Fijar">
    </form>
    <button (click)="seting=false">Cancelar</button>
</div>




<!-- 
<div class="row justify-content-center " style="color: white;">
    <div class="col-md-6 ">
        <h4 style="font-size: 20px; font-weight: bold; text-align: center;">Usuarios</h4>
    </div>
</div> -->


<!-- <div class="row" >
<div class="col-12" >
<div class="row justify-content-center" >
<div class="col-md-2  col-4" >
<button class="btn btn-primary" (click)="verUsuarios()" >Ver usuarios</button>
</div>
<div class="col-md-2  col-4" >
    <button class="btn btn-primary" (click)="verEmpresas()" >Ver empresas</button>
    </div>
    <div class="col-md-2  col-4" >
        <button class="btn btn-primary" (click)="VerBloqueado()" >Ver bloqueados</button>
    </div>
</div>
</div>
</div> -->

<div *ngIf="vusers && !vempresas" class=" text-white">
    <div *ngIf="!usuariosEmpresa" class="row justify-content-start">
        <div class="col-9  d-flex justify-content-end  ">
        </div>
    </div>
<!--     <div *ngIf="!usuariosEmpresa" class="row   justify-content-start">
        <div class="container">
            <div class="d-flex justify-content-start">
                <div class="col-5 " style="color: #04453b !important">
                    Buscar por cédula
                    <br>
                    <input type="text" id="buscado" autocomplete="off">
                    <button class="btn btn-primary m-3" (click)="buscar('1')">Buscar</button>
                    <button class="btn btn-danger m-3" (click)="limpiar('1')">limpiar</button>
                </div>
                <div class="col-5 texto-color" style="color: #04453b !important">
                    Buscar por nombre:
                    <br>
                    <input type="text" id="buscado2" autocomplete="off">
                    <button class="btn btn-primary m-3" (click)="buscar('2')">Buscar</button>
                    <button class="btn btn-danger m-3" (click)="limpiar('2')">limpiar</button>
                </div>
            </div>
        </div>
    </div> -->
    <div *ngIf="usuariosEmpresa" class="row  m-1 justify-content-center">
        <div class="col-4">
            <h1>Usuarios por empresa {{empresaUsers.name}}</h1>
        </div>
        <div class="col-2">
            <button (click)="limpiar('1')" class="btn btn-primary">ver todos los usuarios</button>
        </div>
    </div>
    <div class="d-flex justify-content-center  mt-3">
        <input type="text" name="Buscador" class="form-control col-md-6" placeholder="Buscar por numero Documento o Nombre"  #txtSearch (keyup)="onSearch(txtSearch.value)">
      </div>
    <div class="row justify-content-center mt-3">
        <div class="col-11 caja-form2">


            <table >

                <cdk-virtual-scroll-viewport itemSize="50" style="width: 1250px; height: 500px;">
                <thead>
                    <tr class="">
                        <th>#</th>
                        <th>Cambiar rol</th>
                        <th>Categoria</th>
                        <th>Estado</th>
                        <th>Nombre</th>
                        <th style="width: 150px;">Correo</th>
                        <th>Documento</th>
                        <th>ARL</th>
                        <th>Empresa</th> 
                        <th>cargo</th>
                        <th>rol</th>
                        <th  style="width: 200px;">categoria</th>
                        <th>Eliminar</th>
                        <th>Bloquear</th>
                        <th>cargar a hikvision</th>
                    </tr>

                </thead>
                <tbody>
                      <tr *cdkVirtualFor="let usr of filtrar | paginate: { itemsPerPage: 7, currentPage: p  }; let i = index" class="cajainfo "
                        [ngClass]="{ 'verde': usr.funcionario }">
                    
                        <td> {{(i+p)+(((p-1)*6))}} </td>

                        <td style="padding-bottom: 2px;">
                            <button class="btn btn-primary" (click)="rol(usr)">Cambiar Rol</button>
                        </td>
                        <td style="padding-bottom: 2px;">
                            <button class="btn btn-primary" (click)="categoria(usr)">Cambiar Categoría</button>
                        </td>
                        <td>
                            <button *ngIf="usr.aprobe!=true" (click)="aprobar(usr)"
                                class="btn btn-primary">Aprobar</button>
                            <div *ngIf="usr.aprobe==true">Aprobado</div>
                        </td>

                        <td (click)="verPersona(usr)" style="cursor: pointer;">{{usr.name}} {{usr.apellido}}</td>
                        <td>{{usr.correo}}</td>
                        <td>{{usr.id}}</td>
                        <td>{{usr.eps}}</td>
                        <td>{{usr.arl}}</td>
                        <!-- <th>{{usr.empresa}}</th> -->
                        <td>{{usr.cargo}}</td>
                        <td>{{usr.rol}}</td>
                        <td>{{usr.categoria}} <br>

                           <p *ngIf="usr.categoria=='beneficiario'&& usr.socioname" style="color: #04453b;"> de: {{usr.socioname?.name}}-{{usr.socioname?.apellido}} </p> 
                        </td>

                        <td>
                            <button class="btn btn-danger" (click)="delete(usr)">Eliminar</button>
                        </td>
                        <td>
                            <button *ngIf="!usr.bloqueado" class="btn btn-danger"
                                (click)="blockUser(usr)">Bloquear</button>
                            <span *ngIf="usr.bloqueado">Bloqueado</span>
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="upload(usr)">Cargar</button>
                        </td>

                    </tr>
                </tbody>
                    

                  

                </cdk-virtual-scroll-viewport>
            </table>
            <div  class="container d-flex justify-content-center" >
                <pagination-controls (pageChange)="data($event)"
                [responsive]="true"></pagination-controls>
            
            </div>




        </div>
    </div>

</div>


<div *ngIf="bblockeusers" class=" text-white">


    <div class="row justify-content-center">
        <div class="col-11">
            <table class="w-100  text-white">
                <tr class="cajainfo">
                    <th>#</th>

                    <th>Nombre</th>
                    <th>Correo</th>
                    <th>id</th>
                    <th>EPS</th>
                    <th>ARL</th>
                    <!-- <th>Empresa</th> -->
                    <th>cargo</th>
                    <th>rol</th>
                    <th>Nota</th>
                    <th>Bloquear</th>

                </tr>
                <tr *ngFor="let usr of blocked; let i = index" class="cajainfo ">
                    <td>
                        {{i+1}}
                    </td>


                    <td>{{usr.name}} {{usr.apellido}}</td>
                    <td>{{usr.correo}}</td>
                    <td>{{usr.id}}</td>
                    <td>{{usr.eps}}</td>
                    <td>{{usr.arl}}</td>
                    <!-- <th>{{usr.empresa}}</th> -->
                    <td>{{usr.cargo}}</td>
                    <td>{{usr.rol}}</td>

                    <td>
                        <div *ngIf="usr.bloqueadoNota && usr.bloqueadoNota!=''">
                            <button class="btn btn-primary" (click)="verNota(usr)">Ver nota</button>
                            <br>
                            <span (click)="addNota(usr)" style="font-size: 12px; cursor: pointer;  color: brown; ">Edtar
                                nota</span>
                        </div>
                        <div *ngIf="!usr.bloqueadoNota || usr.bloqueadoNota==''">
                            <button class="btn btn-primary" (click)="addNota(usr)">agregar nota</button>
                        </div>

                    </td>
                    <td>
                        <button *ngIf="usr.bloqueado" class="btn btn-danger"
                            (click)="unBlockUser(usr)">Desbloquear</button>

                    </td>

                </tr>
            </table>
        </div>
    </div>

</div>


<div *ngIf="vempresas && !vusers" class=" text-white">
    <div class="row justify-content-center">
        <div class="col-11">
            <table class="w-100  text-white">
                <tr class="cajainfo">
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Añadir</th>
                    <th>nit</th>
                    <th>responsable</th>
                    <th>correo</th>
                    <th>telefono</th>
                    <th>registro</th>
                    <th>Eliminar</th>
                    <!-- <th>Empresa</th> -->

                    <!-- <th>Eliminar</th> -->

                </tr>
                <tr *ngFor="let usr of empresas; let i = index" class="cajainfo ">
                    <th>{{i+1}}</th>
                    <th style="max-width: 150px;">{{usr.name}} <button (click)="verUsuariosEmpresa(usr)">Ver
                            usuarios</button> </th>
                    <th><button (click)="anadirPersona(usr)"> vincular persona</button></th>
                    <th>{{usr.nit}}</th>
                    <th style="max-width: 150px;">{{usr.responsable.name}}</th>
                    <th>{{usr.responsable.correo}}</th>
                    <!-- <th>{{usr.empresa}}</th> -->
                    <th>{{usr.responsable.telefono}}</th>
                    <th>
                        <button (click)="copiar(usr.registerurl)"> copiar link registro</button>
                    </th>
                    <td>
                        <button class="btn btn-danger" (click)="deleteEmpresa(usr)">Eliminar</button>
                    </td>
                    <!-- <th>
                        <button class="btn btn-danger" (click)="delete(usr)" >Eliminar</button>
                    </th>
                 -->
                </tr>
            </table>
        </div>
    </div>

</div>
<div style="
    position: fixed;
    top: 1%;
    left: 10%;
    width: 80%;
    z-index: 99;
    background-color: rgb(71, 71, 241);
    color: white !important;
    padding: 15px;
    max-height: 70%;
    overflow-y: auto;
    " *ngIf="anadir">
    <h1>Agregue personas a: {{empresasol.name}} </h1>
    <div *ngIf="!nuser">
        Ingrese las cédulas de las personas a agregar (puede pegar desde una hoja de calculo) : <br>
        <textarea cols="20" rows="3" id="marcauno" autocomplete="off" #marcauno></textarea>
        <button type="button" (click)="marcaruno()"> Agregar</button>
    </div>

    <!-- <form  *ngIf="!nuser" [formGroup]="idrperson" (ngSubmit)="lookPerson()"   >

        <div class="form-group">
            <h3>Documento de identidad:</h3>
            <input class="form-control" type="number" placeholder="Documento de identidad" formControlName="id" >
            
        </div>
        <input type="submit" class="btn btn-primary" value="Buscar">
    </form> -->
    <div *ngIf="nuser">
        <!-- <div class="row mt-4 justify-content-center" >
            <div class="col-5" >
                <app-persona [user]="luser" [show]="false" ></app-persona>    
            </div>
        </div>
        <button class="btn btn-primary"  (click)="confirmAddPerson()" >Confirmar</button> -->

        <div class="row">
            <div class="col-md-6">
                <span>documentos a agregar: {{agregados.length}}</span><br>
                <span *ngFor="let agregado of agregados">-{{agregado}} <br> </span>
            </div>

        </div>
        <button class="btn btn-primary" (click)="anadirpersonas()">Confirmar</button>
    </div>
    <button class="btn btn-danger" (click)="anadir=false">Cancelar </button>
</div>