import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import {SocketioService} from "../../services/socketio.service";
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import  Swal from 'sweetalert2'; 

import {OpenviduSessionComponent, StreamEvent, Session, UserModel, OpenViduLayout, OvSettings, OpenViduLayoutOptions, SessionDisconnectedEvent, Publisher} from 'openvidu-angular';

import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-taller',
  templateUrl: './taller.component.html',
  styleUrls: ['./taller.component.css']
})
export class TallerComponent implements OnInit {

  audio;
  user;
  event;
  activePull;
  pull_txt;
  callactive;

  connectedUsers=[];
 
  sub: Subscription;
  existingUsrs=[];
  eDisplay="none";
  @ViewChild("chat") chat: ElementRef;
  @ViewChild("btnChat") btnChat: ElementRef;



//openvidu
OPENVIDU_SERVER_URL = 'https://selesoluciona.com';
OPENVIDU_SERVER_SECRET = 'ipservices3715';

// Join form
mySessionId = 'SessionA';
myUserName = 'Participant' + Math.floor(Math.random() * 100);
tokens: string[] = [];
session = false;

ovSession: Session;
ovLocalUsers: UserModel[];
ovLayout: OpenViduLayout;
ovLayoutOptions: OpenViduLayoutOptions;
ovSettings: OvSettings;
@ViewChild('ovSessionComponent')
public ovSessionComponent: OpenviduSessionComponent;
//end-openvidu



  constructor(private eventService : EventsService, 
    private route: ActivatedRoute, private router : Router,
    private socketservice: SocketioService,
    private renderer: Renderer2,
    private elRef:ElementRef,
    private httpClient: HttpClient
    ) { 

  } 
  ngOnDestroy() {
 this.socketservice.close();
  }

  ngOnInit(): void {
    this.ovSettings = {
      chat: false,
      autopublish: true,
      toolbar:true,
       footer:true,
      toolbarButtons: {
        audio: true,
        video: true,
        layoutSpeaking:true,
        screenShare: true,
        fullscreen: true,
        exit: false,
      }
    };
  
    this.audio= new Audio();
   this.audio.src ="../../../assets/ring.mp3";
  //var audio =this.elRef.nativeElement.querySelector('#audio');
  this.audio.load();
 
    
   const manage=this.manage;
    this.sub= this.socketservice.setupSocketConnection()
   .subscribe({
    next(_mesage) {
      //console.log('Mensage recibido: ', _mesage);
     manage(_mesage);  
    }
   });
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(user);
let id=this.route.snapshot.paramMap.get("id")
this.loadEvent(id);
console.log("id: "+id);


  }

manage=(msg)=>{
  const existingUsers=(usrs)=>{
    var usuarios=[];
    console.log(usrs);
    usrs.forEach(element => {
      if(element.correo != this.user.correo){
        var id=element.correo.replace("@", "11");
        id=id.replace(/\./g, "11");
        element.id=id;
        usuarios.push(element);
      }
    });
   this.existingUsrs=usuarios;
    }
  const addUser=(usr)=>{
  if(this.user.correo != usr.correo){
    var id=usr.correo.replace("@", "11");
    id=id.replace(/\./g, "11");
    usr.id=id;
var usuario ={"name":usr.name ,"id":usr.id, "correo":usr.correo,"rol":usr.rol}
this.existingUsrs.push(usuario);
  } 
  }
  const userLeft =(usr)=>{
    var i=0;
    console.log("userleft"+usr.correo);
    this.existingUsrs.forEach(us=>{
if(us.correo==usr.correo){
this.existingUsrs.splice(i, 1);
}
i=i+1;
    })
  }

const reciveChat =(msg)=>{
  try{
 this.audio.play();
// setTimeout(()=>{
//  this.audio.pause();
//  },700);
console.log(msg);
//if (this.user.rol=="admin"){
  var id=msg.from.replace("@", "11");
  id=id.replace(/\./g, "11");
  
  var place = this.elRef.nativeElement.querySelector('#chat_txt'+id);
  this.elRef.nativeElement.querySelector('#li'+id).innerHTML=msg.fromName+" (1)";
   place.appendChild(document.createTextNode(msg.fromName+": "));
  place.appendChild(document.createTextNode(msg.text));
  place.appendChild(document.createElement("br"));
  place.scrollTop = place.scrollHeight;
//}
// else if(this.user.rol=="usuario"){
//   var place = this.elRef.nativeElement.querySelector('#chat_txtadmin1');
//   this.elRef.nativeElement.querySelector('#btnChat').innerHTML="Solicitar videollamada (1)";
//   place.appendChild(document.createTextNode(msg.fromName+": "));
//   place.appendChild(document.createTextNode(msg.text));
//   place.appendChild(document.createElement("br"));
//   place.scrollTop = place.scrollHeight;

// }
}
catch(err){}

}
const callin=(msg)=>{
let user=this.user;
  Swal.fire(     {
    title: "Estas recibiendo una llamada de "+msg.from,
    text:"¿que deseas hacer?",
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Aceptar',
    cancelButtonText: "Rechazar"
    
}).then((result)=>{
  var mesg={};
if(result.value){
this.joinSession();
 mesg={
  "id":"callResponse",
  "response":"accept",
  "to":msg.emailfrom,
  "from":user.name,
  "emailfrom":user.correo
}
this.socketservice.send(mesg);
}
else{
  mesg={
    "id":"callResponse",
    "response":"refuse",
    "to":msg.emailfrom,
    "from":user.name
  }
  this.socketservice.send(mesg);
}
   });


}
const callResponse=(msg)=>{
if(msg.response=="accept"){
  this.closealert();
this.joinSession();
var usr={"name":msg.from,"correo":msg.emailfrom}
this.connectedUsers.push(usr);
console.log(this.connectedUsers);

}
else if(msg.response=="refuse"){
this.closealert();
Swal.fire(msg.from+"ha rechazado tu llamada ...");

}
}

const colgar=(msg)=>{
  this.ovSession.disconnect();
this.session=false;
}

const recivePull=(msg)=>{
  this.activePull=true;
    //this.elRef.nativeElement.querySelector('#pull').value=msg.pull;
    this.pull_txt=msg.pull;
  
  }
  const closePull=()=>{
    this.activePull=false;
  }

    var p_msg=JSON.parse(msg);
    console.log(p_msg);
  switch (p_msg.id){
  
    case "newUser":
      addUser(p_msg.user);
      //console.log(p_msg);
      break; 
    case "existingUsers":
      //console.log(p_msg.users);
      existingUsers(p_msg.users);
      break;
      case "userLeft":
        userLeft(p_msg.user);
        break;
      case "reconnect":
        this.joinRoom(this.user,this.event);
        break;
      case "reciveChat":
      reciveChat(p_msg);
      break;
      case "recivePull":
        recivePull(p_msg);
        break;
       case "closePull":
        closePull();
          break;
       case "callin":
            callin(p_msg);
            break;
    case "callResponse":
      callResponse(p_msg);
      break;
      case "colgar":
        colgar(p_msg);
        break;
 
  }


    
  
  }

addUser(user){

}


sendPull(){
  var pull=this.elRef.nativeElement.querySelector('#spull').value;
  this.elRef.nativeElement.querySelector('#spull').value="";
  var msg={
    "id":"sendPull",
    "pull":pull,
    "event":this.event._id,
  }
  this.socketservice.send(msg);
}

closePull(){
  var msg={
    "id":"closePull",
    "event":this.event._id,
  }
  this.socketservice.send(msg);
}


pull(ans){
  this.activePull=false;
  var msg={
    "id":"pull",
    "pull":ans,
    "event":this.event._id,
    "user":this.user
  }
  this.socketservice.send(msg);
}

sendQuestion(){
  var question=this.elRef.nativeElement.querySelector('#pregunta').value;
 this.elRef.nativeElement.querySelector('#pregunta').value="";
  var msg={
    "id":"sendQuestion",
    "question":question,
    "event":this.event._id,
    "user":this.user
  }
  this.socketservice.send(msg);
}



loadEvent(id){
    this.eventService.load(id)
  .then((response) => {
    console.log(response);
    this.event=response["event"]
    this.joinRoom(this.user,this.event);
  })
  .catch((err)=>{
    this.router.navigate(['']);
  })
}

joinRoom(usr,event){
this.socketservice.joinRoom(usr,event);
}

openChat(){
  this.renderer.setStyle(this.chat.nativeElement, 'display', 'block');
  this.renderer.setStyle(this.btnChat.nativeElement, 'display', 'none');
  this.elRef.nativeElement.querySelector('#btnChat').innerHTML="Chat";
}

closeChat(){
  this.renderer.setStyle(this.chat.nativeElement, 'display', 'none');
  this.renderer.setStyle(this.btnChat.nativeElement, 'display', 'block');
}

closeChatAdmin(correo){
  var div = this.elRef.nativeElement.querySelector('#chat'+correo);
  div.style.display="none";
  
}

openChatAdmin(correo){
  console.log(correo);
  var div = this.elRef.nativeElement.querySelector('#chat'+correo);
  div.style.display="block";
  var text=this.elRef.nativeElement.querySelector('#li'+correo).innerHTML
 var ind= text.search("(1)");
  if(ind==-1){
    this.elRef.nativeElement.querySelector('#li'+correo).innerHTML=text;
  }
  else {
    var ftext=text.slice(0,ind-1);
    this.elRef.nativeElement.querySelector('#li'+correo).innerHTML=ftext;
  }
  
}

downUsers(){
  let csvContent = "data:text/csv;charset=utf-8,";
this.existingUsrs.forEach((usr)=>{
  let u=[]
  u.push(usr.name);
  u.push(usr.correo);
  let row = u.join(",");
  csvContent += row + "\r\n";
})
var now= new Date();
var h=now.getHours();
var m= now.getMinutes();
saveAs(csvContent,"auddiencia_"+this.event.name+"-"+h+":"+m+".csv");
}


calluser(user){
console.log("call to "+user);

  
       var message = {
        id : 'calluser',
        name : this.user.name,
        from:this.user.correo,
         to: user.correo 
      };
      this.socketservice.send(message);
       Swal.fire("llamando a: "+user.name+" ...");
      setTimeout(this.closealert,3000);
    
}
closealert(){
  Swal.close();
}

colgar(correo){
  var message = {
    id : 'colgar',
     to: correo 
  };
  this.socketservice.send(message);
  var i=0;
  this.connectedUsers.forEach(element => {
    console.log(element);
    if(element.correo==correo){
this.connectedUsers.splice(i,1)
    }
    i=i+1;
  });
if(this.connectedUsers.length==0){
this.cerrar_llamada();
}
}

cerrar_llamada(){
  this.connectedUsers.forEach(element => {
    this.colgar(element.correo);
  });
  this.ovSession.disconnect();
  this.session=false;
}

//openvidu methods

async joinSession() {
  const token1 = await this.getToken();
  const token2 = await this.getToken();
  this.tokens.push(token1, token2);
  this.session = true;
}

handlerSessionCreatedEvent(session: Session): void {
this.ovSession=session;
  // You can see the session documentation here
  // https://docs.openvidu.io/en/stable/api/openvidu-browser/classes/session.html

  console.log('SESSION CREATED EVENT', session);

  session.on('streamCreated', (event: StreamEvent) => {
    // Do something
  });

  session.on('streamDestroyed', (event: StreamEvent) => {
    // Do something
  });

  session.on('sessionDisconnected', (event: SessionDisconnectedEvent) => {
    this.session = false;
    this.tokens = [];
  });

  this.myMethod();

}

handlerPublisherCreatedEvent(publisher: Publisher) {

  // You can see the publisher documentation here
  // https://docs.openvidu.io/en/stable/api/openvidu-browser/classes/publisher.html

  publisher.on('streamCreated', (e) => {
    console.log('Publisher streamCreated', e);
  });

}

handlerErrorEvent(event): void {
  // Do something
}

myMethod() {
  this.ovLocalUsers = this.ovSessionComponent.getLocalUsers();
  this.ovLayout = this.ovSessionComponent.getOpenviduLayout();
  this.ovLayoutOptions = this.ovSessionComponent.getOpenviduLayoutOptions();

}

/**
 * --------------------------
 * SERVER-SIDE RESPONSIBILITY
 * --------------------------
 * This method retrieve the mandatory user token from OpenVidu Server,
 * in this case making use Angular http API.
 * This behavior MUST BE IN YOUR SERVER-SIDE IN PRODUCTION. In this case:
 *   1) Initialize a session in OpenVidu Server	 (POST /api/sessions)
 *   2) Generate a token in OpenVidu Server		   (POST /api/tokens)
 *   3) The token must be consumed in Session.connect() method of OpenVidu Browser
 */

getToken(): Promise<string> {
  return this.createSession(this.mySessionId).then((sessionId) => {
    return this.createToken(sessionId);
  });
}

createSession(sessionId) {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({ customSessionId: sessionId });
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
        'Content-Type': 'application/json',
      }),
    };
    return this.httpClient
      .post(this.OPENVIDU_SERVER_URL + '/api/sessions', body, options)
      .pipe(
        catchError((error) => {
          if (error.status === 409) {
            resolve(sessionId);
          } else {
            console.warn('No connection to OpenVidu Server. This may be a certificate error at ' + this.OPENVIDU_SERVER_URL);
            if (
              window.confirm(
                'No connection to OpenVidu Server. This may be a certificate error at "' +
                  this.OPENVIDU_SERVER_URL +
                  '"\n\nClick OK to navigate and accept it. If no certificate warning is shown, then check that your OpenVidu Server' +
                  'is up and running at "' +
                  this.OPENVIDU_SERVER_URL +
                  '"',
              )
            ) {
              location.assign(this.OPENVIDU_SERVER_URL + '/accept-certificate');
            }
          }
          return observableThrowError(error);
        }),
      )
      .subscribe((response) => {
        console.log(response);
        resolve(response['id']);
      });
  });
}

createToken(sessionId): Promise<string> {
  return new Promise((resolve, reject) => {
    const body = JSON.stringify({ session: sessionId });
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
        'Content-Type': 'application/json',
      }),
    };
    return this.httpClient
      .post(this.OPENVIDU_SERVER_URL + '/api/tokens', body, options)
      .pipe(
        catchError((error) => {
          reject(error);
          return observableThrowError(error);
        }),
      )
      .subscribe((response) => {
        console.log(response);
        resolve(response['token']);
      });
  });
}



}