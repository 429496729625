<!-- <div  class="row justify-content-center">
<div class="col-8" style="text-align: center;" >
    <select class="form-control" #mycamara (change)="seleccionarCamara(mycamara.value)">
        <option value="">-Selecciona-</option>
        <option  *ngFor="let camara of camaras" [value]="camara.deviceId">{{camara.label}}</option>
    </select>
    
    <zxing-scanner [(device)]="desiredDevice" (scanSuccess)="scanSuccessHandler($event)" (camerasFound)="camerasFoundHandler($event)"></zxing-scanner>
    
</div>
<div class="col-4" >
       
        <form  [formGroup]="manillaForm" (ngSubmit)="addmanilla()">
            <div class="form-group">
                <h3 style="color: white;" >Ingrese o lea el numero de la manilla</h3>
                <input class="form-control" type="text"  placeholder="número de manilla" formControlName="id" [ngClass]="{ 'is-invalid': submitted && f4.id.errors }">
                <div *ngIf="submitted && f4.id.errors" class="invalid-feedback ">
                    <div *ngIf="f4.id.errors.required">ingrese el dato</div>
                   
                </div> 
            </div>
            <input type="submit" value="Ingresar" class="btn btn-info">
        
        </form>

</div>
</div> -->

<div  class="row justify-content-center">
    <div class="col-8 " style="text-align: center;" >
        <select #listcameras (change)="selectedCamera(listcameras.value)">
            <option value="">-</option>
            <option *ngFor="let camera of cameras" value="{{camera.id}}">{{camera.label}}</option>
        </select>
        
        <video  playsinline  #video1  width="80%" height="auto" ></video>
    </div>
    <div class="col-4" >
           
            <form  [formGroup]="manillaForm" (ngSubmit)="addmanilla()">
                <div class="form-group">
                    <h3 style="color: white;" >Ingrese o lea el numero de la manilla</h3>
                    <input class="form-control" type="text"  placeholder="número de manilla" formControlName="id" [ngClass]="{ 'is-invalid': submitted && f4.id.errors }">
                    <div *ngIf="submitted && f4.id.errors" class="invalid-feedback ">
                        <div *ngIf="f4.id.errors.required">ingrese el dato</div>
                       
                    </div> 
                </div>
                <input type="submit" value="Ingresar" class="btn btn-info">
            
            </form>
    
    </div>
    </div>