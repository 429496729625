import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import QrScanner from 'qr-scanner';
import { FormControl, FormGroup, Validators } from '@angular/forms'; 
@Component({
  selector: 'app-lector',
  templateUrl: './lector.component.html',
  styleUrls: ['./lector.component.css']
})
export class LectorComponent implements OnInit {

  data: boolean=false;
  manilla: any;
  result: any
  cameras:any;
  qrScanner: any;
  @Output() newCode =new EventEmitter();
  @ViewChild('video1', { static: false}) videoElement: ElementRef;
  inter=null;
  ipersonForm: FormGroup;
  submitted: boolean;
  constructor() { }

  ngOnInit(): void {
    this.inter=setInterval(()=>{
      console.log("i ")
    if(this.videoElement.nativeElement){
      this.iniciar();
    }
    },100)
  }

  changeInput(event){

    console.log( this.ipersonForm.controls['id'].value)
    //this.ipersonForm.controls['id'].value= this.ipersonForm.controls['id'].value.replace(/^0+/, '');
    var value=this.ipersonForm.controls['id'].value.replace(/\s/g, '');
    value=value.replace(/^0+/, '');
    this.ipersonForm.controls['id'].patchValue(value)
    }
    
  title = 'qr';
  camaras:any = [] ;
  desiredDevice:any = "";
  selectedCamera(id){
    console.log(id)
    this.qrScanner.setCamera(id);
  }
  iniciar(){
    clearInterval(this.inter);
        this.qrScanner = new QrScanner(this.videoElement.nativeElement,(result) => {
         
          //console.log("RES", this.result.data)
          
          if (result) {
           
           console.log("resultado: ",result);
              
              this.newCode.emit(result.data)
              this.qrScanner.stop()
              
              this.qrScanner.destroy()
            
    
            //this.destroy();
            
           // this.ngOnInit();
            // return
      
          }
       
        }, {
          highlightScanRegion: true,
          highlightCodeOutline: true });
      
        this.qrScanner.start()
        QrScanner.listCameras().then((r)=>{
          console.log(r)
          this.cameras = r
        })
      }
}
