<div class="d-flex justify-content-center m-0" >
    <button class=" btn btn-danger" (click)="logout()">Salir</button>
</div>



<div style="padding: 30px; text-align: center;">
    <h3 style="color: white">Bienvenido,</h3>
    <p style="color: white">{{user.name}}.<p>
      


  

      <div class="row justify-content-center">
        <div class="col-11 col-md-6">
          <div style="text-align: left;" *ngFor="let stand of stands" >
          <div >
            <img width="100%" src={{stand.imgSrc}}>
            <div style="margin-top: 2px; padding: 20px; background-color: #eaeaea;  border-radius: 2px 2px 2px 2px">
              <div><strong> {{stand.name}}</strong></div>
            
          
               <div >
                <img width="48%" (click)="ingresar(stand,stand._id)"  src="/assets/boton_ingresar.png">
               
                - 
            </div >
    
            <button class="btn btn-danger" (click)="removeEvent(stand._id)" *ngIf="user.rol=='admin'">Eliminar</button> <br>
            <button class="btn btn-danger" (click)="edit(stand)" *ngIf="user.rol=='admin'">Editar</button> <br>
            <button class="btn btn-info" (click)="registrados(stand._id,stand.name)" *ngIf="user.rol=='admin'">Registrados</button>
            <button class="btn btn-info" (click)="asistentes(stand._id,stand.name)" *ngIf="user.rol=='admin'">Asistentes</button>

            </div>

           

          </div>

          <br>
        </div>




          <br>


  

        </div>
      </div>
    </div>





<div class="container w-50 bg-dark p-2 rounded-top shadow text-white" *ngIf="user.rol=='admin'">
    <form [formGroup]="createStand" (ngSubmit)="newStand()" novalidate="">    
        <div class="form-group" *ngIf="createStand.controls['id'].value!='0'" > 
        <h3>Id</h3>
        <input type="text" class="form-control" formControlName="id" readonly>
        </div>
        <div class="form-group">
            <h3>Ingrese el poster del Stand</h3>     
            <input class="form-control" type="file" (change)="onFileChange($event)" placeholder="png, jpg" formControlName="img" required>
            </div>
         <div class="form-group">
         <h3>Ingrese el nombre del Stand</h3>     
         <input class="form-control" type="text" placeholder="Stand" formControlName="name" required>
         </div>
    
 
        <div class="form-group">
          <h3>Ingrese la url del iframe derecho:</h3>     
       <input class="form-control" type="text" placeholder="https://" formControlName="riframe" required>
      </div>
      <div class="form-group">
        <h3>Ingrese la url del iframe izquierdo:</h3>     
     <input class="form-control" type="text" placeholder="https://" formControlName="liframe" required>
    </div>
         <input type="submit" value="Guardar" class="btn btn-info">
    
    </form>
    </div>


    
