<div class="row m-1">
    <div class="col-md-2">
        <button class="btn btn-primary" routerLink="/">volver</button>
    </div>
  
</div>
<div class="row justify-content-center">
    <div class="col-11 col-md-5 " >
        <div style="padding: 30px;" class="caja-form2" *ngIf="validaB == 1">
                <h1 class="text-center color-texto">¿Para quien deseas reservar?</h1>
                <div class="row justify-content-between">
                    <button class="btn btn-success" (click)="paraMi()">Para Mi</button>
                    <button class="btn btn-success" (click)="cargarBene()">Para uno de mis Beneficiarios</button>

                </div>
        </div>

        <div style="padding: 30px;" class="caja-form2" *ngIf="validaB == 3">
            <div class="caja-form2" *ngFor="let beneficiario of beneficiarios">
                Nombre: {{ beneficiario.name }} {{ beneficiario.apellido }}
                <br>
                Documento: {{ beneficiario.id }}
                <br>
                <label for="">Selecciona Beneficiarios a Agregar</label>
                <button class="btn btn-primary" (click)="agregarBeneficiario(beneficiario)">Agregar</button>

<!--                 <input type="button" [disabled]="beneficiarioSeleccionado"  (change)="agregarBeneficiario(beneficiario)">
 -->                <hr style="border: 1px solid navy; background-color: navy;">
              </div>
              
        </div>
    </div>
        
    <div *ngIf="validaB == 2" class="col-11 col-md-8 ">
        <div style="padding: 30px;" class="caja-form2">
            <div class="row justify-content-between">

                <h1 class="color-texto">RESERVAR {{this.types[this.type].title | uppercase}}</h1>
                <h3>{{beneficiarioSeleccionado ? beneficiarioSeleccionado.name + " " + beneficiarioSeleccionado.apellido : this.user.name + " " + this.user.apellido}}</h3>
                <button class="btn btn-primary" (click)="regresar()">Regresar</button>
            </div>
            <!-- <h2>para el día {{manana}}</h2> -->
        
            <h2 *ngIf="reservasuser.length > 0 && user.rol != 'admin' ">Reservas activas</h2>
            <div class="row">
                <div class="col-md-4 reserva-activa m-2" *ngFor="let r of reservasuser" >
                    <strong>Día: </strong> {{r.date}}
                    <br>
                    <strong>Lugar: </strong> {{r.fieldType}} {{r.field}}
                    <br>
                    <strong>Hora: </strong> {{r.hour}}
                </div>
            </div>
        
            <div *ngIf="user.rol == 'admin' || user.rol == 'areas'" class="row">
                <div class="col-md-11">
                    <label>Cambiar día</label>
                    <input type="date" (change)="datechange($event)" class="form-control" value="">
                </div>
            </div>
        
            <br>
        
        
            <div *ngIf="user.rol == 'admin'|| user.rol == 'areas'" class="row">
                <div class="col-md-11">
                    <div class="col-md-4" *ngFor="let r of reservas" class="reserva-activa">
                        <strong>Día: </strong> {{r.date}}
                        <br>
                        <strong>Lugar: </strong> {{r.fieldType}} {{r.field}}
                        <br>
                        <strong>Hora: </strong> {{r.hour}}
                        <br>
                        <strong>Reserva: </strong> {{r.userName}}
                    </div>
                </div>
            </div>
        
            <br>
        
        
        
        
            <div *ngIf="user.rol != 'admin'|| user.rol != 'areas'" class="row">
                <div class="col-md-11">
                    <label>Día :</label>
                    <select class="form-control" (change)="daychange($event)" placeholder="seleccionar día">
                        <option value="">-seleccionar día-</option>
                        <option value="{{hoy}}">Hoy</option>
                        <option value="{{manana}}">Mañana</option>
                    </select>
                </div>
            </div>
        
        
        
            <br>
            <div *ngIf="this.types[this.type].fields.length > 0" class="row">
                <div *ngIf="dia != '' "  class="col-md-11">
                    <label>{{this.types[this.type].title | titlecase}} :</label>
                    <select class="form-control" (change)="typechange($event)" placeholder="seleccionar hoyo">
                        <option value="">-seleccionar {{this.types[this.type].fieldtype}}-</option>
                        <option *ngFor="let f of this.types[this.type].fields" value="{{f.value}}">{{f.name}}</option>
                    </select>
                </div>
            </div>
        
            <br>
            <div *ngIf="user.rol == 'admin'"  class="row">
                <div class="col-md-3">
                    <button class="btn btn-primary" (click)="reservar_todo_el_dia()">Bloquear el día</button>
                </div>
            </div> 
        
            <br>
        
            <div *ngIf="dias" class="row" style=" background-color: white;">
                <div *ngFor="let hora of horas; let i = index" class="col-md-12 cajahora">
                    <div class="row p-1">
                        <div class="col-8" style="font-size: 35px;">
                            <strong>{{hora.hora}}</strong><br>
                            <!-- <span style="font-size: 18px;" >
                                Reservado por {{hora.reservo}}
                            </span> -->
                            <span style="font-size: 21px;" *ngIf="hora.reservado ">
                                Reservado por {{hora.reservo}} <br>
                                Participantes:{{hora.reserva.registers.length}} <br>
                                <div>
                                    <span *ngFor="let participante of hora.reserva.registers " >
                                   - {{participante.name}}  {{participante.apellido}}   <br>   
                                    </span>
                                </div> 
                                <span *ngIf="hora.reserva.userId==user.id || user.rol=='admin' || user.rol=='areas'" >
                                    Estado: <span *ngIf="hora.reserva.stat==0" >Por confirmar <br>
                                        
                                        link: <a href="https://socios.serrezuela.com/vinculacion-reserva/{{hora.reserva._id}}" target="_blank" rel="noopener noreferrer">https://socios.serrezuela.com/vinculacion-reserva/{{hora.reserva._id}}</a> 
                                        <button class="btn btn-primary p-0" (click)="copiar('https://socios.serrezuela.com/vinculacion-reserva/'+hora.reserva._id)" >copiar</button>
                                        <br>
        
                                          <span id="min{{hora.reserva._id}}" ></span> : <span id="seg{{hora.reserva._id}}"
                                        
                                          ></span> </span>
                                    <span *ngIf="hora.reserva.stat==1" >Confirmado</span>
                                </span>
                         
                            </span>
                            <div *ngIf="(user.rol == 'admin' && hora.reservo) || (user.rol == 'areas'&& hora.reservo)">
                                <button *ngIf="(user.rol == 'admin' && hora.reservo) || (user.rol == 'areas'&& hora.reservo)" (click)="reservalector(hora.idreserva, i)"
                                type="button" class="btn btn-secondary">Validar ingreso</button>
                                <h2 style="color: #333;">Ingresos</h2>
                            <ul style="font-size: 18px;">
                                <li *ngFor="let ingreso of hora.ingresos">{{ingreso.fullname}} {{ingreso.date | date:
                                    'dd/MM/yyyy hh:mm a'}}</li>
                            </ul>
                            </div>
                        </div>
                        <div class="col-3" style="text-align: center; padding-right: 10px ;">
                            <button *ngIf="!hora.reservado" type="button" (click)="reservar(i, hora.hora)"
                                class="btn btn-primary">Reservar</button>
        
                            <button *ngIf="hora.reservo == fullname" (click)="cancelar(hora.idreserva, i)" type="button"
                                class="btn btn-danger">Cancelar reserva</button>
                         
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-11">
        
                </div>
            </div>
        
        
        
        </div>
    </div>
</div>

<div *ngIf="poplector" style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: white; z-index: 9999999999999999;">
<div (click)="poplector = false" style="float: right; font-size: 40px; padding: 10px; cursor: pointer;" >X</div>
<app-lector-reservas [idr]="idr"></app-lector-reservas>
</div>
