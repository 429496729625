import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  miFormulario: FormGroup;
  submitted;
  pay;
  user;
  loading;
  stream;
  videoWidth = 0;
  videoHeight = 0;
  @ViewChild('video', { static: false }) videoElement: ElementRef;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  constructor(private authService: AuthService, private router: Router, private renderer: Renderer2) {

    this.miFormulario = new FormGroup({

      'correo': new FormControl('', [
        Validators.required,
        Validators.email
      ]),

      // "recaptchaReactive": new FormControl("", [
      //   Validators.required,

      // ])
    });


  }

  ngOnInit() {


  }
  async resolved(captchaResponse: string) {
    console.log(`Resolved response token: ${captchaResponse}`);
    await this.sendTokenToBackend(captchaResponse);
  }

  sendTokenToBackend(tok) {
    //calling the service and passing the token to the service
    this.authService.sendToken(tok).subscribe(
      data => {
        console.log(data)

      },
      err => {
        console.log(err)
      },
      () => { }
    );
  }
  ocultar_modal() {
    document.getElementById("loadfondo").style.display = "none";

  }
  get f() { return this.miFormulario.controls; }
  login() {
    this.submitted = true;
    if (this.miFormulario.invalid) {
      console.log("invalido");
      return;
    }
    this.authService.validate(this.miFormulario.get("correo").value)
      .then((response) => {
        console.log(response);


        if (response["user"]["pay"] == "premium") {
          this.activeVideo();
          this.pay = true;
          this.user = response["user"];
        }
        else {
          if (true) {
            this.authService.setUserInfo({ 'user': response['user'] });
            this.router.navigate(['agenda']);
          }
          else {
            Swal.fire("Tu registro esta en proceso de validación, recibiras un correo de confirmación")
          }

        }

      })
      .catch((err) => {

        Swal.fire({
          title: "No encontramos tu correo ",
          text: "Si no te has registrado, por favor registrate",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#89c9b8',
          confirmButtonText: 'Registrarme',
          cancelButtonText: "Reintentar"

        }).then((result) => {
          if (result.value) {

            this.router.navigate(['register']);

          }
          else { }
        });


      })
  }



  miSubmit() {
    console.log(this.miFormulario.value);
    console.log(this.miFormulario);
  }


  handleError(error) {
    console.log('Error: ', error);
  }

  attachVideo(stream) {

    this.stream = stream;
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
      this.videoHeight = this.videoElement.nativeElement.videoHeight;
      this.videoWidth = this.videoElement.nativeElement.videoWidth;
    });

  }

  activeVideo() {

    var constraints = {
      video: {

        width: { ideal: 640 },
        height: { ideal: 480 }
      }
    };
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
    } else {
      alert('Sorry, camera not available.');
    }



  }

  tomar() {
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
    var data = this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
    this.pay = false;
    this.loading = true;
    this.authService.validateP(data, this.user.correo)
      .then((response) => {
        this.authService.setUserInfo({ 'user': response['user'] });
        localStorage.setItem('access_token', response["token"]);
        this.router.navigate(['home']);
        this.stream.getTracks()[0].stop();
      })
      .catch((err) => {
        console.log(err);
        alert("los rostros no coinciden");
        this.pay = false;
        this.loading = false;
        this.router.navigate(['login']);
      })

  }


}
