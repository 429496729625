import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  submitted = false;
  registerForm: FormGroup;
  rpersonForm;
id
foto1Url="nofoto";
constructor(    private route: ActivatedRoute,private authService : AuthService, private router : Router) {


  

   }

  ngOnInit(): void {
   this.id=this.route.snapshot.paramMap.get("id")
   this.rpersonForm = new FormGroup({
    'name': new FormControl('', [
      Validators.required,
      Validators.minLength(1)
    ]),
    'apellido': new FormControl('', [
      Validators.required,
      Validators.minLength(1)
    ]),
    'correo': new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    'id': new FormControl('', [
      Validators.required,
      Validators.min(1111)
    ]),
    'empresa': new FormControl(this.id, [
      Validators.required,
  
    ]),
    'cargo': new FormControl('', [
      Validators.required,
  
    ]),
  
    'telefono': new FormControl('', [
      Validators.required,
      Validators.min(1111)
    ]),
     'arl': new FormControl('', [
      Validators.required,
  
    ]),
    'eps': new FormControl('', [
      Validators.required,
  
    ]),
    'contacto': new FormControl('', [
      Validators.required,
  
    ]),
    'contactotel': new FormControl('', [
      Validators.required,
  
    ]),
  
    'rol': new FormControl('usuario', [
      Validators.required,
  
    ]),
    'tipo': new FormControl('persona', [
      Validators.required,
  
    ]),
    'categoria': new FormControl('socio', [
      Validators.required,
  
    ]),
    'aprobe': new FormControl(false, [
      Validators.required,
  
    ]),
  
  
  
  });
  }

  get f2() { return this.rpersonForm.controls; }

  registerp(){
    this.submitted = true;
    this.rpersonForm.patchValue({
      rol:"usuario",
      tipo:"persona",
      categoria:"socio",
      aprobe:false
    })
    console.log(this.rpersonForm.controls);
    if (this.rpersonForm.invalid) {
      console.log("no enviado ");
      return;
  }
  console.log("enviado ");
    this.authService.createPerson(this.rpersonForm.value,this.foto1Url)
    .then((response) => {
      this.authService.setUserInfo({'user' : response['user']});
      localStorage.setItem('access_token',response["token"]);
      location.href="/"
    
  }) 
  .catch((err)=>{
      
  })
     // this.completo=true;
 
  
  }


}
