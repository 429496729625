import { Component, OnInit,ElementRef, Renderer2,ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 

@Component({
  selector: 'app-salida',
  templateUrl: './salida.component.html',
  styleUrls: ['./salida.component.css']
})
export class SalidaComponent implements OnInit {

 
  horasalida;
vis;
ipersonForm;
visitas=[];
submitted;
  foto1Url: any;
  fotop: boolean;
  videoWidth = 0;
videoHeight = 0;
@ViewChild('video', { static: false }) videoElement: ElementRef;
@ViewChild('canvas', { static: false }) canvas: ElementRef;
  stream: any;
  id: any;
  fotovalidate: boolean;
visita: any;
user: any;
foto: any;
finish: boolean;
yaingreso: boolean;

constructor(
  private authService: AuthService,
  private router:Router,
  private renderer: Renderer2


) { }

ngOnInit(): void { 
  this.ipersonForm = new FormGroup({

    'id': new FormControl('', [
      Validators.required,
        ]),    
  });

}

get f4() { return this.ipersonForm.controls; }

changeInput(event){

console.log( this.ipersonForm.controls['id'].value)
//this.ipersonForm.controls['id'].value= this.ipersonForm.controls['id'].value.replace(/^0+/, '');
var value=this.ipersonForm.controls['id'].value.replace(/\s/g, '');
value=value.replace(/^0+/, '');
this.ipersonForm.controls['id'].patchValue(value)
}

ingresop(){
  this.submitted = true;
  if (this.ipersonForm.invalid) {
   console.log("invalido");
   return;
}

this.authService.validatep(this.ipersonForm.get("id").value)
  .then((response) => {
   
    console.log(response);  
  //   this.authService.setUserInfo({'user' : response['user']});
     this.user=response["user"];  
     if(this.user.funcionario){
       //proceso de salida para funcionarios
       //1-buscar ingreso
       this.authService.getIngresosUser(this.user.id)
       .then((result)=>{
          //console.log(result["result"]);
          
          var ingreso=result["result"];
          var now= new Date();
            var n = now.getUTCHours();
            if(n<5){
              n=24+n; 
            } 
            now.setHours(n-5);
            this.authService.newsalidaFuncionario(now,this.user,ingreso._id)
            .then((response)=>{
              Swal.fire("Se registro la salida")
              .then(()=>{
                this.yaingreso=true;
                this.finish=true
                this.vis=true;
                this.horasalida=this.formatoHora(now)
                //this.terminar()
              })
            
            }) 
            .catch((err)=>{
              console.log(err);

              // location.reload();
            })

       })
       .catch((err)=>{
         console.log(err)
         //validar cod error no ha ingresado.
         if(err.error.statusCode==100){
          Swal.fire("No se ha registrado su ingreso en la plataforma")
          .then(()=>{
            this.finish=true
            this.terminar()
          })
         }

       })



     }
     else{
      this.authService.getvisitas(this.ipersonForm.get("id").value)
      .then((response) => {
      console.log(response); 
      this.visitas=response["visitas"];
      this.ordenar();
      })
      .catch((err)=>{
      console.log(err);
       Swal.fire(     {
         title: "No encotramos visitas asociadas a tu número de identificacion ",
             icon: 'warning',
         showCancelButton: false,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#89c9b8',
         confirmButtonText: 'ok',
      
         
      }).then((result)=>{
      if(result.value){
      
      location.reload();
       
      }
      else{}
      });
      
       
      })
      
     }


   
  })
  .catch(()=>{
    Swal.fire("El usuario no se encuentra registrado aún en nuestra plataforma");
  })


}

marcarSalidaSiIngreso(){
  this.authService.getIngresosUser(this.user.id)
  .then((result)=>{
     //console.log(result["result"]);
     
     var ingreso=result["result"];
     var now= new Date();
       var n = now.getUTCHours();
       if(n<5){
         n=24+n; 
       } 
       now.setHours(n-5);
       this.authService.newsalidaFuncionario(now,this.user,ingreso._id)
       .then((response)=>{
         Swal.fire("Se registro la salida")
         .then(()=>{
           this.yaingreso=true;
           this.finish=true
           this.vis=true;
           this.horasalida=this.formatoHora(now)
           //this.terminar()
         })
       
       }) 
       .catch((err)=>{
         console.log(err);

         // location.reload();
       })

  })
  .catch((err)=>{
    console.log(err)
    //validar cod error no ha ingresado.
    if(err.error.statusCode==100){
     Swal.fire("No se ha registrado su ingreso en la plataforma")
     .then(()=>{
       this.finish=true
       this.terminar()
     })
    }

  })
}

ordenar(){
if(this.visitas.length>0){
var events=this.visitas;
function compare( a, b ) {
  var datea=Date.parse(a.fecha);
  var dateb= Date.parse(b.fecha);
  
  // console.log("fecha a",);
  // console.log("fecha b",dateb);
  if ( datea > dateb ){      
    return 1;   }
  if ( datea < dateb ){
          return -1;  }
  return 0; 
}
function checkHoy(visita){
  //return false
  var now =new Date();
  var yesterday=new Date();
  yesterday.setDate(now.getDate() - 1);
var date= new Date(visita.fecha);
// console.log(now.getDate()==date.getDate() && now.getMonth()==date.getMonth());
if(now.getDate()==date.getDate() && now.getMonth()==date.getMonth()){
  return true
}
else if(yesterday.getDate()==date.getDate() && yesterday.getMonth()==date.getMonth()){
  return true
}
 else{
   return false
 }
}

events.sort(compare);
console.log("ordenadas ",events);
var events=events.filter(checkHoy);
console.log("filtrada",events);
if(events.length==0){
Swal.fire("No tienes citas para hoy");
this.marcarSalidaSiIngreso();
}
else{
var visit=events[0];
console.log(visit);
var aprobado=true;
    this.visita=visit
    this.vis=true;
    this.visita.personas.forEach(element => {
      if (element.ingreso && element.id==this.user.id && !element.salida ){
this.yaingreso=true;
this.salida();
      }
      else if(element.ingreso && element.id==this.user.id && element.salida ){
        this.yaingreso=true;
        this.finish=true;
        this.horasalida=this.formatoHora(element.salida.hora);
      }
      else{
Swal.fire("no hemos registrado tu ingreso")
.then(()=>{
  this.terminar();
})

      }
      
            
    });
 


  

}


}
else{
Swal.fire("No tienes citas para hoy");
this.marcarSalidaSiIngreso();
}

}



//control camara
handleError(error) {
console.log('Error: ', error);
}

attachVideo(stream) {
this.stream=stream;
this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
  this.videoHeight = this.videoElement.nativeElement.videoHeight;
  this.videoWidth = this.videoElement.nativeElement.videoWidth;
});

}

activeVideo(){
   
    var  constraints = {
      video: {
          
          width: { ideal: 1280 },
          height: { ideal: 720 }
      }
  };
  if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
    navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
       } else {
           alert('Sorry, camera not available.');
       }



}

tomar(){
this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
this.authService.sendfoto(data)
.then((response)=>{
  console.log(response["url"]);
  this.foto1Url=response["url"];

this.fotop=true;

})
.catch((err)=>{
alert("no encontramos tu rostro en la foto");
})

}

tomarregistro(){
this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');

this.authService.validatePingreso(data,this.user.id)
.then((response)=>{
  this.foto=response["foto"];
  this.ingreso();
}) 
.catch((err)=>{
  console.log(err);

    location.reload();
})

}

ingreso(){
var now= new Date();
var n = now.getUTCHours();
if(n<5){
  n=24+n; 
} 
now.setHours(n-5);
this.authService.newingreso(this.visita._id,this.foto,now,this.user)
.then((response)=>{
  Swal.fire("Se registro el ingreso")
  .then(()=>{
    this.finish=true
  })
  
}) 
.catch((err)=>{
  console.log(err);

   // location.reload();
})


}
formatoHora(dater){
  var date= new Date(dater);
  return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
}

salida(){
var now= new Date();
var n = now.getUTCHours();
if(n<5){
  n=24+n; 
} 
now.setHours(n-5);
this.authService.newsalida(this.visita._id,now,this.user)
.then((response)=>{
  Swal.fire("Se registro la salida")
  .then(()=>{
    this.finish=true
    this.horasalida=this.formatoHora(now)
   // this.terminar()
  })
 
}) 
.catch((err)=>{
  console.log(err);

   // location.reload();
})


}
terminar(){
location.reload();
}

}
