<nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="height: 60px;">
    
    <div class="navbar-brand">
        <div class="d-flex">
                <div class="justify-content-start">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                      </button>
                </div>
              <div class="justify-content-end">
                <img src="../../../assets/FondosClub/logo-serrrezuela-ico.png" width="20%" />
              </div>
              <div class="estadoD " >
                <p style="font-size: 10px; color: #04453b;">Modulo Administracion <br>
                    Bienvenido {{user.name}} {{user.apellido}}
                </p>
            </div>
        </div>
     
    </div>
    <div class="collapse navbar-collapse bg-dark" id="navbarNav" style=" background-color:  #5A9E94 !important;">
        <ul class="navbar-nav">
            <li><a  (click)="verEventos()" class="nav-link px-3 btn btn-header">Administrar eventos </a></li>
            <li><a  (click)="verAreas()" class="nav-link px-2 btn btn-header">Administrar areas</a></li>
            <li><a (click)="verVisitas()" class="nav-link px-2 btn btn-header">Administrar visitas</a></li>
            <li><a href="/areas"  class="nav-link px-2 btn btn-header">Vincular ó crear visitas</a></li>
           <li><a href="/usuarios" class="nav-link px-2 btn btn-header">Ver usuarios</a></li>
            <li><a href="/reservas" class="nav-link px-2 btn btn-header">Reservas</a></li>
                  <li><a href="/porteria" class=" px-2 btn btn-header">Porteria</a></li>

            <li  *ngIf="!vbitacora" ><a (click)="verbitacora()" class="nav-link px-2 btn btn-header">Bitacora</a></li>
            <h2 *ngIf="vbitacora" >Bitacora </h2>  
        </ul>
    </div>
    <div class="estadoA">
        <p style="color: #04453b;">Modulo Administracion <br>
            Bienvenido {{user.name}} {{user.apellido}}
        </p>
    </div>
  </nav>
  

<!-- <header class="p-3  navbar-expand-lg" style="background-color: rgb(28, 50, 61); height:77px">
    <div class="row"  >
      <div class="row">
        <div class="d-flex">
            <a href="/" class="d-flex align-items-between mb-2 mb-lg-0 text-white text-decoration-none">
                <svg class="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlink:href="#bootstrap"></use></svg>
              </a>
            <div class="col-md-10">
                <ul class="nav col-md-12 col-lg-auto me-lg-auto mb-2 justify-content-between mb-md-0">
                    <li><a  (click)="verEventos()" class="nav-link px-2 text-white">Administrar eventos </a></li>
                    <li><a  (click)="verAreas()" class="nav-link px-2 text-white">Administrar areas</a></li>
                    <li><a  (click)="verVisitas()" class="nav-link px-2 text-white">Administrar visitas</a></li>
                    <li><a href="/areas"  class="nav-link px-2 text-white">Vincular ó crear visitas</a></li>
                    <li><a href="/usuarios" class="nav-link px-2 text-white">Ver usuarios</a></li>
                    <li><a href="/reservas" class="nav-link px-2 text-white">Reservas</a></li>
                    <li  *ngIf="!vbitacora" ><a (click)="verbitacora()" class="nav-link px-2 text-white">Bitacora</a></li>
                    <h2 *ngIf="vbitacora" >Bitacora</h2>  
                </ul>

            </div>
              <div class="col-md-6" style="margin-left: 5%;">
                    <p><em style="color:white">Modulo de administración</em></p>
                    <strong><em  style="color:white"> Bienvenido {{user.name}} {{user.apellido}}</em></strong>
              </div>
        </div>
       
      </div>
    </div>
  </header> -->

  
<!--- AQUI ESTA BOTON MENSAJEEEE
<div style="position: absolute;top: 10px;right: 10px; text-align: end; z-index: 99;" >
    
    <button class="btn btn-boton2" (click)="vmensajes=!vmensajes" >Mensajes  <span *ngIf="nuevos>0" >({{nuevos}})</span></button>
    <app-mensajes *ngIf="vmensajes" (newConversaions)="onNewConversations($event)"  [conversations]="mensajes" style="z-index: 99;" >
    </app-mensajes>
</div>
AQUI BOTON MENSAJE 
-->

<!--<div class="row justify-content-center  pc" style="color: white; text-align: center; justify-content: center;" > 
    <div class=" col-md-10 justify-content-center " style="text-align: center;" >
        <button class="btn btn-info m-3" (click)="verEventos()" >Administrar eventos </button>
        <button class="btn btn-info m-3" (click)="verAreas()" >Administrar areas </button>
        <button class="btn btn-info m-3" (click)="verVisitas()">Administrar visitas</button>
        <a href="/areas">
            <button class="btn btn-info m-3" >Vincular ó crear visitas </button></a>
            <a href="/usuarios">
                <button class="btn btn-info m-3" >Ver usuarios</button></a>
                <button *ngIf="!vbitacora" class="btn btn-info m-3" (click)="verbitacora()" >Ver bitacora</button>
                <h2 *ngIf="vbitacora" >Bitacora</h2>
    </div>
     <div class="col-md-2"  style="text-align: left;" >
        <button class="btn btn-botonmenu" (click)="verEventos()" >Administrar eventos </button>
    </div>
    <div class="col-md-2"  style="text-align: left;" >
        <button class="btn btn-botonmenu" (click)="verAreas()" >Administrar areas </button>
    </div>
    <div class="col-md-2"  style="text-align: left;" >
        <button class="btn btn-botonmenu" (click)="verVisitas()">Administrar visitas</button>
    </div>
    <div class="col-md-2"  style="text-align: left;" >
        <a href="/areas">
        <button class="btn btn-botonmenu" >Vincular personas o empresas </button></a>
    </div>
    <div class="col-md-2"  style="text-align: left;" >
        <a href="/usuarios">
        <button class="btn btn-botonmenu" >Ver usuarios</button></a>
    </div>
    <div class="col-md-2"  style="text-align: left;" >
        <button *ngIf="!vbitacora" class="btn btn-botonmenu"  (click)="verbitacora()" >Ver bitacora</button>
        <h2 *ngIf="vbitacora" >Bitacora</h2>
    </div> 
</div>-->
<!-- 
<div class="row justify-content-center ml-1 cel" style="color: white;" > 
    <button class="btn btn-primary" (click)="menu=!menu"  >Menú</button>
 <div class="col-12 " *ngIf="menu" >
    <div class="col-md-2"  style="text-align: left;" >
        <button class="btn btn-botonmenu" (click)="verEventos()" >Administrar eventos </button>
    </div>
    <div class="col-md-2"  style="text-align: left;" >
        <button class="btn btn-botonmenu" (click)="verAreas()" >Administrar areas </button>
    </div>
    <div class="col-md-2"  style="text-align: left;" >
        <button class="btn btn-botonmenu" (click)="verVisitas()">Administrar visitas</button>
    </div>
    <div class="col-md-2"  style="text-align: left;" >
        <a href="/areas">
        <button class="btn btn-botonmenu" >Vincular personas o empresas </button></a>
    </div>
    <div class="col-md-2"  style="text-align: left;" >
        <a href="/usuarios">
        <button class="btn btn-botonmenu" >Ver usuarios</button></a>
    </div>
    <div class="col-md-2"  style="text-align: left;" >
        <button *ngIf="!vbitacora" class="btn btn-botonmenu"  (click)="verbitacora()" >Ver bitacora</button>
        <h2 *ngIf="vbitacora" >Bitacora</h2>
    </div>
 </div>
</div> -->

<div  *ngIf="active" >
<!-- CREACION DE EVENTOS-->
<div *ngIf="veventos" class="container" >
    <div class="d-flex col-md-2  mt-3" >
        <button *ngIf="!varchivados"  class="btn btn-dark"  (click)="varchivados=!varchivados" >Ver Archivados</button>
        <button *ngIf="varchivados"   class="btn btn-dark"  (click)="varchivados=!varchivados" >Ocultar Archivados</button>
        <div *ngIf="varchivados"  >
            <div class="row justify-content-center">
                <div style="text-align: left;" class="col-6  col-md-3 cajainfo " *ngFor="let event of eventosArchivados" >
                    <app-evento [event]="event" ></app-evento>
                    <a href="/evento/{{event._id}}"><button  class="btn btn-dark" >Ver resumen</button></a>
                </div>  
            </div>
        </div>
    </div>
    <div class="row ">
        <div class="caja-form2">   
            <form [formGroup]="eventForm" class="row py-1"  (ngSubmit)="newEvent()">
                <div class="form-group" *ngIf="eventForm.controls['id'].value!='0'" > 
                    <h3>Id</h3>
                    <input type="text" class="form-control" formControlName="id" readonly>
                </div>
             
                <div class="field col-md-4">
                    <h3>Ingrese  nombre del evento </h3>
                    <input type="text" class="form-control"  formControlName="nombre"  [ngClass]="{ 'is-invalid': submitted2 && f2.nombre.errors }">
                    <div *ngIf="submitted2 && f2.nombre.errors" class="invalid-feedback">
                        <div *ngIf="f2.nombre.errors.required">Ingrese su nombre</div>
                       
                    </div>
                </div> 
                <div class="field col-md-4">
                    <h3>Ingrese una descripcion del evento </h3>
                    <input type="text" class="form-control"  formControlName="descripcion"  [ngClass]="{ 'is-invalid': submitted2 && f2.descripcion.errors }">
                    <div *ngIf="submitted2 && f2.descripcion.errors" class="invalid-feedback">
                        <div *ngIf="f2.descripcion.errors.required">Ingrese su descripcion</div>
                       
                    </div>
                </div> 
                <div class="field col-md-4">
                    <h3>Ingrese la fecha y hora del evento</h3>     
                    <input class="form-control" type="datetime-local" placeholder="Fecha" formControlName="fecha"
                    min="2021-06-01" max="2023-12-31"
                    required      >
                    </div>
                    <div class="field col-md-12 text-center">
                    <h3>seleccione las áreas que se involucraran en el proceso </h3>
                    <div *ngFor="let area of eventForm.get('areasc')['controls']; let i=index;"style="color: white;">
                        <input type="checkbox" [formControl]="area"  >
                        {{areas[i].nombre}}
                      </div>
                    </div>

                    <div class="col-md-12 text-center">
                        <input type="submit" value="Crear Evento" class="btn btn-orange" >
    
                    </div>
            </form>
        </div>
       
        </div>
<div class="row">
  
        <div  class="col-md-4  caja-form2 m-2"  *ngFor="let event of eventos"  >
            <app-evento [event]="event" ></app-evento>
            <div class="" >
                <button class="btn btn-warning m-2" (click)="deleteEvent(event._id)" >Archivar</button>
                <button class="btn btn-info m-2" (click)="editEvent(event)" >Editar</button>
                <a href="/evento/{{event._id}}"><button  class="btn btn-info m-2" >Ver resumen</button></a>

            </div>
        </div>    
    

    
</div>

</div>
<!--- Creaccion de eventos  -->

<div *ngIf="vbitacora" class="row justify-content-center m-1" >
    <div class="col-12"*ngIf="vbitacora" >
        <app-bitacora></app-bitacora>
                    </div>
</div>

<div *ngIf="vareas" class="row justify-content-center m-1" > 
  
  
<div class="container" >
    <div class="row">
    <div class="col-md-6 caja-form2 ">
        <h1  style="color:black">Creación de áreas</h1>
        <form [formGroup]="areaForm" (ngSubmit)="newArea()"  > 
            <div class="form-group" *ngIf="areaForm.controls['id'].value!='0'" > 
                <h3>Id</h3>
                <input type="text" class="form-control" formControlName="id" readonly>
                </div>
            <div class="form-group">
                <h3>Ingrese  nombre del area </h3>
                <input type="text" class="form-control"  formControlName="nombre"  [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                    <div *ngIf="f.nombre.errors.required">Ingrese su nombre</div>
                   
                </div>
            </div> 
            <div class="form-group">
              <h3>Resonsables:</h3>
              <h4 *ngFor="let resposable of addResponsables;index as i; " >{{i+1}}: {{resposable}} <button (click)="borrarResp(i)" class="btn btn-danger" >Borrar</button> </h4> 
              <input type="number"  autocomplete="off" id="resp_"> <button  type='button'class="btn btn-dark" style="font-size: 9px; margin-left:50px" (click)="addRes()" >Agregar</button>
            </div> 
            <div class="d-flex justify-content-center mt-3">
                <input type="submit" value="Crear Nueva Area" class="btn btn-orange" >
            </div>
        </form> 
    </div>
    <div class="col-md-6" > 
        <div class="row ml-1 mt-4 justify-content-center " >
            <div class="col-12" >
                <h1 style="font-size: 25px; font-weight: bold; text-align: left;"style="color:black"     >Áreas Creadas: </h1>
            </div>
        </div>
    <div class="row">
        <div class="col-md-12" style="max-height: 470px; overflow-y: auto;"  >
            <div class="ml-3" >
                <div style="text-align: left;  margin-top: 5px; padding: 3px; " class="col-12  caja-form2" *ngFor="let area of areas" >
                    <span style="font-size: 19px; font-weight: bold; text-align: left;line-height: 45px; ">{{area.nombre}}</span>
                    <button class="btn btn-warning m-1" style="float: right; font-size: 16px; " (click)="deleteArea(area._id)" >Eliminar</button>
                    <button class="btn btn-info m-1" style="float: right; font-size: 16px; " (click)="editArea(area)" >Editar</button>
                    <button class="btn btn-info m-1" style=" font-size: 16px; float: right;" *ngIf="!area.open" (click)="verResponsables(area)" >Ver responsables</button>
                    <button class="btn btn-info m-1" style=" font-size: 16px; float: right;" *ngIf="area.open" (click)="area.open=false" >Ocultar responsables</button>
                    <div *ngIf="area.open" >
                        <span style="font-size: 19px; font-weight: bold; text-align: left;line-height: 45px; ">Responsables:</span>
                        <h3  style="margin-bottom: 5px;"  *ngFor="let persona of area.personas" >{{persona.name}} {{persona.apellido}} - {{persona.cargo}}</h3>
                    </div>
                </div>  
            </div>
        
        </div>
    </div>  
   
</div>
</div>
      
         
    
  
    </div> 
</div>

  
  
<div *ngIf="vvisitas" class="row justify-content-center m-1" >
    <div class="col-12" >
        <div class="row justify-content-center" >
            <div class="col-md-2 col-4" >
                <button *ngIf="!vhoy" style="font-size: 13px;" class="btn btn-botonAdmin" (click)="verhoy()"  >Ver visitas de hoy</button>
                <h2 *ngIf="vhoy" class="texto-color" >Visitas hoy</h2>
            </div>
        <div class="col-md-2 col-4" >
            <button *ngIf="!vanteriores" style="font-size: 13px;" class="btn  btn-botonAdmin"  (click)="verantes()" >Ver visitas anteriores</button>
        <h2 *ngIf="vanteriores" class="texto-color"  >visitas anteriores</h2>
        </div>
        <div class="col-md-2 col-4" >
            <button *ngIf="!vproximas" style="font-size: 13px;" class="btn  btn-botonAdmin"  (click)="verproxi()" >Ver proximas visitas</button>
            <h2 *ngIf="vproximas" class="texto-color" >Proximas visitas</h2>
        </div>
        <div class="col-md-2 col-4" >
            <button *ngIf="!vingresos" style="font-size: 13px;" class="btn   btn-botonAdmin"   (click)="veringresos()" >Ingresos en tiempo real</button>
            <button *ngIf="vingresos"  style="font-size: 13px;" class="btn  btn-botonAdmin"  >Ingresos tiempo real</button>
        </div>
        <div class="col-md-2 col-4" >
            <button *ngIf="!vingresosfecha" style="font-size: 13px;" class="btn  btn-botonAdmin"  (click)="verIngresoFecha2();"  >Ingresos por fecha</button>
            <button *ngIf="vingresosfecha" style="font-size: 13px;" class="btn  btn-botonAdmin">Ingresos por fecha</button>
        </div>
        <!-- <div class="col-md-2 col-4" >
            <a href="/ingresoempresas" target="_blank" rel="noopener noreferrer">
            <button class="btn  btn-success" style="font-size: 13px;" >Ingresos por evento</button>
        </a>
           
        </div> -->
     
        </div>


        <div   class="row justify-content-center m-2" >
         
            <div class="col-12" >
                            
                <div *ngIf="vingresos && ingresos" class="row justify-content-center" >
                   
<div *ngIf="Tingreso=='hoy'" class=" col-md-12 col-12 centrar">
    <div class="d-flex justify-content-center">
         <h4 class="justify-content-start m-4" style="color:#5A9E94">Cantidad de personal ingresado: {{ingresos.length}}</h4>
        <button class="btn btn-botonWarning m-3"style="font-size: 10px;" (click)="deleteIngresos()" >Limpiar ingresos</button>
        <button class="btn btn-botonWarning m-3"style="font-size: 10px;" (click)="deleteIngresosPasados()" >Limpiar ingresos anteriores</button>
    </div>
  
    <div class="">
        <div class="row m-3 ml-2 justify-content-center " >
            <div class="col-md-6 col-12">
  
                          <div  class=" caja-form2"  *ngIf="!yabusco "  style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  padding:15px  ;">
                            <span style="color: rgb(0, 0, 0); font-size: 15px;" >Buscar por cédula: </span>
                            <br>
                            <input type="text" id="buscado2" class=" m-3 col-md-6" autocomplete="off" > 
                            <button class="btn btn-info mt-1" (click)="buscar('1')" >Buscar</button> 

                        <button  class="btn btn-danger " *ngIf="yabuscoCedula" (click)="limpiar('1')" >Ver todos</button>
                       
                          </div>
                        </div>
    </div>
   <!--  <div class="row justify-content-center" *ngIf="!yabusco && !yabuscoCedula" >
        <div  class="col-md-3 m-1 col-6 caja-form2" style="color: #04453b; ; padding: 10px;" [ngClass]="{'verde':ingreso.user.funcionario==true }"  *ngFor="let ingreso of ingresos">
            {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
            CC: {{ingreso.user.id}} <br>
            Ingreso: {{ingreso.fechat}} <br>
            <span  *ngIf="ingreso.empresa &&  ingreso.empresa.name" >Empresa: {{ingreso.empresa.name}}<br></span> 
            <img  src="{{ingreso.foto}}" *ngIf="ingreso.open && ingreso.foto" width="100%" alt="">
            <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-botonWarning btn-peque "  
             >Ver foto</button>
            <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-botonWarning btn-peque " 
             >Ocultar foto</button>
            </div>
    </div> -->

    <div class="container">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tab1">Funcionarios {{funcionario}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tab2">Visitantes {{visitantes}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tab5">Socios y Beneficiarios {{socio}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tab3">Todos {{ingresos.length}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tab4" >Salidas {{ingresoNum}}</a>
            </li>
        </ul>
    
        <div class="tab-content">
            <div id="tab1" class="tab-pane fade show active">
                <div class="row justify-content-center" *ngIf="!yabusco && !yabuscoCedula">
                    <!-- Contenido de la pestaña 1 -->
                    <div  class="col-md-3 m-1 col-6 caja-form2" style="color: #04453b; padding: 10px;" [ngClass]="{'verde':ingreso.user.funcionario==true }" *ngFor="let ingreso of funcionarios" >
                       <div >
                           {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                           CC: {{ingreso.user.id}} <br>
                           Cargo: {{ingreso.user.cargo}} <br>
                           Ingreso: {{ingreso.fechat}} <br>
                           Sede: {{ingreso.sede}} <br>

                           <span *ngIf="ingreso.empresa &&  ingreso.empresa.name">Empresa: {{ingreso.empresa.name}}<br></span> 
                           <img src="{{ingreso.foto}}" *ngIf="ingreso.open && ingreso.foto" width="100%" alt="">
                           <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-botonWarning btn-peque">Ver foto</button>
                           <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-botonWarning btn-peque">Ocultar foto</button>
                       </div>
                    </div>
                </div>
            </div>
    
            <div id="tab2" class="tab-pane fade">
                <div class="row justify-content-center" *ngIf="!yabusco && !yabuscoCedula">
                    <!-- Contenido de la pestaña 1 -->
                    <div class="col-md-3 m-1 col-6 caja-form2" style="color: #04453b; padding: 10px;" [ngClass]="{'verde':ingreso.user.funcionario==true }" *ngFor="let ingreso of empleados">
                        {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                        CC: {{ingreso.user.id}} <br>
                        Cargo: {{ingreso.user.cargo}} <br>

                        Ingreso: {{ingreso.fechat}} <br>
                        Sede: {{ingreso.sede}} <br>

                        <span *ngIf="ingreso.empresa &&  ingreso.empresa.name">Empresa: {{ingreso.empresa.name}}<br></span> 
                        <img src="{{ingreso.foto}}" *ngIf="ingreso.open && ingreso.foto" width="100%" alt="">
                        <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-botonWarning btn-peque">Ver foto</button>
                        <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-botonWarning btn-peque">Ocultar foto</button>
                    </div>
                </div>
            </div>
    
            <div id="tab3" class="tab-pane fade">
                <div class="row justify-content-center" *ngIf="!yabusco && !yabuscoCedula">
                    <!-- Contenido de la pestaña 1 -->
                    <div class="col-md-3 m-1 col-6 caja-form2" style="color: #04453b; padding: 10px;" [ngClass]="{'verde':ingreso.user.funcionario==true }" *ngFor="let ingreso of ingresos">
                        {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                        CC: {{ingreso.user.id}} <br>
                        Cargo: {{ingreso.user.cargo}} <br>

                        Ingreso: {{ingreso.fechat}} <br>
                        Sede: {{ingreso.sede}} <br>

                        <span *ngIf="ingreso.empresa &&  ingreso.empresa.name">Empresa: {{ingreso.empresa.name}}<br></span> 
                        <img src="{{ingreso.foto}}" *ngIf="ingreso.open && ingreso.foto" width="100%" alt="">
                        <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-botonWarning btn-peque">Ver foto</button>
                        <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-botonWarning btn-peque">Ocultar foto</button>
                    </div>
                </div>
            </div>
            <div  id="tab4" class="tab-pane fade">
                <div class="row justify-content-center">
                    <div  class="col-md-3 col-6 m-1 caja-form2 " style="color: #04453b; padding: 10px;"  [ngClass]="{'verde':ingreso.user.funcionario==true }"  *ngFor="let ingreso of ingresosd">
                        {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                        CC: {{ingreso.user.id}} <br>
                        Cargo: {{ingreso.user.cargo}} <br>

                        Ingreso: {{ingreso.fechat}} <br>
                         <p *ngIf="ingreso.salida" style="color: white;"> 
                            Salida: {{ingreso.fechas}}  <br>                       
    
                          Sede Entrada: {{ingreso.sede}} <br>

                          Sede Salida: {{ingreso.sedeSalida}} </p>

                         <p  *ngIf="ingreso.empresa &&  ingreso.empresa.name" >Empresa: {{ingreso.empresa.name}}</p> 
                        <img src="{{ingreso.foto}}" *ngIf="ingreso.open" width="100%" alt="">
                        <div class="d-flex justify-content-end" style="padding:10px">
                            <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-info btn-peque " >Ver foto</button>
                            <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-info btn-peque " >Ocultar foto</button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div  id="tab5" class="tab-pane fade">
                <div class="row justify-content-center">
                    <div   class="col-md-3 m-1 col-6 caja-form2"  style="color: #04453b; padding: 10px;"  [ngClass]="{'verde':ingreso.user.funcionario==true }"  *ngFor="let ingreso of socios">
                        {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                        CC: {{ingreso.user.id}} <br>
                        Ingreso: {{ingreso.fechat}} <br>
                        Sede: {{ingreso.sede}} <br>
                         <span *ngIf="ingreso.salida" >  Salida: {{ingreso.fechas}} <br> </span>
                         <span  *ngIf="ingreso.empresa &&  ingreso.empresa.name" >Empresa: {{ingreso.empresa.name}}<br></span> 
                        <img src="{{ingreso.foto}}" *ngIf="ingreso.open" width="100%" alt="">
                        <div class="d-flex justify-content-end" style="padding:10px">
                            <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-info btn-peque " >Ver foto</button>
                            <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-info btn-peque " >Ocultar foto</button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
</div>
                </div>




<div class="row justify-content-center"  *ngIf="this.vingresosfecha==true"  >
<div *ngIf="Tingreso=='fecha'" class="col-md-12 col-11 ">
   
    <h1>Cantidad de ingresos encontrados: {{ingresosd.length}}</h1>
    <div  *ngIf="!sDay && !sFecha" class="row mt-5 justify-content-center" >
        <div class="col-md-4 col-6">
            <button class="btn btn-info"  (click)="buscarFecha()" >Buscar ingresos en rango de fecha</button>
    
        </div>
        <div class="col-md-4 col-6">
            <button class="btn btn-info"  (click)="buscarDia()" >Buscar ingresos en un día</button>
    
        </div>

    </div>
    <div class="caja-form2 col-md-10" *ngIf="sDay" style=" border-radius: 10px; margin-bottom: 20px;"  >
        <button class="btn btn-dark" style="font-size: 15px; float: right;" (click)="verIngresoFecha2();">X</button>
        
        <div class="d-flex justify-content-center">
            <h1 style="color:black">Ingresos por fecha: {{fecha}}</h1>
        </div>
    
        <form  class="" *ngIf="!buscoDia"  [formGroup]="fechaForm" (ngSubmit)="newFecha()" style="padding: 10px;">
            <div >
                <label>Ingrese la fecha:</label>
                <input class="form-control col-md-8" type="date"  formControlName="fecha"
                    min="2021-06-01" max="2023-12-31" required>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <button class="btn btn-info" >Buscar</button>
            </div>
           
        </form>
        <button class="btn btn-info" *ngIf="buscoDia"  (click)="descagarDia()" >Descargar ingresos día</button> <br>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-success" (click)="verIngresoFecha2()" >Realizar otra busqueda</button> <br>
        </div>
</div>
    <div class="caja-form2" *ngIf="sFecha" style="background-color: rgb(255, 255, 255); margin-bottom: 20px; 
    border-radius: 10px;" >
        <button class="btn btn-dark" style="font-size: 15px; float: right;" (click)="verIngresoFecha()" >X</button>
   
        <h1 style="color:rgb(0, 0, 0)">Ingresos en un rango de fechas</h1>
        <div class="m-2 " style="text-align: center;color: rgb(0, 0, 0);"  >
            <mat-form-field  >
                <mat-label  style="color: rgb(0, 0, 0);" >Seleccione el rango de fechas</mat-label>
                <mat-date-range-input  [formGroup]="range" [rangePicker]="picker">
                  <input (dateInput)="addEvent('input', $event)" matStartDate formControlName="start" placeholder="Start date">
                  <input (dateInput)="addEvent('input', $event)" matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-hint style="color:black">MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
        </div>
       
          <br>
          <!-- <p>Selected range: {{range.value | json}}</p> -->
          <div *ngIf="initdate" style="color: white; margin:10px ; padding:15px" >
              <span  style="font-size: 21px;" >Rango seleccionado: {{initdate}} - {{enddate}}</span>
           <div class="d-flex justify-content-between  ">
            <button class="btn btn-info" (click)="newFechaRange()" >Buscar ingresos en este rango</button>
             
            <button class="btn btn-info" *ngIf="findRange" (click)="descagarDia()" >Descargar ingresos rango</button>
           
            <button class="btn btn-success" (click)="verIngresoFecha()" >Realizar otra busqueda</button>
           </div>
           
        
          </div>
      
    </div>


    <div class="row" >
        <div class="col-10">
            <div class="row m-2 " *ngIf="ingresosd &&  ingresosd.length>0" >
                <div class="col-md-8 col-12">
                
                 <div *ngIf="!yabusco" >
                   <span style="color: white; font-size: 20px;" >Filtrar ingresos por empresa:</span>
                    <select  (change)="buscar('2')" id="buscadoi">
                        <option value="">Selecciona</option>
                    <option value="{{empresa._id}}"  *ngFor="let empresa of empresasIngresos"  >{{empresa.name}}</option>
                    </select>
                 </div>
                 <div *ngIf="yabusco" >
                    <span style="color: white; font-size: 20px;" >ingresos por empresa <br></span>
                    <button  class="btn btn-danger" style=" font-size: 15px; padding: 2px; border: none;" (click)="limpiar('2')" >limpiar</button>
                 </div>
                
                </div>
                <div  class="col-md-4" *ngIf="!yabusco ">
                    <span style="color: white; font-size: 20px;" >Buscar por cédula: </span>
                    <br>
                    <input type="text" id="buscado2" autocomplete="off" > <button class="btn btn-primary" (click)="buscar('1')" >Buscar</button> 
                <button  class="btn btn-danger" *ngIf="yabuscoCedula" (click)="limpiar('1')" >Ver todos</button>
               
                  </div> 
                              
                            </div>
        </div>
    </div>



    <div class="row" >

        <div  class="col-md-4 col-6 caja-ingreso "  [ngClass]="{'verde':ingreso.user.funcionario==true }"  *ngFor="let ingreso of ingresosd">
            {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
            CC: {{ingreso.user.id}} <br>
            Ingreso: {{ingreso.fechat}} <br>
             <span *ngIf="ingreso.salida" >  Salida: {{ingreso.fechas}} <br> </span>
             <span  *ngIf="ingreso.empresa &&  ingreso.empresa.name" >Empresa: {{ingreso.empresa.name}}<br></span> 
            <img src="{{ingreso.foto}}" *ngIf="ingreso.open" width="100%" alt="">
            <div class="d-flex justify-content-end" style="padding:10px">
                <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-info btn-peque " >Ver foto</button>
                <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-info btn-peque " >Ocultar foto</button>
            </div>
        </div>
    </div>
</div>
                </div>
        


            </div>
         
            <div class="col-md-12" *ngIf="vanteriores"  >
             
<div class="row "  >
<div  class="col-4 caja-form2 "  >
    <form [formGroup]="fechaForm2" (ngSubmit)="newFecha2()">
        <div >
            <h3 style="color:black">Ingrese la fecha:</h3>
            <input class="form-control" type="date"  formControlName="fecha"
                min="2021-06-01" max="2023-12-31" required>
        </div>
        <div class="d-flex justify-content-center mt-4">
            <button class="btn btn-info" >Buscar</button>

        </div>
    </form>
</div>
               </div>
                <div class="row justify-content-center" >
                   
        <div class="col-md-4"*ngFor="let user of anteriores" >
            <div class="mt-2 caja-datos "  style=" border-radius: 15px; padding: 5px;" >
                <div  *ngIf="user.open" >
                    <app-visita [visita]="user"  ></app-visita>
                    <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
                </div>
             
               <div *ngIf="!user.open" >
                <button  class="btn btn-primary"  (click)="user.open=true" >Ver detalles</button> <br>
                <span  >{{user.fechat}}</span><br>

                <span class="text" *ngIf="user.eventid!='general'"  > Evento: {{user.eventname}}</span>
                <span class="text" *ngIf="user.eventid=='general'"  > Evento: General</span>
               
                <br>
                <span class="text" > Descripción: {{user.descripcion}}</span><br>
                <span class="text" > Nivel de acceso: {{user.acceso}}</span><br>
                <span class="text" *ngIf="user.empresa" > Empresa: {{user.empresa.name}}<br></span>
                <span class="text" *ngIf="!user.empresa" > Empresa: Independiente<br> </span>
                <span class="text" > Número de personas: {{user.personas.length}}<br> </span>
                <div style="border:solid 2px black" >
                    <h2 >Notas de la visita</h2>
                    <span *ngFor="let nota of user.notas" >
                    - {{nota.txt}} 
                    <br>
                    </span>
                    <div class="d-flex justify-content-end">

                        <button  (click)="newnota(user._id)" class="btn btn-primary m-1" >Agregar nota</button>
                    </div>
                            </div>
                <!-- <div *ngIf="user.empresa">
                    {{user.empresa.name}}
                    <span class="text"> NIT: {{user.empresa.nit}}</span>
              
                    <br>
              
                    <span class="text" *ngIf="user.placa"> Vehiculo: {{user.placa}}</span>
                    <br>
                                
              
              
                  </div> -->
        
                <span  [ngClass]="{'verde':persona.aprobe3}" *ngFor="let persona of user.personas" >
                    {{persona.name}} {{persona.apellido}}    <br> 
                    <span *ngIf="persona.aprobe3 && persona.aprobe2 && persona.aprobe1 " > Aprobado <br> </span>
                    <span *ngIf="!persona.aprobe3 || !persona.aprobe2 || !persona.aprobe1 "  > No aprobado <br> </span>
             
                <span *ngIf="persona.ingreso" > Ya registro ingreso <br> </span>
                <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
                <span *ngIf="persona.salida" > Ya registro salida  <br> </span>
                    <span *ngIf="!persona.salida" > No ha salido <br> </span>
                <hr>
                </span>
            
               
               </div>
               <div class="d-flex justify-content-end">

                   <button class="btn-dark"  (click)="eliminar(user._id)" >Eliminar</button>
               </div>
            </div>
        </div>
        
                </div>
                
            </div>
        
            <div class="col-md-12" *ngIf="vhoy" >
              <div class="row ml-2 " >
<!-- <div class="col-md-6 col-12">



</div> -->
              </div>
        
                <div class="row justify-content-center" >
                    <div class="col-md-4 caja-form2"  *ngFor="let user of hoy" > 
                       
                <div class="mt-2 " [ngClass]="{'sss':user.aprobada==user.personas.length && !user.admin,'verde3':user.aprobada==user.personas.length && user.porteria, 'verde2':user.aprobada==user.personas.length && user.admin, 'amarillo':user.aprobada != user.personas.length && user.aprobada!=0,'caja-datos':user.aprobada==0}"   style=" border-radius: 15px; padding: 5px;" >
                    <!-- <div   *ngIf="user.open" >
                        <app-visita [visita]="user"  ></app-visita>
                       
                        <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
                    </div>
                   -->
                    
                   <div *ngIf="!user.open" class="caja-form2 texto-color" >
                    <!-- <a href="/aprobar4/{{user._id}}" target="_blank" rel="noopener noreferrer">
                        <button class="btn btn-primary btn-2" >Aprobar</button>
                        </a> -->
                        <!-- <button  *ngIf="!user.open" class="btn btn-primary btn-2"  (click)="user.open=true" >Ver detalles</button><br> -->
                    <span  class="texto-color">{{user.fechat}}</span><br>
                   
                    
                        <br>
                       <span>Recibe: {{user.recibeName}} </span> 
                       <br>
                        <!-- Area: {{user.recibeArea}} -->
                    
                  
                   
                    <br>
                    <span class="text" > Descripción: {{user.descripcion}}</span><br>
                    <span class="text"*ngIf="user.acceso"  > Nivel de acceso: {{user.acceso}}<br></span> 
                    <!-- <span class="text" *ngIf="user.empresa" > Empresa: {{user.empresa.name}}<br></span> -->
                    <!-- <span class="text" *ngIf="!user.empresa" > Empresa: Independiente<br> </span> -->
                    <span class="text" > Número de personas: {{user.personas.length}}<br> </span>
                    <div class="d-flex justify-content-end">

                        <button *ngIf="!user.notasopen" class="btn btn-primary btn-2" (click)="user.notasopen=true" >ver notas</button><br>
                    </div>
                    <div *ngIf="user.notasopen" style="border:solid 2px black" >
                        <h2 >Notas: </h2>
                        <span *ngFor="let nota of user.notas" >
                        - {{nota.txt}} 
                        <br>
                        </span>
                        <button  (click)="newnota(user._id)" class="btn btn-primary m-1 btn-2" >Agregar nota</button>
                        <button  class="btn btn-primary btn-2" (click)="user.notasopen=false" >ocultar notas</button>
                                </div>
                    <!-- <div *ngIf="user.empresa">
                        {{user.empresa.nombre}}
                        <span class="text"> NIT: {{user.empresa.nit}}</span>
                  
                        <br>
                  
                        <span class="text" *ngIf="user.placa"> Vehiculo: {{user.placa}}</span>
                     <br>
                                    
                  
                  
                      </div> -->
                      <span class="text" style="font-size: 22px;" > Personas:</span><br>
                    <span [ngClass]="{'verde':persona.aprobe3}" *ngFor="let persona of user.personasObj" >
                        {{persona.name}} {{persona.apellido}}    <br> 
                        <span *ngIf="persona.ingreso" > Ya registro ingreso {{persona.ingreso.hora | date:'medium'}} <br> </span>
                        <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
                   
                    <hr>
                    </span>
                
               
                   </div>
                   <div class="d-flex justify-content-center">
                       <button class="btn-orange"  (click)="eliminar(user._id)" >Eliminar</button>
                   </div>
                </div>
              
                    </div>
                    
                            </div>
                
            </div>
            </div>
            <div class="col-md-12" *ngIf="vproximas"  >
             
                <div class="row justify-content-center" >
                    <div class="col-md-4" *ngFor="let user of proximas" >
                        <div class="mt-2  " [ngClass]="{'verde':user.aprobada==user.personas.length && !user.admin,'verde3':user.aprobada==user.personas.length && user.porteria, 'verde2':user.aprobada==user.personas.length && user.admin, 'amarillo':user.aprobada != user.personas.length && user.aprobada!=0,'caja-datos':user.aprobada==0}"   style="border-radius: 15px; padding: 5px;" >
                            <!-- <div *ngIf="user.open" >
                                <app-visita [visita]="user"  ></app-visita>
                                <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
                            </div>
                            <a href="/aprobar4/{{user._id}}" target="_blank" rel="noopener noreferrer">
                                <button class="btn btn-primary" >Aprobar</button>
                                </a> -->
                           <div *ngIf="!user.open" >
                           
                            <span  >{{user.fechat}}</span><br>
                            
              
                            Recibe: {{user.recibeName}} <br>
                            <span class="text" > Descripción: {{user.descripcion}}</span><br>
                     
                
                            <span class="text" > Número de personas: {{user.personas.length}}<br> </span>
                            <div style="border:solid 2px black" >
                                <h2 >Notas de la visita</h2>
                                <span *ngFor="let nota of user.notas" >
                                - {{nota.txt}} 
                                <br>
                                </span>
                                <button  (click)="newnota(user._id)" class="btn btn-primary m-1" >Agregar nota</button>
                                        </div>
                            <!-- <div *ngIf="user.empresa">
                                {{user.empresa.name}}
                                <span class="text"> NIT: {{user.empresa.nit}}</span>
                          
                                <br>
                          
                                <span class="text" *ngIf="user.placa"> Vehiculo: {{user.placa}}</span>
                     <br>
                                            
                          
                          
                              </div> -->
                           
                            <span *ngFor="let persona of user.personasObj" >
                                {{persona.name}} {{persona.apellido}}    <br> 
                      
                            <span *ngIf="persona.ingreso" > Ya registro ingreso <br> </span>
                            <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
                           
                            <hr>
                            </span>
                        
                   
                           </div>
                         
                            <button class="btn-danger"  (click)="eliminar(user._id)" >Eliminar</button>
                        </div>
                      
                    </div>
                    
                            </div>
               
            </div>
        </div> 
    
    
    </div>
    

    </div>
  
    
    <div  *ngIf="newNota" class="pop" (click)="closenota()">

        <div (click)="nopro($event)"   style="position: fixed; top: 10%; left: 30%; width: 40%; background-color: rgb(4, 4, 65); " >  
            <h1 style="color: white;" >
                Agregue la nota correspondiente a la visita.
            </h1>
            <form [formGroup]="notaForm" (ngSubmit)="sendnota()" novalidate="">
                <textarea formControlName="question"cols="40" rows="7"></textarea>
               
                <br>
                <input type="submit" value="Guardar"  class="btn btn-primary"  >
            </form>
        </div>
    
    </div>

    
<div class="floating-button cl" *ngIf="showAddToHomeScreenButton">
    <button class="btn btn-orange" (click)="addToHomeScreen()">Agregar a mi inicio</button>

</div>
        <script >

$(document).ready(main);

var contador = 1;

function main(){
	$('.menu_bar').click(function(){
		// $('nav').toggle(); 

		if(contador == 1){
			$('nav').animate({
				left: '0'
			});
			contador = 0;
		} else {
			contador = 1;
			$('nav').animate({
				left: '-100%'
			});
		}

	});

};  
</script>
