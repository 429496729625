<header class="p-3 bg-info text-white">
    <div class="row">
      <div class="row">
        <div class="d-flex">
            <a href="/" class="d-flex align-items-between mb-2 mb-lg-0 text-white text-decoration-none">
                <svg class="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlink:href="#bootstrap"></use></svg>
              </a>
            <div class="col-md-10">
                <ul class="nav col-md-12 col-lg-auto me-lg-auto mb-2 justify-content-between mb-md-0">
                    <li> <a href="/validacion"  class="nav-link px-2 text-white" target="_blank" >
                        Validar ingresos</a></li>
                    <li> <a href="/salida"  class="nav-link px-2 text-white" target="_blank" >
                        Validar salidas</a></li>
                    <li><a  (click)="createVisita()" class="nav-link px-2 text-white">Crear visita</a></li>
                    <li><a href="" *ngIf="!vhoy"   (click)="verhoy()" class="nav-link px-2 text-white">Ver visitas de hoy</a>
                        <h5 *ngIf="vhoy" style="color:white" class="m-2"  >Visitas hoy</h5>
                    </li>
                    <li><a href="" *ngIf="!vanteriores" (click)="verantes()" class="nav-link px-2 text-white">Ver visitas anteriores</a>
                        <h2 *ngIf="vanteriores" >visitas anteriores</h2>
                    </li>
                    <li><a href=""  *ngIf="!vproximas"  (click)="verproxi()"  class="nav-link px-2 text-white">Ver proximas visitas</a>
                        <h2 *ngIf="vproximas" >Proximas visitas</h2>
                    </li>
                    <li><a href="" (click)="logout()" class="nav-link px-2 text-white">Cambiar de turno</a></li>

                </ul>

            </div>
              <div class="col-md-6" style="margin-left: 15%;">
                  <strong>Bienvenido {{user.name}} {{user.apellido}}</strong>
              </div>
        </div>
       
      </div>
    </div>
  </header>

<!-- 
        <a href="/salida" target="_blank" rel="noopener noreferrer">
        <button  class="btn btn-danger"  >Validar salidas</button></a>
        
    </div>
    <div class="col-md-2  col-4 " >
        
        <button  class="btn btn-danger" (click)="createVisita()" >Crear visita</button>
        
    </div>
    <div class="col-md-2  col-4 " >
        <button *ngIf="!vhoy" class="btn btn-primary" (click)="verhoy()"  >Ver visitas de hoy</button>
        <h2 *ngIf="vhoy" >Visitas hoy</h2>
    </div>
<div class="col-md-2  col-4 " >
    <button *ngIf="!vanteriores" class="btn btn-primary"  (click)="verantes()" >Ver visitas anteriores</button>
<h2 *ngIf="vanteriores" >visitas anteriores</h2>
</div>
<div class="col-md-2  col-4 " >
    <button *ngIf="!vproximas" class="btn btn-primary"  (click)="verproxi()" >Ver proximas visitas</button>
    <h2 *ngIf="vproximas" >Proximas visitas</h2>
</div>

<div class="col-2">
    <button  class="btn btn-danger" style="font-size: 14px; font-weight: 100; border: none;"   (click)="logout()" >Cambiar de turno</button>
</div>
</div>-->

 <div   class="row justify-content-center m-2" >
     <h1>Bienvenido {{user.name}} {{user.apellido}} </h1>
     <div class="col-md-12" *ngIf="vanteriores"  >
             
        <div class="row ">
<div  class="col-4">
<form [formGroup]="fechaForm2" (ngSubmit)="newFecha2()">
 <div >
     <h3>Ingrese la fecha:</h3>
     <input class="form-control" type="date"  formControlName="fecha"
         min="2021-06-01" max="2023-12-31" required>
 </div>
<button class="btn btn-primary" >Buscar</button>
</form>
</div>
        </div>
         <div class="row justify-content-center" >
            
 <div class="col-md-4"*ngFor="let user of anteriores" >
     <div class="mt-2 caja-datos "  style=" border-radius: 15px; padding: 5px;" >
         <div  *ngIf="user.open" >
             <app-visita [visita]="user"  ></app-visita>
             <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
         </div>
      
        <div *ngIf="!user.open" >
         <button  class="btn btn-primary"  (click)="user.open=true" >Ver detalles</button> <br>
         <span  >{{user.fechat}}</span><br>

         <span class="text" *ngIf="user.eventid!='general'"  > Evento: {{user.eventname}}</span>
         <span class="text" *ngIf="user.eventid=='general'"  > Evento: General</span>
        
         <br>
         <span class="text" > Descripcióndd: {{user.descripcion}}</span><br>
         <span class="text" > Nivel de acceso: {{user.acceso}}</span><br>
         <div style="border:solid 2px black" >
             <h2 >Notas de la visita</h2>
             <span *ngFor="let nota of user.notas" >
             - {{nota.txt}} 
             <br>
             </span>
             <button  (click)="newnota(user._id)" class="btn btn-primary m-1" >Agregar nota</button>
                     </div>
         <!-- <div *ngIf="user.empresa">
             {{user.empresa.name}}
             <span class="text"> NIT: {{user.empresa.nit}}</span>
       
             <br>
       
             <span class="text" *ngIf="user.placa"> Vehiculo: {{user.placa}}</span>
             <br>
                         
       
       
           </div> -->
 
         <span  [ngClass]="{'verde':persona.aprobe3}" *ngFor="let persona of user.personas" >
             {{persona.name}} {{persona.apellido}} -- {{persona.id}}     <br> 
             <span *ngIf="persona.aprobe3 && persona.aprobe2 && persona.aprobe1 " > Aprobado <br> </span>
             <span *ngIf="!persona.aprobe3 || !persona.aprobe2 || !persona.aprobe1 "  > No aprobado <br> </span>
      
         <span *ngIf="persona.ingreso" > Ya registro ingreso <br> </span>
         <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
        
         <hr>
         </span>
     
        
        </div>
         <button class="btn-danger"  (click)="eliminar(user._id)" >Eliminar</button>
     </div>
 </div>
 
         </div>
         
     </div>
 
     <div class="col-md-12" *ngIf="vhoy" >
       <div class="row ml-2 " >
<div class="col-md-6 col-12">

<div class="col-md-6" *ngIf="!yabusco" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; background: white; padding:15px; border-radius: 10px;">
    <span style="color: rgb(0, 0, 0); font-size: 15px;" >Buscar visitas por empresa:</span>
    <select  (change)="buscar()" id="buscado" class="form-control">
    <option value="">Selecciona</option>
    <option value="{{empresa._id}}"  *ngFor="let empresa of empresas"  >{{empresa.name}}</option>
    </select>
</div>
<div *ngIf="yabusco" >
<span style="color: white; font-size: 20px;" >visitas por empresa <br></span>
<button  class="btn btn-danger" style=" font-size: 15px; padding: 2px; border: none;" (click)="limpiar()" >limpiar</button>
</div>

</div>
       </div>
 
         <div class="row justify-content-center" >
             <div class="col-md-5"  *ngFor="let user of hoy" >
                
         <div class="mt-2 " [ngClass]="{'verde':user.aprobada==user.personas.length && !user.admin,'verde3':user.aprobada==user.personas.length && user.porteria, 'verde2':user.aprobada==user.personas.length && user.admin, 'amarillo':user.aprobada != user.personas.length && user.aprobada!=0,'caja-datos':user.aprobada==0}"   style=" border-radius: 15px; padding: 5px;" >
             <div   *ngIf="user.open" >
                 <app-visita [visita]="user"  ></app-visita>
                
                 <button  class="btn btn-info"  (click)="user.open=false" >Ocultar detalles</button>
             </div>
           
             
            <div *ngIf="!user.open">
             <!-- <a href="/aprobar4/{{user._id}}" target="_blank" rel="noopener noreferrer">
                 <button class="btn btn-primary btn-2" >Aprobar</button>
                 </a> -->
                 <div class="d-flex justify-content-between  m-2">
                    <button  *ngIf="!user.open" class="btn btn-info btn-2"  (click)="user.open=true" >Ver detalles</button>
                    <button (click)="aprbationSol(user)" class="btn btn-info btn-2"  >
                            Enviar solicitud de aprobación
                    </button>
                    <br>
                    <div *ngIf="user.eventid=='general' && user.aprobada!=user.personas.length">
                        <button class="btn btn-info btn-2"  (click)="aprobar(user)"  >Aprobar </button>

                    </div>
                    <div *ngIf="user.eventid=='general' && user.aprobada==user.personas.length">
                        <button class="btn btn-info btn-2"  (click)="anadirPersona(user)"  >Añadir persona</button>
                    </div>
                </div>
             <span  >{{user.fechat}}</span><br>
            
             <span class="text" *ngIf="user.eventid!='general'"  > Evento: {{user.eventname}}
                 <br>
                 Recibe: {{user.recibeName}} <br>
                 Area: {{user.recibeArea}}
             </span>
             <span class="text" *ngIf="user.eventid=='general'"  > Evento: General
                 <br>
                 Recibe: {{user.recibeName}} <br>
                 Correo: {{user.anfitrion}}
             </span>
            
             <br>
             <span class="text" > Descripción: {{user.descripcion}}</span><br>
             <span class="text"*ngIf="user.acceso"  > Nivel de acceso: {{user.acceso}}<br></span> 
             <span class="text" *ngIf="user.empresa" > Empresa: {{user.empresa.name}}<br></span>
             <span class="text" *ngIf="!user.empresa" > Empresa: Independiente<br> </span>
             <span class="text" > Número de personas: {{user.personas.length}}<br> </span>
             <div class="d-flex justify-content-end  ">
                 <button *ngIf="!user.notasopen" class="btn btn-info btn-2" (click)="user.notasopen=true" >ver notas</button><br>
             </div>
             <div *ngIf="user.notasopen" style="border:solid 2px black" >
                 <h2 >Notas: </h2>
                 <span *ngFor="let nota of user.notas" >
                 - {{nota.txt}} 
                 <br>
                 </span>
                 <button  (click)="newnota(user._id)" class="btn btn-info m-1 btn-2" >Agregar nota</button>
                 <button  class="btn btn-info btn-2" (click)="user.notasopen=false" >ocultar notas</button>
                         </div>
             <!-- <div *ngIf="user.empresa">
                 {{user.empresa.nombre}}
                 <span class="text"> NIT: {{user.empresa.nit}}</span>
           
                 <br>
           
                 <span class="text" *ngIf="user.placa"> Vehiculo: {{user.placa}}</span>
              <br>
                             
           
           
               </div> -->
               <span class="text" style="font-size: 22px;" > Personas:</span><br>
             <span [ngClass]="{'verde':persona.aprobe3}" *ngFor="let persona of user.personas" >
                 {{persona.name}} {{persona.apellido}}  -- {{persona.id}}  <br> 
                 <span *ngIf="persona.aprobe3 && persona.aprobe2 && persona.aprobe1 " > Aprobado <br> </span>
                 <span *ngIf="!persona.aprobe3 || !persona.aprobe2 || !persona.aprobe1 "  > No aprobado <br> </span>
                 <span>Aprobación nivel 1: <span *ngIf="persona.aprobe1" >Aprobado</span> <span *ngIf="!persona.aprobe1" >No aprobado</span>  </span> <br>
                 <span>Aprobación nivel 2: <span *ngIf="persona.aprobe2" >Aprobado</span> <span *ngIf="!persona.aprobe2" >No aprobado</span>  </span> <br>
             <span *ngIf="persona.ingreso" > Ya registro ingreso <br> </span>
             <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
             <span *ngIf="persona.salida" > Ya registro salida  <br> </span>
             <span *ngIf="!persona.salida" > No ha salido <br> </span>
            
             <hr>
             </span>
         
        
            </div>
            <div class="d-flex justify-content-end  ">
             <button class="btn-danger"  (click)="eliminar(user._id)" >Eliminar</button>
            </div>
         </div>
       
             </div>
             
                     </div>
         
     </div>
    <div class="col-md-12" *ngIf="vproximas"  >
     
        <div class="row justify-content-center" >
            <div class="col-md-4" *ngFor="let user of proximas" >
                <div class="mt-2 caja-datos" [ngClass]="{'verde':user.aprobada==user.personas.length && !user.admin,'verde3':user.aprobada==user.personas.length && user.porteria, 'verde2':user.aprobada==user.personas.length && user.admin, 'amarillo':user.aprobada != user.personas.length && user.aprobada!=0,'caja-datos':user.aprobada==0}"   style=" border-radius: 15px; padding: 5px;" >
                    <div *ngIf="user.open" >
                        <app-visita [visita]="user"  ></app-visita>
                        <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
                    </div>
                
                   <div *ngIf="!user.open" >
                    <button (click)="aprbationSol(user)" class="btn btn-primary btn-2"  >
                        Enviar solicitud de aprobación
                 </button>
                 <br>
                 <div *ngIf="user.eventid=='general'">
                    <button class="btn btn-primary btn-2"  (click)="aprobar(user)"  >Aprobar </button>

                 </div>
                 <div *ngIf="user.eventid=='general' && user.aprobada==user.personas.length">
                    <button class="btn btn-primary btn-2"  (click)="anadirPersona(user)"  >Añadir persona</button>
                 </div>
             <span  >{{user.fechat}}</span><br>
            
             <span class="text" *ngIf="user.eventid!='general'"  > Evento: {{user.eventname}}
                 <br>
                 Recibe: {{user.recibeName}} <br>
                 Area: {{user.recibeArea}}
             </span>
             <span class="text" *ngIf="user.eventid=='general'"  > Evento: General
                 <br>
                 Recibe: {{user.recibeName}} <br>
                 Correo: {{user.anfitrion}}
             </span>
            
             <br>
             <span class="text" > Descripción: {{user.descripcion}}</span><br>
             <span class="text"*ngIf="user.acceso"  > Nivel de acceso: {{user.acceso}}<br></span> 
             <span class="text" *ngIf="user.empresa" > Empresa: {{user.empresa.name}}<br></span>
             <span class="text" *ngIf="!user.empresa" > Empresa: Independiente<br> </span>
             <span class="text" > Número de personas: {{user.personas.length}}<br> </span>

                    <span *ngFor="let persona of user.personas" >
                        {{persona.name}} {{persona.apellido}} -- {{persona.id}}    <br> 
                        <span *ngIf="persona.aprobe3 && persona.aprobe2 && persona.aprobe1 " > Aprobado <br> </span>
                <span *ngIf="!persona.aprobe3 || !persona.aprobe2 || !persona.aprobe1 "  > No aprobado <br> </span>
                    <span *ngIf="persona.ingreso" > Ya registro ingreso <br> </span>
                    <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
                   
                    <hr>
                    </span>
                
                    <button  class="btn btn-primary"  (click)="user.open=true" >Ver detalles    </button>
                   </div>
                    <button class="btn-danger"  (click)="eliminar(user._id)" >Eliminar</button>
                </div>
              
            </div>
            
                    </div>
       
    </div>
</div> 
<div style="position: fixed;bottom: 0px; left: 0; width: 100%; " >
<div class="row" >
<div class="col-md-3 col-9" >
<button class="btn btn-primary" (click)="bita=true" *ngIf="!bita"  >Abrir Bitacora </button>
<div  class="m-2" *ngIf="bita" style="color: black; background-color: white; " >
    <div style="text-align: end; justify-content: end;" >
<button class="btn btn-danger" (click)="bita=false"  >Cerrar</button>
    </div>
    <div  style="color: black; background-color: white; max-height: 250px; overflow-y: scroll; " class=" container" >
       
        <div  *ngFor="let question of questions" >
            - {{question.question}}  -  {{question.time}} 
        </div>
    </div>
<form [formGroup]="bitaForm" (ngSubmit)="newitem()" novalidate="">
    <input type="text " formControlName="question" >
    <input type="submit" value="Guardar"  class="btn btn-orange"  >
</form>
</div>

</div>
</div>
</div>

<div  *ngIf="newNota" class="pop" (click)="closenota()">

    <div (click)="nopro($event)"   style="position: fixed; top: 10%; left: 30%; width: 40%; background-color: rgb(4, 4, 65); " >  
        <h1 style="color: white;" >
            Agregue la nota correspondiente a la visita.
        </h1>
        <form [formGroup]="notaForm" (ngSubmit)="sendnota()" novalidate="">
            <textarea formControlName="question"cols="40" rows="7"></textarea>
           
            <br>
            <input type="submit" value="Guardar"  class="btn btn-primary"  >
        </form>
    </div>

</div>


    <div 
    style="
    position: fixed;
    top: 5%;
    left: 25%;
    width: 50%;
    z-index: 99;
    background-color: rgb(71, 71, 241);
    padding: 15px;
    "
    *ngIf="selecanfi"
    > 
    <h1>Seleccione a quien enviarle solicitud de aprobación para esta visita</h1>

    <select  id="anfitrionSelect">
        <option value="">Selecciona</option>
        <option value="{{anfitrion}}" *ngFor="let anfitrion of correosAnfitrion" >{{anfitrion}}</option>
    </select>

    <button class="btn btn-primary"  (click)="sendNotify()" >Confirmar</button>
    <button class="btn btn-danger" (click)="selecanfi=false">Cancelar </button>
    </div>

    <div 
    style="
    position: fixed;
    top: 1%;
    left: 10%;
    width: 80%;
    z-index: 99;
    background-color: rgb(71, 71, 241);
    padding: 15px;
    "
    *ngIf="anadir"
    > 
    <h1>Agregue personas a esta visita</h1>
    <form  *ngIf="!nuser" [formGroup]="idrperson" (ngSubmit)="lookPerson()"   >

        <div class="form-group">
            <h3>Documento de identidad:</h3>
            <input class="form-control" type="number" placeholder="Documento de identidad" formControlName="id" >
            
        </div>
        <input type="submit" class="btn btn-primary" value="Buscar">
    </form>
    <div *ngIf="nuser" >
        <div class="row mt-4 justify-content-center" >
            <div class="col-5" >
                <app-persona [user]="luser" [show]="false" ></app-persona>    
            </div>
        </div>
        <button class="btn btn-primary"  (click)="confirmAddPerson()" >Confirmar</button>
    </div>
    <button class="btn btn-danger" (click)="anadir=false">Cancelar </button>
    </div>  



    <div *ngIf="visitapop" class="row cajainfo text-white  container mt-5" style="position: fixed; top: 1%; left:8%; padding: 10px; overflow-y: scroll; max-height: 550px;">
        <div class="col-md-10">
         <form [formGroup]="visitaForm" (ngSubmit)="newVisita()" novalidate="">  
             <div class="form-group">
                 <h3>Nivel de acceso: </h3>
                 <select  id=""  formControlName="acceso" [ngClass]="{ 'is-invalid': submitted && f.acceso.errors }">
                   <option value="">Seleccione</option>
                   <option value="General personal">General personal</option>
                   <option value="General sin manilla">General sin manilla</option>
                   <option value="Protocolo">Protocolo</option>
                   <option value="Servicio Platea">Servicio Platea</option>
                   <option value="VIP-Platea-Boxes-Suites">VIP-Platea-Boxes-Suites</option>
                   <option value="All access">All access</option>
                 </select>
                 <div *ngIf="submitted && f.acceso.errors" class="invalid-feedback">
                     <div *ngIf="f.acceso.errors.required">Campo requerido</div>
                        </div>
             </div>
             <div class="form-group">
                <h3>Empresa:</h3>    
                <select  formControlName="empresa" [ngClass]="{ 'is-invalid': submitted && f.empresa.errors }">
                    <option value="">Independiente</option>
                   <option value="{{empresa._id}}" *ngFor="let empresa of tempresas" >{{empresa.name}}</option>
        
                </select>
                <div *ngIf="submitted && f.empresa.errors" class="invalid-feedback">
                    <div *ngIf="f.empresa.errors.required">Campo requerido</div>
                       </div>
                </div>

             <div class="form-group" >
                <h3>
                    seleccione el evento que va a visitar
                </h3>
                <select formControlName="eventid"   [ngClass]="{'is-invalid': submitted && f.eventid.errors  }">
                
                <option value="">Seleccione</option>
                <option value="general">Visita general</option>
                <option value="{{event._id}}" *ngFor="let event of eventos" >{{event.nombre}}</option>
                </select>
                <div *ngIf="submitted && f.eventid.errors" class="invalid-feedback">
                    <div *ngIf="f.eventid.errors.required">Campo requerido</div>
                       </div>
               </div> 
     
                        
                         <div class="form-group" *ngIf="visitaForm.controls['tipo'].value=='prestacion de servicios'" >
                     <h3>Describa el servicio que va a prestar</h3>
                     <input type="text" class="form-control"  formControlName="descripcion" [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }" >
                         </div>

                         <div class="form-group"  >
                            <h3>Escriba la nota de aprobación (Quien aprueba el ingreso)</h3>
                            <input type="text" class="form-control"  formControlName="nota" [ngClass]="{ 'is-invalid': submitted && f.nota.errors }" >
                            <div *ngIf="submitted && f.nota.errors" class="invalid-feedback">
                                <div *ngIf="f.nota.errors.required">Campo requerido</div>
                                   </div>
                                </div>
                         
                         <div class="form-group">
                             <h3>Ingrese la fecha y hora de su visita</h3>     
                             <input class="form-control" type="datetime-local" placeholder="Fecha" formControlName="date"
                             min="2021-06-01" max="2023-12-31"
                             required      >
                             <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                                 <div *ngIf="f.date.errors.required">Campo requerido</div>
                                    </div>
                             </div>
                            Personas agregadas: {{agregados.length}} <br>
                             <div *ngIf="!validcionPersonas" >
                                 Ingrese las cédulas de las personas a agregar (puede pegar desde una hoja de calculo) : <textarea  cols="20" rows="3" id="marcauno" autocomplete="off" #marcauno ></textarea> <button (click)="marcaruno()" type="button" > Agregar</button>
                             </div>
     
                             
                             <ul *ngIf="!validcionPersonas"  style="max-height: 100px; overflow-y: auto;" >
                                 <li *ngFor="let id of visitaForm.controls['personasc'].value" >{{id}}</li>
                             </ul>
                             <div *ngIf="validcionPersonas" class="row" >
                                 <div class="col-md-6">
                                     <h2>Usuarios registrados</h2>
                                     <ul   style="max-height: 100px; overflow-y: auto;" >
                                         <ng-container *ngFor="let id of visitaForm.controls['personas'].value" >
                                             <li *ngIf="id.name" >{{id.name}}- {{id.id}} </li>
                                         </ng-container>
                                         
                                     </ul>
                                 </div>
                                 <div class="col-md-6">
                                     <h2>Usuarios no registrados</h2>
                                     <ul   style="max-height: 100px; overflow-y: auto;" >
                                         <ng-container *ngFor="let id of visitaForm.controls['personas'].value" >
                                             <li *ngIf="!id.name" > {{id.id}} </li>
                                         </ng-container>
                                         
                                     </ul>
                                 </div>
                                
                                 
                             
                    
                </div>
                 <input type="submit" *ngIf="validcionPersonas" value="Crear visita" class="btn btn-primary">
           
             </form>
             <div class="row">
     <div class="col-10">
         <button class="btn btn-primary" *ngIf="!validcionPersonas" (click)="validarPersonas()" >Validar documentos</button>
         <button class="btn btn-danger ml-2" (click)="visitapop=false" >Cancelar</button>
     
     </div>
             </div>
        </div>
        
        </div> 