<!-- <div class="d-flex justify-content-center mb-5 text-white">
     <h2 >Bienvenido</h2>
</div>
<div class="container w-50 bg-gray p-2 rounded-top shadow text-dark" *ngIf="!pay" style="text-align: center;">
<form [formGroup]="miFormulario" (ngSubmit)="login()" novalidate="">    
     <div class="form-group">
     <h3>Ingrese su correo electronico</h3>     
     <input class="form-control" type="email" placeholder="Correo electrónico" formControlName="correo"  [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
     <div *ngIf="submitted && f.correo.errors" class="invalid-feedback ">
          <div *ngIf="f.correo.errors.required">ingrese su correo</div>
          <div *ngIf="f.correo.errors.email">ingrese un correo valido</div>
      </div> 
     </div>
     <input type="submit" value="Ingresar" class="btn btn-orange">

</form>
</div>  -->
   

<div class="container h-100">
     <div class="row h-100 justify-content-center align-items-center">
       <div style="padding: 30px; text-align: center;">
         <h3 style="color: white">¡Bienvenidos!</h3>
   
         <div *ngIf="!pay && !loading"  class="row justify-content-center align-items-center">
           <div class="col-12 col-md-8 ">
             <img width="99%" src="./assets/caja_correo.png">
             <div style="position: absolute; top: 43%; left: 10%; width: 80%; text-align: left;">
               <form [formGroup]="miFormulario" (ngSubmit)="login()" novalidate="">  
               Correo electrónico
               <input  placeholder="micorreo@email.com" formControlName="correo"  [ngClass]="{ 'is-invalid': submitted && f.correo.errors }" style=" height: 60px; width: 100%; border-radius: 5px 5px 5px 5px" type="text" name="">
               <div *ngIf="submitted && f.correo.errors" class="invalid-feedback ">
                    <div *ngIf="f.correo.errors.required">ingrese su correo</div>
                    <div *ngIf="f.correo.errors.email">ingrese un correo valido</div>
                </div> 
              <!-- <div>         
              <re-captcha (resolved)="resolved($event)" 
              formControlName="recaptchaReactive" 
              siteKey="6LeyXfYaAAAAAECGHAvnSdZRAwKuO833rqrVO8UI">  
               </re-captcha>
   
</div>
<div *ngIf="submitted && f.recaptchaReactive.errors" class="invalid-feedback ">
  <div *ngIf="f.recaptchaReactive.errors.required">Marque la casilla</div> 
</div> -->
               <img (click)="login()" style="display: block; margin: 0 auto;" width="67%" src="./assets/boton_ingresar.png" >
         
          </form>
             </div>
           </div>
         </div>
         <div class="container  bg-gray p-2 rounded-top shadow text-dark row justify-content-center align-items-center" *ngIf="pay"  >

          <video  playsinline  width="400" height="225" autoplay #video></video> <br>
          <button (click)="tomar()" >Validar</button>
     
          <canvas #canvas style="display:none; "></canvas>
          </div>

          <div *ngIf="loading">
            <h1 style="color: white"><strong>Cargando ...</strong></h1>
          </div>
   
       </div>
     </div>
   </div> 
   

   
   
   
   



<div class="container w-50 bg-gray p-2 rounded-top shadow text-dark" *ngIf="pay"  >

     <video  playsinline  width="400" height="225" autoplay #video></video> <br>
     <button (click)="tomar()" >Validar</button>

     <canvas #canvas style="display:none; "></canvas>
     </div>