<div class="row justify-content-center " style="color: white;" > 
    <div class="col-md-6 mt-2" style="justify-content: center; text-align: center;" >
        <h1 *ngIf="event" style="font-size: 35px; font-weight: bold; text-align: center; margin-bottom: 5px;" >Evento: {{event.nombre}}</h1>
        <h2>  Bienvenido {{user.name}} {{user.apellido}}</h2>
    </div>
</div>

<div class="row justify-content-center m-1" >
<div class="col-11 col-md-9">
    <div class="row justify-content-center" >
        <div class="col-md-9 col-10 ">
            <h1>
                Visitas asociadas 
            </h1>
        </div>
        
        </div>
    <!-- <div class="row ml-2 " >
        <div class="col-md-6 col-12">
        
         <div *ngIf="!yabusco" >
           <span style="color: white; font-size: 20px;" >Buscar visitas por empresa:</span>
            <select  (change)="buscar()" id="buscado">
                <option value="">Selecciona</option>
            <option value="{{empresa._id}}"  *ngFor="let empresa of empresas"  >{{empresa.name}}</option>
            </select>
         </div>
         <div *ngIf="yabusco" >
            <span style="color: white; font-size: 20px;" >visitas por empresa <br></span>
            <button  class="btn btn-danger" style=" font-size: 15px; padding: 2px; border: none;" (click)="limpiar()" >limpiar</button>
         </div>
        
        </div>
                      </div> -->
                
                        <div class="row justify-content-center" >
                            <div class="col-md-4"  *ngFor="let user of visitas" >
                               
                        <div class="mt-2 caja-datos" [ngClass]="{'verde':user.aprobada==user.personas.length && !user.admin,'verde3':user.aprobada==user.personas.length && user.porteria, 'verde2':user.aprobada==user.personas.length && user.admin, 'amarillo':user.aprobada != user.personas.length && user.aprobada!=0,'caja-datos':user.aprobada==0}"   style=" border-radius: 15px; padding: 5px;" >
                            <div   *ngIf="user.open" >
                                <app-visita [visita]="user"  ></app-visita>
                               
                                <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
                            </div>
                          
                            
                           <div *ngIf="!user.open" >
                         
                                <button  *ngIf="!user.open" class="btn btn-primary btn-2"  (click)="user.open=true" >Ver detalles</button><br>
                            <span  >{{user.fechat}}</span><br>
                           
                            <span class="text" *ngIf="user.eventid!='general'"  > Evento: {{user.eventname}}
                                <br>
                                Recibe: {{user.recibeName}} <br>
                                Area: {{user.recibeArea}}
                            </span>
                            <span class="text" *ngIf="user.eventid=='general'"  > Evento: General
                                <br>
                                Recibe: {{user.recibeName}} <br>
                                Correo: {{user.anfitrion}}
                            </span>
                           
                            <br>
                            <span class="text" > Descripción: {{user.descripcion}}</span><br>
                            <span class="text"*ngIf="user.acceso"  > Nivel de acceso: {{user.acceso}}<br></span> 
                            <span class="text" *ngIf="user.empresa" > Empresa: {{user.empresa.name}}<br></span>
                            <span class="text" *ngIf="!user.empresa" > Empresa: Independiente<br> </span>
                            <span class="text" > Número de personas: {{user.personas.length}}<br> </span>
        
                            <button *ngIf="!user.notasopen" class="btn btn-primary btn-2" (click)="user.notasopen=true" >ver notas</button><br>
                            <div *ngIf="user.notasopen" style="border:solid 2px black" >
                                <h2 >Notas: </h2>
                                <span *ngFor="let nota of user.notas" >
                                - {{nota.txt}} 
                                <br>
                                </span>
                                <button  (click)="newnota(user._id)" class="btn btn-primary m-1 btn-2" >Agregar nota</button>
                                <button  class="btn btn-primary btn-2" (click)="user.notasopen=false" >ocultar notas</button>
                                        </div>
                       
                              <span class="text" style="font-size: 22px;" > Personas:</span><br>
                            <span [ngClass]="{'verde':persona.aprobe3}" *ngFor="let persona of user.personas" >
                                {{persona.name}} {{persona.apellido}}    <br> 
                                <span *ngIf="persona.aprobe3 && persona.aprobe2 && persona.aprobe1 " > Aprobado <br> </span>
                                <span *ngIf="!persona.aprobe3 || !persona.aprobe2 || !persona.aprobe1 "  > No aprobado <br> </span>
                                <span>Aprobación nivel 1: <span *ngIf="persona.aprobe1" >Aprobado</span> <span *ngIf="!persona.aprobe1" >No aprobado</span>  </span> <br>
                                <span>Aprobación nivel 2: <span *ngIf="persona.aprobe2" >Aprobado</span> <span *ngIf="!persona.aprobe2" >No aprobado</span>  </span> <br>
                            <span *ngIf="persona.ingreso" > Ya registro ingreso <br> </span>
                            <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
                            <span *ngIf="persona.salida" > Ya registro salida  <br> </span>
                            <span *ngIf="!persona.salida" > No ha salido <br> </span>
                           
                            <hr>
                            </span>
                        
                       
                           </div>
                        
                            
                        </div>
                      
                            </div>
                            
                                    </div>
                        
                    </div>
                    <div  class="col-11 col-md-3">
                        <!-- <div class="row" >

                            <div  class="col-md-4 col-6 caja-ingreso"  [ngClass]="{'verde':ingreso.user.funcionario==true }"  *ngFor="let ingreso of ingresos">
                                {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                                CC: {{ingreso.user.id}} <br>
                                Ingreso: {{ingreso.fechat}} <br>
                                 <span *ngIf="ingreso.salida" >  Salida: {{ingreso.fechas}} <br> </span>
                                <img src="{{ingreso.foto}}" *ngIf="ingreso.open" width="100%" alt="">
                                <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-primary btn-peque " >Ver foto</button>
                                <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-primary btn-peque " >Ocultar foto</button>
                                </div>
                        </div> -->
                        <h1>Personas habilitadas para ingreso: {{nPeople}} </h1>
                        <button *ngIf="downReady" (click)="downIngreso()" >Descargar ingresos asociados</button>

                    </div>
</div>

