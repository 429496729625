import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-muestra',
  templateUrl: './muestra.component.html',
  styleUrls: ['./muestra.component.css']
})
export class MuestraComponent implements OnInit {

  intervalid;
  user;
  emptyUser;
 createStand: FormGroup;
 stands=[];
 
 enabled;
   constructor(private authService : AuthService, private eventService : EventsService, private router: Router, private cd: ChangeDetectorRef) { 
     this.createStand = new FormGroup({
       'img':new FormControl("",[Validators.required,]),
       'imgSrc':new FormControl("",[Validators.required,]),
  'id':new FormControl("0",[Validators.required,]),
       'name': new FormControl('', [
         Validators.required,
         Validators.minLength(5),
       ]),

       'riframe': new FormControl('', [
         Validators.required,
         Validators.minLength(10),
       ]),
       'liframe': new FormControl('', [
        Validators.required,
        Validators.minLength(10),
      ]),


     });
  
 
 
   }
 
   ngOnInit(): void {
     this.enabled=false;
     let user = localStorage.getItem('userInfo')
     this.user= JSON.parse(user).user;
     console.log(this.user);
     this.loadAllStands();
  
   this.intervalid = setInterval(() => {
     this.loadAllStands();
   }, 15000);
     
   }
   ngOnDestroy() {
     if (this.intervalid) {
       clearInterval(this.intervalid);
     }
   }
 
   onFileChange(event) {
     let reader = new FileReader();
 
     if (event.target.files.length > 0) {
       const file = event.target.files[0];
       reader.readAsDataURL(file);
       reader.onload = () => {
    
         this.createStand.patchValue({
           imgSrc: reader.result
         });
         
         // need to run CD since file load runs outside of zone
         this.cd.markForCheck();
       };
 
     }
   }
 
 
   private loadAllStands() {
 
     this.eventService.getStands()
     .then((response)=>{
      var stands=response["stands"];
 
       this.stands=response["stands"];
  
     });
 
 }
 
 
 
 newStand(){
   this.eventService.createStand(this.createStand.value)
   .then((response) => {
     console.log(response);
     this.emptyUser={
       name:"",
       riframe:"",
       liframe:"",
       id:"0"
     };
     this.createStand.reset(this.emptyUser );
     this.loadAllStands();
   })
   .catch((err)=>{
    console.log(err);
 })
 }
 removeEvent(id){
   Swal.fire(     {
     title: "Estas seguro que deseas eliminar el stand? ",
     text:"",
     icon: 'warning',
     showCancelButton: true,
     confirmButtonColor: '#3085d6',
     cancelButtonColor: '#d33',
     confirmButtonText: 'Archivar',
     cancelButtonText: "Cancelar"
     
 }).then((result)=>{
 if(result.value){
 
   this.eventService.deletestand(id)
   .then((response) => {
     console.log(response);
     this.loadAllStands();

   })
   .catch((err)=>{
    console.log(err);
 })}
 else{}
 });
 }
 
 removepEvent(id){
   Swal.fire(     {
     title: "Estas seguro que deseas eliminar el evento? ",
     text:"Se perderan todos los datos",
     icon: 'warning',
     showCancelButton: true,
     confirmButtonColor: '#3085d6',
     cancelButtonColor: '#d33',
     confirmButtonText: 'Eliminar',
     cancelButtonText: "Cancelar"
     
 }).then((result)=>{
 if(result.value){
 
   this.eventService.deletep(id)
   .then((response) => {
     console.log(response);
     this.loadAllStands();
  
   })
   .catch((err)=>{
    console.log(err);
 })}
 else{}
 });
 }
 
 
 registrados(id,name){
   this.eventService.registrados(id)
   .then((response) => {
     console.log(response["results"]);
     var rows=[];
     response["results"].forEach(element => {
       rows.push(element.user);
     });
     
     let csvContent = "data:text/csv;charset=utf-8,";
     var user=[];
     rows.forEach((rowArray)=> {
       user=[];
       user.push(rowArray.name);
       user.push(rowArray.id);
       user.push(rowArray.correo);
       user.push(rowArray.ciudad);
       user.push(rowArray.especialidad);
       console.log(user);
         let row = user.join(",");
         csvContent += row + "\r\n";
     });
     saveAs(csvContent,"registrados_"+name+".csv");
 
   })
   .catch((err)=>{
    console.log(err);
 })
 }
 
 
 asistentes(id,name){
   this.eventService.asistentes(id)
   .then((response) => {
     console.log(response["results"]);
     var rows=[];
     response["results"].forEach(element => {
       rows.push(element.user);
     });
     
     let csvContent = "data:text/csv;charset=utf-8,";
     var user=[];
     rows.forEach((rowArray)=> {
       user=[];
       user.push(rowArray.name);
       user.push(rowArray.id);
       user.push(rowArray.correo);
       user.push(rowArray.ciudad);
       user.push(rowArray.especialidad);
       console.log(user);
         let row = user.join(",");
         csvContent += row + "\r\n";
     });
     saveAs(csvContent,"asistentes_"+name+".csv");
 
   })
   .catch((err)=>{
    console.log(err);
 })
 }
 
 
 edit(stand){
   var stande:Object ={
     id:stand._id,
     name:stand.name,
     riframe:stand.riframe,
     liframe:stand.liframe,
   
 
   }
   this.createStand.reset(stande);
 }
 
 logout(){
   localStorage.removeItem('userInfo');
   this.router.navigate(['login']);
 }
 
 ingresar(event,id){

   location.href="/stand/"+id;


 }
 
 

 
 registrarme(event){
   this.eventService.register(event,this.user)
   .then((response) => {
     console.log(response);
     this.user.events.push(event._id);
     this.authService.setUserInfo({'user' : this.user});
   })
   .catch((err)=>{
    console.log(err);
 })
 
 }
 
 ir_url(){
   location.href="https://selesoluciona.com:4201/#/ipservices"
 }
 
 
 
 }
 