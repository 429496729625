<div style="text-align: center;"  >

<div class="bg-white justify-content-start " id="chattable" style="margin-left: 1%;  width: 100%; text-align: start; height: 420px; overflow: scroll; overflow-x: hidden;" >
    <!-- <ul style="text-align: start;">
        <li  style="color: black;" *ngFor="let question of chats">{{question.user.name}}: {{question.question}} </li>
    
    </ul> -->
    <div *ngFor="let question of chats" style="border-radius: 10px;margin: 0%; "  >
<h4 style="margin: 0px; color: #454549; font-family: gotham_bold;"> - <span style="color: rgb(245,178,0) ;"> {{question.user.name}}</span>: {{question.question}}</h4>
    </div>
</div>
<div style="width: 100%; text-align: start;">
   
    <textarea class="input-form"(keyup.enter)="sendChat()" style="width: 99%; font-family: gotham_light; border:1px solid #ffffff; width: 100%; border-radius: 2px 2px 2px 2px" name="preguta" id="pregunta" cols="30" rows="2" placeholder="Envía tu mensaje"></textarea>
    <button (click)="sendChat()"  style="background-color: rgb(245,  178,0); color: black;" class="btn btn-form" >Enviar</button>
    <button *ngIf="user.rol=='admin'" (click)="descargar()" class="btn btn-danger" >Descargar</button>
    <button *ngIf="user.rol=='admin'" (click)="borrar()" class="btn btn-danger" >Borrar</button>

</div>
</div>