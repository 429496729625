import { Component, OnInit , ElementRef, Renderer2, ViewChild,} from '@angular/core';
import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service";

import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { interval } from 'rxjs';
import  Swal from 'sweetalert2'; 
import { saveAs } from 'file-saver';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-solicitudes-extras',
  templateUrl: './solicitudes-extras.component.html',
  styleUrls: ['./solicitudes-extras.component.css']
})
export class SolicitudesExtrasComponent implements OnInit {
user;
range: FormGroup;
solicitudesPendiente;
solicitudesAprobadas;
solicitudesRechazadas;
ver=0;
title="PENDIENTES";
closeResult = '';
initdate: string;
enddate: string;
findRange: boolean;
rSolicitudes:any;
  reporte: boolean;
  constructor(
    private authService : AuthService,
    private renderer: Renderer2,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.range = new FormGroup({
      start: new FormControl(<Date | null>(null)),
      end: new FormControl(<Date | null>(null)),
    });
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    this.loaduser();
   
    
  }
  loaduser(){
    this.authService.validatep(this.user.id)
    .then((response) => {
      console.log(response);
      this.authService.setUserInfo({'user' : response['user']});
      let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    this.getSolicitudes();

    })     
  }




  //reportes
  addEvent(type,event){
   
    var value=this.range.value;
    if(value.end && value.start){
      console.log(this.range.value)
      //calcular y mostrar rango de fechas
      this.initdate=value.start.getUTCDate()+"/"+(value.start.getMonth()+1)+"/"+value.start.getUTCFullYear();
      this.enddate=value.end.getUTCDate()+"/"+(value.end.getMonth()+1)+"/"+value.end.getUTCFullYear();
      this.reporte=false;
  
    }
  }
  newFechaRange(){
    this.rSolicitudes=[]
  
    var value=this.range.value;
    this.initdate=value.start.getUTCDate()+"/"+(value.start.getUTCMonth()+1)+"/"+value.start.getUTCFullYear();
    this.enddate=value.end.getUTCDate()+"/"+(value.end.getUTCMonth()+1)+"/"+value.end.getUTCFullYear();
      var idia=value.start.getUTCDate();
      var imes=value.start.getUTCMonth()+1;
      var iano=value.start.getFullYear();
      var edia=value.end.getUTCDate();
      var emes=value.end.getUTCMonth()+1;
      var eano=value.end.getFullYear();
  
  
  if(iano!=eano){
    Swal.fire("El rango de fechas debe estar en el mismo año")
    .then(()=>{
      this.range.reset()
      return
    })
  }
  else if(iano==eano && emes-imes>1){
    Swal.fire("El rango de fechas es muy extenso","Por favor seleccine un rango de maximo 40 días")
    .then(()=>{
      this.range.reset()
      return
    })
  }
  else{
    let data
    //busco las solicitudes
    //this.authService.getSolrango(idia,imes,iano,edia,emes,eano)
    data.then((response:any)=>{
  console.log(response);
  var i=0;
response.forEach(sol => {
  i+=1;
    if(sol.hed>0){
      var row={
          id:sol.user.id,
          nombre:sol.user.name+" "+sol.user.apellido,
          tipo:"HED",
          sede:sol.sede,
          codTipo:"13",
          cantidad:sol.hed

      }
      this.rSolicitudes.push(row)
    }
    if(sol.hdf>0){
      var row={
          id:sol.user.id,
          nombre:sol.user.name+" "+sol.user.apellido,
          tipo:"HDF",
          sede:sol.sede,
          codTipo:"60",
          cantidad:sol.hdf

      }
      this.rSolicitudes.push(row)
    }

    if(sol.rn>0){
      var row={
          id:sol.user.id,
          nombre:sol.user.name+" "+sol.user.apellido,
          tipo:"RN",
          sede:sol.sede,
          codTipo:"89",
          cantidad:sol.rn

      }
      this.rSolicitudes.push(row)
    }
    if(sol.hnf>0){
      var row={
          id:sol.user.id,
          nombre:sol.user.name+" "+sol.user.apellido,
          tipo:"HNF",
          sede:sol.sede,
          codTipo:"110",
          cantidad:sol.hnf

      }
      this.rSolicitudes.push(row)
    }
    if(sol.hen>0){
      var row={
          id:sol.user.id,
          nombre:sol.user.name+" "+sol.user.apellido,
          tipo:"HEN",
          sede:sol.sede,
          codTipo:"53",
          cantidad:sol.hen

      }
      this.rSolicitudes.push(row)
    }
    if(sol.hedf>0){
      var row={
          id:sol.user.id,
          nombre:sol.user.name+" "+sol.user.apellido,
          tipo:"HEDF",
          sede:sol.sede,
          codTipo:"58",
          cantidad:sol.hedf

      }
      this.rSolicitudes.push(row)
    }
    if(sol.henf>0){
      var row={
          id:sol.user.id,
          nombre:sol.user.name+" "+sol.user.apellido,
          tipo:"HENF",
          sede:sol.sede,
          codTipo:"59",
          cantidad:sol.henf

      }
      this.rSolicitudes.push(row)
    }

    if(i>=response.length ){
      console.log(this.rSolicitudes)
      this.reporte=true
    }
});
})
  }
    
  }



  generarExcel(){
    this.exportAsExcelFile(this.rSolicitudes.map((sol)=>{
      return {
       "CC":sol.id,
        "NOMBRE":sol.nombre,
        "SEDE":sol.sede,
        "TIPO":sol.tipo,
        "CODIGO":sol.codTipo,
        "CANTIDAD":sol.cantidad
      }
    }),"Horasextra"
    )
   
  }
  


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }



    ///function modal
    open(content) {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',size:"lg" }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
    }
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
  changeVer(i){this.ver=i;
    switch (this.ver) {
      case 0:
        this.title="PENDIENTES"
     
        break;
        case 1:
          this.title="APROBADAS"
         
          break;
      case 2:
        this.title="RECHAZADAS"
     
        break
      default:
        this.title="PENDIENTES"
     
        break;
    }
  
  }

  selecionar:any;

  verSol(){
    console.log(this.selecionar);
    console.log(this.solicitudesPendiente);
    
    switch (this.ver) {
      case 0:
        if (this.selecionar && this.selecionar != "" ) {
          return this.solicitudesPendiente.filter(item=> item.sede == this.selecionar);
        }else{

          return this.solicitudesPendiente;
        }
        break;
        case 1:

        if (this.selecionar) {
          return this.solicitudesAprobadas.filter(item=> item.sede == this.selecionar);
        }else{

          return this.solicitudesAprobadas;
        }
       
          break;
      case 2:
        if (this.selecionar) {
          return this.solicitudesRechazadas.filter(item=> item.sede == this.selecionar);
        }else{

          return this.solicitudesRechazadas;
        }
       
        break
      default:
        if (this.selecionar) {
          return this.solicitudesPendiente.filter(item=> item.sede == this.selecionar);
        }else{

          return this.solicitudesPendiente;
        }
      
        break;
    }
    
  }

  getSolicitudes(){
    /*   this.authService.getSolExtra()
      .then((r:any)=>{
        console.log(r);
        const solicitudesOrdenadas = r.sort((a, b) => {
          console.log("sss",a);
          
          // Ordenar por fecha de creación, de más reciente a más antigua
          return new Date(b.createddate).getTime() - new Date(a.createddate).getTime();
        });

        this.solicitudesPendiente = solicitudesOrdenadas.filter(el => !el.aprobe && !el.archive);
        this.solicitudesAprobadas = solicitudesOrdenadas.filter(el => el.aprobe);
        this.solicitudesRechazadas = solicitudesOrdenadas.filter(el => el.archive);
        this.solicitudesPendiente=r.filter((el)=>{return !el.aprobe && !el.archive})
        this.solicitudesAprobadas=r.filter((el)=>{return el.aprobe})
        this.solicitudesRechazadas=r.filter((el)=>{return el.archive}) 

      }) */
  }
  aprobar(id){
  /*   this.authService.aprobeSolExtra(id,this.user._id)
    .then((r)=>{
      this.getSolicitudes();
    }) */

  }
  async rechazar(id){

    const { value: pass } = await Swal.fire({
      title: 'Ingresa el motivo del rechazo',
      input: 'text',
      confirmButtonText:"Confirmar",
      inputPlaceholder: 'Nota',
  
    
    })
     
    if (pass) {
   /*    this.authService.archiveSolExtra(id,pass,this.user._id)
    .then((r)=>{
      this.getSolicitudes();
    }) */
    }



   
  }
  


}
