
<div class="row justify-content-center">
    <div class="col-md-4 reserva-activa m-2"  >
        <div class="card">
            <strong>Día: </strong> {{reserva.date}}
            <br>
            <strong>Lugar: </strong> {{reserva.fieldType}} {{reserva.field}}
            <br>
            <strong>Hora: </strong> {{reserva.hour}}
            <br> <br>
            <button class="btn btn-primary" *ngIf="!reserva.registers.includes(user._id) && !lleno " (click)="vincularme()" >Vincularme</button>
            <h2 *ngIf="reserva.registers.includes(user._id) ">Ya estas vinculado a la reserva</h2>
            <h2 *ngIf="!reserva.registers.includes(user._id) && lleno ">Ya se completo el cupo para la reserva</h2>
        </div>
    
    </div>
</div>