<div style="padding: 30px;" class="caja-form2">
    <h1 class="color-texto" >RESERVAR GOLF</h1>
    <h2 class="color-texto">para el día {{manana}}</h2>
 
     <div *ngIf="user.rol == 'admin'" class="row ">
        <div class="col-md-11 ">
            <label class="color-texto">Cambiar día</label>
            <input type="date" (change)="datechange($event)"  class="form-control"  value="">
        </div>
    </div> 

    <br>
    <div class="row">
        <div class="col-md-11">
            <label>Hoyo:</label>
            <select class="form-control" (change)="holechange($event)" placeholder="seleccionar hoyo">
                <option value="">-seleccionar hoyo-</option>
              <option value="1">1</option>
                <option value="10">10</option>
            </select>
        </div>
    </div>
    <br>
    <div *ngIf="dias" class="row" style=" background-color: white;">
        <div *ngFor="let hora of horas; let i = index" class="col-md-12 cajahora" >
            <div class="row p-1">
                <div class="col-8" style="font-size: 35px;" >
                   <strong>{{hora.hora}}</strong><br>
                   <span style="font-size: 21px;" *ngIf="hora.reservado">Reservado por {{hora.reservo}}</span>
                   <h2 style="color: #333;" >Ingresos</h2>
                   <ul style="font-size: 18px;">
                    <li *ngFor="let ingreso of hora.ingresos">{{ingreso.fullname}} {{ingreso.date | date: 'dd/MM/yyyy hh:mm a'}}</li>
                   </ul>
                </div>
                <div class="col-3" style="text-align: center; padding-right: 10px ;" >
                    <button *ngIf="!hora.reservado && user.rol != 'admin'" type="button" (click)="reservar(i, hora.hora)" class="btn btn-primary">Reservar</button>
                    <button *ngIf="hora.reservo == fullname" (click)="cancelar(hora.idreserva, i)" type="button"  class="btn btn-danger">Cancelar</button>
                    <button *ngIf="user.rol == 'admin' && hora.reservo" (click)="reservalector(hora.idreserva, i)" type="button"  class="btn btn-secondary">Leer código</button>
                </div>
            </div>  
        </div>
    </div>
    <div class="row">
        <div class="col-md-11">
           
        </div>
    </div>
    
   
    
</div>

