import { Component, OnInit , ElementRef,} from '@angular/core';
import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service";

import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { interval } from 'rxjs';
import  Swal from 'sweetalert2'; 
import { saveAs } from 'file-saver';
import { promises } from 'dns';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-reportes-ingreso',
  templateUrl: './reportes-ingreso.component.html',
  styleUrls: ['./reportes-ingreso.component.css']
})
export class ReportesIngresoComponent implements OnInit {
ingresosd;
sDay;
sFecha;
range: FormGroup;
initdate: string;
enddate: string;
findRange: boolean;
vingresosfecha: boolean;
usuarios:any=[]
usuariosSeleccionados:any=[]
usuariosSeleccionadosFinal:any=[]
  constructor(
    private authService : AuthService, 
    private EventService:EventsService,
    private Authservice: AuthService,
    private elRef:ElementRef,
  ) { }

  ngOnInit(): void {
    this.range = new FormGroup({
      start: new FormControl(<Date | null>(null)),
      end: new FormControl(<Date | null>(null)),
    });

  }

  buscarFecha(){
    this.ingresosd=[];
    this.sDay=false;
    this.sFecha=true;
  }


  addEvent(type,event){
 
    var value=this.range.value;
    if(value.end && value.start){
      console.log(this.range.value)
      //calcular y mostrar rango de fechas
      this.initdate=value.start.getUTCDate()+"/"+(value.start.getMonth()+1)+"/"+value.start.getUTCFullYear();
      this.enddate=value.end.getUTCDate()+"/"+(value.end.getMonth()+1)+"/"+value.end.getUTCFullYear();
  
  
    }
  }
  
  newFechaRange(){

    this.ingresosd=[]
  
    var value=this.range.value;
    this.initdate=value.start.getUTCDate()+"/"+(value.start.getUTCMonth()+1)+"/"+value.start.getUTCFullYear();
    this.enddate=value.end.getUTCDate()+"/"+(value.end.getUTCMonth()+1)+"/"+value.end.getUTCFullYear();
      var idia=value.start.getUTCDate();
      var imes=value.start.getUTCMonth()+1;
      var iano=value.start.getFullYear();
      var edia=value.end.getUTCDate();
      var emes=value.end.getUTCMonth()+1;
      var eano=value.end.getFullYear();
  
  
  if(iano!=eano){
    Swal.fire("El rango de fechas debe estar en el mismo año")
    .then(()=>{
      this.range.reset()
      return
    })
  }
  else if(iano==eano && emes-imes>1){
    Swal.fire("El rango de fechas es muy extenso","Por favor seleccinoes un rango de maximo 20 días")
    .then(()=>{
      this.range.reset()
      return
    })
  }
  else{
    this.authService.getingresosrango(idia,imes,iano,edia,emes,eano)
    .then((response:any)=>{
  console.log("INGRESOS", response.ingresos);

  /* response.ingresos.map((u)=>{
    console.log("USER", u.user)
    this.usuarios.push(
      {
        "ingreso": u,
        "selected": false,
        "disabled": false
      }
      )
  }) */
  
  //console.log("USUARIOS", this.usuarios)

  this.findRange=true;
  this.ingresosd=response["ingresos"]
  console.log("INGRESOSD", this.ingresosd);

  this.horaingreso(this.ingresosd);
    })
  }
    
  }


  onChangeUsuarios(e, i, id){

    console.log(e.checked, i, id)

    if(e.checked)
    {
      this.usuarios[i].selected = true
    }
    else{
      this.usuarios[i].selected = false
    }

    this.usuariosSeleccionados = this.usuarios.filter(item => item.selected === true)

    /* let selected;
    selec.map((u)=>{
      console.log("UU", u.user)
    
    }) */

    console.log("SUSERS",  this.usuariosSeleccionados)

  }

  generarExcel(){


    this.usuariosSeleccionadosFinal = []
    this.usuariosSeleccionados.map((u)=>{
      //console.log("UU", u.user)
      //selectedu.push(u.user)
      this.usuariosSeleccionadosFinal.push(u.ingreso)
    }) 

    console.log("SELECTEDD", this.usuariosSeleccionadosFinal)

    //this.horaingreso(this.usuariosSeleccionadosFinal);

    this.filterByDay(this.usuariosSeleccionadosFinal)

  }


  funcionarios(e){

    this.usuarios.map((i)=>{
      if(i.funcionario === true){
        if(e.checked){
          i.selected = true
        }
        else{
          i.selected = false
        }    
      }      
    })

    this.usuariosSeleccionados = this.usuarios.filter(item => item.selected === true)

    console.log("SELECC", this.usuariosSeleccionados)

  }

  

  horaingreso(arr){
  
    arr.forEach(solicitud => {
     // console.log("visita: ",solicitud);
      var date= new Date(solicitud.fecha);
       var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
       solicitud.fechat=fecha;  
       if(solicitud.salida){
        var date2= new Date(solicitud.fechaSalida);
        var fecha2= date2.getDate()+"/"+(date2.getMonth()+1)+"/"+date2.getUTCFullYear()+" hora: "+date2.getHours()+":"+(date2.getMinutes() < 10 ? '0' : '') + date2.getMinutes();
        solicitud.fechas=fecha2;  
       }
      
        solicitud.empresa=solicitud.user.empresa;
        
       
  
    }); 
    this.filterByuser();
  }


filterByuser(){
  var ingresosPorUsuaios=[];
  var users=this.ingresosd.map((el)=>{return el.userid})
  
 users= users.filter((element, index) => {
    return users.indexOf(element) === index;
});
//users=array de ids de usuarios 
var i=0
users.forEach(id => {
       i++
    var ingresos=this.ingresosd.filter((el)=>{return el.userid==id})
    ingresosPorUsuaios.push({
      userid:id,
      ingresos:ingresos
    })
    if(i>=users.length){
      console.log(ingresosPorUsuaios);

//comentar

ingresosPorUsuaios.map((u)=>{
  //console.log("USER", u.user)

  let us = this.ingresosd.filter(ing => ing.user.id == u.userid )

  console.log("USERR", us[0].user.name)


  this.usuarios.push(
    {
      "ingreso": u,
      "selected": false,
      "disabled": false,
      "fullname": us[0].user.name+" "+us[0].user.apellido,
      "funcionario": us[0].user.funcionario
    }
    )
})

console.log("ING", ingresosPorUsuaios)

// this.filterByDay(ingresosPorUsuaios);



    }
});



}

async filterByDay(ingresosPorUsuarios){







  var days:any= await this.getDays()
  if(days){
    var j=0
    var hojas=[];
    ingresosPorUsuarios.forEach(userObj => {
      j++;
      var user=userObj.userid;  
      var i =0   
      var filas=[];
    
      days.forEach(day => {
       
        i++;
        var idia=userObj.ingresos.filter((ingreso)=>{ return ingreso.dia==day.dia && ingreso.mes==day.mes && ingreso.ano==day.ano });
       // console.log("inter",idia.map((el)=>{ console.log(el); var end=new Date(el.fechaSalida); var init= new Date(el.fecha);  if(el.salida){var h=((end.getTime()-init.getTime())/(1000*60*60))}else{ var h=0} return h;}))
          var horas = idia.map((el)=>{ var end=new Date(el.fechaSalida); var init= new Date(el.fecha);  if(el.salida){var h=((end.getTime()-init.getTime())/(1000*60*60));}else{ var h=0} return h;}).reduce((total,num)=>{return total + num;},0)
          filas.push({"dia":day.dia,
        "mes":day.mes,
        "ano":day.ano,
        "horas":horas
        })

          if(i>=days.length){
            
           hojas.push({filas:filas,user:user})
          // console.log("filas",hojas);
          }
         
      });

      //console.log("j",j)
      if(j>=ingresosPorUsuarios.length){
        //console.log("hojas",hojas)
        this.exportAsExcelFile(hojas,"inrgesos")
      }

 
    });
  }

}

getDays(){
  return new Promise((resolve,reject)=>{
    try {
      var dias=[]
      var value=this.range.value;
      this.initdate=value.start.getUTCDate()+"/"+(value.start.getUTCMonth()+1)+"/"+value.start.getUTCFullYear();
      this.enddate=value.end.getUTCDate()+"/"+(value.end.getUTCMonth()+1)+"/"+value.end.getUTCFullYear();
        var idia=value.start.getUTCDate();
        var imes=value.start.getUTCMonth()+1;
        var iano=value.start.getFullYear();
        var edia=value.end.getUTCDate();
        var emes=value.end.getUTCMonth()+1;
        var eano=value.end.getFullYear();
   //console.log("calculando de:",idia,"/",imes,"/",iano,"hasta: ",edia,"/",emes,"/",eano)
        if(eano==iano && imes==emes){
          for (let index = 0; index < 33; index++) {
          
            if(idia<=index && edia>=index){
              //console.log("aca")
              dias.push({
                dia:index,
                mes:emes,
                ano:eano
              })
            
            }
            if(index>=32){
             console.log("dias",dias,index)
              resolve(dias)
            }
            
          }
        }
        else if(eano==iano && imes!=emes){
          for (let index = imes; index < emes+1; index++) {
           // console.log("mes",index)
            for (let index2 = idia; index2 < 32; index2++) {
             // console.log("dia",index2)
              if(imes==index){
                if(idia<=index2 ){
                  //console.log("aca")
                  dias.push({
                    dia:index2,
                    mes:index,
                    ano:eano
                  })
                
                }

              }
              else if(index<emes){
                dias.push({
                  dia:index2,
                  mes:index,
                  ano:eano
                })

              }
              else if(index==emes){
                if(edia>=index2){
                  //console.log("aca")
                  dias.push({
                    dia:index2,
                    mes:index,
                    ano:eano
                  })
                }
              }
                      
              if(index2>=31 && index>=emes ){
                console.log("dias",dias,index)
                 resolve(dias)
               }
          
              
            }
            
          }

        }
      
    } catch (error) {
      resolve(false)
    }

  })
}

public exportAsExcelFile(jsonHojas: any[], excelFileName: string): void {
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  var i=0;
  jsonHojas.forEach(hojaUsuario=> {
    console.log("hojausuaior",hojaUsuario);
    i++

    var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(hojaUsuario.filas);
    
    XLSX.utils.book_append_sheet(workbook, worksheet, hojaUsuario.user);

if(i>=jsonHojas.length){

  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}

  });


  
 
}

private saveAsExcelFile(buffer: any, fileName: string): void {
  const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
  FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
}
 

}
