import { Component, OnInit,ElementRef, Renderer2, ViewChild,Input,Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-registro-pago',
  templateUrl: './registro-pago.component.html',
  styleUrls: ['./registro-pago.component.css']
})
export class RegistroPagoComponent implements OnInit {
  submitted = false;
  registerForm: FormGroup;
validationForm: FormGroup;
validate;
foto1Url="nofoto";
foto2Url;
completo;
foto1;
foto2;
fotos;
videoWidth = 0;
videoHeight = 0;
visita: any;
@ViewChild('video', { static: false }) videoElement: ElementRef;
@ViewChild('canvas', { static: false }) canvas: ElementRef;

  ingresoForm: FormGroup;
  seeRegister=true;
  user;
  @Input() id;
  @Output() done = new EventEmitter<boolean>();
  log: boolean;
  constructor(private usersService : AuthService, private router : Router,   private renderer: Renderer2) {


this.registerForm = new FormGroup({
  'name': new FormControl('', [
    Validators.required,
    Validators.minLength(3)
  ]),
 
  'correo': new FormControl('', [
    Validators.required,
    Validators.email
  ]),
  'id': new FormControl("", [
    Validators.required,
  ]),
  'empresa': new FormControl("independiente", [
    Validators.required,

  ]),

  'rol': new FormControl('usuario', [
    Validators.required,
  ]),

  'categoria': new FormControl('invitado', [
    Validators.required,

  ]),
  'aprobe': new FormControl(false, [
    Validators.required,

  ]),

   
});
this.ingresoForm = new FormGroup({
   
  'doc': new FormControl('', [
    Validators.required,

  ]),

    });
 

   }

  ngOnInit(): void {

  }

  addToVisita(){
    this.usersService.addPersonToVisit(this.visita,this.user._id)
    .then((r)=>{
     Swal.fire("Persona agreagada a la visita")
     this.done.emit(true);
    })
  }
getVisita(){
  this.usersService.getvisitasbyid(this.id)
  .then((r:any)=>{
  
    this.visita=r.visita;
    console.log("vis",this.visita)
    this.addToVisita();
  })
  .catch((err)=>{
 
  })
  }
  
  findPerson(){
    if(this.ingresoForm.invalid){
      return
    }
    this.usersService.validatep(this.ingresoForm.controls["doc"].value)
    .then((r:any)=>{
      console.log("invi",r)
  
      this.user=r.user;
      this.getVisita();
      this.seeRegister=false;
  
    })
    .catch((err)=>{
      console.log("invi",err)
      this.log=true;
      this.registerForm.patchValue({id:this.ingresoForm.controls["doc"].value})
      this.ingresoForm.reset();
  
    })
  }
  get f(){return this.ingresoForm.controls}
    get f2() { return this.registerForm.controls; }
  
    register(){
    
      this.submitted = true;
      this.registerForm.patchValue({
        rol:"usuario",
        tipo:"persona",
        categoria:"invitado",
        aprobe:true,
        empresa:"independiente"
     
      })
      console.log(this.registerForm.value)
      if (this.registerForm.invalid) {
        return;
      }
      else{
      
         this.usersService.createPerson(this.registerForm.value,this.foto1Url)
         .then((res)=>{
          console.log(res);
    
          this.user=res['user']
          this.getVisita();
        // this.invitados.push(res["user"])
        this.seeRegister=false;
        
        })
        .catch((err)=>{
          Swal.fire(     {
            title: "El registro no fue exitoso",
            text:"Por favor verifica tus datos, si ya te encuentras registrado pulsa el boton ingresar. Recomendamos el uso del navegador web Chrome. Si estás conectado por VPN por favor desconéctate y vuelve a intentarlo.",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ingresar ',
            cancelButtonText: "Reintentar"
            
        }).then((result)=>{
        if(result.value){
        
        
        }
        else{
          //this.router.navigate(['']); 
        }
        });
         })
    
      }
    
    
    }


}

