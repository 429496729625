import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url = "https://selesoluciona.com:8223";
  constructor(private http: HttpClient) { }

  public isAuthenticated(): Boolean {
    let userData = localStorage.getItem('userInfo')
    if (userData && JSON.parse(userData)) {
      return true;
    }
    return false;
  }
  //canjes

  public newSol(canje) {
    return this.http.post(this.url + '/canjes/create', { canje }).toPromise()
  }
  public getCanjes() {
    return this.http.get(this.url + '/canjes/',).toPromise()
  }
  //pqr
  public newPqr(pqr) {
    return this.http.post(this.url + '/pqr/create', { pqr }).toPromise()
  }
  public getPqrs() {
    return this.http.get(this.url + '/pqr/',).toPromise()
  }
  public findPqrUser(user) {
    return this.http.post(this.url + '/pqr/findPqrUser', { user }).toPromise()
  }
  public createMsg(msg) {
    return this.http.post(this.url + '/pqr/createMsg', { msg }).toPromise()
  }
  public findMsgPqr(pqr) {
    return this.http.post(this.url + '/pqr/findMsgPqr', { pqr }).toPromise()
  }

  //mensajes
  public vistoMesnaje(id) {
    return this.http.post(this.url + '/vistomensaje', { "id": id }).toPromise()
  }
  public newMesnaje(mensaje) {
    return this.http.post(this.url + '/newMensaje', { "mensaje": mensaje }).toPromise()
  }

  public lookMensajes(id) {
    return this.http.post(this.url + '/lookmensajes', { "id": id }).toPromise()
  }
  public lookConver(id) {
    return this.http.post(this.url + '/lookconver', { "id": id }).toPromise()

  }

  //finmensaje

  public setUserInfo(user) {
    localStorage.setItem('userInfo', JSON.stringify(user));
  }
  public sendToken(token) {
    return this.http.post(this.url + '/token_validate', { recaptcha: token })
  }
  public setacceso(id, nivel) {
    return this.http.post(this.url + '/setacceso', { "id": id, "nivel": nivel }).toPromise()
  }
  public aprobar(id, index) {
    return this.http.post(this.url + '/aprobe', { "id": id, "index": index }).toPromise()
  }
  



  public aprobarpersona(visita, id, index) {
    return this.http.post(this.url + '/aprobarpersona', { "visita": visita, "id": id, "index": index }).toPromise()
  }
  public addPersonToVisit(visita, persona) {
    return this.http.post(this.url + '/addpersontovisit', { visita, persona }).toPromise()
  }
  public validate(email) {
    return this.http.post(this.url + '/auth', { 'correo': email }).toPromise()
  }

  public create(user) {
    return this.http.post(this.url + '/create', { user }).toPromise()
  }
  public password(tipo, pass) {
    return this.http.post(this.url + '/password', { tipo, pass }).toPromise()
  }
  
  
  
  

  
  
  
  


//usuarios

  public createPerson(user, f1) {
    return this.http.post(this.url + '/usuarios/newpersona', { "user": user, "foto1": f1 }).toPromise()
  }

  public sendfoto(data) {
    return this.http.post(this.url + "/usuarios/foto1", { data }).toPromise()
  }

  public validateP(foto, id) {
    return this.http.post(this.url + '/usuarios/photovalidate', { "foto": foto, "id": id }).toPromise()
  }

  public validatePingreso(foto, id) {
    return this.http.post(this.url + '/usuarios/photovalidateingreso', { "foto": foto, "id": id }).toPromise()
  }
  public validateArea( id) {
    return this.http.post(this.url + '/usuarios/validateArea', {  "id": id }).toPromise()
  }

  public addfoto(id, f1) {
    return this.http.post(this.url + '/usuarios/addfoto', { "id": id, "foto1": f1 }).toPromise()
  }

  public getUsuariosCategoria(categoria: any) {
    return this.http.post(this.url + '/usuarios/userscategoria', { "categoria": categoria }).toPromise()
  }

  public loadUserById(id) {
    return this.http.post(this.url + "/usuarios/loaduserbyid", { id }).toPromise()
  }

  public unBlockUser(correo) {
    return this.http.post(this.url + '/usuarios/unblockuser', { correo }).toPromise()
  }

  public blockUser(correo, nota) {
    return this.http.post(this.url + '/usuarios/blockuser', { correo, nota }).toPromise()
  }

  public aprobarUsuario(id) {
    return this.http.post(this.url + '/usuarios/aprobeuser', { "id": id }).toPromise()
  }

  public deleteuser(id) {
    return this.http.post(this.url + '/usuarios/deleteuserid', { id }).toPromise()
  }
 
  public getFuncnioarios() {
    return this.http.get(this.url + '/usuarios/funcionarios').toPromise()
  }

  public getusers() {
    return this.http.get(this.url + '/usuarios/users').toPromise()
  }


  public setrol(rol, correo) {
    return this.http.post(this.url + '/usuarios/setrol', { rol, correo }).toPromise()
  }


  public setcategoria(rol, correo) {
    return this.http.post(this.url + '/usuarios/setcategoria', { rol, correo }).toPromise()
  }


  public setfuncionario(correo) {
    return this.http.post(this.url + '/usuarios/setfuncionario', { correo }).toPromise()
  }


  public setnofuncionario(correo) {
    return this.http.post(this.url + '/usuarios/setnofuncionario', { correo }).toPromise()
  }


  public upToHik(id) {
    return this.http.post(this.url + '/usuarios/loadtohik', { id }).toPromise()
  }


  public eliminarPersonac(id, nit) {
    return this.http.post(this.url + '/usuarios/deletepersonac', { 'id': id, "nit": nit }).toPromise()
  }


  public borrardoc(doc) {
    return this.http.post(this.url + '/usuarios/deletedoc', { doc }).toPromise()
  }


  public subirdoc(doc) {
    return this.http.post(this.url + '/usuarios/updoc', { doc }).toPromise()
  }

  public getBenefits(id) {
    return this.http.post(this.url + "/usuarios/getbenefits", { id }).toPromise()
  }
  public validatep(id) {
    return this.http.post(this.url + '/usuarios/authp', { 'id': id }).toPromise()
  }
  public verificarFoto(url) {
    return this.http.post(this.url + '/usuarios/verificafoto', { url }).toPromise()
  }

  
  //fin usuarios


//empresa

  public olvidoclave(empresa) {
    return this.http.post(this.url + '/olvidoclave', { empresa }).toPromise()
  }
  public recuperoClave(recu) {
    return this.http.post(this.url + '/recuperoclave', { recu }).toPromise()
  }

  public validatec(nit) {
    return this.http.post(this.url + '/authe', { 'nit': nit }).toPromise()
  }
 

  public deleteempresa(id) {
    return this.http.post(this.url + '/deleteempresaid', { id }).toPromise()
  }
  
  
  public getempresas() {
    return this.http.get(this.url + '/empresas').toPromise()
  }
  public addPerson(empresa, user) {
    return this.http.post(this.url + '/addperson', { "empresa": empresa, "user": user }).toPromise()
  }
 
  public createCompany(user) {
    return this.http.post(this.url + '/newempresa', { "user": user }).toPromise()
  }

  //finempresa




  public subirdocVisita(doc) {
    return this.http.post(this.url + '/updocvisit', { doc }).toPromise()
  }
  public borrardocVisita(doc) {
    return this.http.post(this.url + '/removedocvisit', { doc }).toPromise()
  }
  
  
  public newvisita(visita) {
    return this.http.post(this.url + '/newvisita', { "visita": visita }).toPromise()
  }

  public newvisitaadmin(visita) {
    return this.http.post(this.url + '/newvisitaadmin', { "visita": visita }).toPromise()
  }
  public newvisitaporteria(visita) {
    return this.http.post(this.url + '/newvisitaporteria', { "visita": visita }).toPromise()
  }
  public getvisitas(id) {
    return this.http.post(this.url + '/getvisita', { 'id': id }).toPromise()
  }
  public getvisitasbyid(id) {
    return this.http.post(this.url + '/getvisitabyid', { 'id': id }).toPromise()
  }
  public getvisitasIdFecha(id, dia, mes, ano) {
    return this.http.post(this.url + '/getvisitaidfecha', { 'id': id, "dia": dia, "mes": mes, "ano": ano }).toPromise()
  }
  public getvisitasAnfitrionFecha(id, dia, mes, ano) {
    return this.http.post(this.url + '/getvisitaanfitrionfecha', { 'id': id, "dia": dia, "mes": mes, "ano": ano }).toPromise()
  }
  public getvisitasInvitadoFecha(id, dia, mes, ano) {
    return this.http.post(this.url + '/getvisitainvitadofecha', { 'id': id, "dia": dia, "mes": mes, "ano": ano }).toPromise()
  }
  public getallvisitas() {
    return this.http.get(this.url + '/visitas').toPromise()
  }
  public getvisitashoy(dia, mes, ano) {
    return this.http.post(this.url + '/visitashoy', { "dia": dia, "mes": mes, "ano": ano }).toPromise()
  }
  public getvisitashoyaprobar(dia, mes, ano) {
    return this.http.post(this.url + '/visitashoyaprobar', { "dia": dia, "mes": mes, "ano": ano }).toPromise()
  }
  public getingresosdia(dia, mes, ano) {
    return this.http.post(this.url + '/ingresosdia', { "dia": dia, "mes": mes, "ano": ano }).toPromise()
  }
  public getingresosrango(idia, imes, iano, edia, emes, eano) {
    return this.http.post(this.url + '/ingresosrango', { idia, imes, iano, edia, emes, eano }).toPromise()
  }
  public getvisitasproxima(dia, mes, ano) {
    return this.http.post(this.url + '/visitasproximas', { "dia": dia, "mes": mes, "ano": ano }).toPromise()
  }
  public getvisitasanterior(dia, mes, ano) {
    return this.http.post(this.url + '/visitasanterior', { "dia": dia, "mes": mes, "ano": ano }).toPromise()
  }
  public getpersonas(id) {
    return this.http.post(this.url + '/getpersonas', { 'id': id }).toPromise()
  }
  public getvisitasc(id) {
    return this.http.post(this.url + '/getvisitac', { 'id': id }).toPromise()
  }
  public getempresa(id) {
    return this.http.post(this.url + "/empresaid", { "id": id }).toPromise()
  }
  public getempresaid(id) {
    return this.http.post(this.url + "/empresaidb", { "id": id }).toPromise()
  }
  public eliminarVisita(id) {
    return this.http.post(this.url + '/deletevisita', { 'id': id }).toPromise()
  }
  
  public eliminarPersonadeempresa(id, nit) {
    return this.http.post(this.url + '/deletepersonafrom', { 'id': id, "nit": nit }).toPromise()
  }
  public newitem(item, user) {
    return this.http.post(this.url + '/newitem', { "item": item, "user": user }).toPromise()
  }
  public newnota(item, user) {
    return this.http.post(this.url + '/newnota', { "item": item, "user": user }).toPromise()
  }


  public getIngresosUser(id) {
    return this.http.post(this.url + '/getingresouser', { 'id': id }).toPromise()
  }

  public newingresoFuncionario(id, foto, hora, user, acompanante = false, responsable = null) {
    return this.http.post(this.url + '/ingresofuncionario', { 'id': id, "foto": foto, "hora": hora, "user": user, "responsable": responsable, "acompanante": acompanante }).toPromise()
  }

  public newsalidaFuncionario(hora, user, ingreso) {
    return this.http.post(this.url + '/salidafuncionario', { "hora": hora, "user": user, "ingreso": ingreso }).toPromise()
  }

  public newingreso(id, foto, hora, user, acompanante = false, responsable = null) {
    return this.http.post(this.url + '/ingreso', { 'id': id, "foto": foto, "hora": hora, "user": user }).toPromise()
  }
  public newsalida(id, hora, user) {
    return this.http.post(this.url + '/salida', { 'id': id, "hora": hora, "user": user }).toPromise()
  }
  public newManilla(user, visita, manilla) {
    return this.http.post(this.url + '/newmanilla', { 'visita': visita, "manilla": manilla, "user": user }).toPromise()
  }

  public getManillaUser(user, visita) {
    return this.http.post(this.url + '/getmanillauser', { 'visita': visita, "user": user }).toPromise()
  }
  public getManilla(id) {
    return this.http.get(this.url + '/getmanilla/' + id,).toPromise()
  }
  public deteleManillas() {
    return this.http.get(this.url + '/deletemanillas').toPromise()
  }

  public dgetManillas() {
    return this.http.get(this.url + '/manillas').toPromise()
  }

  public findPago(email) {
    return this.http.post(this.url + '/findpago', { email }).toPromise()
  }
  public geturl() {
    return this.http.get(this.url + '/conferenceurl',).toPromise()
  }
  public findConfer(correo) {
    return this.http.post(this.url + '/findconfer', { correo }).toPromise()
  }








}
