
<div class="row mt-5 justify-content-center" >
    <div class="col-md-8" >
        <div class="row mt- 5 justify-content-center" >
            <div class="col-md-3 col-5 ">
                <button class="btn btn-primary m-2" routerLink="/reservar/golf">Reservas Golf</button>
            </div>
            <div class="col-md-3 col-5 ">
                <button class="btn btn-primary m-2" routerLink="/reservar/tennis">Reservas Tennis</button>
            </div>
            <div class="col-md-3 col-5 ">
                <button class="btn btn-primary m-2" routerLink="/reservar/bolos">Reservas Bolos</button>
            </div>
            <div class="col-md-3 col-5 ">
                <button class="btn btn-primary m-2" routerLink="/reservar/ski">Reservas Esquí</button>
            </div>
            <div class="col-md-3 col-5 ">
                <button class="btn btn-primary m-2" routerLink="/reservar/ninera">Reservas Niñera</button>
            </div>
            <div class="col-md-3 col-5 ">
                <button class="btn btn-primary m-2" routerLink="/reservar/masajista">Reservas Masajista</button>
            </div>
            <div class="col-md-3 col-5 ">
                <button class="btn btn-primary m-2" routerLink="/reservar/profesortenis">Reservas Profesor de tenis</button>
            </div>
            <div class="col-md-3 col-5 ">
                <button class="btn btn-primary m-2" routerLink="/reservar/profesorgolf">Reservas Profesor de golf</button>
            </div>
            <div class="col-md-3 col-5 ">
                <button class="btn btn-primary m-2" routerLink="/reservar/saladebelleza">Reservas Sala de belleza</button>
            </div>
        </div>
    </div>

  
</div>


