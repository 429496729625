<div class=" " style="background-color: transparent;font-family:Arial, Helvetica, sans-serif;">
  <div class="card-body cajainfo" style=" padding: 0px; height:auto; ">
    <div style=" width: 100%; padding: 10px; overflow: hidden; color: white;">

      <span class="text"  style="color: #04453b !important;" > Fecha: {{visita.fechat}}</span>
      <br>
      <span class="text" style="color: #04453b !important;" > Descripción: {{visita.descripcion}}</span>
      
      <!-- <span class="text"  style="color: white;" > Nivel de acceso: {{visita.acceso}}</span><br> -->
      <!-- <div class="d-flex justify-content-end mt-3">
        <button (click)="descargarPersonas()" class="btn btn-success" >Descargar Personas</button>
      </div> -->

   
    </div>
    <hr>
    <h4 style="color:  #04453b ; margin: 5px;">Número de ingresos: {{ingresos}}/{{personasc.length}} </h4>
    <h4 style="color:  #04453b ; margin-bottom: 2px;">Número de personas:  {{personasc.length}} </h4>
    <hr>
    <div *ngIf="personasc.length>0" class="row m-0 "style="color: white;">
      <div class="col-md-12 mb-1" *ngFor="let persona of personasc" style="color:  #04453b ;"  >
     
       Nombre: {{persona.name}} {{persona.apellido}} <br>
        Documento: {{persona.id}} <br>
        <span *ngIf="persona.ingreso" style="color:  #04453b ;" > Ya registro ingreso {{persona.ingreso.hora | date:'medium'}} <br> </span>
        <span *ngIf="!persona.ingreso" style="color:  #04453b ;" > No ha ingresado <br> </span>
<hr>
      </div>
      <hr >
    </div>

    <div class="m-0" >
      <h4 style="color: #04453b !important;"> Por favor comparte este enlace con tus invitados, para que realicen su vinculación a la visita : 
        <span style="word-wrap: break-word; font-style: italic; " > {{registerurl}}</span> 
        <button class="btn btn-dark" style="padding: 1px; font-size: 12px; margin-left: 15px;" (click)="copiar()" >copiar</button> 
      </h4>
      
    </div>

    <div *ngIf="empresa">
      {{empresa.name}}
      <br>
      <span class="text" style="color: white;" > NIT: {{empresa.nit}}</span>

      <br>

      <span class="text" style="color: white;"  *ngIf="visita.placa"> Vehiculo: {{visita.placa}}</span>
      <br>
      <h2 style="color: white; margin: 2px;" >Responsable:</h2>
      <span class="text" style="color: white;" > {{empresa.responsable.name}}</span>
      <br>
      <span class="text" style="color: white;" > {{empresa.responsable.correo}}</span>
      <br>
      <span class="text" style="color: white;" > {{empresa.responsable.telefono}}</span>


      <h2 style="color: white !important;">Documentos: </h2>
      <div *ngFor="let link of empresa.documentos">
        <a href="{{link}}" target="_blank" rel="noopener noreferrer">
          <iframe [src]="link | safe" width="90%" height="200px" frameborder="0"></iframe> {{link}}</a>

      </div>


    </div>

  </div>
</div>

<div *ngIf="subird" class="row text-white   mt-5 cajainfo" style="position: fixed; bottom:40%; left:25%; z-index: 9999;">

  <div class="col-md-8">
      <form [formGroup]="docForm" (ngSubmit)="upDoc()" novalidate="">
          <div class="form-group">
              <h3>Anexe el documento en formato PDF:</h3>
              <input class="form-control" type="file" placeholder="pdf" accept=".pdf" (change)="onFileChange($event)"
                  required>
          </div>
          <input type="submit" value="Subir" class="btn btn-boton2" style="float: right; margin: 8px;">
          
         
      </form>
      <button (click)="subird=false"  class="btn btn-orange"  >Cancelar</button>
  </div>
 


 
  
</div>