import { Component, OnInit, Input,ViewChild, ElementRef, Renderer2} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {SocketioService} from "../../services/socketio.service";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'] 
}) 
export class ChatComponent implements OnInit {
user;
interval;
@Input() remoteUser ={"name":"Soporte","correo":"adminDefault","rol":"admin","id":"admin1"};
chatFormulario: FormGroup;

  constructor(  private elRef:ElementRef,
      private socketservice: SocketioService) { 

    this.chatFormulario = new FormGroup({
 
      'mensaje': new FormControl('', [ 
        Validators.required,
        Validators.minLength(4)
      ])
    });
  } 

  ngOnChanges(changes) {
    console.log('Changed', changes.remoteUser.currentValue, changes.remoteUser.previousValue);
  }

  ngOnInit(): void {
    console.log(this.remoteUser);
    this.interval = setInterval(() => {
      this.scroll();
    }, 3000);
    let user = localStorage.getItem('userInfo');
    this.user= JSON.parse(user).user;
    console.log(user);
    // this.user={"name":"miguel","correo":"miga"};
  }
  scroll(){
    var zona = this.elRef.nativeElement.querySelector('.paraChat');
    zona.scrollTop = zona.scrollHeight;
  }
sendChat(){


  var txt=this.chatFormulario.value.mensaje;
  var msg;
  if (this.remoteUser.rol=="admin"&& this.remoteUser.correo=="adminDefault"){
 msg={
  "id":"sendChat",
  "text":txt,
  "from":this.user.correo,
  "to":"admin"
}
    this.socketservice.send(msg);
if( this.elRef.nativeElement.querySelector('#chat_txt'+this.remoteUser.id)){
var place =this.elRef.nativeElement.querySelector('#chat_txt'+this.remoteUser.id);
}
  else{  var place = this.elRef.nativeElement.querySelector('#chat_txtadmin1');}

    place.appendChild(document.createTextNode(this.user.name+": "));
    place.appendChild(document.createTextNode(txt));
    place.appendChild(document.createElement("br"));
    place.scrollTop = place.scrollHeight;

  }
else{
   msg={
    "id":"sendChat",
    "text":txt,
    "from":this.user.correo,
    "to":this.remoteUser.correo
  }
      this.socketservice.send(msg);

      var place = this.elRef.nativeElement.querySelector('#chat_txt'+this.remoteUser.id);
      place.appendChild(document.createTextNode(this.user.name+": "));
      place.appendChild(document.createTextNode(txt));
      place.appendChild(document.createElement("br"));
      place.scrollTop = place.scrollHeight;

}
this.chatFormulario.reset();

}

}









