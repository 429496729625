<div class="card cajainfo" style="background-color: transparent;font-family:Arial, Helvetica, sans-serif;" >
  <div class="card-body" style=" padding: 0px; height:auto;" >
       <div style=" width: 100%; padding: 10px; overflow: hidden;">
        <h4 class="card-title mb-1" style="font-size: 22px; font-weight: bold;color: white; " >
          Nombre: <span style="font-weight: 600;" >{{user.name}}</span></h4>
        <p class="card-text" >
            <span class="text"  style="color: white;" > NIT: <span style="font-weight: 600;" >{{user.nit}}</span></span> </p>
        <br>
      <h2 style="color: white; margin-bottom: 2px;">Responsable:</h2>
       <span class="text" style="color: white; font-weight: 400; " > {{user.responsable.name}}</span>
       <br>
       <span class="text" style="color: white; font-weight: 400; " > {{user.responsable.correo}}</span>
       <br>
       <span class="text" style="color: white; font-weight: 400; " > {{user.responsable.telefono}}</span>

       
 
        <h2 style="color: white;">Personas: </h2>
        <h4 style="color: white;">Usuarios cargados: {{user.personas.length}}</h4>
        <button class="btn btn-primary" (click)="loadpersonas()" >Ver personas</button>
        <div class="row " *ngIf="verPersonas"  >
          <div class="col-md-11" *ngFor="let persona of personasc">
            <app-persona [user]="persona" [show]="false" ></app-persona>
            <button  class="btn-danger" (click)="eliminarPersona(persona.id)" >Desvincular </button>
          </div>
        </div>
    </div>
       
    
    </div>

    <div *ngIf="show">   
      <h2   >Documentos: </h2> 
  <div *ngFor="let link of user.documentos">
      <a   href="{{link}}" target="_blank" rel="noopener noreferrer">{{link}}</a>
      <!-- <iframe [src]="link | safe" width="90%" height="200px" frameborder="0"></iframe> -->

      <button class="btn btn-danger" (click)="deletedocu(link)" > Eliminar</button>
  </div>
  <button (click)="subir()"  class="btn btn-primary" >Subir documento</button>
  </div>
  <div *ngIf="subird" class="bg-dark text-white w-50 container mt-5" style="position: fixed; top: 20%; z-index: 999; left:25%; z-index: 999;" >
     
      <form [formGroup]="docForm" (ngSubmit)="upDoc()" novalidate="">  
          <div class="form-group">  
              <h3>Anexe el documento en formato PDF:</h3>     
              <input class="form-control" type="file" placeholder="pdf" accept=".pdf" (change)="onFileChange($event)"  required >
              </div>
              <input type="submit" value="Subir" class="btn btn-orange">
          </form>
          <button (click)="subird=false"  class="btn btn-danger"  >Cancelar</button>
      </div>
    </div>
    

  