import { Component, OnInit,Input } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.css']
})
export class EventoComponent implements OnInit {
  @Input() event ={"nombre":"","descripcion":"","areas":[],"empresas":[],"fecha":"","fechat":"","":[],"personas":[],"visitas":[],"_id":"000"};
 
  constructor() { }

  ngOnInit(): void {
    this.updatefecha();

  }
  updatefecha(){
    var date= new Date(this.event.fecha);
    var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
   this.event.fechat=fecha
  }

}
