import { Component, OnInit , ElementRef,} from '@angular/core';
import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service";

import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { interval } from 'rxjs';
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-ingresosempresa',
  templateUrl: './ingresosempresa.component.html',
  styleUrls: ['./ingresosempresa.component.css']
})
export class IngresosempresaComponent implements OnInit {
eventos;
  empresasc: any[];
  timer: NodeJS.Timeout;
  ingresos: any;
  empresas: any;
  totales: any=false;
  eventid: any;
  constructor( private authService : AuthService, 
    private EventService:EventsService,
    private Authservice: AuthService,) { }

  ngOnInit(): void {
    this.getEvents();
  }
  getEvents(){
    this.EventService.getAllEvents()
    .then((result)=>{
      this.eventos=result["results"].filter((el)=>{return !el.archivado}); 
      console.log(this.eventos);
    })
    }
    onEventChange(eventid){    
      //var evento=this.eventos.find(e=>e._id===eventid);
      //this.loadempresas(evento);
      //this.timer=setInterval(()=>{this.loadempresas(evento)},10*1000)
      this.eventid=eventid;
      this.getIngresos(eventid);
      this.timer=setInterval(()=>{this.getIngresos(eventid)},50*1000)
    }



getIngresos(evento){
  this.EventService.getingresosEvento(evento)
  .then((result)=>{
    console.log(result)
    this.ingresos=result["ingresos"];
    if(!this.totales){
      this.ingresos=this.ingresos.filter((el)=>{return !el.salida})
    }

    console.log(this.ingresos);
   this.getEmpresas(this.ingresos);
  })
}

vtotales(){
  this.totales=true;
  this.getIngresos(this.eventid);
}
vactual(){
  this.totales=false;
  this.getIngresos(this.eventid);
}


getEmpresas(arr){
  var empresas=[];
  this.empresasc=[{"_id":"00","name":"independiente","ingresos":[]}]
  arr.forEach(element => {
    if(element.empresa && element.empresa!="independiente"){
      this.authService.getempresa(element.empresa)
      .then((response)=>{
        console.log(response);
        if(!empresas.includes(element.empresa)){
          empresas.push(element.empresa)
          var emp=response["empresa"]
          emp.ingresos=[element];
          this.empresasc.push(emp)
        }
        else{
          var pos=this.empresasc.map((e)=>{return e._id}).indexOf(element.empresa)
          console.log("pos",pos);
          this.empresasc[pos].ingresos.push(element);

        }
        
        //element.empresa=response["empresa"];
      })
            
    }
    else{
      this.empresasc[0].ingresos.push(element);
    }
  });

}



  loadempresas(event){
      this.empresasc=[]
     
      event.empresas.forEach(id => {        
        this.authService.validatec(id.id)
        .then((response)=>{
          response["user"].cupo=id.cupo
          var empresa=response["user"];
          this.EventService.getvinculacion(empresa._id,event._id)
          .then((response2)=>{
            console.log(response2);
                empresa.ingresos=response2["empresa"].ingresos;
                console.log(empresa)
                this.empresasc.push(empresa);
          })
          //
          //cargar vinculacion y 
        })
        .catch((err)=>{
      
        })
        });
      
      }
}
