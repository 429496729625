import { Component, OnInit , ElementRef, Renderer2, ViewChild,} from '@angular/core';
import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service";

import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { interval } from 'rxjs';
import  Swal from 'sweetalert2'; 
import { saveAs } from 'file-saver';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-solicitud-extra',
  templateUrl: './solicitud-extra.component.html',
  styleUrls: ['./solicitud-extra.component.css']
})
export class SolicitudExtraComponent implements OnInit {
user;
extraForm;
submitted = false;
misSolicitudes;
closeResult = '';
modal : NgbModalRef;

  constructor(private authService : AuthService,
    private renderer: Renderer2,
    private modalService: NgbModal
) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    this.loaduser();
    this.extraForm= new FormGroup({
      'user': new FormControl('', [
        Validators.required,
       
      ]),
      'sede': new FormControl('', [ Validators.required, ]),
      'date': new FormControl('', [ Validators.required, ]),
      'dia': new FormControl('', [ Validators.required, ]),
      'mes': new FormControl('', [ Validators.required, ]),
      'ano': new FormControl('', [ Validators.required, ]),
      'hinit': new FormControl('', [ Validators.required, ]),
      'hend': new FormControl('', [ Validators.required, ]),
      // 'hdiurna': new FormControl(0,[ Validators.required, ]),
      // 'hnocturna': new FormControl(0,[ Validators.required, ]),
     
      'actividad': new FormControl('', [ Validators.required, ]),
      "hed": new FormControl(0, [ Validators.required, ]),
    "hdf": new FormControl(0, [ Validators.required, ]),
    "rn": new FormControl(0, [ Validators.required, ]),
    "hnf": new FormControl(0, [ Validators.required, ]),
   "hen":new FormControl(0, [ Validators.required, ]),
    "hedf": new FormControl(0, [ Validators.required, ]),
    "henf": new FormControl(0, [ Validators.required, ]),
    "compensatorio": new FormControl('',[ Validators.required, ]),
    "compFecha": new FormControl('',),
    "compPago": new FormControl('',),
     
    })
 
  }
  get f() { return this.extraForm.controls; }

  ///function modal
  open(content) {
    this.modal = this.modalService.open(content,  { size: 'lg' })    
    this.modal.result.then((e) => {
        console.log("dialogo cerrado")
    });        
  }

  cerrar() {
    this.modal.close();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  loaduser(){
    this.authService.validatep(this.user.id)
    .then((response) => {
      console.log(response);
      this.authService.setUserInfo({'user' : response['user']});
      let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
      this.getMisSol();
    })
    
  
     
         
  }

  getMisSol() {
    /* this.authService.getSolExtraUser(this.user._id)
      .then((response) => {
        if (Array.isArray(response)) {
          const solicitudesOrdenadas = response.sort((a, b) => {
            return new Date(b.createddate).getTime() - new Date(a.createddate).getTime();
          }); 
          this.misSolicitudes = solicitudesOrdenadas;
        } else {
          console.error("La respuesta no es un arreglo de solicitudes:", response);
        }
      })
      .catch((error) => {
        console.error("Error al obtener las solicitudes:", error);
      }); */
  }
  

  newExtra(){
    this.submitted=true;
    var date= new Date(this.extraForm.controls["date"].value);
    date.setTime(date.getTime()+(6*60*60*1000))
console.log(date)
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    this.extraForm.patchValue({
      user:this.user._id,
      ano:ano,
      mes:mes,
      dia:dia,
      });
      console.log(this.extraForm.value)
    
   if(this.extraForm.invalid){
    return 
   }
 
  /*  this.authService.newSolExtra(this.extraForm.value)
   .then((r)=>{
    console.log(r)
    Swal.fire("Solicitud creada con exito")
    this.submitted=false;
    this.extraForm.reset();
    this.getMisSol();
    this.cerrar();
   }) */
  }
}
