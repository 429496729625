<div class="lienzo">
    <div class="row m-1">
        <div class="col-md-1">
            <button class="btn btn-danger" routerLink="/agenda" >Volver</button>
        </div>
    </div>
    
    <div class="row m-1 mt-4 justify-content-center">
        
        <div class="col-md-10" >
            <div class="row">
                <div class="col-md-2">
                    <button class="btn btn-primary" (click)="open(openn)" >Reportar extras</button>
                </div>
            </div>
            <h2>Mis solicitudes</h2>
            <div class="caja-form2" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; background-color: rgb(255, 255, 255);  padding:15px  ;" >
                <table class="table table-striped table-bordered" >
                    <thead>
                        <tr>
                           <th>
                            USUARIO
                           </th>
                           <TH>
                            FECHA
                           </TH>
                           <th>
                            SEDE
                           </th>
                           <th>
                            HED
                           </th>
                           <TH>
                            HDF
                           </TH>
                           <TH>
                            RN
                           </TH>
                           <TH>
                            HNF
                           </TH>
                           <TH>
                            HEN
                           </TH>
                           <TH>
                            HEDF
                           </TH>
                           <TH>
                            HENF
                           </TH>
                     
                           <TH>ESTADO</TH>
                         
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let solicitud of misSolicitudes" 
                         >
                            <td>
                                {{user.name}} {{user.apellido}}
                            </td>
                            <td>
                                {{solicitud.dia}}/{{solicitud.mes}}/{{solicitud.ano}}
                            </td>
                            <td>
                                {{solicitud.sede}}
                            </td>
                            <td>
                                {{ solicitud.hed}}
                                </td>
                                <Td>
                                 {{solicitud.hdf}}
                                </Td>
                                <Td>
                                 {{solicitud.rn}}
                                </Td>
                                <Td>
                                 {{solicitud.hnf}}
                                </Td>
                                <Td>
                                 {{solicitud.hen}}
                                </Td>
                                <Td>
                                {{ solicitud.hedf}}
                                </Td>
                                <Td>
                                 {{solicitud.henf}}
                                </Td>
                            <td>
                                <span *ngIf="solicitud.aprobe" >Aprobada</span>
                                <span *ngIf="solicitud.archive" >
                                    Rechazada
                                    <div style="margin-left: 15px;" >
                                        {{solicitud.nota}}
                                    </div>
                                </span>
                                <span *ngIf="!solicitud.archive &&  !solicitud.aprobe" > Pendiente</span>
                            </td>
                          
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    
    
    </div>
    
    <ng-template #openn let-modal > 
        <div class="modal-header">
          
            <button type="button" class="btn-close btn " aria-label="Close" (click)="modal.dismiss('Cross click')">X</button>
        </div>
        <div class="modal-body ">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div>
                        <form  (ngSubmit)=" newExtra()"  class="caja-form"  >  
                            
                 
                            <div class="row" >
                                <div class="col-11 col-md-3" >
                                    <div class="form-group">
                                        <h3>FECHA DE LA NOVEDAD:</h3>
                                        <input  type="date" class="form-control"  formControlName="date"  
                                      >
                                        <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                                            <div *ngIf="f.date.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-11 col-md-3" >
                                    <div class="form-group">
                                        <h3>HORA INICIO:</h3>
                                        <input type="time" class="form-control"  formControlName="hinit"  
                                        >
                                        <div *ngIf="submitted && f.hinit.errors" class="invalid-feedback">
                                            <div *ngIf="f.hinit.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-11 col-md-3" >
                                    <div class="form-group">
                                        <h3>HORA FINAL:</h3>
                                        <input type="time" class="form-control"  formControlName="hend"  
                                        >
                                        <div *ngIf="submitted && f.hend.errors" class="invalid-feedback">
                                            <div *ngIf="f.hend.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                    </div> 
                                </div>
                              
                                <div class="col-11 col-md-3" >
                                    <div class="form-group">
                                        <h3>ACTIVIDAD DESARROLLADA:</h3>
                                        <input type="text" class="form-control"  formControlName="actividad"  
                                        >
                                        <div *ngIf="submitted && f.actividad.errors" class="invalid-feedback">
                                            <div *ngIf="f.actividad.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-11 col-md-3" >
                                    <div class="form-group">
                                        <h3>SEDE:</h3>
                                        <select class=" form-select form-select-sm"  
                                        formControlName="sede"  
                                       >
                                        <option value="">Seleccione</option>
                                       <object data="GDA-Pompeya" >GDA-Pompeya</object>
                                       <option value="GDT-Castilla la nueva">GDT-Castilla la nueva</option>
                                       <option value="CTO-9-Acacias">CTO-9-Acacias</option>
                                       <option value="GCH-Acacias">GCH-Acacias</option>
                                       <option value="Remoto">Remoto</option>
                                    </select>
                                        <div *ngIf="submitted && f.sede.errors" class="invalid-feedback">
                                            <div *ngIf="f.sede.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <hr>
                            <div class="row" >
                                <div class="col-11 col-md-6" >
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-8">
                                                <h3>Hora Extra Diurna Ordinaria:</h3>
                                            </div>
                                            <div class="col-4">
                                                <input type="text" class="form-control"  formControlName="hed"  
                                                >
                                                <div *ngIf="submitted && f.hed.errors" class="invalid-feedback">
                                                    <div *ngIf="f.hed.errors.required">Este campo es obligatorio</div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                       
                                       
                                    </div> 
                                </div>
                               
            
            
            
            
                                <div class="col-11 col-md-6" >
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-8">
                                                <h3>Hora Ordinaria Diurna Festiva (Domingo y/o Festivo):</h3>
                                            </div>
                                            <div class="col-4">
                                                <input type="text" class="form-control"  formControlName="hdf"  
                                        >
                                        <div *ngIf="submitted && f.hdf.errors" class="invalid-feedback">
                                            <div *ngIf="f.hdf.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                            </div>
                                        </div>
                                       
                                       
                                    </div> 
                                </div>
                                <div class="col-11 col-md-6" >
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-8">
                                                <h3>Recargo Hora Ordinaria Nocturna :</h3>
                                            </div>
                                            <div class="col-4">
                                                <input type="text" class="form-control"  formControlName="rn"  
                                              >
                                                <div *ngIf="submitted && f.rn.errors" class="invalid-feedback">
                                                    <div *ngIf="f.rn.errors.required">Este campo es obligatorio</div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                       
                                       
                                    </div> 
                                </div>
                               
                                <div class="col-11 col-md-6" >
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-8">
                                                <h3>Hora Ordinaria Nocturna Festiva:</h3>
                                            </div>
                                            <div class="col-4">
                                                <input type="text" class="form-control"  formControlName="hnf"  
                                        >
                                        <div *ngIf="submitted && f.hnf.errors" class="invalid-feedback">
                                            <div *ngIf="f.hnf.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                            </div>
                                        </div>
                                       
                                       
                                    </div> 
                                </div>
                             
                                <div class="col-11 col-md-6" >
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-8">
                                                <h3>Hora Extra Nocturna Ordinaria:</h3>
                                            </div>
                                            <div class="col-4">
                                                <input type="text" class="form-control"  formControlName="hen"  
                                        >
                                        <div *ngIf="submitted && f.hen.errors" class="invalid-feedback">
                                            <div *ngIf="f.hen.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                            </div>
                                        </div>
                                       
                                       
                                    </div> 
                                </div>
                                <div class="col-11 col-md-6" >
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-8">
                                                <h3>Hora Extra Diurna Festiva:</h3>
                                            </div>
                                            <div class="col-4">
                                                <input type="text" class="form-control"  formControlName="hedf"  
                                        >
                                        <div *ngIf="submitted && f.hedf.errors" class="invalid-feedback">
                                            <div *ngIf="f.hedf.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                            </div>
                                        </div>
                                       
                                       
                                    </div> 
                                </div>
                                
                                <div class="col-11 col-md-6" >
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-8">
                                                <h3>Hora Extra Nocturna Festiva:</h3>
                                            </div>
                                            <div class="col-4">
                                                <input type="text" class="form-control"  formControlName="henf"  
                                       >
                                        <div *ngIf="submitted && f.henf.errors" class="invalid-feedback">
                                            <div *ngIf="f.henf.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                            </div>
                                        </div>
                                       
                                       
                                    </div> 
                                </div>
                                
                                
                              
                            </div>
                            <hr>
                            <div class="row" >
                                <div class="col-11 col-md-6" >
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-8">
                                                <h3>DESCANSO COMPENSATORIO</h3>
                                            </div>
                                            <div class="col-4 ">
                                                <select class=" form-select form-select-sm"  formControlName="compensatorio"  
                                                >
                                                <option value="">Seleccione</option>
                                                <option value="true">Si</option>
                                                <option value="false">No</option>
                                            </select>
                                                <div *ngIf="submitted && f.compensatorio.errors" class="invalid-feedback">
                                                    <div *ngIf="f.compensatorio.errors.required">Este campo es obligatorio</div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                       
                                        
                                       
                                    </div> 
                                </div>
                                <div class="col-11 col-md-5" *ngIf="extraForm.get('compensatorio').value === 'true'" >
                                    <div class="form-group">
                                        <h3>FECHA :</h3>
                                        <input  type="date" class="form-control"  formControlName="compFecha"  
                                       >
                                        <div *ngIf="submitted && f.compFecha.errors" class="invalid-feedback">
                                            <div *ngIf="f.compFecha.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-11 col-md-4" *ngIf="extraForm.get('compensatorio').value === 'true'" >
                                    <div class="form-group">
                                        <h3>PAGO:</h3>
                                        <select class="form-select"  formControlName="compPago"  
                                        >
                                        <option value="">Seleccione</option>
                                        <option value="true">Si</option>
                                        <option value="false">No</option>
                                    </select>
                                        <div *ngIf="submitted && f.compPago.errors" class="invalid-feedback">
                                            <div *ngIf="f.compPago.errors.required">Este campo es obligatorio</div>
                                           
                                        </div>
                                    </div> 
                                </div>
            
                            </div>
                            <br>
                            <br>
                            <input type="submit" class="btn btn-primary" value="Enviar">
                        </form>
                    </div>
                </div>
            </div>
    
        </div>
    </ng-template>
</div>
