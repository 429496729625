<!-- <div style="position: absolute;top: 10px; left: 10px; z-index: 99;" >
    <a href="/tutorial" target="_blank" rel="noopener noreferrer">
<button class="btn btn-boton2">Tutoriales</button></a>
</div> -->
<!-- <div style="position: absolute;top: 10px; left: 10px; z-index: 99;">
    <a href="/tutorial" target="_blank" rel="noopener noreferrer">
        <button class="btn btn-boton2">Tutoriales</button></a>
</div> -->
<!-- <div style="position: absolute;top: 10px;right: 10px; text-align: end; " >
    
    <button class="btn btn-botonorange" (click)="vmensajes=!vmensajes" >Mensajes  <span *ngIf="nuevos>0" >({{nuevos}})</span></button>
    <app-mensajes *ngIf="vmensajes" (newConversaions)="onNewConversations($event)"  [conversations]="mensajes" style="z-index: 99999999;" >
    
    </app-mensajes>
    </div> -->


<nav class="navbar navbar-expand-lg " style="height: 60px;">
    <div class="navbar-brand">
        <div class="d-flex">
             
              

              <div class="justify-content-end">
                <img src="../../../assets/FondosClub/logo-serrrezuela-ico.png" width="20%" />
              </div>
               <div class="estadoD">
                <p class="texto-color" style="font-size: 10px;">
                    Bienvenido {{user.name}} {{user.apellido}}
                </p>
                </div>
            
        </div>
     
    </div>
        <div class="collapse navbar-collapse btn-group " id="navbarNav" style=" background-color:  #5A9E94 !important;">
          <!--   <div class="col-2 col-md-2" *ngIf="user.categoria=='socio' || user.categoria=='beneficiario'|| (user.categoria=='canje'&& user.canjeHabil)" >
                <button class="btn  dropdown-toggle color-texto" 
                style="border-color: #04453b;"
                type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Reservas
                  </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="background-color:#5A9E94 ;">
                    <li ><a routerLink="/reservar/golf"   style="color: #04453b;"  class="dropdown-item">Reservas Golf</a></li>
                    <li><a  routerLink="/reservar/tennis" class="dropdown-item   texto-color">Reservas Tennis</a></li>
                    <li><a  routerLink="/reservar/bolos" class="dropdown-item  texto-color">Reservas Bolos</a></li>
                    <li><a routerLink="/reservar/ski"  class="dropdown-item  texto-color">Reservas Esquí</a></li>
                    <li><a routerLink="/reservar/ninera"  class="dropdown-item  texto-color">Reservas Niñera</a></li>
                    <li><a routerLink="/reservar/profesortenis"  class="dropdown-item  texto-color">Reservas Profesor de tenis</a></li>
                    <li><a routerLink="/reservar/profesorgolf"  class="dropdown-item  texto-color">Reservas Profesor de golf</a></li>
                    <li><a routerLink="/reservar/masajista"  class="dropdown-item  texto-color">Reservas Masajista</a></li>
                    <li><a routerLink="/reservar/saladebelleza"  class="dropdown-item  texto-color">Reservas Sala de belleza</a></li>

                </ul>
            </div> -->

           <!--  <div class="collapse navbar-collapse bg-dark" id="navbarNav" style=" background-color:  #5A9E94 !important;">
                <ul class="navbar-nav">
                    <li><a  (click)="popPQR=true" class="nav-link px-3 btn btn-header">Generar PQR</a></li>
                    <li><a  (click)="popCanje=true" class="nav-link px-2 btn btn-header">Solicitud canje</a></li>
                    
                </ul>
            </div> -->
           
    
           
        </div>
    
    <div class=" estadoA">
        <p class="texto-color">Modulo Agenda <br>
            Bienvenido {{user.name}} {{user.apellido}}
        </p>
    </div>    
</nav>    


<!-- <div  style="text-align:center">
<div style="text-align:center">
    <h1>Bienvenido {{user.name}}</h1>
</div> -->

<div class="row mt-2 m-1" *ngIf="!foto">

    <div class="col-md-4  ">

        <app-persona [user]="user" [show]="false" [qr]="true"  ></app-persona>

       
            <div class="caja-form2 mt-3"  *ngIf="user.categoria=='socio' || user.categoria=='beneficiario'|| (user.categoria=='canje'&& user.canjeHabil)">
                <h2 class="color-texto">Generar PQR</h2>
                <button class="btn btn-primary" (click)="popPQR=true" >Generar</button>
            </div>
        
        
            <div class=" caja-form2  mt-3"  *ngIf="user.categoria=='socio' || user.categoria=='beneficiario'|| (user.categoria=='canje'&& user.canjeHabil)">
                <h2 class="color-texto">Solicitud canje</h2>
                <button class="btn btn-primary" (click)="popCanje=true" >Solicitar</button>
            </div>
            <div class="mt-3"  *ngIf="user.categoria=='socio' || user.categoria=='beneficiario'|| (user.categoria=='canje'&& user.canjeHabil)">
                <button class="btn col-md-12  dropdown-toggle color-texto" 
                style="border-color: #04453b; background-color: #DBEAE7;"
                
                type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Reservas
                  </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="background-color:#5A9E94 ;">
                    <li ><a routerLink="/reservar/golf"   style="color: #04453b;"  class="dropdown-item">Reservas Golf</a></li>
                    <li><a  routerLink="/reservar/tennis" class="dropdown-item   texto-color">Reservas Tennis</a></li>
                    <li><a  routerLink="/reservar/bolos" class="dropdown-item  texto-color">Reservas Bolos</a></li>
                    <li><a routerLink="/reservar/ski"  class="dropdown-item  texto-color">Reservas Esquí</a></li>
                    <li><a routerLink="/reservar/ninera"  class="dropdown-item  texto-color">Reservas Niñera</a></li>
                    <li><a routerLink="/reservar/profesortenis"  class="dropdown-item  texto-color">Reservas Profesor de tenis</a></li>
                    <li><a routerLink="/reservar/profesorgolf"  class="dropdown-item  texto-color">Reservas Profesor de golf</a></li>
                    <li><a routerLink="/reservar/masajista"  class="dropdown-item  texto-color">Reservas Masajista</a></li>
                    <li><a routerLink="/reservar/saladebelleza"  class="dropdown-item  texto-color">Reservas Sala de belleza</a></li>
        
                </ul>
            </div>
 
    </div>

    <div class="col-md-4 p-2 " style="max-height: 500px; overflow-y: scroll;">
      

        <div class="caja-form2 ">

            <h2 class="color-texto">Visitas programadas</h2>
            <button class="btn btn-primary" *ngIf="user.categoria=='socio' || user.categoria=='beneficiario'"
                (click)="visitapop=true">Crear visita</button>
       
       
        </div>
        <div class="">
            <div class="mb-1 caja-form2" *ngFor="let visita of visitas">

                <app-visita *ngIf="visita.anfitrion==user._id" [visita]="visita" [show]="true"></app-visita>
    
                <div class="card " style="background-color: rgba(0, 0, 0, 0.2);" *ngIf="visita.anfitrion!=user._id">
                    <span class="text"  style="color: #04453b !important;"> Fecha: {{visita.fecha | date:"medium"}}</span>
                    <br>
                    <span class="text" > Descripción: {{visita.descripcion}}</span>
                    <br>
                    <span class="text" style="color: #04453b;"> Anfitrion: {{visita.anfitrion.name}}
                        {{visita.anfitrion.apellido}}</span>
                </div>
                <div *ngIf="visita.anfitrion==user._id"class="d-flex justify-content-end">    
                    <button class="btn btn-primary m-1" (click)="popInv=true;visitaid=visita._id" >Agregar invitado</button>
                    <button class="btn btn-dark"  (click)="eliminar(visita._id)">Eliminar</button> 
                </div>
            </div>
        </div>
    
    </div>


    <div class="col-4" *ngIf="user.categoria=='ninera' || user.categoria=='masajista'">
        <h2>Agenda</h2>
        <app-reserva-agenda [iduser]="user.id"></app-reserva-agenda>
    </div>

   

 <!--    <div class="caja-form2" >
        <h2 class="color-texto">Servicios disponibles: </h2>
        
        <app-reservas></app-reservas>
        <a href="/perfil"><button class="btn btn-primary">Editar Datos</button></a>
        <app-persona [user]="user" [show]="false" [qr]="true"></app-persona>


    </div> -->


  <!--   <div class="col-md-2 p-2" *ngIf="user.categoria=='socio' || user.categoria=='beneficiario'|| (user.categoria=='canje'&& user.canjeHabil)">
        
        <div class="caja-form2">
            <h2 class="color-texto">Servicios disponibles: </h2>

            <app-reservas></app-reservas>

        </div>

    </div>    -->
   
 



  <div  class="col-md-3 p-2"  *ngIf="user.categoria=='socio' || (user.categoria=='canje'&& user.canjeHabil)">
    <div class="caja-form2 " > 
        <h2 class="color-texto">Mis beneficiarios</h2>
        <button class="btn btn-primary" (click)="  copiar()" >Copiar enlace de registro</button>
        <br>
        <button class="btn btn-primary" (click)="pop=true" >Agregar</button>
        <br><br>
        <div class="row" >
            <div class="col-md-12">
                <div class="bg-white w-100">
                    <div *ngFor="let beneficiario of beneficiarios" >
                       Nombre: {{beneficiario.name}} {{beneficiario.apellido}}
                       <br>
                       Documento: {{beneficiario.id}}
                       <br>
                       Aprobado: <span  style="color: red;" *ngIf="!beneficiario.aprobe" >No aprobado</span>
                       <span  style="color: rgb(25, 200, 63);" *ngIf="beneficiario.aprobe" > aprobado</span>
                        <hr style="border: 1px solid navy; background-color:navy;  ">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2 p-2" *ngIf="user.categoria=='ninera' || user.categoria=='masajista'">
        
        <div class="caja-form2">
            <h2 class="color-texto">Agenda: </h2>

            <app-reserva-agenda [iduser]="this.user.id"></app-reserva-agenda>

        </div>

    </div>


  
    

   

</div>


</div>

<div class="row mt-2 m-1">
    
   
</div>





<div *ngIf="visitapop" class="caja-form2 text-white col-md-6 mt-5"
style="position: fixed; top: 8%; left:8%; padding: 10px; overflow-y: scroll; max-height: 500px;">
<div>
    <button style="float: right; padding: 1px;" class="btn btn-danger" (click)="visitapop=false">X</button>
</div>
<div class="d-flex justify-content-center">
    <div class="col-md-6 col-10">
        <form [formGroup]="visitaForm" (ngSubmit)="newVisita()" novalidate="">
            <div class="form-group">
                <div class="form-group">
                    <h3 class="color-texto">Descripcion de la visita</h3>
                    <input type="text" class="form-control" formControlName="descripcion"
                        [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }">
                </div>
    
                <div class="form-group">
                    <h3 class="color-texto">Ingrese la fecha y hora de su visita</h3>
                    <input class="form-control" type="datetime-local" placeholder="Fecha" formControlName="fecha"
                        min="2021-06-01" max="2023-12-31" required>
                </div>
    
            </div>
            <br>
            <input type="submit" value="Crear" class="btn btn-primary" style="margin-right: 10px;">
            <button class="btn btn-dark" (click)="visitapop=false">Cancelar</button>
        </form>
    </div>
</div>






</div>


<div class="popFull" *ngIf="pop">
    <div class="row m-1 mt-1 justify-content-center">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header">
                    <button style="float: right; padding: 1px;" class="btn btn-danger" (click)="pop=false">X</button>
                </div>
                <div class="card-body"
                    >
                    <app-register-beneficiario [socio]="user._id" (done)="done($event)"></app-register-beneficiario>

                </div>
            </div>
        </div>
    </div>

</div>
<div class="popFull" *ngIf="popInv">
    <div class="row m-1 mt-1 justify-content-center">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header">
                    <button style="float: right; padding: 1px;" class="btn btn-danger" (click)="popInv=false">X</button>
                </div>
                <div class="card-body"
                    >
                    <app-registro-pago [id]="visitaid" (done)="done2($event)"></app-registro-pago>

                </div>
            </div>
        </div>
    </div>

</div>

<div *ngIf="foto" class="row justify-content-center ">

    <div class="col-md-7">


        <div class="container  bg-dark  p-2 rounded-top shadow text-white" >
            <video width="90%" height="225" autoplay #video></video> <br>
            <h2 style="color: white;" >Al ingresar y tomarse la fotografía esta aceptando nuestra política de tratamiento de datos </h2>

            <button (click)="tomar()" class="btn btn-primary">Tomar foto</button>

            <canvas #canvas style="display:none; "></canvas>
        </div>

    </div>
</div>
<!-- popap de pqr -->
<div *ngIf="popPQR" class="caja-form2 text-white col-md-9 mt-5"
style="position: fixed; top: 8%; left:8%; padding: 10px; overflow-y: scroll; max-height: 700px;">
        <div>
            <button style="float: right; padding: 1px;" class="btn btn-danger" (click)="popPQR=false">X</button>
        </div>
        <div class="caja-form2" >
           <h3 class="text-center"><strong>Generar PQR</strong> </h3>
            <form [formGroup]="pqrForm" (ngSubmit)="newPqr()">
                <div class="col-11 d-flex justify-content-center">
                    <div class="col-md-8">
                        <h3>Escriba su peticion, queja o reclamo </h3>
                        <textarea class="form-control" formControlName="mensaje" cols="30" rows="3"></textarea>
                        <div class="text-center mt-5">
                            <button class="btn btn-primary">Enviar</button>
                        </div>
                    </div>
                </div>
            </form>

        </div>

</div>
<!-- popap de pqr -->
<div *ngIf="popCanje" class="caja-form2 text-white col-md-9 mt-5"
style="position: fixed; top: 8%; left:8%; padding: 10px; overflow-y: scroll; max-height: 800px;">
        <div>
            <button style="float: right; padding: 1px;" class="btn btn-danger" (click)="popCanje=false">X</button>
        </div>
      
        <div  class="caja-form2">
          <h3 class="text-center"> <strong>Solicitudes de canje</strong>  </h3>
            <form [formGroup]="canjeForm" (ngSubmit)="newCanje()">
             
                    <div class="form-group">
                        <h3 class="texto-color" style="color: #04453b;">Seleccione el club </h3>
                        <select class="form-control" formControlName="club" >
                            <option value="{{club}}" *ngFor="let club of clubes" >{{club}}</option>
                        </select>
                    </div>
                
                <div class="form-group">
                    <div class="label texto-color" style="color: #04453b;">Fecha de inicio</div>
                    <input class="form-control" placeholder="" formControlName="inicio"
                    [ngClass]="{ 'is-invalid':f2.inicio.touched && f2.inicio.invalid }"
                        style=" border-radius: 5px 5px 5px 5px" type="date" min="2023-01-01" name="">
                    <div *ngIf="f2.inicio.touched && f2.inicio.errors" class="invalid-feedback ">
                        <div *ngIf="f2.inicio.errors.required">campo obligatorio</div>
                       
                    </div>
                </div>
                <div class="form-group">
                    <div class="label" style="color: #04453b;">Fecha de fin </div>
                    <input class="form-control" placeholder="" formControlName="fin"
                    [ngClass]="{ 'is-invalid':f2.fin.touched && f2.fin.invalid }"
                        style=" border-radius: 5px 5px 5px 5px" type="date" min="2023-01-01" name="">
                    <div *ngIf="f2.fin.touched && f2.fin.errors" class="invalid-feedback ">
                        <div *ngIf="f2.fin.errors.required">campo obligatorio</div>
                       
                    </div>
                </div>
                
                <div class="d-flex justify-content-center mt-4">
                    <button class="btn-primary">Enviar</button>
                </div>
            </form>
        </div>


</div>