import { Component, OnInit,EventEmitter,Output,ElementRef,OnDestroy } from '@angular/core';
import { ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import QrScanner from 'qr-scanner';

@Component({
  selector: 'app-addmanilla',
  templateUrl: './addmanilla.component.html',
  styleUrls: ['./addmanilla.component.css']
})
export class AddmanillaComponent implements OnInit {
manillaForm;
submitted; 
result: any
cameras:any;
qrScanner: any;
ya;
@ViewChild('video1', { static: true}) videoElement: ElementRef;
  
  @Output() newManilla =new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.onNewManilla("0230156842315201236548912305")
    // }, 5000);
   // this.scanSuccessHandler("0230156842315201236548912305");
   this.manillaForm = new FormGroup({
 
    'id': new FormControl('', [
      Validators.required,
        ]),    
  });

 

this.iniciar();
 
  }
iniciar(){

  this.qrScanner = new QrScanner(this.videoElement.nativeElement,(result) => {
   
    //console.log("RES", this.result.data)
    
    if (result) {
     
      this.newManilla.emit(result.data);

      this.qrScanner.stop()
      
      this.qrScanner.destroy()
      //this.destroy();
      
     // this.ngOnInit();
      // return

    }
 
  }, {
    highlightScanRegion: true,
    highlightCodeOutline: true });

  this.qrScanner.start()
  QrScanner.listCameras().then((r)=>{
    console.log(r)
    this.cameras = r
  })
}

destroy(){
  this.qrScanner.destroy();
  this.qrScanner=null
}

  selectedCamera(id){
    console.log(id)
    this.qrScanner.setCamera(id);
  }


  title = 'qr';
  camaras:any = [] ;
  desiredDevice:any = "";
  get f4() { return this.manillaForm.controls; }
  addmanilla(){
    this.submitted = true;
    this.newManilla.emit(this.manillaForm.controls['id'].value)
  }

  scanSuccessHandler(e:string){
    console.log(e)
    this.newManilla.emit(e);
  
  }

  camerasFoundHandler(e:any){
    console.log(e)
    this.camaras = e;
  }

  
  seleccionarCamara(deviceValue:any) {
    console.log(deviceValue);
    if(deviceValue != ""){
      this.desiredDevice = deviceValue
    }
}



}
