<div class="row justify-content-center m-4" >
    <div class="col-md-6">

      
        <div class="container caja-form2">
            <form [formGroup]="registerForm" (ngSubmit)="register()"  class="">
                <div class="row ">
                    <div class="col-11 col-md-6 ">
                        <div class="form-group">
                            <h3>Nombres:</h3>
                            <input type="text" class="form-control" placeholder="Nombre" formControlName="name"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Ingrese su nombre</div>
                                <div *ngIf="f.name.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Apellidos:</h3>
                            <input type="text" class="form-control" placeholder="Apellido" formControlName="apellido"
                                [ngClass]="{ 'is-invalid': submitted && f.apellido.errors }">
                            <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
                                <div *ngIf="f.apellido.errors.required">Ingrese sus apellidos</div>
                                <div *ngIf="f.apellido.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Correo electronico:</h3>
                            <input class="form-control" type="email" placeholder="Correo electrónico"
                                formControlName="correo" [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                            <div *ngIf="submitted && f.correo.errors" class="invalid-feedback ">
                                <div *ngIf="f.correo.errors.required">ingrese su email</div>
                                <div *ngIf="f.correo.errors.email">ingrese un correo valido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Documento de identidad:</h3>
                            <input class="form-control" type="number" placeholder="Documento de identidad"
                                formControlName="id" [ngClass]="{ 'is-invalid': submitted && f.id.errors }">
                            <div *ngIf="submitted && f.id.errors" class="invalid-feedback ">
                                <div *ngIf="f.id.errors.required">ingrese su documento</div>
                                <div *ngIf="f.id.errors.min">ingrese un documento valido</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Celular:</h3>
                            <input class="form-control" type="number" placeholder="celular" formControlName="telefono"
                                [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }">
                            <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback ">
                                <div *ngIf="f.telefono.errors.required">ingrese su telefono</div>
                                <div *ngIf="f.telefono.errors.min">ingrese un telefono valido</div>
                            </div>
                        </div>
                    </div>
               
                </div>
                <!-- <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Cargo</h3>
                            <input type="text" class="form-control" placeholder="Cargo" formControlName="cargo"
                                [ngClass]="{ 'is-invalid': submitted && f.cargo.errors }">
                            <div *ngIf="submitted && f.cargo.errors" class="invalid-feedback">
                                <div *ngIf="f.cargo.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>ARL</h3>
                            <input type="text" class="form-control" placeholder="ARL" formControlName="arl"
                                [ngClass]="{ 'is-invalid': submitted && f.arl.errors }">
                            <div *ngIf="submitted && f.arl.errors" class="invalid-feedback">
                                <div *ngIf="f.arl.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                </div> -->
    
                <!-- <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>EPS</h3>
                            <input type="text" class="form-control" placeholder="EPS" formControlName="eps"
                                [ngClass]="{ 'is-invalid': submitted && f.eps.errors }">
                            <div *ngIf="submitted && f.eps.errors" class="invalid-feedback">
                                <div *ngIf="f.eps.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Contacto de emergencia</h3>
                            <input type="text" class="form-control" placeholder="Contacto" formControlName="contacto"
                                [ngClass]="{ 'is-invalid': submitted && f.contacto.errors }">
                            <div *ngIf="submitted && f.contacto.errors" class="invalid-feedback">
                                <div *ngIf="f.contacto.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Celular del contacto</h3>
                            <input type="number" class="form-control" placeholder="celular" formControlName="contactotel"
                                [ngClass]="{ 'is-invalid': submitted && f.contactotel.errors }">
                            <div *ngIf="submitted && f.contactotel.errors" class="invalid-feedback">
                                <div *ngIf="f.contactotel.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
    
                    </div>
                </div> -->
    
                
                <div class="text-center mt-2">
                    <input type="submit" value="Ingresar" class="btn btn-orange">
                </div>
            </form>
        </div>
        
    </div>

</div>