import { Component, OnInit } from '@angular/core';
import { ReservaService } from '../../services/reservas.services'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reserva-tennis',
  templateUrl: './reserva-tennis.component.html',
  styleUrls: ['./reserva-tennis.component.css']
})
export class ReservaTennisComponent implements OnInit {

  user:any;

  dias = false;
  diaseleccion: any;
  manana:any
  field:any
  fullname:any

  horas = [
    {
      "hora": "6:00 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
   
    {
      "hora": "7:00 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    
    {
      "hora": "8:00 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    
    {
      "hora": "9:00 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "10:00 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "11:00 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "12:00 p.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "1:00 p.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "2:00 p.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    }
  ]

  // horas = ["6:10", "6:20", "6:30", "6:40", "6:50", "7:00", "7:10", "7:20", "7:30", "7:40"]

  reservaForm: any

  constructor(private router: Router, private api: ReservaService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.reservaForm = new FormGroup({
      'dia': new FormControl('', [
        Validators.required,
      ]),
      'hora': new FormControl('', [
        Validators.required,
      ]),
      'user': new FormControl('', [
        Validators.required,
      ])

    });

    var todayDate = new Date();
    todayDate .setDate(todayDate .getDate() + 1);
    this.manana = this.datePipe.transform(todayDate, 'yyyy-MM-dd');
    console.log("MAÑANA", this.manana)

    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;

    console.log("USER", this.user)

    this.fullname = this.user.name+" "+this.user.apellido

    console.log("FULL", this.fullname)

    
  }

  get f2() { return this.reservaForm.controls; }

  reservations(e: any, hole:any) {


    this.horas = [
      {
        "hora": "6:00 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
     
      {
        "hora": "7:00 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      
      {
        "hora": "8:00 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      
      {
        "hora": "9:00 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "10:00 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "11:00 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "12:00 p.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "1:00 p.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "2:00 p.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      }
    ]

    this.diaseleccion = e


    console.log(e)

    this.api.getReservas(e, this.field, "tennis").then((r: any) => {
      
      console.log("RESERVAS", r)

      r.map((re: any) => {
        console.log(re.position)
        this.horas[re.position].reservado = true
        this.horas[re.position].reservo = re.userName
        this.horas[re.position].idreserva = re._id
        this.horas[re.position].ingresos = re.team
      })

      this.dias = true;

    })

  }

  reservar(ind: any, hora: any) {

    console.log(hora, ind)

    

  }


  datechange(e:any){

    console.log(e.target.value)
    this.diaseleccion = e.target.value
    this.reservations(this.diaseleccion, this.field)

  }

  readcode(i){
     
  }


  fieldchange(e:any){

    console.log(e.target.value)
    this.field = e.target.value
    this.reservations(this.manana, e.target.value)

  }

  cancelar(id: any, i:any) {

    console.log("ID CANCEL", id, i)


    this.horas[i].reservado = false
          this.horas[i].reservo = ""
          this.horas[i].idreserva = ""
          this.horas[i].ingresos = []

    
    this.api.cancelar(id).then((r) => {
      console.log(r)

      this.horas[i].reservado = false
          this.horas[i].reservo = ""
          this.horas[i].idreserva = ""
          this.horas[i].ingresos = []

     
    })

  }

  reservalector(id: any, i:any){
    console.log("IDRes", id, i)
    this.router.navigate(['/reservalector',  {'idr': id}] ); 
  }

}
 