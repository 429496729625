<div class="row m-2 justify-content-center">
 

</div>
<div  class="row justify-content-center">
    <div class="col-5 m-1 " style="border: solid 3px white; text-align: center;" >
            <h1>Registrarme </h1>
            <video src="../../../assets/tutoriales/registro.mp4" width="100%" controls  ></video>
    </div>
       <div class="col-5 m-1 " style="border: solid 3px white; text-align: center;" >
            <h1>Registrar beneficiario </h1>
            <video src="../../../assets/tutoriales/beneficiario.mp4" width="100%" controls  ></video>
    </div>

    <div class="col-5 m-1  " style="border: solid 3px white; text-align: center;" >
        <h1>Creacion de acceso directo</h1>
        <iframe src="../../../assets/tutoriales/crearacceso.mp4" width="100%" height="80%" allowfullscreen frameborder="0"></iframe>
        
</div>
<div class="col-5 m-1 " style="border: solid 3px white; text-align: center;" >
    <h1>Crear visita</h1>
    <video src="../../../assets/tutoriales/creacionvisitas.mp4" width="100%" controls  ></video>
</div>
<div class="col-5 m-1 " style="border: solid 3px white; text-align: center;" >
    <h1>Crear reserva</h1>
    <video src="../../../assets/tutoriales/reservas.mp4" width="100%" controls  ></video>
</div>
</div>