import { Component, ElementRef, OnInit, } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { threadId } from 'worker_threads';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { log } from 'console';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  vusers;
  vempresas;
  users;
  blocked = [];
  seting;
  c_user;
  rolForm;
  categoriaForm
  user;
  vPersona;
  search: string = "";

  empresas: any;
  bblockeusers: boolean;
  persona: any;
  users_: any;
  usuariosEmpresa: boolean;
  empresaUsers: any;
  anadir: boolean;
  nuser: boolean;
  luser: any;
  empresasol: any;
  idrperson: any;
  agregados = [];
  done: number;
  popCategoria = false
  constructor(private authService: AuthService, private elRef: ElementRef,) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user = JSON.parse(user).user;
    console.log("USER", this.user);
    this.idrperson = new FormGroup({
      'id': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
    })
    this.vusers = true;
    this.getUsers();

    this.rolForm = new FormGroup({

      'rol': new FormControl('', [
        Validators.required,
      ]),

    });

    this.categoriaForm = new FormGroup({

      'categoria': new FormControl('', [
        Validators.required,
      ]),

    });
  }


  descargar2(){
    var  contacts_=this.users.map((item)=>{return { "Nombres": item.name+""+item.apellido,"Correo":item.correo ,
    "Categoria":item.categoria, "ARL":item.arl ,"Contacto": item.contacto,"Rol":item.admin,
    "Tipo": item.tipo,"empresa":item.empresa, "funcionario": item.funcionario ? 'SI': 'NO'}})
    this.exportAsExcelFile(contacts_,"Usuarios")
  }
    
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }
  

  verPersona(usr) {
    console.log("usuario: ", usr);

    this.persona = usr;
    this.vPersona = true;
  }

  VerBloqueado() {
    this.bblockeusers = true;
    this.vusers = false;
    this.vempresas = false;
  }
  verUsuarios() {
    this.bblockeusers = false;
    this.vusers = true;
    this.vempresas = false;
    this.getUsers();
  }
  verEmpresas() {
    this.bblockeusers = false;
    this.vusers = false;
    this.vempresas = true;
    this.getEmpresas()
  }
  p = 1;

  //FILTRO
  onSearch(search: string) {
    this.search = search.toLowerCase();
    this.p=1
  }
  data(data) {
    this.p = data;
  }
  get filtrar() {

    const filtroLista = this.users.filter(item => (item.id && JSON.stringify(item.id).toLowerCase().indexOf(this.search) !== -1) ||  JSON.stringify(item.name).toLowerCase().indexOf(this.search) !== -1 );

    let filtro1 =  this.users.filter(item => item.aprobe == false ||  item.aprovado2 === true);
    //filtro1.push(this.listaApro);

    let filtro2 =  this.users.filter(item => item.funcionario == true);
    let filtro3 =  this.users.filter(item => item.funcionario == false);

    return this.filtroN ==0 ? filtroLista:this.filtroN ==1? filtro1
    : this.filtroN ==2 ?filtro2 :this.filtroN ==3 ? filtro3 :filtroLista ;
  }

  aprobar(id) {

   
    
    console.log("aprobar", id._id)
    this.authService.aprobarUsuario(id)
      .then((response) => {
        this.getUsers();
        
    this.filtroN = 1;
      })
      .catch((err) => {

      }) 
  }

  getUsers() {
    if (this.user.rol == "admin") {
      this.authService.getusers()
        .then((response) => {
          console.log(response);
          this.users = response["usuarios"];
          this.users_ = response["usuarios"];
          //this.deleteUserError();
          this.blocked = this.users.filter((el) => el.bloqueado)
          this.users.forEach(item => {
             if (item.categoria == "beneficiario") {
              const socio = this.users.find(u =>  item.socio === u._id ); // Busca el beneficiario correspondiente
              item.socioname = socio
           /*       this.users.filter(item.socio === item._id) */
                //item.contact(beneficiario)
             } 
          });
        })
       /*  .catch((err) => {
          location.href = "/login"
        }) */
    }
  }

  
  compare(a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {

      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }
  filtroN = 0;

  

  porAprobar(){
      this.filtroN = 1;
      
      //return this.users = filtro;
  }
  funcionario(){
    this.filtroN = 2;
  }
  visitas(){
    this.filtroN = 3;
  }
  getEmpresas() {
    if (this.user.rol == "admin") {
      this.authService.getempresas()
        .then((response) => {
          console.log(response);
          this.empresas = response["empresas"];
          this.empresas.sort(this.compare)
          this.empresas.forEach(element => {
            element.registerurl = "https://controldevisitas.movistararena.co/register/" + element._id;
          });

          //this.deleteUserError();
        })
        .catch((err) => {
        })
    }
  }

  copiar(txt) {

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(txt);
  }


  deleteUserError() {
    this.users.forEach(element => {
      if (element.correo.includes("gmial")) {
        this.delete(element);
      }
    });
  }

  rol(usr) {
    this.seting = true
    this.c_user = usr;
    console.log(this.c_user);
    var evento: Object = {
      rol: this.c_user.rol,

    }
    this.rolForm.reset(evento);


  }


  categoria(usr) {
    this.seting = true
    this.c_user = usr;
    console.log(this.c_user);
   
   this.popCategoria = true


  }

  delete(usr) {
    this.authService.deleteuser(usr.id)
      .then((response) => {
        console.log(response);
        this.getUsers();
      })
  }
  upload(usr) {
    this.authService.upToHik(usr.id)
      .then((response) => {
        console.log(response);
        this.getUsers();
      })
  }

  deleteEmpresa(usr) {
    this.authService.deleteempresa(usr.nit)
      .then((response) => {
        console.log(response);
        this.getUsers();
      })
  }

  
CargarTodos(){
  this.users.forEach(usr => {
    this.authService.upToHik(usr.id)
    .then((response) => {
      console.log(response);
      this.getUsers();
  })
  });
}

  verNota(usr) {
    Swal.fire("La nota de bloqueo es: ", usr.bloqueadoNota)
  }

  async addNota(user) {
    const { value: nota } = await Swal.fire({
      title: 'Ingresa una nota con el motivo del bloqueo',
      input: 'text',
      background: "#F0F8FF",
      inputPlaceholder: 'Nota',
      confirmButtonColor: "#696969",

    })

    if (nota) {
      this.authService.blockUser(user.id, nota)
        .then((response) => {
          console.log(response);
          this.getUsers();

        })
    }

  }
  async blockUser(user) {

    const { value: nota } = await Swal.fire({
      title: 'Ingresa una nota con el motivo del bloqueo',
      input: 'text',
      background: "#F0F8FF",
      inputPlaceholder: 'Nota',
      confirmButtonColor: "#696969",

    })

    if (nota) {
      this.authService.blockUser(user.id, nota)
        .then((response) => {
          console.log(response);
          this.getUsers();

        })
    }


  }

  unBlockUser(user) {
    this.authService.unBlockUser(user.id)
      .then((response) => {
        console.log(response);
        this.getUsers();

      })
  }


  setRols() {

    console.log(this.rolForm.value,  this.c_user);

     this.authService.setrol(this.rolForm.value, this.c_user.id)
      .then((response) => {
        console.log(response);
        this.getUsers();
        this.seting = false;
      }) 
  }


  setCategoria() {

    console.log(this.categoriaForm.value, this.c_user.id);

      this.authService.setcategoria(this.categoriaForm.value,this.c_user.id)
    .then((response) => {
      console.log(response);
      this.getUsers();
      this.seting=false;
      this.popCategoria = false
  })  

  }


  setFuncionario() {
    this.authService.setfuncionario(this.c_user.id)
      .then((response) => {
        console.log(response);
        this.getUsers();
        this.seting = false;
      })
  }

  setNoFuncionario() {
    this.authService.setnofuncionario(this.c_user.id)
      .then((response) => {
        console.log(response);
        this.getUsers();
        this.seting = false;
      })
  }

  descargar() {

    var rows = [];
    this.users.forEach(element => {
      rows.push(element.user);
    });

    let csvContent = "data:text/csv;charset=utf-8,";
    var user = [];
    this.users.forEach((rowArray) => {
      delete rowArray.pulls;
      delete rowArray.events;
      delete rowArray.aprobe;
      delete rowArray.compras;
      delete rowArray._id;
      delete rowArray._v;
      delete rowArray.rol;
      delete rowArray.pay;
      user = Object.values(rowArray);



      console.log(user);
      let row = user.join(",");

      csvContent += row + "\r\n";
    });
    saveAs(csvContent, "registrados.csv");

  }

  limpiar(or) {
    this.usuariosEmpresa = false;
    if (or == 1) {
      //  this.usuarios_=this.usuarios.filter(this.personbyId.bind(this))
      this.users = this.users_;
      this.elRef.nativeElement.querySelector('#buscado').value = ""
    }
    else {
      this.users = this.users_;
      this.elRef.nativeElement.querySelector('#buscado2').value = ""
    }


  }

  buscar(or) {
    if (or == 1) {
      var buscado = this.elRef.nativeElement.querySelector('#buscado').value;
      console.log("buscando", buscado);

      this.users = this.users_.filter((usr) => { return usr.id.includes(buscado) })
    }
    else {
      var buscado = this.elRef.nativeElement.querySelector('#buscado2').value.toLowerCase().replace(/ /g, "");
      console.log("buscando", buscado);

      this.users = this.users_.filter((usr) => { console.log(usr.name.toLowerCase() + usr.apellido.toLowerCase()); return usr.name.toLowerCase().includes(buscado) || usr.apellido.toLowerCase().includes(buscado) || (usr.name.toLowerCase() + usr.apellido.toLowerCase()).replace(/ /g, "").includes(buscado) })
    }


  }
  verUsuariosEmpresa(empresa) {
    this.users = this.users_.filter((usr) => { return usr.empresa == empresa._id })
    this.verUsuarios();
    this.usuariosEmpresa = true;
    this.empresaUsers = empresa;
  }


  anadirPersona(empresa) {
    this.anadir = true;
    this.nuser = false;
    this.luser = null;
    this.empresasol = empresa;
  }



  marcaruno() {
    var uno = this.elRef.nativeElement.querySelector('#marcauno').value;
    // console.log(uno)
    var uno = uno.split("\n")
    uno.forEach((el) => {
      el = el.replace(/[,.-\s]/g, '');
      if (this.agregados.indexOf(el) == -1 && el != "") this.agregados.push(el);

    })
    this.nuser = true;

  }

  lookPerson() {
    var id = this.idrperson.controls["id"].value;
    if (id) {
      this.authService.validatep(id)
        .then((response) => {
          console.log(response);
          this.luser = response["user"];
          this.nuser = true;
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("No se encuentra el usuario")
        })


    }
  }
  anadirpersonas() {
    this.done = 0;
    this.agregados.forEach(element => {
      this.done += 1;
      this.confirmAddPerson(element);
      if (this.done == this.agregados.length) {
        this.anadir = false;
      }


    });
  }

  confirmAddPerson(user) {
    this.authService.addPerson(this.empresasol._id, user)
      .then((response) => {
        //     this.anadir=false;
        // this.getEmpresas();
      })
      .catch((err) => {
        //console.log(err);
      })

  }

}
