import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import {SocketioService} from "../../services/socketio.service";
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import  Swal from 'sweetalert2';  

import {OpenviduSessionComponent, StreamEvent, Session, UserModel, OpenViduLayout, OvSettings, OpenViduLayoutOptions, SessionDisconnectedEvent, Publisher} from 'openvidu-angular';

import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.css']
})
export class BitacoraComponent implements OnInit {

  bitacoraForm: FormGroup;
  submitted = false;
  type = "imagen";
  items = [] ;
  d1=[];
  d2=[];
  d3=[];
  d4=[];
  d5=[];
  d6=[];
  day = 1;
  user;
  fechaForm;
  pop;
  popSrc;
  response = false;
  questions: any;
  bitaForm;
  visita: any;
  constructor(    private authService : AuthService, 
    private eventService : EventsService, 
    private route: ActivatedRoute, private router : Router,
    private socketservice: SocketioService,
    private renderer: Renderer2,
    private elRef:ElementRef,
    private httpClient: HttpClient) { }

  ngOnInit(): void {

    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(this.user);
    this.fechaForm= new FormGroup({
      'fecha':new FormControl("",[Validators.required]),
    })
      this.bitaForm = new FormGroup({
 
      'question': new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),              
      'time': new FormControl('', ),    
    });
    this.loadquestions();
  }

 
  
  newFecha(){
    console.log(this.fechaForm.controls)
    var date=new Date(this.fechaForm.controls["fecha"].value.toString());
  
    var dia=date.getUTCDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    console.log(dia,mes,ano)
    this.eventService.preguntas(dia,mes,ano)
    .then((response) => {
      //console.log(response);
      var preguntas=response["results"]
      preguntas.forEach(element => {
        //console.log(element);
        var date= new Date(element.time);
        element.fecha=element.time;
   var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
   element.time=fecha;
      })
   this.questions=preguntas
   this.questions.sort( this.compare );
   this.questions.reverse()
    })
    .catch((err)=>{
   
    })
  }
  closepop(){
   this.pop=false;
  }
openpop(id){
 
  this.eventService.getsolicitud(id)
  .then((response) => {
    this.visita=response["visita"];
    this.pop=true;
  })
}




  get f() { return this.bitacoraForm.controls; }


 

delete(id){
   console.log(id);

}
compare( a, b ) {
  if ( a.fecha < b.fecha ){
    
    return -1;
  }
  if ( a.fecha > b.fecha ){
    return 1;
  }
  return 0;
}
loadquestions(){ 
  var date=new Date();
 
  var dia=date.getDate();
  var mes=date.getMonth()+1;
  var ano=date.getFullYear();
  this.eventService.preguntas(dia,mes,ano)
 .then((response) => {
   console.log(response);
   var preguntas=response["results"]
   preguntas.forEach(element => {
     //console.log(element);
     var date= new Date(element.time);
     element.fecha=element.time;
var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
element.time=fecha;
   })
this.questions=preguntas
this.questions.sort( this.compare );
this.questions.reverse()
 })
 .catch((err)=>{

 })
}
newitem(){
  var now= new Date();
  var n = now.getUTCHours();
  if(n<5){
    n=24+n; 
  } 
 now.setHours(n-5);  
  this.bitaForm.patchValue({
    time: now  
  });

  this.authService.newitem(this.bitaForm.value,this.user)
  .then((response)=>{
    this.loadquestions();
    this.bitaForm.patchValue({
      question:""  
    });
  })


}
  
  


}
