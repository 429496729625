import { Component, OnInit, ElementRef } from '@angular/core';
import { ReservaService } from '../../services/reservas.services'
import { AuthService } from '../../services/auth.service'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute, ChildActivationEnd } from '@angular/router';
import Swal from 'sweetalert2';
import reservar from './reservar.json';

import { Location } from '@angular/common';

@Component({
  selector: 'app-reservar',
  templateUrl: './reservar.component.html',
  styleUrls: ['./reservar.component.css']
})
export class ReservarComponent implements OnInit {

  user: any;
  dias = false;
  diaseleccion: any;
  manana: any
  hoy: any
  field: any
  fullname: any
  type: any;
  reservasuser: any = []
  horas: any
  types = reservar
  dia: any = ""
  userscategoria: any
  reservas: any
  poplector = false
  idr;
  horaactual;
  todoeldia = false;
  validaB = 1;
  beneficiarios;
validMisb = false;

  constructor(private UserService: AuthService,
    private elRef: ElementRef,
    private router: Router, private route: ActivatedRoute, 
    private api: ReservaService, private datePipe: DatePipe,
    private location: Location) { }

  ngOnInit(): void {

    this.type = this.route.snapshot.paramMap.get("type")
    console.log("TYPE", this.type)

    this.horas = this.types[this.type].horas

    var todayDate = new Date();

    this.hoy = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.horaactual = todayDate.getHours()



    console.log("HORA", this.horaactual)

    todayDate.setDate(todayDate.getDate() + 1);
    this.manana = this.datePipe.transform(todayDate, 'yyyy-MM-dd');

    console.log("HOY", this.hoy, "MAÑANA", this.manana)

    let user = localStorage.getItem('userInfo')
    this.user = JSON.parse(user).user;

    console.log("USER", this.user)

    this.fullname = this.user.name + " " + this.user.apellido

    console.log("DATA", this.manana, this.user.id, this.type)


    this.api.getReservasUser('undefined', this.user.id, this.type).then((r: any) => {
      console.log("RESERVA USER", r)

      r.map((re) => {
        console.log(re)
        if (re.date == this.manana || re.date == this.hoy) {
          this.reservasuser.push(re)
        }
      })
    })



    if (this.types[this.type].users) {
      console.log("TYPE", this.type)

      this.UserService.getUsuariosCategoria(this.type).then((r: any) => {
        console.log("CATEGORIA USERS", r)
        this.userscategoria = r
        this.types[this.type].fields = []

        this.userscategoria.map((i: any) => {
          this.types[this.type].fields.push({ "name": i.name + " " + i.apellido, "value": i.id })
        })

        console.log("USUA", this.types[this.type].fields)

      })
    }

  }


  beneficiariosSeleccionados: number = 0;

  beneficiarioSeleccionado: any;

  agregarBeneficiario(beneficiario: any) {
   
      this.beneficiarioSeleccionado = beneficiario;
      this.validaB = 2;
      // Realiza la lógica para agregar el beneficiario seleccionado
  
  }
  paraMi(){
    this.validaB = 2
  }
  cargarBene(){
      this.validaB = 3;
      this.UserService.getBenefits(this.user._id)
        .then((r) => {
          this.beneficiarios = r;
          console.log(this.beneficiarios)
        })
    
  }

  reservations(dia: any, field: any) {

    console.log("DIA", this.dia, "HOY", this.hoy)

    this.horas = this.types[this.type].horas

    let hor = []
    let h = 0;


     

    if(this.type != "ninera"){

      this.horas.map((re: any) => {

        console.log("HORAS", this.horaactual, re.hora24, this.dia, this.hoy )
       
  
        let h = parseInt(re.hora24.split(":")[0])
  
  
     
      if(this.dia == this.hoy){
        console.log("HOYYY")
        // if((this.horaactual < h) || this.user.rol=="admin" || this.user.rol=="areas"  )
        if(true)
        {
          hor.push(re)
        }
      }
        
        
  
      })
  
      console.log("H", h, hor)
  
  
  
      if(this.dia == this.hoy){
        console.log("HOR")
        this.horas = hor
      }

    }

    
    






    this.horas.map((re: any) => {
      re.reservado = false
      re.reservo = ""
      re.idreserva = ""
      re.ingresos = []
    })

    this.dia = dia

    console.log("DIA", dia, "HORAS", this.horas)

    this.api.getReservas(dia, this.field, this.type).then((r: any) => {

      console.log("RESERVAS", r)

      r.map((re: any) => {
        console.log(re.position)
        this.horas[re.position].reservado = true
        this.horas[re.position].reservo = re.userName
        this.horas[re.position].idreserva = re._id
        this.horas[re.position].ingresos = re.team
        this.horas[re.position].reserva = re
        if (re.stat == 0) {
          this.calCuenta(re)
        }
      })

      this.dias = true;

    })

  }
  copiar(txt) {

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(txt);
  }


  compareTime(str1, str2){
    if(str1 === str2){
        return 0;
    }
    var time1 = str1.split(':');
    var time2 = str2.split(':');
    if(eval(time1[0]) > eval(time2[0])){
        return 1;
    } else if(eval(time1[0]) == eval(time2[0]) && eval(time1[1]) > eval(time2[1])) {
        return 1;
    } else {
        return -1;
    }
}

  calCuenta(res) {
    console.log(res);

    const segundo = 1000,
      minuto = segundo * 60,
      hora = minuto * 60,
      dia = hora * 24;

    setInterval(() => {

      if (this.elRef.nativeElement.querySelector('#min' + res._id)) {
        let ahora = new Date().getTime();
        let cuenta = new Date(res.confirmDate).getTime();
        var distancia = cuenta - ahora;
        this.elRef.nativeElement.querySelector('#min' + res._id).innerText = (Math.floor((distancia % (hora)) / (minuto)) < 10 ? '0' : '') + Math.floor((distancia % (hora)) / (minuto));
        this.elRef.nativeElement.querySelector('#seg' + res._id).innerText = (Math.floor((distancia % (minuto)) / segundo) < 10 ? '0' : '') + Math.floor((distancia % (minuto)) / segundo);
        if (distancia <= 0) {
          this.reservations(this.dia, this.field)
        }
      }

    }, segundo)

  }

  regresar(){
    this.validaB = 1;
    this.beneficiarioSeleccionado = [];
    location.reload();


  }


  reservar(ind: any, hora: any) {
    let user = this.beneficiarioSeleccionado ? this.beneficiarioSeleccionado : this.user;

    console.log(hora, ind, this.types[this.type].horas.length)

    this.api.reservar(this.dia, hora, user.id, user.name + " " + user.apellido, ind, this.field, this.type, this.types[this.type].fieldtype, user.rol, user._id).then((r: any) => {
      console.log(r)

      if (r.estado == "reserva") {

        Swal.fire("Ya tiene una reserva para este día")
          .then(() => {

          })
      }
      else {
        this.horas[r.position].reservado = true
        this.horas[r.position].reservo = r.userName
        this.horas[r.position].idreserva = r._id
        this.horas[r.position].ingresos = r.team
        this.horas[r.position].reserva = r
        if (r.stat == 0) {
          this.calCuenta(r)
        }
      }


    })

  }


  datechange(e: any) {

    console.log("DATE",e.target.value)

    this.dia = e.target.value

    if (this.field != "") {
      this.reservations(this.dia, this.field)
    }


    this.api.getReservas(this.dia, this.field, this.type).then((r: any) => {

      console.log("RESERVAS", r)
      this.reservas = r

    })

  }


  typechange(e: any) {

    console.log("TYPE", e.target.value)
    this.field = e.target.value
    this.reservations(this.dia, this.field)

  }


  daychange(e: any) {

    console.log("DAY", e.target.value, this.hoy, this.type)
    this.dia = e.target.value

    if (this.types[this.type].fields.length == 1) {
      console.log(this.types[this.type].fields[0].value)
      this.field = this.types[this.type].fields[0].value
      // this.reservations(this.dia, this.types[this.type].fields[0].value)
    }


  }

  cancelar(id: any, i: any) {

    console.log(id)
    this.api.cancelar(id).then((r) => {
      console.log(r)

      this.horas[i].reservado = false
      this.horas[i].reservo = ""
      this.horas[i].idreserva = ""
      this.horas[i].ingresos = []


    })

  }

  reservalector(id: any, i: any) {
    console.log("IDRes", id, i)
    this.poplector = true
    this.idr = id
    //this.router.navigate(['/reservalector', { 'idr': id }]);
  }

  reservar_todo_el_dia() {
    this.types[this.type].horas.map((r, index) => {
      console.log(r.hora, index)
      this.reservar(index, r.hora)
    })

  }

}

