import { Component, OnInit,ElementRef, Renderer2,ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2';  
@Component({
  selector: 'app-register-canjes',
  templateUrl: './register-canjes.component.html',
  styleUrls: ['./register-canjes.component.css']
})
export class RegisterCanjesComponent implements OnInit {

  user;
  submitted
  foto1Url: any;
  fotovalidate: boolean;
  fotop: boolean;
  videoWidth = 0;
videoHeight = 0;
ingresoForm;
loading: boolean = false;
clubes=[
  "CLUB CAMPESTRE DE  IBAGUÉ",
  "CORPORACION CENTRO MANIZALES",
  "CLUB CAMPESTRE DE  NEIVA",
  "CLUB CAMPESTRE DE  PEREIRA",
  "CLUB CAMPESTRE DE ARMENIA",
  "CLUB CAMPESTRE DE BUCARAMANGA.",
  "CLUB CAMPESTRE DE CARTAGENA",
  "CLUB CAMPESTRE DE  MEDELLIN",
  "CLUB CAMPESTRE DE POPAYÁN",
  "CLUB CAMPESTRE EL RODEO  ",
  "CLUB LAGOS DE CAUJARAL DE BARRANQUILLA",
  "CORPORACION CLUB MONTERIA JARAGUAY GOLF",
  "CORPORACION COUNTRY CLUB DE EJECUTIVOS MEDELLIN",
  "COUNTRY CLUB DE BARRANQUILLA",
  "FARALLONES CLUB CAMPESTRE DE CALI",
  "RUITOQUE GOLF COUNTRY CLUB S.A.",
  "CORPORACIÓN RECREATIVA TENNIS GOLF CLUB ",
 "CLUB EL BOSQUE",
  "CLUB CAMPESTRE POTRERILLO",
  "CLUB COLOMBO LIBANÉS",
  "CLUB DE BANQUEROS Y EMPRESARIOS",
  "CLUB MILITAR DE GOLF",
  "CLUB LOS BÚHOS",
  "CONDOMINIO CAMPESTRE EL PEÑON",
  "LOS PINOS POLO CLUB MOSQUERA",
  "CORPORACIÓN ATHENEUM WORLD TRADE CENTER CLUB",
  "JUNKO GOLF CLUB  ",
  "CLUB CAMPESTRE CUSCATLAN (SAN SALVADOR)",
  "SUMMIT GOLF &  RESORT S.A.",
  "HOROKO GOLF &  RESORT S.A.",
  "CITY UNIVERSITY CLUB LONDRES",
  "CLUB UNIÓN DE PANAMÁ",
  "IZCARAGUA COUNTRY CLUB",
  "LOS INKAS GOLF CLUB",
  "GUAYAQUIL COUNTRY CLUB ",
  "ARRAYANES COUNTRY CLUB",
  "DUBLIN COUNTRY CLUB",
  "CLUB DE GOLF DE PANAMÁ"

]

@ViewChild('video', { static: false }) videoElement: ElementRef;
@ViewChild('canvas', { static: false }) canvas: ElementRef;
  newRegister: boolean;
  registerForm: any;
  stream: any;
  habilitando: boolean;
  constructor(
    private authService: AuthService,
    private router:Router,
    private renderer: Renderer2,
    private usersService: AuthService,
  ) { }

  ngOnInit(): void {


    this.ingresoForm = new FormGroup({
   
      'doc': new FormControl('', [
        Validators.required,

      ]),

        });

    this.registerForm = new FormGroup({
      'name': new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      'apellido': new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      'correo': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'tipoid': new FormControl('', [
        Validators.required,
     
      ]),
      'dias': new FormControl('', [
        Validators.required,
        Validators.max(30)
     
      ]),
      'nacimiento': new FormControl('', [
        Validators.required,     
      ]),
      'sexo': new FormControl('', [
        Validators.required,     
      ]),
      "clubcanje":new FormControl('', [
        Validators.required,     
      ]),
      'id': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
      'empresa': new FormControl('', [
    
  
      ]),
      'cargo': new FormControl('', [ ]),

      'habilitado': new FormControl('', [ ]),

      'telefono': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
       'arl': new FormControl('', [
     
  
      ]),
      'eps': new FormControl('', [
  
  
      ]),
   
      'rol': new FormControl('usuario', [
        Validators.required,
    
      ]),
      'tipo': new FormControl('persona', [
        Validators.required,
    
      ]),
      'categoria': new FormControl('socio', [
        Validators.required,
    
      ]),
      'aprobe': new FormControl(false, [
        Validators.required,
    
      ]),
    
    

    });
  }


  habilitarCanje(){
    this.registerForm.patchValue(this.user)
    this.habilitando=true
  }
  sednDias(){
    this.submitted = true;
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
let time = d.getTime();
const minute = 1000 * 60;
const hour = minute * 60;
const day = hour * 24;
    var habilitado={"inicio":time,"fin":time+(day*this.registerForm.controls["dias"].value)}
    this.registerForm.patchValue({
      rol:"usuario",
      tipo:"persona",
      categoria:"canje",
      habilitado:habilitado,
    })
    console.log(this.registerForm.controls);
   
    if (this.registerForm.invalid) {
      console.log("no enviado ");
      return;
  }
//enviar formulario

this.authService.createPerson(this.registerForm.value,this.foto1Url)
.then((response) => {
  console.log(response);
if(response["user"].aprobe){
this.user=response["user"]
this.habilitando=false;
this.registerForm.reset();
this.usersService.validatep(this.user.id)
.then((r:any)=>{
  console.log("invi",r)

  this.user=r.user
  this.findHorarios();
})
}
else{
Swal.fire("Usuario cargado correctamente")
this.newRegister=false;
}



 // this.completo=true;
})
.catch((err)=>{
  // this.router.navigate(['registerp']);
  Swal.fire("Error registrando usuario")
})


  }

  get f4() { return this.ingresoForm.controls; }
  get f() { return this.registerForm.controls; }
  findPerson(){
    if(this.ingresoForm.invalid){
      return
    }

    this.usersService.validatep(this.ingresoForm.controls["doc"].value)
    .then((r:any)=>{
      console.log("invi",r)
  
      this.user=r.user
      this.findHorarios();
    })
    .catch((err)=>{
      console.log("invi",err)
      this.newRegister=true;    
      this.registerForm.patchValue({id:this.ingresoForm.controls["doc"].value})
      this.ingresoForm.reset();
      this.activeVideo();
      
    })
  }
  

  findHorarios(){
    const d = new Date();
    var ano=d.getFullYear();
    let time = d.getTime();
    var diasCanje=this.user.diasCanje
    const anos=diasCanje.map((el)=>{return el.ano});
    var diasCanje=this.user.diasCanje
    if(diasCanje[anos.indexOf(ano)]){
      this.user.diasTomados=diasCanje[anos.indexOf(ano)].dias
     
    }
    else{
      this.user.diasTomados=0;
    }
    this.registerForm.get("dias").setValidators([Validators.max(30-this.user.diasTomados)])
    this.registerForm.get('dias').updateValueAndValidity();
    if(this.user.categoria=="canje"){

      if(this.user.habilitado.fin>time){
        this.user.canjeHabil=true
      }
      else{
        this.user.canjeHabil=false
      }
    }




  }



  registerp(){
    this.submitted = true;
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
let time = d.getTime();
const minute = 1000 * 60;
const hour = minute * 60;
const day = hour * 24;
    var habilitado={"inicio":time,"fin":time+(day*this.registerForm.controls["dias"].value)}
    this.registerForm.patchValue({
      rol:"usuario",
      tipo:"persona",
      categoria:"canje",
      habilitado:habilitado,
      aprobe:false
    })
    console.log(this.registerForm.controls);
   
    if (this.registerForm.invalid) {
      console.log("no enviado ");
      return;
  }
  console.log("enviado ");
    this.authService.createPerson(this.registerForm.value,this.foto1Url)
    .then((response) => {
      console.log(response);
if(response["user"].aprobe){
  this.authService.setUserInfo({'user' : response['user']});
  this.router.navigate(['agenda']);
}

else{
  Swal.fire("Usuario cargado correctamente")
  this.router.navigate(['login']);
}


 
     // this.completo=true;
    })
    .catch((err)=>{
      // this.router.navigate(['registerp']);
      Swal.fire("Error registradno usuario")
  })
  
  }
  
//control camara
handleError(error) {
  console.log('Error: ', error);
}

attachVideo(stream) {
  this.stream=stream;
  this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
  this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
    this.videoHeight = this.videoElement.nativeElement.videoHeight;
    this.videoWidth = this.videoElement.nativeElement.videoWidth;
});

}

activeVideo(){
     
      var  constraints = {
        video: {
            
            width: { ideal: 1280 },
            height: { ideal: 720 }
        }
    };
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
      navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
         } else {
             alert('Sorry, camera not available.');
         }



}

tomar(){
  this.loading = true;
  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
  this.authService.sendfoto(data)
  .then((response)=>{
    console.log(response["url"]);
    this.foto1Url=response["url"];
    this.loading=false;
    this.fotop=true;
  })
  .catch((err)=>{
alert("no encontramos tu rostro en la foto");
this.loading=false;

  })

}
}
