<div  class="row justify-content-center">
    <div class="col-11"   >
        <h2>Visitas: </h2>
        <div class=" row justify-content-center " >
            <div class="col-3 m-2 caja-datos" *ngFor="let user of visitas"  >
                <span style="color: black;" >{{user.fechat}}</span><br>
                <span class="text" > Evento: {{user.eventname}}</span>
               
                <br>
                <span class="text" > Descripción: {{user.descripcion}}</span>
                <span *ngFor="let persona of user.personas" >
                    {{persona.name}} {{persona.apellido}}    <br> 
                    <span *ngIf="persona.aprobe3" > Aprobado <br> </span>
                <span *ngIf="!persona.aprobe3" > No aprobado <br> </span>
                <span *ngIf="persona.ingreso" > Ya registro ingreso <br> </span>
                <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
               
                <hr>
                </span>
                <a href="/aprobar3/{{user._id}}" target="_blank" rel="noopener noreferrer">
                <button class="btn btn-primary" >Aprobar</button>
                </a>
            </div>
        </div>
    </div>

</div>