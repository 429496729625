
    <div style="padding: 5px; text-align: center;">
        <h1 style="color: white" >Bienvenido {{user.name}} </h1> 
        <h3 style="color: white" *ngIf="event"  >{{event.name}}</h3>
  
     
          
          <div class="row justify-content-center" *ngIf="event">
           
            <div class="col-1">
              <img (click)="cambiarCam()" width="100%" src="/assets/switch.png" alt="">
              </div>
              <div *ngIf="session"  class=" col-md-9  ">
                
                <div  id="session" width="98%" style="margin-top: 0; height:450px ; text-align: left !important;">
              
                  <opv-session #ovSessionComponent [sessionName]="event.name" [user]="user.name" [tokens]="tokens" [ovSettings]="ovSettings"
                    (sessionCreated)="handlerSessionCreatedEvent($event)"  (publisherCreated)="handlerPublisherCreatedEvent($event)" (participantCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)">
                  </opv-session>
              </div> 
      
              </div>
              <div class="col-1">
               
                </div>
 
          </div>
       
          <br>
          <br>
    
        </div>

       