import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.css']
})
export class CuentaComponent implements OnInit {
url1;
url2;
user;
  constructor(private authService : AuthService,) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(this.user);
    this.authService.geturl()
    .then((response)=>{
      console.log(response);
      this.url1 =response["url1"];
      this.url2 =response["url2"];
    })
  }

}
