import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'


@Injectable({
    providedIn: 'root'
})
export class ReservaService {

    url = environment.apiUrl
    constructor(private http: HttpClient) { }

    public isAuthenticated(): Boolean {
        let userData = localStorage.getItem('userInfo')
        if (userData && JSON.parse(userData)) {
            return true;
        }
        return false;
    }

    public getReservas(dia:any, field:any, type:any ) {
        return this.http.get(this.url + '/reservas/byday/'+dia+'/'+field+'/'+type).toPromise()
    }

    

    public getReservasUser(dia:any, userid:any, type:any ) {
        return this.http.get(this.url + '/reservas/byuser/'+userid+'/'+dia+'/'+type).toPromise()
    }

    public getReservasField(field:any ) {
        return this.http.get(this.url + '/reservas/byfield/'+field).toPromise()
    }

    public addToReserva(reserva,user){
        return this.http.post(this.url+"/reservas/addtoreserva",{reserva,user}).toPromise()
    }

    public reservar(dia:any, hora:any, userid:any, username:any, position:any, field:any, type:any, fieldtype:any, rol:any,_id:any) {
        return this.http.post(this.url + '/reservas/', {"dia": dia, "hora":hora, "userid":userid, "username":username, "position":position, "field":field, "type":type, "fieldtype": fieldtype, "rol":rol,"_id":_id}).toPromise()
    }

   
    public cancelar(id:any) {
        return this.http.delete(this.url + '/reservas/'+id).toPromise()
    }
    public getreserva(id:any) {
        return this.http.get(this.url + '/reservas/'+id).toPromise()
    }
    
    public codigoreserva(userid:any, reservaid:any, comentario:any) {
        return this.http.post(this.url + '/reservas/code', {"reservaid": reservaid,  "userid":userid,  "comentario":comentario}).toPromise()
    }

   




    public infouser(userid:any) {
        return this.http.post(this.url + '/usuarios/loaduserbyid/', {"id":userid}).toPromise()
    }

    public infousercedula(cedula:any) {
        return this.http.post(this.url + '/usuarios/loaduserbycedula/', {"cedula":cedula}).toPromise()
    }



     
 
}
