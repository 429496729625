import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';

import { ChangeDetectorRef } from '@angular/core'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-solcitud-canje',
  templateUrl: './solcitud-canje.component.html',
  styleUrls: ['./solcitud-canje.component.css']
})
export class SolcitudCanjeComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private eventService: EventsService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,

  ) { }
  user;
canjes;
  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;

    this.loaduser();
  }

  loaduser(){
    this.authService.validatep(this.user.id)
    .then((response) => {
      console.log(response);
      this.authService.setUserInfo({'user' : response['user']});
      let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    this.loadSolicitudes();
    })
       
  }

  loadSolicitudes(){

    function compare(a, b) {
    var aDate=new Date(a.createddate);
    var bDate=new Date(b.createddate);
      if (aDate < bDate) {

        return 1;
      }
      if (aDate > bDate) {
        return -1;
      }
      return 0;
    }
this.authService.getCanjes()
.then((r)=>{
  console.log(r)
  this.canjes=r;
  this.canjes.sort(compare)
})
  }

}
