<img src="../assets/logomovi.png" alt="" style="position: absolute; bottom: 10px; left: 5px; width: 25%; height: auto; background-color: white;border-radius: 15px; padding: 10px; ">

<div  *ngIf="!rperson" class="row mt-4 justify-content-center" >
<div class="col-md-6" *ngIf="!vis" >
<form  *ngIf="!load"  [formGroup]="ipersonForm" (ngSubmit)="ingresop()">
    <div class="form-group">
        <h1>Validación de ingreso</h1>
        <input class="form-control" type="text" (change)="changeInput($event)" placeholder="número de identificación" formControlName="id" [ngClass]="{ 'is-invalid': submitted && f4.id.errors }">
        <div *ngIf="submitted && f4.id.errors" class="invalid-feedback ">
            <div *ngIf="f4.id.errors.required">ingrese su documento</div>
           
        </div> 
    </div>
    <input type="submit" value="Ingresar" class="btn btn-info">

</form>
</div>
<div class="row mt-4 justify-content-center" *ngIf="vis && !yaingreso" >


<div class="col-md-5"   >
   
    </div>
    <div class="col-md-6" *ngIf="!finish" >
        <div class="container  bg-dark  p-2 rounded-top shadow text-white" *ngIf="!fotop"  >
            <video  playsinline   playsinline (click)="tomarregistro()" width="90%" height="225" autoplay #video style="transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */" ></video> <br>
           
            <canvas #canvas style="display:none; "></canvas>
            </div>
            <br>
            <h2>Al ingresar y tomarse la fotografía esta aceptando nuestra política de tratamiento de datos </h2>
<button   class="btn btn-primary" *ngIf="!loading"  (click)="tomarregistro()" >Validar</button>
<h2  *ngIf="loading"  >Cargando...</h2>
    </div>
    <div class="col-md-6" *ngIf="finish" >
<h1>
    ingreso validado
</h1>
<img class="card-img-top" src={{foto}} width="25%" style="border-radius:20% 20% 0 0 ;  " alt="">

   <button  class="btn btn-primary" (click)="terminar()"  >Terminar</button>
    </div>
</div>
<div class="row mt-4 justify-content-center" *ngIf="yaingreso && !validatemanilla" >
 
    <div class="col-md-6 col-10"  > 
<h1 style="color: white;" >
    Ya valido su ingreso para esta cita
</h1>
<img src="{{user.photo1}}" width="50%" alt="">
<h2>Nombre: {{user.name}} {{user.apellido}}</h2>
<h2>EPS: {{user.eps}}</h2>
<h2>ARL: {{user.arl}}</h2>
<h2 *ngIf="visita!='funcionario'" >Nivel de acceso: {{visita.acceso}}</h2>
<h2 *ngIf="visita!='funcionario'" >Descripción: {{visita.descripcion}}</h2>
<h2   *ngIf="visita.empresa && visita.empresa!='independiente'" >Empresa: {{empresa.name}}</h2>
<h2 >Evento: {{visita.eventname}}</h2>
<h3 *ngIf="yamanilla" >Manilla asignada.</h3>

<button  class="btn btn-primary"*ngIf="yamanilla || visita=='funcionario' "   (click)="terminar()"  >Terminar</button>
<button  class="btn btn-primary"  *ngIf="!yamanilla"   (click)="validate()" >Asignar manilla</button>
  
    </div> 
</div>
<div  *ngIf="validatemanilla" >
    <div  class="row justify-content-center">
        <div class="col-md-9 col-11">
            <app-addmanilla (newManilla)="onNewManilla($event)" ></app-addmanilla>
        </div>

    </div>

</div>


</div>

<div class="row justify-content-center m-4" *ngIf="rperson">
    <div class="col-md-6">

        <div class="container  bg-dark  p-2 rounded-top shadow text-white" *ngIf="!fotop">
            <video  playsinline  width="90%" height="225" autoplay #video style="transform: rotateY(180deg);
            -webkit-transform:rotateY(180deg); /* Safari and Chrome */
            -moz-transform:rotateY(180deg); /* Firefox */" ></video> <br> 
            <h2>Al ingresar y tomarse la fotografía esta aceptando nuestra política de tratamiento de datos </h2>
            <button (click)="tomar()" *ngIf="!fotovalidate  && !loading" class="btn btn-primary">Tomar fotografía</button>
            <h2 *ngIf="loading" >Cargando...</h2>
            <!-- <button (click)="tomarregistro()" *ngIf="fotovalidate" class="btn btn-primary">Tomar foto</button> -->

            <canvas #canvas style="display:none; "></canvas>
        </div>

        <form [formGroup]="rpersonForm" (ngSubmit)="registerp()" *ngIf="fotop" class="">


            <div class="row">
                <div class="col-11 col-md-6">
                    <div class="form-group">
                        <h3>Nombres:</h3>
                        <input type="text" class="form-control" placeholder="Nombre" formControlName="name"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Ingrese su nombre</div>
                            <div *ngIf="f.name.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                        </div>
                    </div>
                </div>
                <div class="col-11 col-md-6">
                    <div class="form-group">
                        <h3>Apellidos:</h3>
                        <input type="text" class="form-control" placeholder="Apellido" formControlName="apellido"
                            [ngClass]="{ 'is-invalid': submitted && f.apellido.errors }">
                        <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
                            <div *ngIf="f.apellido.errors.required">Ingrese sus apellidos</div>
                            <div *ngIf="f.apellido.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-11 col-md-6">
                    <div class="form-group">
                        <h3>Correo electronico:</h3>
                        <input class="form-control" type="email" placeholder="Correo electrónico"
                            formControlName="correo" [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                        <div *ngIf="submitted && f.correo.errors" class="invalid-feedback ">
                            <div *ngIf="f.correo.errors.required">ingrese su email</div>
                            <div *ngIf="f.correo.errors.email">ingrese un correo valido</div>
                        </div>
                    </div>
                </div>
                <div class="col-11 col-md-6">
                    <div class="form-group">
                        <h3>Documento de identidad:</h3>
                        <input class="form-control" type="number" disabled placeholder="Documento de identidad"
                            formControlName="id" [ngClass]="{ 'is-invalid': submitted && f.id.errors }">
                        <div *ngIf="submitted && f.id.errors" class="invalid-feedback ">
                            <div *ngIf="f.id.errors.required">ingrese su documento</div>
                            <div *ngIf="f.id.errors.min">ingrese un documento valido</div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-11 col-md-6">
                    <div class="form-group">
                        <h3>Celular:</h3>
                        <input class="form-control" type="number" placeholder="celular" formControlName="telefono"
                            [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }">
                        <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback ">
                            <div *ngIf="f.telefono.errors.required">ingrese su telefono</div>
                            <div *ngIf="f.telefono.errors.min">ingrese un telefono valido</div>
                        </div>
                    </div>
                </div>
           
            </div>


            <div class="row">
                <div class="col-11 col-md-6">
                    <div class="form-group">
                        <h3>Cargo</h3>
                        <input type="text" class="form-control" placeholder="Cargo" formControlName="cargo"
                            [ngClass]="{ 'is-invalid': submitted && f.cargo.errors }">
                        <div *ngIf="submitted && f.cargo.errors" class="invalid-feedback">
                            <div *ngIf="f.cargo.errors.required">Campo requerido</div>
                        </div>
                    </div>
                </div>
                <div class="col-11 col-md-6">
                    <div class="form-group">
                        <h3>ARL</h3>
                        <input type="text" class="form-control" placeholder="ARL" formControlName="arl"
                            [ngClass]="{ 'is-invalid': submitted && f.arl.errors }">
                        <div *ngIf="submitted && f.arl.errors" class="invalid-feedback">
                            <div *ngIf="f.arl.errors.required">Campo requerido</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-11 col-md-6">
                    <div class="form-group">
                        <h3>EPS</h3>
                        <input type="text" class="form-control" placeholder="EPS" formControlName="eps"
                            [ngClass]="{ 'is-invalid': submitted && f.eps.errors }">
                        <div *ngIf="submitted && f.eps.errors" class="invalid-feedback">
                            <div *ngIf="f.eps.errors.required">Campo requerido</div>
                        </div>
                    </div>
                </div>
                <div class="col-11 col-md-6">
                    <div class="form-group">
                        <h3>Contacto de emergencia</h3>
                        <input type="text" class="form-control" placeholder="Contacto" formControlName="contacto"
                            [ngClass]="{ 'is-invalid': submitted && f.contacto.errors }">
                        <div *ngIf="submitted && f.contacto.errors" class="invalid-feedback">
                            <div *ngIf="f.contacto.errors.required">Campo requerido</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-11 col-md-6">
                    <div class="form-group">
                        <h3>Celular del contacto</h3>
                        <input type="number" class="form-control" placeholder="celular" formControlName="contactotel"
                            [ngClass]="{ 'is-invalid': submitted && f.contactotel.errors }">
                        <div *ngIf="submitted && f.contactotel.errors" class="invalid-feedback">
                            <div *ngIf="f.contactotel.errors.required">Campo requerido</div>
                        </div>
                    </div>
                </div>
                <div class="col-11 col-md-6">

                </div>
            </div>

            

            <input type="submit" value="Ingresar" class="btn btn-boton2">
        </form>
    </div>

</div>