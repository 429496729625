<div   class="row mt-5 justify-content-center" >
 
    <div class="col-md-5 caja-form2"  style=" margin-bottom: 20px;" >
       
        <h1>Ingresos en un rango de fechas</h1>
        <div class="m-2 " style="text-align: center;color: white;"  >
            <mat-form-field appearance="fill"  >
                <mat-label  style="color: white;" >Seleccione el rango de fechas</mat-label>
                <mat-date-range-input  [formGroup]="range" [rangePicker]="picker">
                  <input (dateInput)="addEvent('input', $event)" matStartDate formControlName="start" placeholder="Start date">
                  <input (dateInput)="addEvent('input', $event)" matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-hint style="color: white;">MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
        </div>
       
          <br>
          <!-- <p>Selected range: {{range.value | json}}</p> -->
          <div *ngIf="initdate" style="color: white; margin:10px ;" >
              <span  style="font-size: 21px;" >Rango seleccionado: {{initdate}} - {{enddate}}</span>
              <br>
              <button class="btn btn-primary" (click)="newFechaRange()" >Buscar ingresos en este rango</button>
              <br>
         
        
          </div>
      
    </div>

</div>