import { Component, OnInit,ElementRef, Renderer2  } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators , FormArray} from '@angular/forms';
import { interval } from 'rxjs';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 

@Component({
  selector: 'app-panelempresa',
  templateUrl: './panelempresa.component.html',
  styleUrls: ['./panelempresa.component.css']
})
export class PanelempresaComponent implements OnInit {
  rpersonForm;

  user;
  subird;
  docForm;
  deletedoc;
  visitapop
  visitaForm;
  submitted = false;
    visitas: any;
  foto1Url="nofoto";
  registerurl="";
  personasc=[];
  eventos: any;
  cargador;
  cargador2: any=false;
  file: boolean;
  idrperson;
  csvRecords: any[] = [];
  header = false;
  luser: any;
  nuser: boolean;
  mensajes: any;
  registrar=false;
  vmensajes;
  nuevos: number;
  cupo: any;
  agregados=[];
  validcionPersonas: boolean;
  validando: boolean;
  nnuser: number;
  ruser: number;
    constructor(
      private elRef:ElementRef,
      private authService : AuthService,
       private eventService : EventsService,
        private router: Router,
         private cd: ChangeDetectorRef,
         private ngxCsvParser: NgxCsvParser,
    ) { }
  
    ngOnInit(): void {
      let user = localStorage.getItem('userInfo')
      this.user= JSON.parse(user).user;
console.log(user);
this.lookConver();
this.loadEmpresa();
this.registerurl="https://controldevisitas.movistararena.co/register/"+this.user._id;
this.getEventosVinculados();
this.idrperson= new FormGroup({
  'id': new FormControl('', [
    Validators.required,
    Validators.min(1111)
  ]),
})
this.rpersonForm = new FormGroup({
  'name': new FormControl('', [
    Validators.required,
    Validators.minLength(1)
  ]),
  'apellido': new FormControl('', [
    Validators.required,
    Validators.minLength(1)
  ]),
  'correo': new FormControl('', [
    Validators.required,
    Validators.email
  ]),
  'id': new FormControl('', [
    Validators.required,
    Validators.min(1111)
  ]),
  'empresa': new FormControl(this.user._id, [
    Validators.required,

  ]),
  'cargo': new FormControl('', [
    Validators.required,

  ]),

  'telefono': new FormControl('', [
    Validators.required,
    Validators.min(1111)
  ]),
   'arl': new FormControl('', [
    Validators.required,

  ]),
  'eps': new FormControl('', [
    Validators.required,

  ]),
  'contacto': new FormControl('', [
    Validators.required,

  ]),
  'contactotel': new FormControl('', [
    Validators.required,

  ]),



});

     


      this.loadvisitas();
  this.visitaForm= new FormGroup({
    'eventid': new FormControl('', [
      Validators.required,
     
    ]),
    'eventname': new FormControl('', [
      Validators.required,
     
    ]),
    'tipo': new FormControl('', [
      Validators.required,
     
    ]),
    'descripcion': new FormControl('', [
       
    ]),
    "area":new FormControl('', [
      Validators.required,   
    ]),
    'date': new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    'personas': new FormControl([], [
       
    ]),
    'dia': new FormControl('', [   ]),
    'mes': new FormControl('', [   ]),
    'ano': new FormControl('', [   ]),
  })
  
      this.docForm= new FormGroup({
  
        "doc_pdf":new FormControl(''),
        "id":new FormControl(''),
        "tipo":new FormControl(''),
      
      })
      this.deletedoc= new FormGroup({
  
        "url":new FormControl(''),
        "id":new FormControl(''),
        "tipo":new FormControl(''),
      
      })
//this.loadpersonas();



    }

    onNewConversations(conver){
      console.log("evento: ",conver);
      this.mensajes=conver;
      this.nuevos=0;
      this.mensajes.forEach(element => {
        if(element.new!=""&& element.new!=this.user.nit){
        this.nuevos+=1;
        }
      });
    }

 
lookConver(){
  this.authService.lookConver(this.user.nit)
.then((response)=>{
console.log(response);
this.mensajes=response["mensajes"];
this.nuevos=0;
this.mensajes.forEach(element => {
  if(element.new!=""&& element.new!=this.user.nit){
  this.nuevos+=1;
  }
});

})
.catch((err)=>{
  console.log(err)
})

}

    onActive(agreed: boolean) {
      this.cargador = false;
      this.loadEmpresa();
  
      //this.ngOnInit();
    }

lookPerson(){
  var id=this.idrperson.controls["id"].value;
  if(id){
    this.authService.validatep(id)
    .then((response)=>{
   console.log(response);
   this.luser=response["user"];
   this.nuser=true;
    })
    .catch((err)=>{
      console.log(err);
      Swal.fire("No se encuentra el usuario")
    })

    
  }
}
vincular(){
  this.authService.addPerson(this.user._id,this.luser.id)
  .then((response)=>{
    this.nuser=false;
this.ngOnInit();
  })
  .catch((err)=>{
    console.log(err);
  })
}

copiar(){
 
   /* Copy the text inside the text field */
  navigator.clipboard.writeText(this.registerurl);
}
 
 createFormaray(Inputs) {
  
    const arr = Inputs.map(marca => {
      return new FormControl(marca.selected || false);
    });
    return new FormArray(arr);
  }

    loadpersonas(){
      this.findDuplicatePersons()
    //   this.user.personas.forEach(id => {
    //     this.authService.validatep(id)
    //     .then((response)=>{
    //          this.personasc.push(response["user"]);
    //     })
    //     .catch((err)=>{
    // this.authService.eliminarPersonadeempresa(id,this.user.nit)
    // .then(()=>{
    //   console.log("borrado")
    // })
    //     }) 
    //     });
   
      
      }

      verifyPersonas(){
            this.user.personas.forEach(id => {
        this.authService.validatep(id)
        .then((response)=>{
             this.personasc.push(response["user"]);
        })
        .catch((err)=>{
    this.authService.eliminarPersonadeempresa(id,this.user.nit)
    .then(()=>{
      console.log("borrado")
    })
        }) 
        });
      }

findDuplicatePersons(){
  const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] == sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  }
var duplicateElementa = findDuplicates(this.user.personas);
console.log(duplicateElementa);
duplicateElementa.forEach(element => {
  this.authService.eliminarPersonadeempresa(element,this.user.nit)
    .then(()=>{
      console.log("borrado")
    })
});
}
      

    get f() { return this.visitaForm.controls; }
  
 
      

  loadvisitas(){
    this.authService.getvisitasc(this.user._id)
    .then((response)=>{
      console.log(response);
  this.visitas=response["visitas"];
  this.visitas.sort( this.compare );
  this.cargarhoras(this.visitas);
    })
    .catch((err)=>{
  
    })
  }

  cargarhoras(arr){
    arr.forEach(solicitud => {
      var date= new Date(solicitud.fecha);
       var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
       solicitud.fechat=fecha;
      solicitud.open=false;   
   
    }); 
  }
  compare( a, b ) {
    if ( a.fecha < b.fecha ){
      
      return -1;
    }
    if ( a.fecha > b.fecha ){
      return 1;
    }
    return 0;
  }
  
  getArea(event){
    if(event=="general"){
      return ["general","general"]
    }
    var select=this.eventos.filter((evento)=>{return evento._id==event})
    //console.log("select: ",personas);
  var personas=select[0].empresas;
  var event=select[0].nombre;
  //console.log("select: ",personas);
  var personas=  personas.filter(persona=>{return persona.id==this.user.nit})
  return [personas[0].area,event]
  }

  fileChangeListener($event: any): void {
    //this.visitaForm.get('personas')['controls'][0].value=true;
    
    this.file=true;
          // Select the files from the event
          const files = $event.srcElement.files;
      
          // Parse the file you want to select for the operation along with the configuration
          this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
            .pipe().subscribe((result: Array<any>) => {
      
            console.log('Result', result);
            this.csvRecords=result;
              this.marcar();
              
            }, (error: NgxCSVParserError) => {
              console.log('Error', error);
            });
      
        }
marcar(){

this.csvRecords.forEach(element => {
  try{

  //console.log("elemento",element[0]);
  var pp=this.personasc.map((e)=>{return e.id})
  var index=pp.indexOf(element[0]);
 // var index= this.personasc.
  //console.log("index ",index);
  //this.visitaForm.controls.personas[0]=true;

  this.visitaForm.controls["personasc"].at(index).patchValue(true);
  this.calcularPersonas();

 // console.log("controls ", this.visitaForm.get('personas')['controls'])
}
catch(err){}
});
}
// marcaruno(){
//   var uno=this.elRef.nativeElement.querySelector('#marcauno').value;
//   console.log(uno);
//   var pp=this.personasc.map((e)=>{return e.id})
//   var index=pp.indexOf(uno);
//   if(index==-1){
//     Swal.fire("No encontramos este número de cédula en el listado de personas vinculadas a tu empresa.","por favor realiza la vinculación")
//   }
//   this.visitaForm.controls["personasc"].at(index).patchValue(true);
//   this.elRef.nativeElement.querySelector('#marcauno').value="";
//   this.calcularPersonas();

// }



// calcularPersonas(){
//   this.agregados=this.visitaForm.controls.personasc['controls'].filter((el)=>{return el.value})
  

// }
validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
  
   async newVisita(){
   // console.log(this.visitaForm.controls.personas['controls']);
   this.submitted=true;
   console.log("controls:",this.f)
   if(this.visitaForm.controls["eventid"].value=="general" &&  this.visitaForm.controls["anfitrion"].value==""){
    Swal.fire("Por favor escriba a quien va a visitar ")
    .then(()=>{
      return
    })
   }
  //  else if(this.visitaForm.controls["eventid"].value=="general" &&  this.visitaForm.controls["anfitrion"].value!="" && !this.validateEmail(this.visitaForm.controls["anfitrion"].value) ){
  //   Swal.fire("El correo de la persona responsable no es valido ")
  //   .then(()=>{
  //     return
  //   })
  //  }
 else if(this.agregados.length==0){
   Swal.fire("No ha agregado personas a esta visita")
   .then(()=>{
     return
    })
 }
 else{

  var datos=await this.getArea(this.visitaForm.controls['eventid'].value); 
 
 console.log("date ",this.visitaForm.controls["date"].value)
 var date= new Date(this.visitaForm.controls["date"].value);
 //console.log(now.getDate()==date.getDate() && now.getMonth()==date.getMonth());
 var dia=date.getDate();
 var mes=date.getMonth()+1;
 var ano=date.getFullYear();
 
 
  this.visitaForm.patchValue({
   area:datos[0],
   eventname:datos[1],
   ano:ano,
   mes:mes,
   dia:dia
    })
   // console.log(this.visitaForm.value);
   // console.log("resultados: ",this.visitaForm.value);
   // return
  
   if(this.visitaForm.invalid){
     console.log("invalido",this.visitaForm.value);
     return
   }
         this.authService.newvisita(this.visitaForm.value)
     .then((response)=>{
      this.visitapop=false;
       console.log(response);
       this.loadvisitas();
     })
       .catch((err)=>{
      
     })
 }
     
  
    }

    get f2() { return this.rpersonForm.controls; }

    registerp(){
      this.submitted = true;
      console.log(this.rpersonForm.controls);
      if (this.rpersonForm.invalid) {
        console.log("no enviado ");
        return;
    }
    console.log("enviado ");
      this.authService.createPerson(this.rpersonForm.value,this.foto1Url)
      .then((response) => {
        
        this.authService.validatec(this.user.nit)
    .then((response)=>{
      console.log(response);
       this.authService.setUserInfo({'user' : response['user']});
      let user = localStorage.getItem('userInfo')
      this.user= JSON.parse(user).user;
      location.reload();
    })
    .catch((err)=>{
        
    })
       // this.completo=true;
      })
      .catch((err)=>{
        
    })
    
    }


    loadEmpresa(){
      this.authService.validatec(this.user.nit)
      .then((response)=>{
        console.log(response);
         this.authService.setUserInfo({'user' : response['user']});
        let user = localStorage.getItem('userInfo')
        this.user= JSON.parse(user).user;
        this.loadpersonas();
      })
      .catch((err)=>{
          
      })
    }

  visitapopopen(){
    this.validcionPersonas=false;
    this.visitaForm= new FormGroup({
      personasc:  new FormControl([], [
        
      ]),
      'eventid': new FormControl('', [
        Validators.required,
       
      ]),
      'eventname': new FormControl('', [
        Validators.required,
       
      ]),
      'tipo': new FormControl('', [
        Validators.required,
       
      ]), 
      'anfitrion': new FormControl('', [
          
      ]),
      'descripcion': new FormControl('', [
         
      ]),
      "area":new FormControl('', [
        Validators.required,   
      ]),
     
      'date': new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      'personas': new FormControl([], [
         
      ]),
      'dia': new FormControl('', [   ]),
      'mes': new FormControl('', [   ]),
      'ano': new FormControl('', [   ]),
      'placa': new FormControl('', [         
          ]),
      "empresa":new FormControl(this.user._id)
    })
   
    this.visitapop=true;
    this.agregados=[];
    //this.visitaForm.controls.personas[0]=true;
 //   console.log(this.visitaForm.get('personas')['controls']);
    // obtener el index de personasc y marcar control como true
    // this.visitaForm.get('personas')['controls'][0].value=true;

  }





  async getSelected(array,ref) {
    
    var selectedMarcas = array.map( (marcas, i) => {
      console.log(marcas.value);
      return marcas.value && {
        "id":ref[i].id,
        "name":ref[i].name+" "+ref[i].apellido,
     "ingreso":null 
     
        };
    });


   var selectedNames = selectedMarcas.filter( function (marca) {
    if (marca !== false) {
      return marca;
    }
  });
  console.log(selectedNames);
  return selectedNames;
  
  }
  
  eliminarVisita(id){
    console.log(id);
    this.authService.eliminarVisita(id)
    .then((response) => {
      this.loadvisitas();
  })
}
getEventosVinculados(){
  this.eventService.getEventosEmpresaa(this.user.nit)
  .then((response)=>{
    console.log(response)
    this.eventos=response["eventos"];
    //buscar cupos totales
  })
}

buscarcupos(event){
  
  if(event!="general" && event!=""){
    console.log("eventos: ",this.eventos);
    
    var evento =this.eventos.find(e=>e._id===event);
    console.log("buscad ",evento);
    var empresa=evento.empresas.find(e=>e.id===this.user.nit)
    this.cupo=empresa.cupo

  }

}


eliminar(id){
  console.log(id);
  this.authService.eliminarVisita(id)
  .then((response) => {
    this.ngOnInit();
})
}

validarPersonas(){
 this.validando=true;
  var length=this.visitaForm.controls.personasc.value.length;
  //console.log(this.visitaForm.controls.personasc.value);
  //console.log(this.visitaForm.controls.personas.value);
  var c=0;
  this.ruser=0;
  this.nnuser=0;
  var arr=this.visitaForm.controls.personasc.value;
  const arr_=this.visitaForm.controls.personasc.value;
 this.visitaForm.controls.personasc.value.forEach((element,i) => {     
 
     this.authService.validatep(element)
     .then((response) => {
       c+=1;
       this.ruser+=1;
       var user=response["user"]; 

       arr[i]={
         "id":element,
         "name":user.name+ " "+user.apellido,
      "ingreso":null,
     
      
       }
       if(c==length){
         this.validando=false;
         this.validcionPersonas=true;
 
   this.visitaForm.patchValue({
     personas :arr,
     personasc:arr_
      })
       }
     })
     .catch((err)=>{
      this.nnuser+=1;
       c+=1;
       arr[i]={
         "id":element,
         "name":null,
      "ingreso":null,
    
      
       }
       if(c==length){
        
         this.validcionPersonas=true;
   this.validando=false;
   this.visitaForm.patchValue({
     personas :arr,
     personasc:arr_
      })
       }
     })


 });
}
marcaruno(){
  var uno=this.elRef.nativeElement.querySelector('#marcauno').value;
 // console.log(uno)
 var uno=uno.split("\n")
uno.forEach((el)=>{
  el =el.replace(/[,.-\s]/g, '');
  if (this.visitaForm.controls.personasc.value.indexOf(el)==-1 && el!="" ) this.visitaForm.controls.personasc.value.push(el);
  
})

 //console.log(this.visitaForm.controls.personasc.value);
  this.calcularPersonas();
  this.elRef.nativeElement.querySelector('#marcauno').value="";
  if(this.validcionPersonas){
    this.validarPersonas();
  }

}

calcularPersonas(){
  this.agregados=this.visitaForm.controls.personasc.value
  

}
  
  }
  