<div style="padding: 30px; text-align: center;">
    <h3 style="color: white">Bienvenido {{user.name}}.</h3>
   
      <div class="row justify-content-center">
        <div class="container text-white col-md-5 col-10 " >
          
           <h3 style="color: white !important;" >Usuarios en linea: </h3>  
           <!-- {{existingUsrs?.length || '0' }} -->

            <ul>
              <div *ngFor="let usr of existingUsrs">                      
     <li (click)="openChatAdmin(usr.id)" *ngIf="usr.room == event._id"  >
     <h4   style="color: white !important; cursor: pointer; " id="li{{usr.id}}" >{{usr.name}} </h4>
     </li>
   </div>
         </ul>  
         </div>

        <div class="col-11 col-md-6">
          <div style="text-align: left;" *ngFor="let sala of salas" >
          <div >
           
            <div style="margin-top: 2px; padding: 20px; background-color: #eaeaea;  border-radius: 2px 2px 2px 2px">
              <div><strong> {{sala.name}}</strong></div>
              <a href="/sala/{{sala._id}}">
                <button class="btn btn-primary" > Ingresar </button></a> <br>
                <div class="container text-white col-md-5 col-10 " >
          
                  <!-- <h3>Usuarios en linea: {{existingUsrs?.length || '0' }} </h3>  -->
                  <ul>
                       <div *ngFor="let usr of existingUsrs">                      
              <li  *ngIf="usr.room == sala.name"  >
              <h4  style="color: black;"  >{{usr.name}} </h4>
              </li>
            </div>
                  </ul>  
               </div>
            </div>

           

          </div>

          <br>
        </div>




          <br>


  

        </div>
      </div>

    </div>



    <div  >
        <div *ngFor="let usr of existingUsrs" >
            <div id="chat{{usr.id}}" style="display: none;">
        <app-chat  [remoteUser]="usr" >
        </app-chat>
        <button (click)="closeChatAdmin(usr.id)" class="btn btn-danger btnChat " >Cerrar</button>
        <!-- <button (click)="inviteuser(usr)" class="btn btn-danger btnCall "  >Invitar</button> -->
        </div>
        </div>
        </div> 

<div class="container w-50 bg-dark p-2 rounded-top shadow text-white" *ngIf="user.rol=='admin'">
    <form [formGroup]="createSala" (ngSubmit)="newEvent()" novalidate="">    
        
       
         <div class="form-group">
         <h3>Ingrese el número de salas'</h3>     
         <input class="form-control" type="number" placeholder="Numero" formControlName="number" required>
         </div>
       
         <input type="submit" value="Guardar" class="btn btn-info">
    
    </form>
    </div>


    
