<div class="row ">
    <div class="col-11">
        <button (click)="borrarManillas()" >Borrar Manillas</button>
    </div>

</div>
<div class="row mt-4 justify-content-center" >

 <div class="col-md-11">
        <table>

        
        <cdk-virtual-scroll-viewport itemSize="30" style="width: 1200px; height: 600px;" > 
                
            <tr class="cajainfo" >
                <th>#</th>
                <th>Manilla</th>
                <th>Nombre</th>
                <th>Correo</th>
                <th>id</th>
        
                <!-- <th>Empresa</th> -->
                <th>cargo</th>    
                <th>Fecha</th>
            </tr>
     
            <tr *cdkVirtualFor="let usr of manillas let i = index"   class="cajainfo "   >
                <td>
                    {{i+1}}
                </td>
              <td>{{usr.manilla}}</td>
               
                <td style="cursor: pointer;" >{{usr.user.name}} {{usr.user.apellido}}</td>
                  <td>{{usr.user.correo}}</td>
                  <td>{{usr.user.id}}</td>
                  <!-- <td>{{usr.user.eps}}</td>
                  <td>{{usr.user.arl}}</td> -->
                  <!-- <th>{{usr.empresa}}</th> -->
                  <td>{{usr.user.cargo}}</td>
                    <td>{{usr.createddate}}</td>
            
           
            
            </tr>
       
       </cdk-virtual-scroll-viewport>
    </table>
    </div>
</div>