import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'


@Injectable({
    providedIn: 'root'
})
export class NotificaService {

    url = environment.apiUrl
    constructor(private http: HttpClient) { }

    public isAuthenticated(): Boolean {
        let userData = localStorage.getItem('userInfo')
        if (userData && JSON.parse(userData)) {
            return true;
        }
        return false;
    }

   


    public suscribirse(data){
        return this.http.post(this.url+"/notifica/suscribir",{data}).toPromise()
    }

   

     
 
}
