<h1>Votaciones</h1>


<div *ngFor="let question of questions" class="bg-white w-75 container" >
  <br>
  <br>
    <google-chart 
    [title]="question.title"
    [type]="type"
    [data]="question.data"
   [options]="options"
   [width]="width"
   [height]="height">
 </google-chart>
 <br>
<hr>

</div>
