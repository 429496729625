import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-manillas',
  templateUrl: './manillas.component.html',
  styleUrls: ['./manillas.component.css']
})
export class ManillasComponent implements OnInit {
  manillas: any;

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getManillas();
  }

  getManillas(){
    this.authService.dgetManillas()
    .then((result)=>{
      console.log(result);
      this.manillas=result["result"]
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  borrarManillas(){
    Swal.fire(     {
      title: "Vas a borrar todos los datos relacionados a las manillas ",
      text:"¿estas seguro?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: "Cancelar"
      
  }).then((result)=>{
   
  if(result.value){
  this.getManillas();
  }
  else{
   
  }
     });
  
  
  }

}
