import { Component, OnInit,Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import {SocketchatService} from "../../services/socketchat.service";
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import  Swal from 'sweetalert2'; 

@Component({
  selector: 'app-chat-publico',
  templateUrl: './chat-publico.component.html',
  styleUrls: ['./chat-publico.component.css']
})
export class ChatPublicoComponent implements OnInit {
 
  sub: Subscription;
  user; 
  id;
  
  chats=[];
  @Input() event ={"name":"Soporte","_id":"adminDefault"};
  constructor(private eventService : EventsService, 
    private route: ActivatedRoute, private router : Router,
    private socketservice: SocketchatService,
    private renderer: Renderer2,
    private elRef:ElementRef,
   
    ) { 

  } 

  
  ngOnInit(): void {
    
  
 
   const manage=this.manage;
    this.sub= this.socketservice.setupSocketConnection()
   .subscribe({
    next(_mesage) {
      //console.log('Mensage recibido: ', _mesage);
     manage(_mesage);  
    }
   });
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(user);
this.id=this.event._id;
this.joinRoom(this.user,this.event);
this.loadquestions(this.id);
setTimeout(()=>{    var elem = document.getElementById('chattable');
elem.scrollTop = elem.scrollHeight;},1000);
  }

  joinRoom(usr,event){
    try{
    this.socketservice.joinRoom(usr,event);}
    catch(err){console.log(err)}
    }
  manage=(msg)=>{
const reciveChat=(msg)=>{
  var date= new Date(msg.chat.time);
  var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  msg.chat.time=fecha;
this.chats.push(msg.chat);
setTimeout(()=>{var elem = document.getElementById('chattable');
elem.scrollTop = elem.scrollHeight;},1000);
} 

      var p_msg=JSON.parse(msg);
      console.log(p_msg);
    switch (p_msg.id){
      case "newChat":
          reciveChat(p_msg);
          
   
    }
  
  
      
    
    }

    sendChat(){
      var now= new Date();
      var n = now.getUTCHours();
      if(n<5){
        n=24+n; 
      } 
     now.setHours(n-5);
     console.log(now);
      console.log("enviando");
      var question=this.elRef.nativeElement.querySelector('#pregunta').value;
     this.elRef.nativeElement.querySelector('#pregunta').value="";
      var msg={
        "id":"sendChat",
        "chat":question,
        "event":this.id,
        "user":this.user,
        "time":now
      }
      this.socketservice.send(msg);
    }

    loadquestions(id){ 
      
      try{
      this.socketservice.getChats(id)
      .then((response) => {
        //console.log(response);
        var preguntas=response["results"]
        preguntas.forEach(element => {
          //console.log(element);
          var date= new Date(element.time);
    var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    element.time=fecha;
        })
     this.chats=preguntas;
 
      })
      .catch((err)=>{
        console.log(err);
      })
  }
    
    catch(err){
console.log(err);
    }
}

descargar(){

  let csvContent = "data:text/csv;charset=utf-8,";
  var user=[];
  this.chats.forEach((rowArray)=> {
    user=[];
    user.push(rowArray.user.name);
    user.push(rowArray.question);
    user.push(rowArray.time);
    
    console.log(user);
      let row = user.join(",");
      csvContent += row + "\r\n";
  });
  saveAs(csvContent,"chat_"+this.event.name+"_.csv");



}

borrar(){
  Swal.fire(     {
    title: "Estas seguro que deseas eliminar el registro de chat?",
    text:"Se perderan todos los datos",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Eliminar',
    cancelButtonText: "Cancelar"
    
}).then((result)=>{
if(result.value){
 

  this.socketservice.deletechats(this.id)
  .then((response) => {
    //console.log(response);
    this.loadquestions(this.id);
  })
  .catch((err)=>{
   console.log(err);
})}
else{}
});
}


}
