import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service"
import  Swal from 'sweetalert2'; 

import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
@Component({
  selector: 'app-aprobacion2',
  templateUrl: './aprobacion2.component.html',
  styleUrls: ['./aprobacion2.component.css']
})
export class Aprobacion2Component implements OnInit {
visitas;
user; 
areas;
eventForm;
submitted2: boolean;
  veventos: boolean;
  eventos: any;
  areascompleta: any;
  mensajes: any;
  vmensajes;
  nuevos: number;
  constructor( private authService : AuthService, 
    private eventService : EventsService,     private EventService:EventsService,) { }

  ngOnInit(): void {  
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(user);
    if(this.user.rol=="ocupacional" ||this.user.rol=="admin" ){
      this.getvisitasporAprobar();
      this.getAreas();
    }
    this.lookConver();
  }
  onNewConversations(conver){
    console.log("evento: ",conver);
    this.mensajes=conver;
    this.nuevos=0;
    this.mensajes.forEach(element => {
      if(element.new!=""&& element.new!=this.user.nit){
      this.nuevos+=1;
      }
    });
  }

 
lookConver(){
this.authService.lookConver(this.user.id)
.then((response)=>{
console.log(response);
this.mensajes=response["mensajes"];
this.nuevos=0;
this.mensajes.forEach(element => {
if(element.new!=""&& element.new!=this.user.nit){
this.nuevos+=1;
}
});

})
.catch((err)=>{
console.log(err)
})

}
  verEventos(){
    console.log(this.areascompleta);
    this.eventForm=new FormGroup({
      areasc: this.createFormaray(this.areascompleta),
      'id':new FormControl("0",[Validators.required,]),
      "nombre": new FormControl("",[Validators.required]),
      "fecha":new FormControl("",[Validators.required]),
      "descripcion":new FormControl("",[Validators.required]),      
     
      "areas":new FormControl([], [
      ]),
    })
    this.veventos=true;
  this.getEvents();
  }
  getEvents(){
    this.EventService.getAllEvents()
    .then((result)=>{
      this.eventos=result["results"]; 
      console.log(this.eventos);
    })
    }
  
    async getSelected(array,ref) {
    
      var selectedMarcas = array.map( (marcas, i) => {
        return marcas.value && {
          "id":ref[i]._id,
          "name":ref[i].nombre,
     
       
          };
      });
    
    
     var selectedNames = selectedMarcas.filter( function (marca) {
      if (marca !== false) {
        return marca;
      }
    });
    console.log(selectedNames);
    return selectedNames;
    
    }
    
    async newEvent(){
      this.submitted2 = true;
      var marcas=await this.getSelected(this.eventForm.controls.areasc['controls'],this.areascompleta);
     console.log(marcas);
           this.eventForm.patchValue({
       areas:marcas
        })
      if (this.eventForm.invalid) {
        console.log("no enviado ");
        return;
        
    }
    //console.log(this.eventForm.value);
    this.EventService.createEvent(this.eventForm.value)
    .then(()=>{
      Swal.fire("evento creado correctamente");
      this.getEvents();
      var evento:Object ={
        id:"0"}
      this.eventForm.reset(evento);
      this.submitted2=false;
    })
    .catch(()=>{
      Swal.fire("hubo en error realizando la acción");
    })
    
    
    }
    deleteEvent(id){
      this.EventService.deleteEvent(id)
      .then((response)=>{
        console.log(response);
        this.getEvents();
      })
      .catch((err)=>{
        console.log(err);
      })
      
      }
      deleteArea(id){
        this.EventService.deleteArea(id)
        .then((response)=>{
          console.log(response);
          this.getAreas();
        })
        .catch((err)=>{
          console.log(err);
        })
        
        }
      
      editEvent(event){
        var evento:Object ={
          id:event._id,
          nombre:event.nombre,
          descripcion:event.descripcion,
          fecha: event.fecha,
         
      
        }
        this.eventForm.reset(evento);
      }
      
      get f2() { return this.eventForm.controls; }

      createFormaray(Inputs) {
        console.log(Inputs);
          const arr = Inputs.map(marca => {
            return new FormControl(marca.selected || false);
          });
          return new FormArray(arr);
        }

  getAreas(){
    this.EventService.getAllAreas()
    .then((result)=>{
    var areas_=result["results"];   
    this.areascompleta=result["results"];
      function chek(area){
      console.log(area.responsables.includes(this.user.id))
      return area.responsables.includes(this.user.id)
    }
areas_=areas_.filter(chek.bind(this))
this.areas=areas_;

    })
  }
  irArea(area){
    if(area.responsables.includes(this.user.id)){
      location.href="/area/"+area._id
    }
    else{Swal.fire("No tienes acceso a esta área")}
        }

  getvisitasporAprobar(){
    this.eventService.getVisitasaprobacion2()
    .then((response)=>{
      console.log(response);
      this.visitas=response["visitas"]
      this.visitas.sort( this.compare );
      this.cargarhoras(this.visitas);
    })
  }
  compare( a, b ) {
    if ( a.fecha < b.fecha ){
      
      return -1;
    }
    if ( a.fecha > b.fecha ){
      return 1;
    }
    return 0;
  }
  cargarhoras(arr){
    arr.forEach(solicitud => {
      console.log("visita: ",solicitud);
      var date= new Date(solicitud.fecha);
       var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
       solicitud.fechat=fecha;
      solicitud.open=false; 
  
    }); 
  }

}
