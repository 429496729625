import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { HomeComponent } from './components/home/home.component';
import { ConferenciaComponent } from './components/conferencia/conferencia.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { ChatComponent } from './components/chat/chat.component';
//import { PreguntasComponent} from "./components/preguntas/preguntas.component"
import {VotacionesComponent} from "./components/votaciones/votaciones.component"
import { RegistroPagoComponent } from './components/registro-pago/registro-pago.component';
import { TallerComponent } from './components/taller/taller.component';
import { MuestraComponent } from './components/muestra/muestra.component';
import { StandComponent } from './components/stand/stand.component';
import { LandingComponent } from './components/landing/landing.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { CuentaComponent} from './components/cuenta/cuenta.component';

import { ConferencistasComponent } from './components/conferencistas/conferencistas.component';
//import { MonitorComponent } from './components/monitor/monitor.component';
import { AgendaComponent } from './components/agenda/agenda.component';
//import { MiagendaComponent } from './components/miagenda/miagenda.component';
import { VizualizadorComponent } from './components/vizualizador/vizualizador.component';
import { SalanetComponent } from './components/salanet/salanet.component';
import { SalaComponent } from './components/sala/sala.component';
import { AprobacionComponent } from './components/aprobacion/aprobacion.component';
import { PorteriaComponent } from './components/porteria/porteria.component';
import { IngresoComponent } from './components/ingreso/ingreso.component';
import {PanelComponent} from "./components/panel/panel.component"
import { PanelempresaComponent } from './components/panelempresa/panelempresa.component';
import { ValidacionComponent } from './components/validacion/validacion.component';

import { AdministracionComponent } from './components/administracion/administracion.component';
import { AreasComponent } from './components/areas/areas.component';
import { AreaComponent } from './components/area/area.component';
import { Aprobacion2Component } from './components/aprobacion2/aprobacion2.component';
import { Aprobacion3Component } from './components/aprobacion3/aprobacion3.component';

import { Aprobar2Component } from './components/aprobar2/aprobar2.component';
import { Aprobar3Component } from './components/aprobar3/aprobar3.component';

import { Aprobar4Component } from './components/aprobar4/aprobar4.component';

import { UseruploaderComponent } from './components/useruploader/useruploader.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { SalidaComponent } from './components/salida/salida.component';
import { ManillaLectorComponent } from './components/manilla-lector/manilla-lector.component';
import { IngresosempresaComponent } from './components/ingresosempresa/ingresosempresa.component';
import { RecuperarClaveComponent } from './components/recuperar-clave/recuperar-clave.component';
import { OlvidoComponent } from './components/olvido/olvido.component';
import { ResumeneventoComponent } from './components/resumenevento/resumenevento.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { PerfilempresaComponent } from './components/perfilempresa/perfilempresa.component';
import { ManillasComponent } from './components/manillas/manillas.component';
import { ReportesIngresoComponent } from './components/reportes-ingreso/reportes-ingreso.component';
import { Reportes2Component } from './components/reportes2/reportes2.component';
import { ReservaGolfComponent } from './components/reserva-golf/reserva-golf.component';
import { ReservasComponent } from './components/reservas/reservas.component';
import { LectorReservasComponent } from './components/lector-reservas/lector-reservas.component';
import { AccesoComponent } from './components/acceso/acceso.component';
import { ReservaTennisComponent } from './components/reserva-tennis/reserva-tennis.component';
import { VinculacionComponent } from './components/vinculacion/vinculacion.component';
import { ReservaBolosComponent } from './components/reserva-bolos/reserva-bolos.component';
import { ReservaSkyComponent } from './components/reserva-sky/reserva-sky.component';
import { RegisterCanjesComponent } from './components/register-canjes/register-canjes.component';
import { ReservarComponent } from './components/reservar/reservar.component';
import { RegisterNineraComponent } from './components/register-ninera/register-ninera.component';
import { ReservaAgendaComponent } from './components/reserva-agenda/reserva-agenda.component';
import { RegistroSelectComponent } from './components/registro-select/registro-select.component';
import { RegisterBeneficiarioLinkComponent } from './components/register-beneficiario-link/register-beneficiario-link.component';

import { SolcitudCanjeComponent } from './components/solcitud-canje/solcitud-canje.component';
import { PqrComponent } from './components/pqr/pqr.component';
import { ReservaVinculacionComponent } from './components/reserva-vinculacion/reserva-vinculacion.component';
import { SolicitudExtraComponent } from './components/solicitud-extra/solicitud-extra.component'; 
import { SolicitudesExtrasComponent } from './components/solicitudes-extras/solicitudes-extras.component'; 
const routes: Routes = [
//   { path: 'home', component: HomeComponent,canActivate : [AuthGuard] },
//   { path: 'agenda', component: AgendaComponent,canActivate : [AuthGuard] },
//   { path: 'miagenda', component: MiagendaComponent,canActivate : [AuthGuard] },

//  { path: 'sala/:id', component: SalaComponent ,canActivate : [AuthGuard] },
//  { path: 'salas', component: SalanetComponent,canActivate : [AuthGuard] },
//  { path: 'aprobacion/:id', component: AprobacionComponent },
//  { path: 'porteria', component: PorteriaComponent },
//   { path: 'conferencia/:id', component: ConferenciaComponent,canActivate : [AuthGuard] },
//   { path: 'preguntas/:id', component: PreguntasComponent,canActivate : [AuthGuard] },
//   { path: 'taller/:id', component: TallerComponent,canActivate : [AuthGuard] },
//   { path: 'stand/:id', component: StandComponent,canActivate : [AuthGuard] },
//   { path: 'votaciones/:id', component: VotacionesComponent,canActivate : [AuthGuard] },
//   { path: 'muestra', component: MuestraComponent,canActivate : [AuthGuard] },
//   { path: 'monitor/:id', component: MonitorComponent,canActivate : [AuthGuard] },
//   { path: 'login', component: LoginComponent },
//   {path:"register",component:RegisterComponent},

//   {path:"inicio",component:LoginComponent},
//   {path:"visualizador",component:VizualizadorComponent},
//   {path:"conferencistas",component:ConferencistasComponent},
//   {path:"",component: RegistroPagoComponent },
//   {path:"registerp",component:RegistroPagoComponent},
//   {path:"cuenta", component:CuentaComponent},
{ path: '', component: IngresoComponent}, 
{ path:"ingresoempresas",component:IngresosempresaComponent },
{ path: 'lector', component: ManillaLectorComponent},
{ path:"register",component: RegistroSelectComponent },///register
{ path: 'login', component: IngresoComponent},
{ path: 'porteria', component: AccesoComponent, canActivate : [AuthGuard]},
{ path: 'acceso', component: AccesoComponent, canActivate : [AuthGuard]},
{ path: 'canjes', component: SolcitudCanjeComponent, canActivate : [AuthGuard]},
{ path: 'pqrs', component: PqrComponent, canActivate : [AuthGuard]},
{ path: 'solicitud-extra', component: SolicitudExtraComponent, canActivate : [AuthGuard]},
{ path: 'solicitudes-extra', component: SolicitudesExtrasComponent, canActivate : [AuthGuard]},
{ path: 'tutorial', component: TutorialComponent },
{ path: 'uploader', component: UseruploaderComponent},
{ path: 'administracion', component: AdministracionComponent, canActivate : [AuthGuard] },
{ path: 'areas', component: AreasComponent,canActivate : [AuthGuard]  },
{ path: 'area/:id', component: AreaComponent,canActivate : [AuthGuard] },
{ path: 'evento/:id', component: ResumeneventoComponent,canActivate : [AuthGuard] },
{ path: 'perfil', component: PerfilComponent,canActivate : [AuthGuard] }, 
{ path: 'perfilempresa', component: PerfilempresaComponent,canActivate : [AuthGuard] }, 
{ path: 'register/:id', component: RegisterComponent }, 
{ path: 'register-beneficiaio/:id', component: RegisterBeneficiarioLinkComponent,canActivate : [AuthGuard] },
{path:"usuarios",component:UsuariosComponent,canActivate : [AuthGuard]},
{ path: 'canjes', component: RegisterCanjesComponent },
{path:"recuperar/:id",component:RecuperarClaveComponent}, 
{path:"olvido/:id",component:OlvidoComponent},
{path:"vinculacion/:id",component:VinculacionComponent},
{ path: 'validacion', component: ValidacionComponent},
{ path: 'salida', component: SalidaComponent},
{ path: 'aprobacion/:id', component: AprobacionComponent },
{ path: 'aprobar2/:id', component: Aprobar2Component },
{ path: 'aprobar3/:id', component: Aprobar3Component },
{ path: 'aprobar4/:id', component: Aprobar4Component },
{ path: 'aprobacion2', component: Aprobacion2Component,canActivate : [AuthGuard] }, 
{ path: 'reportes', component: ReportesIngresoComponent,canActivate : [AuthGuard] },
{ path: 'reportes2', component: Reportes2Component,canActivate : [AuthGuard] },
{ path: 'aprobacion3', component: Aprobacion3Component,canActivate : [AuthGuard] },
{ path: 'manillas', component: ManillasComponent,canActivate : [AuthGuard] },
{ path: 'agenda', component: PanelComponent,canActivate : [AuthGuard] },
{ path: 'empresa', component: PanelempresaComponent,canActivate : [AuthGuard] },
{path:"reservas", component:ReservasComponent},
{path:"reservaagenda", component: ReservaAgendaComponent},
{path:"reservagolf", component:ReservaGolfComponent},
{path:"reservatennis", component:ReservaTennisComponent},
{path:"reservabolos", component:ReservaBolosComponent}, 
{path:"reservasky", component: ReservaSkyComponent},
{path:"reservar/:type", component: ReservarComponent},
{path:"vinculacion-reserva/:id",component:ReservaVinculacionComponent,canActivate : [AuthGuard] }, 
{path:"reservalector", component:LectorReservasComponent},
{path:"registroninera", component: RegisterNineraComponent},
{ path: '**', redirectTo: '' } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
