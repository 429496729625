import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import {SocketioService} from "../../services/socketio.service";
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-votaciones',
  templateUrl: './votaciones.component.html',
  styleUrls: ['./votaciones.component.css']
})
export class VotacionesComponent implements OnInit {
  intervalid;
  questions=[];

  type = 'PieChart';

  columnNames = ['Votos', 'Porcentage'];
  options = {    
  };
  width = 550;
  height = 400;
    constructor(  
      private eventService : EventsService, 
      private route: ActivatedRoute, private router : Router,
      private socketservice: SocketioService,
      private renderer: Renderer2,
      private elRef:ElementRef) { }
  
    ngOnInit(): void {
      let id=this.route.snapshot.paramMap.get("id")
   
  this.loadquestions(id);
  this.intervalid = setInterval(() => {
    this.loadquestions(id);
  }, 150000);
    
    }
    ngOnDestroy() {
      if (this.intervalid) {
        clearInterval(this.intervalid);
      }
    }
    loadquestions(id){
      this.eventService.pulls(id)
    .then((response) => {
      console.log(response);

  var ques=[];
   response["results"].forEach(element => {
     var va=0;
     var vb=0;
     var vc=0;
     element.pull.pulls.forEach(voto => {
       if(voto.vote=="a"){va=va+1}
       if(voto.vote=="b"){vb=vb+1}
       if(voto.vote=="c"){vc=vc+1}
     });
     var  data = [
      ['a', va],
      ['b', vb],
      ['c', vc],
   ];
    var question={
title: element.pull.pull,
data: data,

    }
console.log("ques: "+question);
ques.push(question);
});
   this.questions=ques;

    })
    .catch((err)=>{
      console.log(err);
    })
  } 

  

//   drawChart(){
//   const data = GoogleCharts.api.visualization.arrayToDataTable([
//     ['Chart thing', 'Chart amount'],
//     ['Lorem ipsum', 60],
//     ['Dolor sit', 22],
//     ['Sit amet', 18]
// ]);
// var chart=this.elRef.nativeElement.querySelector('#chart_zone');
// const pie_1_chart = new GoogleCharts.api.visualization.PieChart(chart);
// pie_1_chart.draw(data);
  
//   }
 
  
  }
  