<div class="row mt-2 justify-content-center" >
<div class="col-md-5">
<h2>Seleccione el evento que desea revisar</h2>
<select (change)="onEventChange($event.target.value)" >
    <option value="">Seleccione</option>
    <option value="{{event._id}}" *ngFor="let event of eventos" >{{event.nombre}}</option>
</select>
<button (click)="vtotales()"  *ngIf="!totales && eventid" >Ver totales</button>
<button (click)="vactual()"  *ngIf="totales && eventid" >Ver personal en el interior</button>
</div>
</div>
<div class="row mt-2 justify-content-center" >
   <div class="col-md-5  "  >
    <table>
        <tr>
            <th>Empresa</th>
            <th>Ingresos</th>
           

        </tr>
        <tr *ngFor="let empresa of empresasc" >
            <td>{{empresa.name}}</td>
            <td>{{empresa.ingresos.length}} </td>
          

        </tr>
   

    </table>


   </div>
    </div>