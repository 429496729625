import { Component, OnInit, Input,EventEmitter,ViewChild,Output, ElementRef, Renderer2} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {SocketioService} from "../../services/socketio.service";

import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service";

import { interval } from 'rxjs';
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-mensajes',
  templateUrl: './mensajes.component.html',
  styleUrls: ['./mensajes.component.css']
})
export class MensajesComponent implements OnInit {
  user;
  chat;
  vchat;
  @Input() conversations =[];
  @Output() newConversaions =new EventEmitter();
  mensajes: any;
  mensajeForm: FormGroup;
  submitted: boolean;
  i: any;

  constructor(
    private authService : AuthService, 
    private EventService:EventsService,
    private Authservice: AuthService,
    private elRef:ElementRef,
  ) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
console.log(user);
this.mensajeForm=new FormGroup({
  'time': new FormControl('', ),    
  'from': new FormControl('', ),   
  'fromName': new FormControl('', ),    
  'conversacion': new FormControl('', ),    
  'to': new FormControl('', ), 
  'txt': new FormControl('',[    Validators.required,] ),        
 
})
  }
 


  changeConversation(){
    this.newConversaions.emit(this.conversations);
  }

  get f2() { return this.mensajeForm.controls; }

  newMensaje(){
    this.submitted = true;

    if (this.mensajeForm.invalid) {
      console.log("no enviado ");
      return;
  }
    var now= new Date();
    var n = now.getUTCHours();
    if(n<5){
      n=24+n; 
    } 
    var to=this.chat.from;
    var from="";
    var name="";
    if(this.user.nit){
from=this.user.nit;
name=this.user.name
    }
    else{
      from=this.user.id;
      name=this.user.name +" "+this.user.apellido;
    }

   now.setHours(n-5);  
    this.mensajeForm.patchValue({
      time: now,
      from:from,
      fromName:name,
  to:to, 
  conversacion:this.chat._id
    });
    console.log(this.mensajeForm.controls);
  this.authService.newMesnaje(this.mensajeForm.value)
  .then((response)=>{
 //this.seeChat(this.chat);
 this.mensajes.push(response["message"]);
 this.conversations[this.i].ultimo=response["message"];
 this.mensajeForm.reset();
 this.submitted=false;
 this.scroll()
  })
  .catch((err)=>{
    console.log(err);
  })
  
  }


  scroll(){
    setTimeout(()=>{
      var zona = this.elRef.nativeElement.querySelector('#caja-chat');
      zona.scrollTop = zona.scrollHeight;
    },1000)
  
  }

  seeOwner(from){
    if(this.user.nit){
      if(from==this.user.nit){
        return {my:true}
        }
        else{
          return {other:true}
        }
    }
    else{
      if(from==this.user.id){
        return {my:true}
        }
        else{
          return {other:true}
        }
    }
  }
  compare( a, b ) {
    if ( a.time < b.time ){
      
      return -1;
    }
    if ( a.time > b.time ){
      return 1;
    }
    return 0;
  }
  seeChat(chat,i){
    this.vchat=true;
    this.chat=chat;
    this.i=i;
console.log("nuevo: ",chat.new)
if(chat.new){
  this.authService.vistoMesnaje(chat._id)
  .then((result)=>{
    this.conversations[this.i].new="";
    this.changeConversation();
  })
}

    this.authService.lookMensajes(chat._id)
.then((results)=>{
  this.mensajes=results["mensajes"];
this.mensajes.sort(this.compare);

this.mensajes.forEach(element => {
  var date= new Date(element.time);
  var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
 element.fecha=fecha;
});
this.scroll();
})
.catch((err)=>{
  console.log(err);
})

  }

  seeNew(conver){
    if(this.user.nit){
      if(conver.new!=""&& conver.new!=this.user.nit){
        conver.new=true;
        return {nuevo:true}
        }
    }
    else{
      if(conver.new!=""&& conver.new!=this.user.id){
        conver.new=true;
        return {nuevo:true}
        }
    }
   
  }
}
