<div  style="background-color: white; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; border-radius: 10px;" >
    <h2 class="card-title" style="color: black; text-align: center; margin-bottom: 15px;" > {{event.nombre}} </h2>
    <div class="card-body" >
    <span style="font-weight: bold;" > Descripción: </span>{{event.descripcion}}
    <br>
    <br>
    <span style="font-weight: bold;" > Fecha: </span>{{event.fechat}}
    <br><br>
    <span style="font-weight: bold;" > Areas vinculadas: </span>
    <ul>
        <li *ngFor="let area of event.areas">
    {{area.name}}
        </li>
    </ul>
    </div>
    
    </div>