<div class="row justify-content-center m-4" >
    <div class="col-md-6">

        <div class="container  cajaFoto" *ngIf="!fotop">
            <video  playsinline  width="90%" height="225" autoplay #video style="margin-left: 20px; transform: rotateY(180deg);
            -webkit-transform:rotateY(180deg); /* Safari and Chrome */
            -moz-transform:rotateY(180deg); /* Firefox */" ></video> <br> 
            <h3 style="color:black" class="fst-italic">Al ingresar y tomarse la fotografía esta aceptando nuestra política de tratamiento de datos </h3>
            <div class="d-flex justify-content-center" style="padding:10px">
                <div  class="text-center py-3" *ngIf="!loading">
                    <button  (click)="tomar()" *ngIf="!fotovalidate" class="btn btn-info ">Tomar foto</button>
                
                </div>
               
                <div *ngIf="loading"  class="text-center py-3">
                    <div   class="spinner-border text-success " role="status">
                        <span class="sr-only">Loading...</span>
                      </div>  
                </div>
            </div>
           

            <canvas #canvas style="display:none; "></canvas>
        </div>
        <div class="container caja-form2 " >
             <form [formGroup]="registerForm" (ngSubmit)="registerp()" *ngIf="fotop" class=""> 
                
                <div class="row ">
                    <div class="col-11 col-md-6 ">
                        <div class="form-group">
                            <h3>Nombres:*</h3>
                            <input type="text" class="form-control" placeholder="Nombre" formControlName="name"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Ingrese su nombre</div>
                                <div *ngIf="f.name.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Apellidos:*</h3>
                            <input type="text" class="form-control" placeholder="Apellido" formControlName="apellido"
                                [ngClass]="{ 'is-invalid': submitted && f.apellido.errors }">
                            <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
                                <div *ngIf="f.apellido.errors.required">Ingrese sus apellidos</div>
                                <div *ngIf="f.apellido.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Tipo de documento:*</h3>
                         <select class="form-control" formControlName="tipoid" [ngClass]="{ 'is-invalid': submitted && f.tipoid.errors }"  >
                            <option value="">Selecione</option>
                            <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
                            <option value="Cédula de Extranjería Colombiana">Cédula de Extranjería Colombiana</option>
                            <option value="Cédula Extranjera">Cédula Extranjera</option>
                            <option value="Pasaporte">Pasaporte</option>
                            <option value="Registro Civil">Registro Civil</option>
                            <option value="Tarjeta de Identidad">Tarjeta de Identidad</option>

                         </select>
                            <div *ngIf="submitted && f.tipoid.errors" class="invalid-feedback ">
                                <div *ngIf="f.tipoid.errors.required">seleccione</div>
                             
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Documento de identidad:*</h3>
                            <input class="form-control" type="number" placeholder="Documento de identidad"
                                formControlName="id" [ngClass]="{ 'is-invalid': submitted && f.id.errors }">
                            <div *ngIf="submitted && f.id.errors" class="invalid-feedback ">
                                <div *ngIf="f.id.errors.required">ingrese su documento</div>
                                <div *ngIf="f.id.errors.min">ingrese un documento valido</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Celular:*</h3>
                            <input class="form-control" type="number" placeholder="celular" formControlName="telefono"
                                [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }">
                            <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback ">
                                <div *ngIf="f.telefono.errors.required">ingrese su telefono</div>
                                <div *ngIf="f.telefono.errors.min">ingrese un telefono valido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Correo electronico:*</h3>
                            <input class="form-control" type="email" placeholder="Correo electrónico"
                                formControlName="correo" [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                            <div *ngIf="submitted && f.correo.errors" class="invalid-feedback ">
                                <div *ngIf="f.correo.errors.required">ingrese su email</div>
                                <div *ngIf="f.correo.errors.email">ingrese un correo valido</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Fecha de nacimiento:*</h3>
                            <input class="form-control" type="date" placeholder="Fecha" formControlName="nacimiento"
                            min="1920-01-01" max="2028-12-31" 
                            [ngClass]="{ 'is-invalid': submitted && f.nacimiento.errors }">
                            <div *ngIf="submitted && f.nacimiento.errors" class="invalid-feedback">
                                <div *ngIf="f.nacimiento.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Sexo:*</h3>
                         <select class="form-control" formControlName="sexo" [ngClass]="{ 'is-invalid': submitted && f.sexo.errors }"  >
                            <option value="">Selecione</option>
                            <option value="Femenino">Femenino</option>
                            <option value="Masculino">Masculino</option>

                         </select>
                            <div *ngIf="submitted && f.sexo.errors" class="invalid-feedback ">
                                <div *ngIf="f.sexo.errors.required">seleccione</div>
                             
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Empresa:</h3>
                            <input type="text" class="form-control" placeholder="Empresa" formControlName="empresa"
                                [ngClass]="{ 'is-invalid': submitted && f.empresa.errors }">
                            <div *ngIf="submitted && f.empresa.errors" class="invalid-feedback">
                                <div *ngIf="f.empresa.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>Cargo:</h3>
                            <input type="text" class="form-control" placeholder="Cargo" formControlName="cargo"
                                [ngClass]="{ 'is-invalid': submitted && f.cargo.errors }">
                            <div *ngIf="submitted && f.cargo.errors" class="invalid-feedback">
                                <div *ngIf="f.cargo.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                  
                </div>
    
                <div class="row">
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>ARL</h3>
                            <input type="text" class="form-control" placeholder="ARL" formControlName="arl"
                                [ngClass]="{ 'is-invalid': submitted && f.arl.errors }">
                            <div *ngIf="submitted && f.arl.errors" class="invalid-feedback">
                                <div *ngIf="f.arl.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                        <div class="form-group">
                            <h3>EPS</h3>
                            <input type="text" class="form-control" placeholder="EPS" formControlName="eps"
                                [ngClass]="{ 'is-invalid': submitted && f.eps.errors }">
                            <div *ngIf="submitted && f.eps.errors" class="invalid-feedback">
                                <div *ngIf="f.eps.errors.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    
                    <div class="col-11 col-md-6">
    
                    </div>
                </div>
    
                
                <div class="text-center mt-2">
                    <input type="submit" value="Ingresar" class="btn btn-orange">
                </div>
            </form>
        </div>
        
    </div>

</div>