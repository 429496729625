<a href="/agenda"><button class="btn btn-primary" >Volver</button></a>
<div class="row justify-content-center mt-4">
    <div *ngIf="!tomandoFoto" class="col-md-3">
        <img src="{{user.photo1}}" width="100%" alt="">
        <button (click)="activarcam()" class="btn btn-info">Cambiar foto </button>
    </div>
    <div class="col-md-6" *ngIf="tomandoFoto" >
        <div class="container  bg-dark  p-2 rounded-top shadow text-white"  >
            <video  playsinline   playsinline  width="90%" height="225" autoplay #video></video> <br>
           
            <canvas #canvas style="display:none; "></canvas>
            </div>
            <br>
            
<button   class="btn btn-primary"  (click)="tomar()" >Tomar</button>
    </div>

    <div class="col-md-6" *ngIf="!tomandoFoto" >
        <h1>Edita tus datos</h1>
        <form [formGroup]="rpersonForm" (ngSubmit)="registerp()"  class="caja-form2"  >  
                
     
            <div class="row" >
                <div class="col-11 col-md-6" >
                    <div class="form-group">
                        <h3>Nombres:</h3>
                        <input type="text" class="form-control" placeholder="Nombre" formControlName="name"  [ngClass]="{ 'is-invalid': submitted && f2.name.errors }">
                        <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
                            <div *ngIf="f2.name.errors.required">Ingrese su nombre</div>
                            <div *ngIf="f2.name.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                        </div>
                    </div> 
                </div>
                <div class="col-11 col-md-6" >
                    <div class="form-group">
                        <h3>Apellidos:</h3>
                        <input type="text" class="form-control" placeholder="Apellido" formControlName="apellido"  [ngClass]="{ 'is-invalid': submitted && f2.apellido.errors }">
                        <div *ngIf="submitted && f2.apellido.errors" class="invalid-feedback">
                            <div *ngIf="f2.apellido.errors.required">Ingrese sus apellidos</div>
                            <div *ngIf="f2.apellido.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                        </div>
                    </div> 
                </div>
                            </div>
    
         
                            <div class="row" >
                                <div class="col-11 col-md-6" >
                                    <div class="form-group">
                                        <h3>Correo electronico:</h3>     
                                        <input  class="form-control" type="email" placeholder="Correo electrónico" formControlName="correo"  [ngClass]="{ 'is-invalid': submitted && f2.correo.errors }">
                                        <div *ngIf="submitted && f2.correo.errors" class="invalid-feedback ">
                                            <div *ngIf="f2.correo.errors.required">ingrese su email</div>
                                            <div *ngIf="f2.correo.errors.email">ingrese un correo valido</div>
                                        </div> 
                                    </div> 
                                </div>
                                <div class="col-11 col-md-6" >
                                    <div class="form-group">
                                        <h3>Documento de identidad:</h3>
                                        <input class="form-control" type="number" disabled="true" placeholder="Documento de identidad" formControlName="id" [ngClass]="{ 'is-invalid': submitted && f2.id.errors }">
                                        <div *ngIf="submitted && f2.id.errors" class="invalid-feedback ">
                                            <div *ngIf="f2.id.errors.required">ingrese su documento</div>
                                            <div *ngIf="f2.id.errors.min">ingrese un documento valido</div>
                                        </div> 
                                    </div>
                                </div>
                                            </div>
        
    
                                            <div class="row" >
                                                <div class="col-11 col-md-6" >
                                                    <div class="form-group">
                                                        <h3>Celular:</h3>
                                                        <input class="form-control" type="number" placeholder="celular" formControlName="telefono" [ngClass]="{ 'is-invalid': submitted && f2.telefono.errors }">
                                                        <div *ngIf="submitted && f2.telefono.errors" class="invalid-feedback ">
                                                            <div *ngIf="f2.telefono.errors.required">ingrese su telefono</div>
                                                            <div *ngIf="f2.telefono.errors.min">ingrese un telefono valido</div>
                                                        </div> 
                                                    </div>  
                                                </div>
                                                <div class="col-11 col-md-6" >
                                                    <div class="form-group">
                                                        <h3>EPS</h3>
                                                        <input type="text" class="form-control" placeholder="EPS" formControlName="eps"  [ngClass]="{ 'is-invalid': submitted && f2.eps.errors }">
                                                        <div *ngIf="submitted && f2.eps.errors" class="invalid-feedback">
                                                            <div *ngIf="f2.eps.errors.required">Campo requerido</div>
                                                               </div>
                                                    </div>
                                                </div>
                                                            </div>
          
       
                                                            <div class="row" >
                                                                <div class="col-11 col-md-6" >
                                                                    <div class="form-group">
                                                                        <h3>Cargo</h3>
                                                                        <input type="text" class="form-control" placeholder="Cargo" formControlName="cargo"  [ngClass]="{ 'is-invalid': submitted && f2.cargo.errors }">
                                                                        <div *ngIf="submitted && f2.cargo.errors" class="invalid-feedback">
                                                                            <div *ngIf="f2.cargo.errors.required">Campo requerido</div>
                                                                               </div>
                                                                    </div> 
                                                                </div>
                                                                <div class="col-11 col-md-6" >
                                                                    <div class="form-group">
                                                                        <h3>ARL</h3>
                                                                        <input type="text" class="form-control" placeholder="ARL" formControlName="arl"  [ngClass]="{ 'is-invalid': submitted && f2.arl.errors }">
                                                                        <div *ngIf="submitted && f2.arl.errors" class="invalid-feedback">
                                                                            <div *ngIf="f2.arl.errors.required">Campo requerido</div>
                                                                               </div>
                                                                    </div> 
                                                                </div>
                                                                            </div>
       
         <div class="row" >
    
    <div class="col-11 col-md-6" >
    <div class="form-group">
        <h3>Contacto de emergencia</h3>
        <input type="text" class="form-control" placeholder="Contacto" formControlName="contacto"  [ngClass]="{ 'is-invalid': submitted && f2.contacto.errors }">
        <div *ngIf="submitted && f2.contacto.errors" class="invalid-feedback">
            <div *ngIf="f2.contacto.errors.required">Campo requerido</div>
               </div>
    </div> 
    </div>
    <div class="col-11 col-md-6" >
        <div class="form-group">
            <h3>Celular del contacto</h3>
            <input type="number" class="form-control" placeholder="celular" formControlName="contactotel"  [ngClass]="{ 'is-invalid': submitted && f2.contactotel.errors }">
            <div *ngIf="submitted && f2.contactotel.errors" class="invalid-feedback">
                <div *ngIf="f2.contactotel.errors.required">Campo requerido</div>
                   </div>
        </div> 
    </div>
            </div>
            <div class="row" >
                <div class="col-11 col-md-6" >
                  
                        <div class="form-group">
                            <h3>Fecha de nacimiento:*</h3>
                            <input class="form-control" type="date" placeholder="Fecha" formControlName="nacimiento"
                            min="1920-01-01" max="2028-12-31" 
                            [ngClass]="{ 'is-invalid': submitted && f2.nacimiento.errors }">
                            <div *ngIf="submitted && f2.nacimiento.errors" class="invalid-feedback">
                                <div *ngIf="f2.nacimiento.errors.required">Campo requerido</div>
                            </div>
                        </div>
                 
                </div>
                <div class="col-11 col-md-6" >
                
                </div>
                            </div>
        
                            
            <div class="text-center mt-4">

                <input type="submit" value="Confirmar" class="btn btn-primary">
            </div>                
        </form>
    </div>
    </div>
    
    
    
    
    
    