<div class="row justify-content-center" >
    <div class="col-10 col-md-4">

        <h2>Recuperación de contraseña</h2>
        <form [formGroup]="recuperarForm" (ngSubmit)="recuperar()" >

                    <div class="form-group">
                        <h3>Clave temporal:</h3>
                        <input type="text" class="form-control"  formControlName="temporal"
                            [ngClass]="{ 'is-invalid': submitted && f.temporal.errors }">
                        <div *ngIf="submitted && f.clave.errors" class="invalid-feedback">
                            <div *ngIf="f.temporal.errors.required">llene este campo</div>
                           
                        </div>
                    </div>
                    <div class="form-group">
                        <h3>Nueva clave:</h3>
                        <input type="text" class="form-control"  formControlName="clave"
                            [ngClass]="{ 'is-invalid': submitted && f.clave.errors }">
                        <div *ngIf="submitted && f.clave.errors" class="invalid-feedback">
                            <div *ngIf="f.clave.errors.required">llene este campo</div>
                           
                        </div>
                    </div>
                    <input type="submit" value="Enviar" class="btn btn-primary" >
        </form>
    </div>
                

</div>