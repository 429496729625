import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';
import QrScanner from 'qr-scanner';

@Component({
  selector: 'app-manilla-lector',
  templateUrl: './manilla-lector.component.html',
  styleUrls: ['./manilla-lector.component.css']
})
export class ManillaLectorComponent implements OnInit {
  data: boolean=false;
  manilla: any;
  result: any
  cameras:any;
  qrScanner: any;
  @ViewChild('video1', { static: false}) videoElement: ElementRef;
  inter: NodeJS.Timeout;
  // @Output() newManilla =new EventEmitter();
  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
this.inter=setInterval(()=>{
  console.log("i ")
if(this.videoElement.nativeElement){
  this.iniciar();
}
},100)

  // setTimeout(() => {
  //   this.iniciar();
  // }, 1000);
  }
  title = 'qr';
  camaras:any = [] ;
  desiredDevice:any = "";
  selectedCamera(id){
    console.log(id)
    this.qrScanner.setCamera(id);
  }
  iniciar(){
clearInterval(this.inter);
    this.qrScanner = new QrScanner(this.videoElement.nativeElement,(result) => {
     
      //console.log("RES", this.result.data)
      
      if (result) {
       
        
  this.scanSuccessHandler(result.data);
        this.qrScanner.stop()
        
        this.qrScanner.destroy()
        //this.destroy();
        
       // this.ngOnInit();
        // return
  
      }
   
    }, {
      highlightScanRegion: true,
      highlightCodeOutline: true });
  
    this.qrScanner.start()
    QrScanner.listCameras().then((r)=>{
      console.log(r)
      this.cameras = r
    })
  }
  


  scanSuccessHandler(e:string){
    console.log("manilla: ",e)
    if(e.length==28 ){
this.authService.getManilla(e)
.then((response)=>{
console.log(response);
if(response["result"]!= null){
  this.data=true;
  this.manilla=response["result"]
  this.changeDetectorRef.detectChanges();
}
else{
  Swal.fire("Error leyendo manilla")
}

})
.catch(()=>{
  Swal.fire("Error leyendo manilla")
})
    }
    else{
      Swal.fire("Error leyendo manilla")
    }
  
  }

  camerasFoundHandler(e:any){
    console.log(e)
    this.camaras = e;
  }

  
  seleccionarCamara(deviceValue:any) {
    console.log(deviceValue);
    if(deviceValue != ""){
      this.desiredDevice = deviceValue
    }
}


termina(){
 location.reload();
}

}
