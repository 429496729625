<a href="/empresa"><button class="btn btn-primary" >Volver</button></a>
<div class="row justify-content-center m-4" >
    <div class="col-md-6" >
        <h1>Edita tus datos</h1><br>
        <form [formGroup]="rcompanyForm" (ngSubmit)="registerc()">
            <div class="form-group">
                <h3>Ingrese el nombre de la empresa</h3>
                <input type="text" class="form-control" placeholder="Nombre" formControlName="name"
                    [ngClass]="{ 'is-invalid': submitted && f2.name.errors }">
                <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
                    <div *ngIf="f2.name.errors.required">Ingrese su nombre</div>
                    <div *ngIf="f2.name.errors.minlength">El nombre debe tener al menos 4 caracteres</div>
                </div>
            </div>
          
           
            <h3>Datos de contacto de la empresa (sus datos)</h3>
            <div class="form-group">
                <h3>Ingrese su nombre</h3>
                <input type="text" class="form-control" placeholder="Nombre" formControlName="rname"
                    [ngClass]="{ 'is-invalid': submitted && f2.rname.errors }">
                <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
                    <div *ngIf="f2.rname.errors.required">Ingrese su nombre</div>
                    <div *ngIf="f2.rname.errors.minlength">El nombre debe tener al menos 4 caracteres</div>
                </div>
            </div>
            <div class="form-group">
                <h3>Ingrese su correo electronico</h3>
                <input class="form-control" type="email" placeholder="Correo electrónico" formControlName="rcorreo"
                    [ngClass]="{ 'is-invalid': submitted && f2.rcorreo.errors }">
                <div *ngIf="submitted && f2.rcorreo.errors" class="invalid-feedback ">
                    <div *ngIf="f2.rcorreo.errors.required">ingrese su email</div>
                    <div *ngIf="f2.rcorreo.errors.email">ingrese un correo valido</div>
                </div>
            </div>
    
            <div class="form-group">
                <h3>Ingrese su número de teléfono</h3>
                <input class="form-control" type="number" placeholder="teléfono" formControlName="rtelefono"
                    [ngClass]="{ 'is-invalid': submitted && f2.rtelefono.errors }">
                <div *ngIf="submitted && f2.rtelefono.errors" class="invalid-feedback ">
                    <div *ngIf="f2.rtelefono.errors.required">ingrese su telefono</div>
                    <div *ngIf="f2.rtelefono.errors.min">ingrese un telefono valido</div>
                </div>
            </div>
    
            <br>
            <input type="submit" value="confirmar" class="btn btn-boton2">
        </form>
    </div>
  

</div>