import { Component, OnInit,Input, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-visita',
  templateUrl: './visita.component.html',
  styleUrls: ['./visita.component.css']
})
export class VisitaComponent implements OnInit {
  @Input() visita ={"ingresos":[],"documentos":[],"personasObj":[],"personas":[],"descripcion":"","fecha":"1231321","fechat":"123","acceso":"","aprobe":true,"_id":"000","empresa":"","placa":"","ingreso":{"hora":""}};
  @Input() show=false;
  personasc=[];
  empresa: any;
  porteria;
  documentos=false;
  ingresos=0;
  subird: boolean;
  docForm: FormGroup;
  deletedoc: FormGroup;
  registerurl: string;
  constructor(
    private router: Router,
    private authService : AuthService, 
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.loadpersonas();
 
   
this.updatefecha();
this.docForm= new FormGroup({

  "doc_pdf":new FormControl(''),
  "id":new FormControl(''),
 

})
this.deletedoc= new FormGroup({

  "url":new FormControl(''),
  "id":new FormControl(''),


})
this.registerurl="https://socios.serrezuela.com/vinculacion/"+this.visita._id;
  }

  copiar(){
 
    /* Copy the text inside the text field */
   navigator.clipboard.writeText(this.registerurl);
 }
updatefecha(){
  var date= new Date(this.visita.fecha);
  var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
 this.visita.fechat=fecha
}

  ingreso(){
    if(this.visita.ingreso){
      var date= new Date(this.visita.ingreso.hora);
      var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
      this.visita.ingreso.hora=fecha;
    }
   
  }
  aprobar(){
  
  }
loadpersonas(){
  this.personasc=[];
this.visita.personas.forEach(id => {
  console.log(id);

  
  this.authService.loadUserById(id)
  .then((response:any)=>{
    var person=response;
    var ingresosp=this.visita.ingresos.map((el)=>{return el.persona})
    
     if(ingresosp.includes(id)){
       person.ingreso=this.visita.ingresos[ingresosp.indexOf(id)];
       this.ingresos+=1
 
     }
    
 
    
    
this.personasc.push(person)
  })
  .catch((err)=>{

  })
  });

}

descargarPersonas(){
  let diasCsv= "data:text/csv;charset=utf-8,";
  var user;
  this.visita.personas.forEach(element => {
   
      var salida;
   
   
      if(element.salida){
         salida=element.salida.hora
      }
      else{
        salida="no registra salida"
      }
      user=[element.name,element.id,element.ingreso.hora,salida];
      let row = user.join(",");
      diasCsv+= row + "\r\n";
    
  });
  saveAs(diasCsv,"personas-visita-"+this.visita.descripcion+".csv");
}



subir(){
  this.subird=true   

  
  this.docForm.patchValue({
   id: this.visita._id,

  });
 
}

onFileChange(event) {
let reader = new FileReader();

if (event.target.files.length > 0) {
  const file = event.target.files[0];
  reader.readAsDataURL(file);
  reader.onload = () => {

    this.docForm.patchValue({
      doc_pdf: reader.result
    });          
    this.cd.markForCheck();
  };

}
}

upDoc(){
this.authService.subirdocVisita(this.docForm.value)
.then((response)=>{
  console.log(response);
  this.subird=false
 this.visita.documentos.push(response["doc"])
  this.ngOnInit();
})
.catch((err)=>{
  this.subird=false
})

}

deletedocu(url){

this.deletedoc.patchValue({
  url: url,
 id:this.visita._id,

});

this.authService.borrardocVisita(this.deletedoc.value)
.then((response)=>{
     this.ngOnInit();
     var index = this.visita.documentos.indexOf(url);
             if (index > -1) {
              this.visita.documentos.splice(index, 1);
             }

})
.catch((err)=>{
  
})
}



}
