

  <div class="row justify-content-center">
     
      <div style="padding: 0px;" class="col-md-8">
        <div style="background-color: #666; width: 100%; padding: 10px; border-radius: 15px; margin-top: 15%;"  class="row justify-content-center">
  
      
          <div class="col-2 caja_numero">
            <div style="position: relative;  ">
              <div class="numero_contador" id="dias" ></div>
             <div class="texto_contador">Días</div>
  
            </div>
            
          </div>
          &nbsp;&nbsp;&nbsp;
          <div  class="col-2 caja_numero ">
            <div style="position: relative; ">
              <div class="numero_contador" id="horas"></div>
             <div class="texto_contador">Horas</div>
            </div>
            <br>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div class="col-3 caja_numero ">
            <div style="position: relative; ">
              <div class="numero_contador" id="minutos"></div>
             <div class="texto_contador">Minutos</div>
            </div>
            
          </div>
          &nbsp;&nbsp;&nbsp;
          <div class="col-3 caja_numero ">
            <div style="position: relative; ">
              <div class="numero_contador" id="segundos"></div>
             <div class="texto_contador" >Segundos</div>
            </div>
            
          </div>
  
         </div>
      </div>
      <div class="row justify-content-center " *ngIf="user.rol!='usuario' " > 
        <a href="{{url1}}"><button class="btn btn-primary" >Ingreso a conferencistas sala 1</button></a>
     
      </div>

  </div>
  <div class="row">
      <div class="col-12">
          
      </div>
  </div>
















