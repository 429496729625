import { Component, OnInit , ElementRef,ChangeDetectorRef, HostListener} from '@angular/core';
import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service";
import {SwPush} from '@angular/service-worker'
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { interval } from 'rxjs';
import  Swal from 'sweetalert2'; 
import { saveAs } from 'file-saver';
import {NotificaService} from '../../services/notifica.service'

@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.css']
})
export class AdministracionComponent implements OnInit {
active=false;
areaForm;
eventForm;
eventos;
areas;
vareas;
veventos;
vvisitas;
vingresos: boolean = true;
vbitacora;
user;
Tingreso:String="hoy"
hoy=[];
anteriores=[];
proximas=[];
porteria;
bita;
vanteriores;
vhoy;
vproximas;
varchivados=false;
menu;
notaForm: any;
addResponsables=[];
  submitted: boolean;
  submitted2: boolean;
  areasCargadas: boolean;
  solicitudes: any;
  ingresos: any;
  ingresosd: any;
  fechaForm;
  fechaForm2;
  fecha: string;
  newNota: boolean;
  mensajes: any;
  vmensajes;
  nuevos: number;
  empresas=[];
  sDay:Boolean=false;
  sFecha:Boolean=false;
  yabusco: boolean =true;
  eventosArchivados: any;
  empresasIngresos: any[];
  empresasIngresos_: any[];
  empresaBuscadaNombre: any;
  range: FormGroup;
  initdate: string;
  enddate: string;
  findRange: boolean;
  vingresosfecha: boolean= false;
  yabuscoCedula: boolean;
  buscoDia: boolean;

  @HostListener('window:beforeinstallprompt', ['$event'])
onBeforeInstallPrompt(event: Event) {
  console.log('beforeinstallprompt Event fired');
  event.preventDefault();
  this.deferredPrompt = event;
}

  constructor(
    private authService : AuthService, 
    private EventService:EventsService,
    private Authservice: AuthService,
    private elRef:ElementRef,
    private notiService: NotificaService,
    private swPush:SwPush,
    private cd :ChangeDetectorRef,

  ) { 
    this.areaForm = new FormGroup({
      'id':new FormControl("0",[Validators.required,]),
      'nombre':new FormControl("",[Validators.required,]),
      'responsables':new FormControl([],[Validators.required]),
      
    })

  
     
  }
  showAddToHomeScreenButton = false;

  ngOnInit(): void {

    const isAndroid = /Android/i.test(navigator.userAgent);

    if (isAndroid && !window.matchMedia('(display-mode: standalone)').matches) {
      // Mostrar el botón de instalación solo si es Android y no está en el modo "standalone"
      this.showAddToHomeScreenButton = true;
    }
    

    this.range = new FormGroup({
      start: new FormControl(<Date | null>(null)),
      end: new FormControl(<Date | null>(null)),
    });
    this.fechaForm= new FormGroup({
      'fecha':new FormControl("",[Validators.required]),
    })
    this.fechaForm2= new FormGroup({
      'fecha':new FormControl("",[Validators.required]),
    })
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    //console.log(this.user); 
   // this.validaPass();
   this.notaForm = new FormGroup({
    "id": new FormControl('', ),  
    'question': new FormControl('', [
      Validators.required,
      Validators.minLength(3)
    ]),              
    'time': new FormControl('', ),    
  });
  //cargando salidas 

  this.newFecha4()
 this.loaduser();
 //this.suscribirse()
  }

  loaduser(){
    this.authService.validatep(this.user.id)
    .then((response) => {
      console.log(response);

      this.authService.setUserInfo({'user' : response['user']});
    
      let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    if(this.user.rol=="admin"){
      this.active=true;
      this.getAreas();
      this.getEvents();
      this.verVisitas();
      this.veringresos();
      this.lookConver();
     }
  else{
    location.href="/login"
  }


    })
    .catch((err)=>{
      location.href="/login"
    })
    
  
     
         
  }



  suscribirse(){
    this.swPush.requestSubscription({serverPublicKey:"BLvfQsuQLQIfjnOCFbKErwxXpDA_PHL3YY-f5isJOz48d7iNHkKKvScbGsXZ820EY5n6zrhYqnGZ03CAZz2jE5Q"}).then((r:any)=>{
      console.log(r)  
      if(r){
        /* this.notiService.suscribirse(r).then((r)=>{

        }) */
      }
      
    })
  }
  


  addEvent(type,event){
 
  var value=this.range.value;
  if(value.end && value.start){
    console.log(this.range.value)
    //calcular y mostrar rango de fechas
    this.initdate=value.start.getUTCDate()+"/"+(value.start.getMonth()+1)+"/"+value.start.getUTCFullYear();
    this.enddate=value.end.getUTCDate()+"/"+(value.end.getMonth()+1)+"/"+value.end.getUTCFullYear();


  }
}

newFechaRange(){
  this.ingresosd=[]

  var value=this.range.value;
  this.initdate=value.start.getUTCDate()+"/"+(value.start.getUTCMonth()+1)+"/"+value.start.getUTCFullYear();
  this.enddate=value.end.getUTCDate()+"/"+(value.end.getUTCMonth()+1)+"/"+value.end.getUTCFullYear();
    var idia=value.start.getUTCDate();
    var imes=value.start.getUTCMonth()+1;
    var iano=value.start.getFullYear();
    var edia=value.end.getUTCDate();
    var emes=value.end.getUTCMonth()+1;
    var eano=value.end.getFullYear();


if(iano!=eano){
  Swal.fire("El rango de fechas debe estar en el mismo año")
  .then(()=>{
    this.range.reset()
    return
  })
}
else if(iano==eano && emes-imes>1){
  Swal.fire("El rango de fechas es muy extenso","Por favor seleccinoes un rango de maximo 20 días")
  .then(()=>{
    this.range.reset()
    return
  })
}
else{
  this.authService.getingresosrango(idia,imes,iano,edia,emes,eano)
  .then((response)=>{
console.log(response);
this.findRange=true;
this.ingresosd=response["ingresos"]
this.horaingreso(this.ingresosd);
  })
}
  
}
ingresoNum: number = 0;
  onNewConversations(conver){
    console.log("evento: ",conver);
    this.mensajes=conver;
    this.nuevos=0;
    this.mensajes.forEach(element => {
      if(element.new!=""&& element.new!=this.user.nit){
      this.nuevos+=1;
      }
    });
  }

 
lookConver(){
this.authService.lookConver(this.user.id)
.then((response)=>{
console.log(response);
this.mensajes=response["mensajes"];
this.nuevos=0;
this.mensajes.forEach(element => {
if(element.new!=""&& element.new!=this.user.nit){
this.nuevos+=1;
}
});

})
.catch((err)=>{
console.log(err)
})

}
  get f() { return this.areaForm.controls; }
  get f2() { return this.eventForm.controls; }

  newFecha(){
    this.ingresosd=[]
  //  console.log(this.fechaForm.controls)
    var date=new Date(this.fechaForm.controls["fecha"].value.toString());
    console.log(date)
    this.fecha = date.getUTCDate()+"/"+(date.getUTCMonth()+1)+"/"+date.getUTCFullYear();
  
    var dia=date.getUTCDate();
    var mes=date.getUTCMonth()+1;
    var ano=date.getFullYear();
    console.log(dia,mes,ano)
    this.authService.getingresosdia(dia,mes,ano)
    .then((response)=>{
  console.log(response);
  this.ingresosd=response["ingresos"]
  
  this.buscoDia=true;
  this.horaingreso(this.ingresosd);
    })
  }
  deferredPrompt: any;

  addToHomeScreen() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice
        .then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('Usuario aceptó la instalación de la aplicación');
          } else {
            console.log('Usuario canceló la instalación de la aplicación');
          }
          this.deferredPrompt = undefined;
        });
    }
  }

  newFecha4() {
    this.ingresosd = [];
    
    var fechaValue = this.fechaForm.controls["fecha"].value;
    var date;
    
    if (fechaValue) {
      date = new Date(fechaValue.toString());
    } else {
      date = new Date(); // Set the date to current date
    }
    this.fecha = date.getUTCDate() + "/" + (date.getUTCMonth() + 1) + "/" + date.getUTCFullYear();
    var dia = date.getUTCDate();
    var mes = date.getUTCMonth() + 1;
    var ano = date.getFullYear();
    console.log(dia, mes, ano);
    
    this.authService.getingresosdia(dia, mes, ano)
      .then((response) => {
        this.ingresosd = response["ingresos"].filter(ingreso => ingreso.salida);
        console.log("holaa", this.ingresosd );
        
        this.ingresoNum =this.ingresosd.length;
        this.buscoDia = true;
        this.horaingreso(this.ingresosd);
      });
  }
  

  newFecha2(){
    console.log(this.fechaForm2.controls)
    var date=new Date(this.fechaForm2.controls["fecha"].value.toString());
    this.fecha= date.getUTCDate()+"/"+(date.getUTCMonth()+1)+"/"+date.getUTCFullYear();
  
    var dia=date.getUTCDate();
    var mes=date.getUTCMonth()+1;
    var ano=date.getFullYear();
    console.log(dia,mes,ano)
    this.authService.getvisitasanterior(dia,mes,ano)
    .then((response)=>{
  console.log(response);
  this.anteriores.sort( this.compare );
    this.anteriores=response["visitas"];
    this.anteriores.sort( this.compare );
    this.anteriores.reverse()
    this.cargarhoras(this.anteriores);
    })
  }
  verhoy(){
    this.getsolicitudeshoy();
    this.vhoy=true
    this.vanteriores=false;
    this.vproximas=false;
    this.vingresos=false
    this.vbitacora=false;
  }
  verproxi(){
    this.getsolicitudesproxima();
    this.vproximas=true;
    this.vhoy=false;
    this.vanteriores=false;
this.vingresos=false
this.vbitacora=false;
  }
  
verantes(){
//  this.getsolicitudesanterior();
  this.vanteriores=true;
  this.vhoy=false;
this.vingresos=false
  this.vproximas=false
  this.vbitacora=false;
}
veringresos(){
  this.vingresosfecha=false;

this.empresasIngresos=[];
this.empresasIngresos_=[];
this.ingresos = true;
this.vingresos=true;

this.Tingreso="hoy"
this.yabusco=false;
this.vanteriores=false;
  this.vhoy=false;
  this.vproximas=false;
  this.vbitacora=false;
  this.getingresos();
  //this.getingresoshoy();
 // this.cd.detectChanges();
  
}
verIngresoFecha2(): void{
  this.empresasIngresos=[];
this.empresasIngresos_=[];
this.ingresosd=[];
  this.vingresosfecha=true;  
  this.sDay=false;
  this.sFecha=false;
  this.yabusco=false;
  this.buscoDia=false;
  this.Tingreso="fecha"
  //this.vingresos=false;
this.vanteriores=false;
//this.vhoy=false
this.vproximas=false;
this.vbitacora=false;
}

verIngresoFecha(): void{
this.vingresosfecha=true;  
this.empresasIngresos=[];
this.empresasIngresos_=[];
this.ingresosd=[];
this.sDay=false;
this.sFecha=false;
this.yabusco=false;
this.buscoDia=false;
this.Tingreso="fecha"
this.vingresos=false;
this.vanteriores=false;
  this.vhoy=false;
  this.vproximas=false;
  this.vbitacora=false;
}

verbitacora(){
  this.veventos=false;
  this.vareas=false;
  this.vvisitas=false;
  this.vingresos=false;
this.vanteriores=false;
  this.vhoy=false;
  this.vproximas=false;
  this.vbitacora=true;
}
buscarDia(){
  this.sDay=true;
  this.sFecha=false
  //this.getingresoshoy();
}
buscarFecha(){
  this.ingresosd=[];
  this.sDay=false;
  this.sFecha=true;
}

compare( a, b ) {
  if ( a.fecha < b.fecha ){
    
    return -1;
  }
  if ( a.fecha > b.fecha ){
    return 1;
  }
  return 0;
}
getingresos(){
  this.EventService.getIngresos()
  .then((response)=>{
    this.ingresos=response["results"]
    this.ingresos.sort(this.compare);
    this.horaingreso(this.ingresos);
    console.log("ingresos",this.ingresos)
  })
}
getingresoshoy(){
  var date=new Date();
  this.fecha= date.getDate()+"/"+(date.getUTCMonth()+1)+"/"+date.getUTCFullYear();

  var dia=date.getDate();
  var mes=date.getUTCMonth()+1;
  var ano=date.getFullYear();
  this.authService.getingresosdia(dia,mes,ano)
  .then((response)=>{
console.log(response);
this.ingresosd=response["ingresos"];
this.ingresosd.sort( this.compare );
this.horaingreso(this.ingresosd);
  })
}
deleteIngresos(){


  Swal.fire(     {
    title: "Se borraran todos los ingresos registrados",
    text:"El personal no podra marcar su salida",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#89c9b8',
    confirmButtonText: 'Confirmar',
    cancelButtonText: "Cancelar"
    
}).then((result)=>{
if(result.value){

  this.EventService.deleteIngresos()
  .then(()=>{
    this.getingresos();
  })
 
  
}
else{}
});



}


deleteIngresosPasados(){
  var date=new Date();
  
  var dia=date.getUTCDate();
  var mes=date.getMonth()+1;
  var ano=date.getFullYear();
  console.log(dia,mes,ano)
  Swal.fire(     {
    title: "Se borraran todos los ingresos registrados ateriores a esta fecha",
    text:"¿Esta seguro?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#89c9b8',
    confirmButtonText: 'Confirmar',
    cancelButtonText: "Cancelar"
    
}).then((result)=>{
if(result.value){

  this.EventService.deleteIngresosPasados(dia,mes,ano)
  .then(()=>{
    this.getingresos();
  })
 
  
}
else{}
});



}
  getsolicitudeshoy(){
    var date=new Date();
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    this.authService.getvisitashoyaprobar(dia,mes,ano)
    .then((response)=>{
  console.log(response);
  
      
    this.hoy=response["visitas"];
    this.hoy.sort( this.compare );
    this.cargarhoras(this.hoy);
    })
  }
  getsolicitudesproxima(){
    var date=new Date();
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    
    this.authService.getvisitasproxima(dia,mes,ano)
    .then((response)=>{
  console.log(response);
     
    this.proximas =response["visitas"];
    this.proximas.sort( this.compare );
    this.cargarhoras(this.proximas);
    })
  }
  getsolicitudesanterior(){
    var date=new Date();
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    this.authService.getvisitasanterior(dia,mes,ano)
    .then((response)=>{
  console.log(response);
  this.anteriores.sort( this.compare );
    this.anteriores=response["visitas"];
    this.anteriores.sort( this.compare );
    this.anteriores.reverse()
    this.cargarhoras(this.anteriores);
    })
  }

horaingreso(arr){
  
  arr.forEach(solicitud => {
   // console.log("visita: ",solicitud);
    var date= new Date(solicitud.fecha);
     var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
     solicitud.fechat=fecha;  
     if(solicitud.salida){
      var date2= new Date(solicitud.fechaSalida);
      var fecha2= date2.getDate()+"/"+(date2.getMonth()+1)+"/"+date2.getUTCFullYear()+" hora: "+date2.getHours()+":"+(date2.getMinutes() < 10 ? '0' : '') + date2.getMinutes();
      solicitud.fechas=fecha2;  
     }
  

  }); 
}

  cargarhoras(arr){
    this.empresas=[];
    var empresas=[];
    arr.forEach(solicitud => {
    //  console.log("visita: ",solicitud);
      var date= new Date(solicitud.fecha);
       var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
       solicitud.fechat=fecha;
      solicitud.open=false; 
      var aprobada=0; 
      var ingresosp=solicitud.ingresos.map((el)=>{return el.persona})
   solicitud.personasObj.forEach(p => {
    if(ingresosp.includes(p._id)){
      p.ingreso=solicitud.ingresos[ingresosp.indexOf(p._id)]

    }
   });
     
    
  solicitud.aprobada=aprobada;
    }); 
  }

  newnota(id){
    this.newNota=true;
    this.notaForm.patchValue({
    id:id  
    });
  
  }
  closenota(){
     if(this.newNota){
       this.newNota=false;
       this.notaForm.reset();
     }
   
  }
  nopro(event){
    event.stopPropagation();
  }
  sendnota(){
    if (this.notaForm.invalid) {
      this.newNota=false;
      this.ngOnInit();
  }
    var now= new Date();
    var n = now.getUTCHours();
    if(n<5){
      n=24+n; 
    } 
   now.setHours(n-5);  
    this.notaForm.patchValue({
      time: now  
    });
  
    this.authService.newnota(this.notaForm.value,this.user)
    .then((response)=>{
      this.newNota=false;
    this.ngOnInit();
    })
  .catch((err)=>{
    this.newNota=false;
  })
  
  }
  verEventos(){
    this.veventos=true;
    this.vareas=false;
    this.vvisitas=false;
    this.vbitacora=false;
  }
  
  verAreas(){
    this.veventos=false;
    this.vareas=true;
    this.vvisitas=false;
    this.vbitacora=false;
  }
  verVisitas(){
    this.veventos=false;
    this.vareas=false;
    this.vvisitas=true;
    this.getsolicitudeshoy();
    this.vhoy=true;
    this.vbitacora=false;


  }
  
  
  eliminar(id){
    console.log(id);
    this.authService.eliminarVisita(id)
    .then((response) => {
     this.verhoy()
  })
  }
async validaPass(){
  const { value: pass } = await Swal.fire({
    title: 'Ingresa la contraseña de admnistrador para acceder',
    input: 'text',
    background:"#F0F8FF",
    inputPlaceholder: 'Contraseña',
    confirmButtonColor:"#696969"
  })
   
  if (pass) {
  this.Authservice.password("admin",pass)
  .then((response)=>{
this.active=true;
this.getAreas();
this.getEvents();
  })
  
.catch((err)=>{
  Swal.fire("Contraseña incorrecta")
  .then(()=>{
    this.ngOnInit()
  })

})

  
  
  }
  else{
    this.ngOnInit()
  }
}


getEvents(){
this.EventService.getAllEvents()
.then((result)=>{
  console.log(result)
  this.eventos=result["results"].filter((el)=>{return !el.archivado}); 
  this.eventosArchivados=result["results"].filter((el)=>{return el.archivado});
  console.log(this.eventos);
})
}
createFormaray(Inputs) {
  console.log(Inputs);
    const arr = Inputs.map(marca => {
      return new FormControl(marca.selected || false);
    });
    return new FormArray(arr);
  }



getAreas(){
  this.EventService.getAllAreas()
  .then((result)=>{
    this.areas=result["results"];
    //ordenar areas alfabeticamente.
    function compare( a, b ) {
      if ( a.nombre < b.nombre ){
        return -1;
      }
      if ( a.nombre > b.nombre ){
        return 1;
      }
      return 0;
    }
    this.areas.sort(compare);
    
    this.eventForm=new FormGroup({
      areasc: this.createFormaray(this.areas),
      'id':new FormControl("0",[Validators.required,]),
      "nombre": new FormControl("",[Validators.required]),
      "fecha":new FormControl("",[Validators.required]),
      "descripcion":new FormControl("",[Validators.required]),      
     
      "areas":new FormControl([], [
      ]),
    })
    this.areasCargadas=true;
  })
}

async getSelected(array,ref) {
    
  var selectedMarcas = array.map( (marcas, i) => {
    return marcas.value && {
      "id":ref[i]._id,
      "name":ref[i].nombre,
 
   
      };
  });


 var selectedNames = selectedMarcas.filter( function (marca) {
  if (marca !== false) {
    return marca;
  }
});
console.log(selectedNames);
return selectedNames;

}

async newEvent(){
  this.submitted2 = true;
  var marcas=await this.getSelected(this.eventForm.controls.areasc['controls'],this.areas);
 console.log(marcas);
       this.eventForm.patchValue({
   areas:marcas
    })
  if (this.eventForm.invalid) {
    console.log("no enviado ");
    return;
    
}
//console.log(this.eventForm.value);
this.EventService.createEvent(this.eventForm.value)
.then(()=>{
  Swal.fire("evento creado correctamente");
  this.getEvents();
  var evento:Object ={
    id:"0"}
  this.eventForm.reset(evento);
  this.submitted2=false;
})
.catch(()=>{
  Swal.fire("hubo en error realizando la acción");
})


}



newArea(){
  this.submitted = true;

  this.areaForm.patchValue({
    responsables:this.addResponsables
     })

  if (this.areaForm.invalid) {
    console.log("no enviado ");
    return;
}
this.EventService.createArea(this.areaForm.value)
.then(()=>{
  Swal.fire("creada correctamente");
  this.getAreas();
  this.areaForm.reset();
  this.addResponsables=[];
  var evento:Object ={
    id:"0"}
  this.areaForm.reset(evento);
  this.submitted=false;
})
.catch(()=>{
  Swal.fire("hubo en error realizando la acción");
})
}

deleteEvent(id){
this.EventService.deleteEvent(id)
.then((response)=>{
  console.log(response);
  this.getEvents();
})
.catch((err)=>{
  console.log(err);
})

}
deleteArea(id){
  this.EventService.deleteArea(id)
  .then((response)=>{
    console.log(response);
    this.getAreas();
  })
  .catch((err)=>{
    console.log(err);
  })
  
  }

editEvent(event){
  var evento:Object ={
    id:event._id,
    nombre:event.nombre,
    descripcion:event.descripcion,
    fecha: event.fecha,
   

  }
  this.eventForm.reset(evento);
}

editArea(area){
  this.addResponsables=area.responsables;
  var evento:Object ={
    id:area._id,
    nombre:area.nombre,
  

  }
  this.areaForm.reset(evento);
}

addRes(){
  var id=this.elRef.nativeElement.querySelector('#resp_').value;
  this.addResponsables.push(id);
  this.elRef.nativeElement.querySelector('#resp_').value=""
}

borrarResp(i){
  this.addResponsables.splice(i, 1);
}


verResponsables(area){

  area.personas=[];
area.responsables.forEach(element => {
  this.authService.validatep(element)
  .then((response)=>{
    area.personas.push(response["user"])

  })
  .catch((err)=>{})
}

)
area.open=true;

}


buscar(i="0"){
  console.log(i,this.Tingreso);
  if(i=="0" && this.Tingreso=="hoy"){
    var buscado=this.elRef.nativeElement.querySelector('#buscado').value;
    console.log("buscando",buscado);
    this.hoy=this.hoy.filter((usr)=>{ if(usr.empresa && usr.empresa._id){
      return  usr.empresa._id== buscado}
    
    else{
  return false}
    });
    this.yabusco=true;
  }

  else if(i=="2" && this.Tingreso=="hoy"){
   
    var buscado=this.elRef.nativeElement.querySelector('#buscadoi').value;
    console.log("buscando",buscado);
    this.ingresos=this.ingresos.filter((usr)=>{ if(usr.user.empresa ){
      return  usr.user.empresa== buscado}
    
    else{
  return false}
    });
   
    this.ingresosd=this.ingresosd.filter((usr)=>{ if(usr.user.empresa ){
      return  usr.user.empresa== buscado}
    
    else{
  return false}
    });
    this.yabusco=true;
  }
  else if(i=="1" && this.Tingreso=="hoy"){
    var buscado=this.elRef.nativeElement.querySelector('#buscado2').value;
   this.ingresos= this.ingresos.filter((el)=>{return el.user.id.includes(buscado)})
   this.yabuscoCedula=true;
  }

  if(i=="0" && this.Tingreso=="fecha"){
    var buscado=this.elRef.nativeElement.querySelector('#buscado').value;
    console.log("buscando",buscado);
    this.hoy=this.hoy.filter((usr)=>{ if(usr.empresa && usr.empresa._id){
      return  usr.empresa._id== buscado}
    
    else{
  return false}
    });
    this.yabusco=true;
  }

  else if(i=="2" && this.Tingreso=="fecha"){
   
    var buscado=this.elRef.nativeElement.querySelector('#buscadoi').value;
    console.log("buscando",buscado);
  
   
    this.ingresosd=this.ingresosd.filter((usr)=>{ if(usr.user.empresa ){
      return  usr.user.empresa== buscado}
    
    else{
  return false}
    });
    this.yabusco=true;
  }
  else if(i=="1" && this.Tingreso=="fecha"){
    var buscado=this.elRef.nativeElement.querySelector('#buscado2').value;
   this.ingresosd= this.ingresosd.filter((el)=>{return el.user.id.includes(buscado)})
   this.yabuscoCedula=true;
  }
 
}
limpiar(i="0"){
  if(i=="0"){
    this.verhoy();
    this.yabusco=false;
  }
  else if(i=="2" && this.Tingreso=="hoy"){
    this.veringresos();
    this.yabusco=false;
    this.elRef.nativeElement.querySelector('#buscadoi').value=""
  }
  else if(i=="1" && this.Tingreso=="hoy"){
    this.getingresos();
    this.yabuscoCedula=false;
    this.elRef.nativeElement.querySelector('#buscado2').value="";
  }
  else if(i=="2" && this.Tingreso=="fecha"){
  //  this.verIngresoFecha();
  if(this.sDay==true){
    this.newFecha();
    this.yabusco=false;
    this.elRef.nativeElement.querySelector('#buscadoi').value=""
  }
  if(this.sFecha){
    this.newFechaRange();
    this.yabusco=false;
    this.elRef.nativeElement.querySelector('#buscadoi').value=""
  }

  }
  else if(i=="1" && this.Tingreso=="fecha"){
    if(this.sDay==true){
      this.newFecha();
      this.yabuscoCedula=false;
      this.elRef.nativeElement.querySelector('#buscado2').value="";
    }
    if(this.sFecha){
      this.newFechaRange();
      this.yabusco=false;
      this.elRef.nativeElement.querySelector('#buscadoi').value=""
    }

  }
}


descagarDia(){
  let funcionariosCsv = "data:text/csv;charset=utf-8,";
  let diasCsv= "data:text/csv;charset=utf-8,";
  var user;
  var dia=this.ingresosd[0].dia;
  var mes=this.ingresosd[0].mes;
  this.ingresosd.forEach(element => {
    if(element.user.funcionario){
      var salida;
      if(element.fechas){
         salida=element.fechas
      }
      else{
        salida="no registra salida"
      }
      user=[element.user.name+" "+element.user.apellido,element.user.id,element.fechat,salida];
      let row = user.join(",");
      funcionariosCsv+= row + "\r\n";
    }
    else{
      var salida;
      var empresa;
      if  (element.empresa && element.empresa.name  && element.empresa.name!=null){
        empresa=element.empresa.name
      }
      else{
        empresa=element.empresa
      }
      if(element.fechas){
         salida=element.fechas
      }
      else{
        salida="no registra salida"
      }
      user=[element.user.name+" "+element.user.apellido,element.user.id,empresa,element.fechat,salida];
      let row = user.join(",");
      diasCsv+= row + "\r\n";
    }
  });
  saveAs(diasCsv,"ingresosdia"+dia+"/"+mes+".csv");
  saveAs(funcionariosCsv,"ingresosfuncnioarios"+dia+"/"+mes+".csv");
}
descargarMes(){

}
 funcionario: number = 0;
 visitantes: number = 0;
 socio: number = 0;
get funcionarios() {

  const filtroLista = this.ingresos.filter(item => item.user.funcionario == true)
  this.funcionario = filtroLista.length;
  return filtroLista
}
get empleados() {
  const filtroLista = this.ingresos.filter(item => item.user.categoria == "invitado")
  this.visitantes = filtroLista.length;
  return filtroLista
}

get socios() {
  const filtroLista = this.ingresos.filter(item => item.user.categoria == "socio" || item.user.categoria == "beneficiario")
  this.socio = filtroLista.length;
  return filtroLista
}

}
