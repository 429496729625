

<div *ngIf="user.rol=='usuario'" style="  position: fixed; bottom:10px; right: 2px; z-index: 200;  text-align: center; color: #999; padding: 10px">
    <div style="  position: absolute; bottom:0px; right: 8px;   text-align: center; color: #999; padding: 10px">
  
        <button class="btn btn-orange" type="button" id="btnChat" #btnChat (click)="openChat()" >
         <strong>Solicitar videollamada</strong> 
        </button>
       <!-- <img src="./imagen/icon_soporte_small.png"> -->
      </div>
      
    <div #chat [style.display]="'none'"  >
        <app-chat >
        </app-chat>
        <button (click)="closeChat()" class="btn btn-danger btnChat " >Cerrar</button>
    
    </div>
</div>



    <div style="padding: 2px; text-align: center;">
        <h1 style="color: white" >Bienvenido {{user.name}} </h1> 
        <h3 style="color: white" *ngIf="event"  >{{event.name}}</h3>
  
      
          
          <div class="row justify-content-center" *ngIf="event">
            <div *ngIf="!session" class=" col-md-8 embed-responsive embed-responsive-16by9">
                <iframe id="iframe" class="embed-responsive-item"  width="98%" frameborder="0" [src]="event.eventSrc | safe" allowfullscreen style="display: block;"></iframe>
                <div  style=" text-align: end;  position: absolute; bottom: 100px;right: 2px; z-index: 99999999;" >
<img src="{{img.src}}" alt="" [@bounceInUpOnEnter] [@bounceOutUpOnLeave]  width="30px;"  *ngFor="let img of exisemoji" >
                </div>
            </div>

          
 
            <div class=" col-md-4">
              <div >
                <button *ngIf="user.rol=='admin' && session" class="bnt btn-primary" (click)="cerrar_llamada()">Cerrar llamada</button>
                <button *ngFor="let usr of connectedUsers" class="bnt btn-primary" (click)="colgar(usr.correo)"> colgar {{usr.name}} </button>
              </div>
              <div style="background-color: #eaeaea; padding: 1px; text-align: left; border-radius: 2px 2px 2px 2px">
                
                

                <div>Envía una pregunta o saludo</div>
                <textarea style="border:1px solid #ffffff; width: 100%; border-radius: 2px 2px 2px 2px" name="preguta" id="pregunta" cols="30" rows="4" placeholder="Pregunta o saludo"></textarea>
                <button (click)="sendQuestion()" class="btn btn-orange" >Enviar</button>
       
              </div>
  
              <div style="background-color: #eaeaea; padding: 1px !important ; text-align: left; border-radius: 2px 2px 2px 2px">

              
                <div class="row justify-content-center   "  >
                  <div  class="col-3" >
Reacciona:
                  </div>
                  <div class="col-1 m-0 p-0  ml-1 mr-1" >
                      <img src="/assets/emojis/love.png"   (click)="emojistart('love')" width="100%" style="cursor: pointer;  border: solid black 1px; border-radius: 10px;  " alt="">
                  </div>
                  <div class="col-1 m-0 p-0 ml-1 mr-1" >
                          <img src="/assets/emojis/like.gif"  (click)="emojistart('like')" width="100%" style="cursor: pointer;  border: solid black 1px; border-radius: 10px; padding: 1px; " alt="">
                  </div>
  
                  <div class="col-1 m-0 p-0 ml-1 mr-1" >
                        <img src="/assets/emojis/angry.png"  (click)="emojistart('angry')" width="100%" style="cursor: pointer;  border: solid black 1px; border-radius: 10px;  padding: 1px;" alt="">
                  </div>
                                      
  
  
                </div>
            
              </div>
           
  
              <div style="background-color: #eaeaea; padding: 10px; text-align: left; border-radius: 2px 2px 2px 2px" *ngIf="activePull">
                    <textarea disabled name="pull" id="pull" cols="30" rows="5">{{pull_txt}}</textarea><br>
                    <div style="margin-top: 10px">
                    <button (click)="pull('a')" class="btn btn-primary" >A</button> <br>
                    <button (click)="pull('b')"  class="btn btn-primary">B</button> <br>
                    <button  (click)="pull('c')"  class="btn btn-primary">C</button> <br>
                </div>


  
              </div>
  
              <br>
  
  
  
              
  
  
           
             
  
            </div>
          </div>
          <br>
          <br>
    
        </div>

       
            <div *ngIf="user.rol=='admin'"  >
                <div *ngFor="let usr of existingUsrs" >
                    <div id="chat{{usr.id}}" style="display: none;">
                <app-chat  [remoteUser]="usr" >
                </app-chat>
                <button (click)="closeChatAdmin(usr.id)" class="btn btn-danger btnChat " >Cerrar</button>
                <button (click)="inviteuser(usr)" class="btn btn-danger btnCall "  >Invitar</button>
                </div>
                </div>
                </div> 
                <div *ngIf="user.rol=='admin'" class="bg-dark text-white m-2 w-50" >
                    <textarea name="spull" id="spull" cols="30" rows="8"></textarea>
                    <br>
                    <button (click)="sendPull()"  class="btn btn-primary m-2" >Enviar</button>
                    <button (click)="closePull()"  class="btn btn-danger m-2" >Cerrar</button>
                    <br>
                    <br>
                    <div class="row">
                    <a href="/preguntas/{{event._id}}" target="black">
                    <button class="btn btn-primary m-2">Preguntas</button>
                    </a>
                    <a href="/votaciones/{{event._id}}" target="black">
                        <button class="btn btn-primary m-2">Votaciones</button>
                        </a>
                        <button (click)="sendPanic()" >Recargar a todos</button>
                </div>
                <div class="row justify-content-center   "   *ngIf="user.rol=='admin'" >
                  <div class="col-1 m-0 p-0  ml-1 mr-1" >
                      <img src="/assets/emojis/love.png"    width="100%" style=" border: solid black 1px; border-radius: 10px;  " alt="">{{event.reactions.love}}
                      
                  </div>
                  <div class="col-1 m-0 p-0 ml-1 mr-1" >
                          <img src="/assets/emojis/like.gif"   width="100%" style=" border: solid black 1px; border-radius: 10px; padding: 1px; " alt="">  {{event.reactions.like}}
                         
                  </div>
  
                  <div class="col-1 m-0 p-0 ml-1 mr-1" >
                        <img src="/assets/emojis/angry.png"   width="100%" style="  border: solid black 1px; border-radius: 10px;  padding: 1px;" alt="">  {{event.reactions.angry}}
                       
                  </div>
                                      
  
  
                </div>
            
                
                </div>
  





                <div class="row justify-content-center" >
                  <div class="container text-white" *ngIf="user.rol=='admin'">
                      <button (click)="downUsers()" class="btn btn-primary" >Descargar audiencia</button><br>
                      <h3>Usuarios en linea: {{existingUsrs?.length || '0' }} </h3> 
                      <ul>
                          
                  <li (click)="openChatAdmin(usr.id)" *ngFor="let usr of existingUsrs" id="li{{usr.id}}">
                  {{usr.name}}
                  </li>
                      </ul>  
                   </div>
                </div>






<div *ngIf="callactive">
    <h2>llamada activa</h2>
    <div>
        local: 
        <video id="localVideo" autoplay="true"  width="400px"></video>
    </div>
    <hr>
    <div>
        remoto:
        <video id="remoteVideo" autoplay="true" width="400px"></video>
    </div>


</div>





<audio id="audio">
    <source src='/ring.mp3' type="audio/mp3">
</audio>



  <!-- <div *ngIf="session" id="session">
    <img src="/assets/boton_ingresar.png" id="img_sesion">
    <opv-session #ovSessionComponent [sessionName]="event.name" [user]="user.name" [tokens]="tokens" [ovSettings]="ovSettings"
      (sessionCreated)="handlerSessionCreatedEvent($event)" (participantCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)">
    </opv-session>
  
 </div>  -->