<div>


    <div class="card  caja-form2 " style="font-family:Arial, Helvetica, sans-serif; color: white !important; width: 100%;">
        <div class="d-flex justify-content-end" *ngIf="qr">
            <a href="/perfil"><img src="../../../assets/FondosClub/icon_edit.png" alt="" srcset=""></a>
        </div>
        <div class="row justify-content-between mt-3">
            <div class="col-md-8">
                <h4 *ngIf="!verCarnet" class="card-title" style="font-size: 18px; font-weight: bold;color: #04453b; ">Nombre:
                    {{user.name}} {{user.apellido}}</h4>
            </div>
            <div class="col-md-4">
                <button class="btn btn-orange" (click)="verCarnet=!verCarnet">{{verCarnet==false? 'Ver carnet': 'Ocultar'}}
                </button>
            </div>
        </div>
        <div *ngIf="verCarnet" class="mt-1">
            <p class="card-text">
                <img class="card-img-top" src={{user.photo1}} style="
                    padding: 5px;
                     margin: auto; 
                     width:auto;
                     border-radius: 15px;
                       border: 2px solid #04453b; 
                       max-height: 250px; 
                       max-width: 98%;
                       " alt="">
                <div class="card-body" style=" padding: 0px; height:auto; color: white; ">
                    <h4 class="card-title mb-1" style="font-size: 27px; font-weight: bold;color: #04453b; ">Nombre:
                        {{user.name}} {{user.apellido}}</h4>
                    <div class="row justify-content-between" >
                        
                        <div class="col-6 col-md-6 mt-4" >
                            <div >
                                
                                <p class="card-text">
                                    <span class="text" > Edad: <span style="font-weight: 600;" >{{user.age}} años</span> </span>
                                    <br>
                                    <span class="text" > Cedula: <span style="font-weight: 600;" >{{user.id}}</span> </span>
                                    <br>
                                    <span class="text"> Telefono: <span style="font-weight: 600;" >{{user.telefono}}</span></span>
                                    <br>
                              </p>
                
                            </div>
                        </div>
                        <div *ngIf="qr" class="col-6 col-md-6" >
                            <br>
                            <br>
                            <ngx-qrcode [elementType]="elementType" [value]="qrvalue" cssClass="aclass"
                            [errorCorrectionLevel]="correctionLevel" alt="Qr code" >
                          </ngx-qrcode>
                        </div>
                    </div>
                </div>
        </div>
       
    </div>

    <div *ngIf="show">
        <h2>Documentos: </h2>
        <div *ngFor="let link of user.documentos">
            <a href="{{link}}" target="_blank" rel="noopener noreferrer">
                <iframe [src]="link | safe" width="90%" height="200px" frameborder="0"></iframe> {{link}}</a>
            <button *ngIf="!porteria" class="btn btn-danger" (click)="deletedocu(link)"> Eliminar</button>
        </div>
        <div *ngIf="!porteria">
            <button (click)="subir()" class="btn btn-boton1">Subir documento</button>
        </div>
    </div>
    <div *ngIf="subird" class="row text-white   mt-5 cajainfo" style="position: fixed; bottom:40%; left:25%; z-index: 9999;">

        <div class="col-md-8">
            <form [formGroup]="docForm" (ngSubmit)="upDoc()" novalidate="">
                <div class="form-group">
                    <h3>Anexe el documento en formato PDF:</h3>
                    <input class="form-control" type="file" placeholder="pdf" accept=".pdf" (change)="onFileChange($event)"
                        required>
                </div>
                <input type="submit" value="Subir" class="btn btn-boton2" style="float: right; margin: 8px;">
                
               
            </form>
            <button (click)="subird=false"  class="btn btn-danger"  >Cancelar</button>
        </div>
        <div class="col-md-4" style="text-align: center;">
        
            <img src="../../../assets/Logos/Logo IP Services  Registro.png" width="50%"  />
        </div>


       
        
    </div>


</div>