import { Directive, ElementRef,Input } from '@angular/core';

@Directive({
  selector: '[appElemdisplay]'
})
export class ElemdisplayDirective {

  constructor( private el: ElementRef) {
    this.el.nativeElement.style.display = "none";
 }
 
private visibilizar(propiedad: string){
  this.el.nativeElement.style.display = propiedad;
}

}
