import { Component, OnInit } from '@angular/core';
import { ReservaService } from '../../services/reservas.services'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reserva-golf',
  templateUrl: './reserva-golf.component.html',
  styleUrls: ['./reserva-golf.component.css']
})
export class ReservaGolfComponent implements OnInit {

  user: any;
  type: any;
  dias = false;
  diaseleccion: any;
  manana: any
  hole: any
  fullname: any

  horas = [
    {
      "hora": "6:00 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "6:10 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "6:20 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "6:30 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "6:40 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "6:50 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "7:00 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "7:10 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "7:20 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "7:30 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "7:40 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "7:50 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "8:00 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "8:10 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    },
    {
      "hora": "8:20 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    }
    ,
    {
      "hora": "11:00 a.m",
      "reservado": false,
      "reservo": "",
      "idreserva": "",
      "ingresos": []
    }
  ]


  types = {
    "golf":{
      "fieldtype":"hoyo",
      "fields":[1,10]
    },
    "tennis":{
      "fieldtype":"cancha",
      "fields":[1,2,3,4]
    },
    "bolos":{
      "fieldtype":"pista",
      "fields":[1,10]
    },
    "sky":{
      "fieldtype":"lago",
      "fields":[1,10]
    } 
  }

  // horas = ["6:10", "6:20", "6:30", "6:40", "6:50", "7:00", "7:10", "7:20", "7:30", "7:40"]

  reservaForm: any

  constructor(private router: Router, private api: ReservaService, private datePipe: DatePipe) { }

  ngOnInit(): void {

    this.type = "golf"


    console.log(this.types["golf"])

    this.reservaForm = new FormGroup({
      'dia': new FormControl('', [
        Validators.required,
      ]),
      'hora': new FormControl('', [
        Validators.required,
      ]),
      'user': new FormControl('', [
        Validators.required,
      ])

    });

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + 1);
    this.manana = this.datePipe.transform(todayDate, 'yyyy-MM-dd');
    console.log("MAÑANA", this.manana)

    let user = localStorage.getItem('userInfo')
    this.user = JSON.parse(user).user;

    console.log("USER", this.user)

    this.fullname = this.user.name + " " + this.user.apellido

    console.log("FULL", this.fullname)


  }

  get f2() { return this.reservaForm.controls; }

  reservations(e: any, hole: any) {


    this.horas = [
      {
        "hora": "6:00 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "6:10 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "6:20 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "6:30 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "6:40 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "6:50 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "7:00 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "7:10 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "7:20 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "7:30 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "7:40 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "7:50 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "8:00 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "8:10 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      },
      {
        "hora": "8:20 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      }
      ,
      {
        "hora": "11:00 a.m",
        "reservado": false,
        "reservo": "",
        "idreserva": "",
        "ingresos": []
      }
    ]

    this.diaseleccion = e


    console.log(e)

    this.api.getReservas(e, this.hole, 'golf').then((r: any) => {
      console.log("RESERVAS", r)


      r.map((re: any) => {
        console.log(re.position)
        this.horas[re.position].reservado = true
        this.horas[re.position].reservo = re.userName
        this.horas[re.position].idreserva = re._id
        this.horas[re.position].ingresos = re.team
      })

      this.dias = true;

    })

  }

  reservar(ind: any, hora: any) {

    console.log(hora, ind)

  

  }


  datechange(e: any) {

    console.log(e.target.value)
    this.diaseleccion = e.target.value
    this.reservations(this.diaseleccion, this.hole)

  }

  readcode(i) {

  }


  holechange(e: any) {

    console.log(e.target.value)
    this.hole = e.target.value
    this.reservations(this.manana, e.target.value)

  }

  cancelar(id: any, i: any) {

    console.log(id)
    this.api.cancelar(id).then((r) => {
      console.log(r)

      this.horas[i].reservado = false
      this.horas[i].reservo = ""
      this.horas[i].idreserva = ""
      this.horas[i].ingresos = []


    })

  }

  reservalector(id: any, i: any) {
    console.log("IDRes", id, i)
    this.router.navigate(['/reservalector', { 'idr': id }]);
  }

}
