import { Component, OnInit,Input } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {
  @Input() user ={"name":"no nombre","nit":"1234","documentos":[],"tipo":"","responsable":{"name":"","correo":"","telefono":""},"personas":[],"_id":"000"};
  @Input() show=false;
  personasc=[];
  subird: boolean;
  docForm;
  deletedoc;
  
  constructor(

    private authService : AuthService,
     private eventService : EventsService,
      private router: Router,
       private cd: ChangeDetectorRef

  ) { }
  verPersonas;
  ngOnInit(): void {
    this.docForm= new FormGroup({

      "doc_pdf":new FormControl(''),
      "nit":new FormControl(''),
      "tipo":new FormControl(''),
    
    })
    this.deletedoc= new FormGroup({

      "url":new FormControl(''),
      "nit":new FormControl(''),
      "tipo":new FormControl(''),
    
    })
 //   this.loadpersonas();
  }

  loadpersonas(){
    this.verPersonas=true;
    this.personasc=[];
    this.user.personas.forEach(id => {
      this.authService.validatep(id)
      .then((response)=>{
        console.log(response);
    this.personasc.push(response["user"])
      })
      .catch((err)=>{
    console.log(err);
    this.authService.eliminarPersonadeempresa(id,this.user.nit)
    .then(()=>{
      console.log("borrado")
    })
      })
      });
    
    }



    subir(){
      this.subird=true   
      console.log(this.user);
      
      this.docForm.patchValue({
       nit: this.user.nit,
       tipo:this.user.tipo
      });
     
    }
  
    onFileChange(event) {
    let reader = new FileReader();
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
   
        this.docForm.patchValue({
          doc_pdf: reader.result
        });          
        this.cd.markForCheck();
      };
  
    }
  }
  
  upDoc(){
    this.authService.subirdoc(this.docForm.value)
    .then((response)=>{
      this.subird=false
      this.authService.validatec(this.user.nit)
      .then((response)=>{
         
        this.authService.setUserInfo({'user' : response['user']});
        let user = localStorage.getItem('userInfo')
        this.user= JSON.parse(user).user;
      })
      .catch((err)=>{
          
      })
    })
    .catch((err)=>{
      this.subird=false
    })
    
  }
  
  deletedocu(url){
    
    this.deletedoc.patchValue({
      url: url,
     nit:this.user.nit,
     tipo:this.user.tipo
    });
  
    this.authService.borrardoc(this.deletedoc.value)
    .then((response)=>{
              this.authService.validatec(this.user.nit)
              .then((response)=>{
                console.log(response);
                this.authService.setUserInfo({'user' : response['user']});
                let user = localStorage.getItem('userInfo')
                this.user= JSON.parse(user).user;
  
              })
              .catch((err)=>{
                  
              })
   
    })
    .catch((err)=>{
      
    })
  }
  
  eliminarPersona(id){
    this.authService.eliminarPersonac(id,this.user.nit)
    .then((response) => {
      this.authService.validatec(this.user.nit)
      .then((response)=>{
        console.log(response);
        this.authService.setUserInfo({'user' : response['user']});
        let user = localStorage.getItem('userInfo')
        this.user= JSON.parse(user).user;
        this.loadpersonas();

      })
      .catch((err)=>{
          
      })
  })
  }
  
}
