import { Component, OnInit,ElementRef, Renderer2,ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-perfilempresa',
  templateUrl: './perfilempresa.component.html',
  styleUrls: ['./perfilempresa.component.css']
})
export class PerfilempresaComponent implements OnInit {
  rcompanyForm: FormGroup;
  submitted: boolean;
user;
  constructor(
    private authService: AuthService,
    private router:Router,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;

    this.rcompanyForm=new FormGroup({
      'name': new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ]),
      'nit': new FormControl('', [
        Validators.required,
      ]),
    
      'clave': new FormControl('', [
        Validators.required,
      ]),
    
      //para representante
      'rname': new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ]),
      'rcorreo': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'rtelefono': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
     
     
    
    })
    if(this.user.nit){
      console.log(this.user);
      this.edituser();
    }
    else(
      location.href="/login"
    )
  }
  get f2() { return this.rcompanyForm.controls; }

  registerc(){
    this.submitted = true;
    console.log(this.rcompanyForm.controls);
    if (this.rcompanyForm.invalid) {
      console.log("no enviado ");
      return;
  }
  console.log("enviado ");
    this.authService.createCompany(this.rcompanyForm.value)
    .then((response) => {
      console.log(response);
      this.authService.setUserInfo({'user' : response['user']});
     // this.completo=true;
     location.reload();
    })
    .catch((err)=>{
     
  })
  
  }

  edituser(){
    var evento:Object ={
     name:this.user.name,
     nit:this.user.nit,
     clave:this.user.clave,
     rname:this.user.responsable.name,
     rcorreo:this.user.responsable.correo,
     rtelefono:this.user.responsable.telefono,
    
  
    }
    this.rcompanyForm.reset(evento);
  }

}
