<h1 >Bienvenidos</h1>

<div *ngIf="!valido"  class="row justify-content-center align-items-center ">
    <div class="h-50" >

    
    <div class="col-12 col-md-8">
      <img width="99%" src="./assets/caja_correo.png">
      <div style="position: absolute; top: 43%; left: 10%; width: 80%; text-align: left;">
        <form [formGroup]="miFormulario" (ngSubmit)="fin()" novalidate="">  
        Correo electrónico
        <input placeholder="micorreo@email.com" formControlName="correo"  [ngClass]="{ 'is-invalid': submitted && f.correo.errors }" style="height: 60px; width: 100%; border-radius: 5px 5px 5px 5px" type="text" name="">
        <div *ngIf="submitted && f.correo.errors" class="invalid-feedback ">
             <div *ngIf="f.correo.errors.required">ingrese su correo</div>
             <div *ngIf="f.correo.errors.email">ingrese un correo valido</div>
         </div> 

        <img (click)="fin()" style="display: block; margin: 0 auto;" width="67%" src="./assets/boton_ingresar.png" >
  
   </form>
      </div>
    </div>
</div>
  </div>

  <div *ngIf="valido"  >
      
    <a href="{{conferencista}}"><button class="btn btn-primary" >Ingreso a conferencistas</button></a>

  </div>