import { Component, OnInit,Input,Renderer2,ViewChild , ElementRef,  } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import {ChangeDetectorRef} from '@angular/core'
import  Swal from 'sweetalert2'; 
var ageCalculator = require('age-calculator');
let {AgeFromDateString, AgeFromDate} = require('age-calculator');
 
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.css']
})
export class PersonaComponent implements OnInit {
  @Input() user ={"name":"no nombre","nacimiento":"","age":"","apellido":"no nombre","arl":"","eps":"","cargo":"","correo":"correo@gmail","telefono":"1234","photo1":"lkjl","id":"1234","documentos":[],"tipo":"usuario","_id":"000","empresa":"independiente"};
  @Input() show=false;
  @Input() qr=false;
  subird;
  docForm;
  deletedoc;
  porteria;
  elementType = NgxQrcodeElementTypes.URL;
  qrvalue = null;
  verCarnet: boolean = false;
  correctionLevel =NgxQrcodeErrorCorrectionLevels.LOW;

  constructor(
    private authService : AuthService,
     private eventService : EventsService,
      private router: Router,
       private cd: ChangeDetectorRef,
       private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    var href = this.router.url;
    if(href.includes("porteria")){
      
      this.porteria=true;
    }
    this.docForm= new FormGroup({

      "doc_pdf":new FormControl(''),
      "id":new FormControl(''),
      "tipo":new FormControl(''),
    
    })
    this.deletedoc= new FormGroup({

      "url":new FormControl(''),
      "id":new FormControl(''),
      "tipo":new FormControl(''),
    
    })
    this.loadempresa();
    if(this.qr){
      this.qrvalue=this.user._id;
    }

  }


  loadempresa(){
    if(this.user.empresa!="independiente"){
      this.authService.getempresa(this.user.empresa)
      .then((response)=>{
        console.log(response);
        this.user.empresa=response["empresa"].name;
      })
      .catch((err)=>{
        return
      })
    }
  }

  subir(){
    this.subird=true   
    console.log(this.user);
    
    this.docForm.patchValue({
     id: this.user.id,
     tipo:this.user.tipo
    });
   
  }

  onFileChange(event) {
  let reader = new FileReader();

  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
 
      this.docForm.patchValue({
        doc_pdf: reader.result
      });          
      this.cd.markForCheck();
    };

  }
}

upDoc(){
  this.authService.subirdoc(this.docForm.value)
  .then((response)=>{
    this.subird=false
    this.authService.validatep(this.user.id)
    .then((response)=>{
       
      this.authService.setUserInfo({'user' : response['user']});
      let user = localStorage.getItem('userInfo')
      this.user= JSON.parse(user).user;
    })
    .catch((err)=>{
        
    })
  })
  .catch((err)=>{
    this.subird=false
  })
  
}

deletedocu(url){
  
  this.deletedoc.patchValue({
    url: url,
   id:this.user.id,
   tipo:this.user.tipo
  });

  this.authService.borrardoc(this.deletedoc.value)
  .then((response)=>{
            this.authService.validatep(this.user.id)
            .then((response)=>{
              console.log(response);
              this.authService.setUserInfo({'user' : response['user']});
              let user = localStorage.getItem('userInfo')
              this.user= JSON.parse(user).user;

            })
            .catch((err)=>{
                
            })
 
  })
  .catch((err)=>{
    
  })
}








}
