import { Component, OnInit,ElementRef, Renderer2,ViewChild  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-recuperar-clave',
  templateUrl: './recuperar-clave.component.html',
  styleUrls: ['./recuperar-clave.component.css']
})
export class RecuperarClaveComponent implements OnInit {
recuperarForm;
submitted;
id;
  constructor(    private authService: AuthService,
    private router:Router,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.paramMap.get("id");
    this.recuperarForm = new FormGroup({
      'empresa': new FormControl(this.id, [
        Validators.required,
          ]),   
      'temporal': new FormControl('', [
        Validators.required,
          ]),   
      'clave': new FormControl('', [
        Validators.required,
          ]),   
  
    });
  }
  get f() { return this.recuperarForm.controls; }

  recuperar(){
    this.submitted = true;
    console.log(this.recuperarForm.controls);
    if (this.recuperarForm.invalid) {
      console.log("no enviado ");
      return;
  }

  this.authService.recuperoClave(this.recuperarForm.value)
  .then((response) => {
    console.log(response);
  Swal.fire("La contraseña ha sido cambiada con exito")
  .then(()=>{
    location.href="/"
  })
   // this.completo=true;
  })
  .catch((err)=>{
    Swal.fire("Hubo un error cambiando la contraseña, asegurese de que la clave temporal es la ultima enviada a su correo")
   
})

  }

}
