import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';

import { ChangeDetectorRef } from '@angular/core'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-pqr',
  templateUrl: './pqr.component.html',
  styleUrls: ['./pqr.component.css']
})
export class PqrComponent implements OnInit {
  msgs:any;
  msgForm: FormGroup;
  pqr: any;

  constructor(
    private authService: AuthService,
    private eventService: EventsService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,

  ) { }
  user;
canjes;
  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;

    this.loaduser();
    this.msgForm=new FormGroup({
      "user": new FormControl('',[Validators.required]),
      "pqr": new FormControl('',[Validators.required]),
      "mensaje": new FormControl('',[Validators.required]),
    })
  }

  loaduser(){
    this.authService.validatep(this.user.id)
    .then((response) => {
      console.log(response);
      this.authService.setUserInfo({'user' : response['user']});
      let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    this.loadSolicitudes();
    })
       
  }
  newMsg(){
    this.msgForm.patchValue({
      user:this.user._id,
      pqr:this.pqr
    })
    if(this.msgForm.invalid){
      return
    }
    this.authService.createMsg(this.msgForm.value)
    .then((r)=>{
  
      this.msgForm.reset();
      this.loadMsg(this.pqr)
    })
  }

  loadSolicitudes(){

    function compare(a, b) {
    var aDate=new Date(a.createddate);
    var bDate=new Date(b.createddate);
      if (aDate < bDate) {

        return 1;
      }
      if (aDate > bDate) {
        return -1;
      }
      return 0;
    }
this.authService.getPqrs()
.then((r)=>{
  console.log(r)
  this.canjes=r;
  this.canjes.sort(compare)
})
  }

  loadMsg(pqr){
    this.pqr=pqr
    this.authService.findMsgPqr(pqr)
    .then((r)=>{
      this.msgs=r;
    })
  }

}
