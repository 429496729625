import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service"
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { interval } from 'rxjs';
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.css']
})
export class AreasComponent implements OnInit {
  active=false;
 
  eventos;
  areas;
  user;
    submitted: boolean;
    submitted2: boolean;
    areasCargadas: boolean;
    constructor(
      private EventService:EventsService,
      private Authservice: AuthService
    ) { 
     
  
    
      
    }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(user);
    this.getAreas();
    this.getEvents();
 
  }

  getEvents(){
    this.EventService.getAllEvents()
    .then((result)=>{
      this.eventos=result["results"]; 
      console.log(this.eventos);
    })
    }
    createFormaray(Inputs) {
      console.log(Inputs);
        const arr = Inputs.map(marca => {
          return new FormControl(marca.selected || false);
        });
        return new FormArray(arr);
      }
    
    
    
    getAreas(){
      this.EventService.getAllAreas()
      .then((result)=>{
      var areas_=result["results"];    
    console.log(this.user);
    console.log(areas_)
      function chek(area){
        console.log(area.responsables.includes(this.user.id))
        return area.responsables.includes(this.user.id)
      }
areas_=areas_.filter(chek.bind(this))
this.areas=areas_;
if(this.areas.length==1){
  this.irArea(this.areas[0])
}

      })
    }

    irArea(area){
if(area.responsables.includes(this.user.id)){
  location.href="/area/"+area._id
}
else{Swal.fire("No tienes acceso a esta área")}
    }

}
