import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import {SocketioService} from "../../services/socketio.service";
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import  Swal from 'sweetalert2';  

import {OpenviduSessionComponent, StreamEvent, Session, UserModel, OpenViduLayout, OvSettings, OpenViduLayoutOptions, SessionDisconnectedEvent, Publisher} from 'openvidu-angular';

import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-aprobacion',
  templateUrl: './aprobacion.component.html',
  styleUrls: ['./aprobacion.component.css']
})
export class AprobacionComponent implements OnInit {
visita;
personasc=[];
empresa: any;
porteria;
  constructor(
    private authService : AuthService, 
    private eventService : EventsService, 
    private route: ActivatedRoute, private router : Router,
    private socketservice: SocketioService,
    private renderer: Renderer2,
    private elRef:ElementRef,
    private httpClient: HttpClient

  ) { }
 
  ngOnInit(): void {
    let id=this.route.snapshot.paramMap.get("id");
    this.getSolicitud(id);
    this.personasc=[];
  }

getSolicitud(id){
  this.eventService.getsolicitud(id)
  .then((response) => {
    console.log(response);
  this.visita=response["visita"]
  var date= new Date(this.visita.fecha);
  var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  this.visita.fechat=fecha;
  this.loadpersonas();
  this.loadempresa();


  })
  .catch((err)=>{
    console.log(err);
  })

}
loadpersonas(){
  
  this.visita.personas.forEach(id => {
    console.log(id);
    
    this.authService.validatep(id.id)
    .then((response)=>{
      if(id.ingreso){
        var date= new Date(id.ingreso.hora);
        var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        response["user"].ingreso=fecha;
       // console.log(response["user"])
      }
      var person=response["user"];
      person.aprobe1=id.aprobe1;
  this.personasc.push(person);
    })
    .catch((err)=>{
  
    })
    });
  
  }
  loadempresa(){
    if(this.visita.empresa){
    if(this.visita.empresa!="independiente"){
  this.authService.getempresa(this.visita.empresa)
  .then((response)=>{
    console.log(response);
    this.empresa=response["empresa"];
  })
  
     } }
  }
aprobar(){
  this.authService.aprobar(this.visita._id,1)
  .then(()=>{
    this.ngOnInit();
  })
}

aprobarpersona(id){
  this.authService.aprobarpersona(this.visita._id,id,1)
  .then(()=>{
    this.ngOnInit();
  })}

}
