<nav class="navbar navbar-expand-lg" style="height: 60px;">
    <div class="justify-content-start">
        <nav class="navbar navbar-dark bg-dark">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
          </nav>
    </div>
    <div class="justify-content-end">
        <img src="../../../assets/FondosClub/logo-serrrezuela-ico.png" width="20%" />
      </div>
     
    <div class="collapse navbar-collapse " id="navbarToggleExternalContent" style=" background-color:  #5A9E94 !important;">
        <ul class="navbar-nav">
            <li><a  (click)="verIngresos()"  class="nav-link px-2 btn btn-header">Ingresos</a></li>
            <li><a  (click)="newIngre()" class="nav-link px-2 btn btn-header">Nuevo ingreso</a></li>
            <li><a (click)="salidaIn()" class="nav-link px-2 btn btn-header">Salidas</a></li>
            <li><a class="nav-link px-2 btn btn-header" (click)="verhoy()"  >Visitas hoy</a></li>
            <li><a class="nav-link px-2 btn btn-header" (click)="verproxi()" >Proximas Visitas</a></li>

            
        </ul>
    </div>
   
  </nav>
<div class="row m-1 justify-content-start  " >
 
    <div class="col-md-1">

    </div>
    
    
    <div class="col-md-8" *ngIf="ver=='salidas'" >
        <div *ngIf="!dato" >
            <app-lector (newCode)="newCodeSalida($event)"  ></app-lector>
            <br>
            <main class="form-signin w-100 m-auto"  >
          
                <form  class="form" [formGroup]="ingresoForm" (ngSubmit)="newSalida()">
                    
                  
                    <div class="form-floating form-group m-2">
                        <input placeholder="" class="form-control mb-2" formControlName="doc"
                                [ngClass]="{ 'is-invalid': submitted && f.doc.errors }"  type="text" name="">
                            <div *ngIf="submitted && f.doc.errors" class="invalid-feedback ">
                                <div *ngIf="f.doc.errors.required">ingrese su número de documento</div>
                            
                            </div>
                        <label for="floatingInput">Número de documento*</label>
                      </div>
                      <div  style="display: inline;" >
                        <input type="submit" value="Confirmar" class="btn btn-primary">
                        
                      </div>
                    
                </form>
                </main>

        </div>
        <div *ngIf="dato && suser" >
            <div class="row mt-3 justify-content-center">
                <div class="col-md-6" >
                    <h3>Lista de salida</h3>
                    <div class="card " *ngFor="let su of listaSalida">
                        <div class="card-body">
                            Nombre: {{su.name}} {{su.apellido}}
                            <br>
                            Documento: {{su.id}}
                            <br>
                        </div>
                    </div>
                    <h3 *ngIf="acompanante.length >0">Seleccionar Beneficiarios a la salida</h3>
                    <div *ngIf="acompanante.length >0">
                        <div  class="card" *ngFor="let b of acompanante">
                            <div class="card-body">
                                Nombre: {{b.user.name}} {{b.user.apellido}}
                                <br>
                                Documento:  {{b.user.id}}
                                <br>
                                <label for="">Selecciona beneficiarios a agregar Salida
                                    <input type="checkbox" [checked]="b.user.selected" (click)="dataAgregarSalida(b.user)" value="false" name="" id="">
                                </label>
                            </div>
                        </div>
                    </div>
                   

                   
                    <br>
                    <br>
                  
                    <div class="row justify-content-between">

                        <button class="btn btn-primary" (click)="validateSalida()" >Marcar salida</button>
                        <button class="btn btn-botonWarning" (click)="AgregarOtro()" >agregar Otra salida</button>
    
                    </div>
                </div>
                
            </div>
            
            
                    </div>
    </div>

    <div class="col-md-11" *ngIf="ver=='ingresos' && vhoy == false && vproximas == false" >

        <div class="container">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#tab1">Funcionarios {{filterNoAcompananteFun().length}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tab2">Socios y Beneficiarios {{filterNoAcompananteSocio().length}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link " data-toggle="tab" href="#tab4">Visitantes {{empleados().length}}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tab3">Todos  {{filterNoAcompanante().length}}</a>
                </li>
            </ul>
            <div class="tab-content">
                <div id="tab1" class="tab-pane fade show active">
                    <div class="row justify-content-center" >
                        <div class="col-md-3 col-11 caja-form2 m-2" *ngFor="let ingreso of filterNoAcompananteFun()" >
                            <div class="card-body" style="max-height: 350px; overflow-y: auto;" >
                              
                                {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                                CC: {{ingreso.user.id}} <br>
                                Categoria: {{ingreso.user.categoria}} <br>
                                Ingreso: {{ingreso.fechat}} <br>
                              <!--   <strong>Beneficiarios:</strong> -->
                            
                                <div *ngIf="ingreso.visita" >
                                    Visitante <br>
                                    Descripción: {{ingreso.visita.descripcion}} <br>
                                    Responsable: {{ingreso.visita.anfitrion.name}} {{ingreso.visita.anfitrion.apellido}}
                                 </div>
                                <div *ngIf="filterAcompanante(ingreso.user._id).length>0" >
                                    Acompañantes <br>
                                    <div  style="background-color: rgb(160, 163, 163); margin-bottom: 5px;" *ngFor="let ingreso of filterAcompanante(ingreso.user._id)" >
                                        {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                                        CC: {{ingreso.user.id}} <br>
                                        Categoria: {{ingreso.user.categoria}} <br>
                                        Ingreso: {{ingreso.fechat}} <br>
                                    </div>
                                </div>
                                <img src="{{ingreso.foto}}" *ngIf="ingreso.open" width="100%" alt="">
                                <div class="d-flex justify-content-end">
                                    <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-botonWarning"   >Ver foto</button>
                                    <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-botonWarning"  >Ocultar foto</button>
                                    <button   class="btn btn-botonAdmin btn-peque " (click)="validateSalida2(ingreso.user)"  >Marcar salida</button>

                                </div>
                                <br>
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-botonAdmin" 
                                    *ngIf="ingreso.user.rol=='admin'  || ingreso.user.categoria=='socio' || ingreso.user.categoria=='beneficiario' && ingreso.user.aprobe==true "
                                      (click)="addGuest(ingreso.user._id)" >Agregar acompañante</button>
                                </div>
                                
                                <br>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div id="tab2" class="tab-pane fade">
                    <div class="row justify-content-center" >
                        <div class="col-md-3 col-11 caja-form2 m-2" *ngFor="let ingreso of filterNoAcompananteSocio()" >
                            <div class="card-body" style="max-height: 350px; overflow-y: auto;" >
                              
                                {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                                CC: {{ingreso.user.id}} <br>
                                Categoria: {{ingreso.user.categoria}} <br>
                                Ingreso: {{ingreso.fechat}} <br>
                                <strong>Beneficiarios:</strong>
                            
                                <div *ngIf="ingreso.visita" >
                                    Visitante <br>
                                    Descripción: {{ingreso.visita.descripcion}} <br>
                                    Responsable: {{ingreso.visita.anfitrion.name}} {{ingreso.visita.anfitrion.apellido}}
                                 </div>
                                <div *ngIf="filterAcompanante(ingreso.user._id).length>0" >
                                    Acompañantes <br>
                                    <div  style="background-color: rgb(160, 163, 163); margin-bottom: 5px;" *ngFor="let ingreso of filterAcompanante(ingreso.user._id)" >
                                        {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                                        CC: {{ingreso.user.id}} <br>
                                        Categoria: {{ingreso.user.categoria}} <br>
                                        Ingreso: {{ingreso.fechat}} <br>
                                    </div>
                                </div>
                                <img src="{{ingreso.foto}}" *ngIf="ingreso.open" width="100%" alt="">
                                <div class="d-flex justify-content-end">
                                    <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-botonWarning"   >Ver foto</button>
                                    <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-botonWarning"  >Ocultar foto</button>
                                    <button   class="btn btn-botonAdmin btn-peque " (click)="validateSalida2(ingreso.user)"  >Marcar salida</button>

                                </div>
                                <br>
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-botonAdmin" 
                                    *ngIf="ingreso.user.rol=='admin'  || ingreso.user.categoria=='socio' || ingreso.user.categoria=='beneficiario' && ingreso.user.aprobe==true "
                                      (click)="addGuest(ingreso.user._id)" >Agregar acompañante</button>
                                </div>
                                
                                <br>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div id="tab3" class="tab-pane fade">
                    <div class="row justify-content-center" >
                        <div class="col-md-3 col-11 caja-form2 m-2" *ngFor="let ingreso of filterNoAcompanante()" >
                            <div class="card-body" style="max-height: 350px; overflow-y: auto;" >
                              
                                {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                                CC: {{ingreso.user.id}} <br>
                                Categoria: {{ingreso.user.categoria}} <br>
                                Ingreso: {{ingreso.fechat}} <br>
                               <!--  <strong>Beneficiarios:</strong> -->
                            
                                <div *ngIf="ingreso.visita" >
                                    Visitante <br>
                                    Descripción: {{ingreso.visita.descripcion}} <br>
                                    Responsable: {{ingreso.visita.anfitrion.name}} {{ingreso.visita.anfitrion.apellido}}
                                 </div>
                                <div *ngIf="filterAcompanante(ingreso.user._id).length>0" >
                                    Acompañantes <br>
                                    <div  style="background-color: rgb(160, 163, 163); margin-bottom: 5px;" *ngFor="let ingreso of filterAcompanante(ingreso.user._id)" >
                                        {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                                        CC: {{ingreso.user.id}} <br>
                                        Categoria: {{ingreso.user.categoria}} <br>
                                        Ingreso: {{ingreso.fechat}} <br>
                                    </div>
                                </div>
                                <img src="{{ingreso.foto}}" *ngIf="ingreso.open" width="100%" alt="">
                                <div class="d-flex justify-content-end">
                                    <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-botonWarning"   >Ver foto</button>
                                    <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-botonWarning"  >Ocultar foto</button>
                                    <button   class="btn btn-botonAdmin btn-peque " (click)="validateSalida2(ingreso.user)"  >Marcar salida</button>

                                </div>
                                <br>
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-botonAdmin" 
                                    *ngIf="ingreso.user.rol=='admin'  || ingreso.user.categoria=='socio' || ingreso.user.categoria=='beneficiario' && ingreso.user.aprobe==true "
                                      (click)="addGuest(ingreso.user._id)" >Agregar acompañante</button>
                                </div>
                                
                                <br>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div id="tab4" class="tab-pane fade">
                    <div class="row justify-content-center" >
                        <div class="col-md-3 col-11 caja-form2 m-2" *ngFor="let ingreso of empleados()" >
                            <div class="card-body" style="max-height: 350px; overflow-y: auto;" >
                              
                                {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                                CC: {{ingreso.user.id}} <br>
                                Categoria: {{ingreso.user.categoria}} <br>
                                Ingreso: {{ingreso.fechat}} <br>
                                <strong>Beneficiarios:</strong>
                            
                                <div *ngIf="ingreso.visita" >
                                    Visitante <br>
                                    Descripción: {{ingreso.visita.descripcion}} <br>
                                    Responsable: {{ingreso.visita.anfitrion.name}} {{ingreso.visita.anfitrion.apellido}}
                                 </div>
                                <div *ngIf="filterAcompanante(ingreso.user._id).length>0" >
                                    Acompañantes <br>
                                    <div  style="background-color: rgb(160, 163, 163); margin-bottom: 5px;" *ngFor="let ingreso of filterAcompanante(ingreso.user._id)" >
                                        {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                                        CC: {{ingreso.user.id}} <br>
                                        Categoria: {{ingreso.user.categoria}} <br>
                                        Ingreso: {{ingreso.fechat}} <br>
                                    </div>
                                </div>
                                <img src="{{ingreso.foto}}" *ngIf="ingreso.open" width="100%" alt="">
                                <div class="d-flex justify-content-end">
                                    <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-botonWarning"   >Ver foto</button>
                                    <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-botonWarning"  >Ocultar foto</button>
                                    <button   class="btn btn-botonAdmin btn-peque " (click)="validateSalida2(ingreso.user)"  >Marcar salida</button>

                                </div>
                                <br>
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-botonAdmin" 
                                    *ngIf="ingreso.user.rol=='admin'  || ingreso.user.categoria=='socio' || ingreso.user.categoria=='beneficiario' && ingreso.user.aprobe==true "
                                      (click)="addGuest(ingreso.user._id)" >Agregar acompañante</button>
                                </div>
                                
                                <br>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        
        </div>

        <!-- <div *ngIf="ingresos"  class="row mt-2">
            <div class="col-12">
                <h3 class="texto-color" >Ingresos actuales: {{this.ingresos.length}}</h3>
            </div>
        </div>
        <div  *ngIf="ingresos" class="row mt-3 ">
            <div class="col-md-3 col-11 caja-form2 m-2" *ngFor="let ingreso of filterNoAcompanante()" >
                <div class="card-body" style="max-height: 350px; overflow-y: auto;" >
                  
                    {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                    CC: {{ingreso.user.id}} <br>
                    Categoria: {{ingreso.user.categoria}} <br>
                    Ingreso: {{ingreso.fechat}} <br>
                    <strong>Beneficiarios:</strong>
                
                    <div *ngIf="ingreso.visita" >
                        Visitante <br>
                        Descripción: {{ingreso.visita.descripcion}} <br>
                        Responsable: {{ingreso.visita.anfitrion.name}} {{ingreso.visita.anfitrion.apellido}}
                     </div>
                    <div *ngIf="filterAcompanante(ingreso.user._id).length>0" >
                        Acompañantes <br>
                        <div  style="background-color: rgb(160, 163, 163); margin-bottom: 5px;" *ngFor="let ingreso of filterAcompanante(ingreso.user._id)" >
                            {{ingreso.user.name}} {{ingreso.user.apellido}} <br>
                            CC: {{ingreso.user.id}} <br>
                            Categoria: {{ingreso.user.categoria}} <br>
                            Ingreso: {{ingreso.fechat}} <br>
                        </div>
                    </div>
                    <img src="{{ingreso.foto}}" *ngIf="ingreso.open" width="100%" alt="">
                    <div class="d-flex justify-content-end">
                        <button *ngIf="!ingreso.open" (click)="ingreso.open=true" class="btn btn-botonWarning"   >Ver foto</button>
                        <button *ngIf="ingreso.open" (click)="ingreso.open=false" class="btn btn-botonWarning"  >Ocultar foto</button>
                    </div>
                    <br>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-botonAdmin" 
                        *ngIf="ingreso.user.rol=='admin'  || ingreso.user.categoria=='socio' || ingreso.user.categoria=='beneficiario' && ingreso.user.aprobe==true "
                          (click)="addGuest(ingreso.user._id)" >Agregar acompañante</button>
                    </div>
                    
                    <br>
                </div>
               
            </div>

        </div> -->
    </div>



    <div class="col-md-8" *ngIf="ver=='lector'" >

        <div *ngIf="!dato" >
            <app-lector (newCode)="newCode($event)"  ></app-lector>
            <br>
            <main class=" w-100 m-auto"  >
          
                <form  class="form" [formGroup]="ingresoForm" (ngSubmit)="newIngreso()">
                    
                  
                    <div class="form-floating form-group m-2">
                        <input placeholder="" class="form-control mb-2" formControlName="doc"
                                [ngClass]="{ 'is-invalid': submitted && f.doc.errors }"  type="text" name="">
                            <div *ngIf="submitted && f.doc.errors" class="invalid-feedback ">
                                <div *ngIf="f.doc.errors.required">ingrese su número de documento</div>
                            
                            </div>
                        <label for="floatingInput">Número de documento*</label>
                      </div>
                      <div  style="display: inline;" >
                        <input type="submit" value="Confirmar" class="btn btn-primary">
                        
                      </div>
                    
                </form>
                </main>

        </div>

<div *ngIf="dato && suser " >
<div class="row mt-3 justify-content-center">
    <div class="col-md-10">
        <div class="card">
            <div class="card-body">

                Nombre: {{suser.name}} {{suser.apellido}}
                            <br>
                  Documento: {{suser.id}}
                <br>
                <div *ngIf="suser.categoria=='invitado' && visita!=null" >
                    Visitante
                    <br>
                    Responsable {{visita.recibeName }}
                </div>
                <div *ngIf="invitados.length>0" >
                    <h5  style="color:rgba(13,55,126,1) ;" >Invitados</h5>
                    <div *ngFor="let invitado of invitados" >
                        
                Nombre: {{invitado.name}}
                <br>
                Documento: {{invitado.id}}
                <hr>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <!-- <button class="btn btn-warning" *ngIf="suser.rol=='admin'  ||  suser.categoria=='socio' || suser.categoria=='beneficiario' && suser.aprobe==true "  (click)="addGuest()" >Agregar invitado</button>
        <br><br> -->
        <span *ngIf="suser.aprobe!=true" style="color: rgb(232, 17, 2);" >El usuario no ha sido aprobado </span>
      
        <div>
            <button class="btn btn-primary" *ngIf="suser.rol=='admin'  ||
              suser.categoria=='socio' && suser.aprobe==true ||
              suser.categoria=='ninera' && suser.aprobe==true ||
              suser.funcionario||
              suser.categoria=='caddie' && suser.aprobe==true ||
               suser.categoria=='beneficiario' && suser.aprobe==true  || 
               suser.categoria=='invitado' && visita ||
               suser.categoria=='canje' && suser.canjeHabil
               "  (click)="validateIngreso()" >Validar ingreso</button>
            <button class="btn btn-danger m-2"(click)="dato=false" >Cancelar</button>
        </div>
        
    </div>
</div>


        </div>


    </div>

</div>

<div *ngIf="vhoy"  class="col-md-12"  >


    <div class="row justify-content-center texto-color"  style="color: #5A9E94 !important " >
  <div class="col-md-4 caja-form2"  *ngFor="let user of hoy" > 
             
      <div class="mt-2 " [ngClass]="{'sss':user.aprobada==user.personas.length && !user.admin,'verde3':user.aprobada==user.personas.length && user.porteria, 'verde2':user.aprobada==user.personas.length && user.admin, 'amarillo':user.aprobada != user.personas.length && user.aprobada!=0,'caja-datos':user.aprobada==0}"   style=" border-radius: 15px; padding: 5px;" >
          <!-- <div   *ngIf="user.open" >
              <app-visita [visita]="user"  ></app-visita>
             
              <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
          </div>
         -->
          
         <div *ngIf="!user.open" class="caja-form2 texto-color" >
          <!-- <a href="/aprobar4/{{user._id}}" target="_blank" rel="noopener noreferrer">
              <button class="btn btn-primary btn-2" >Aprobar</button>
              </a> -->
              <!-- <button  *ngIf="!user.open" class="btn btn-primary btn-2"  (click)="user.open=true" >Ver detalles</button><br> -->
          <span  class="texto-color">{{user.fechat}}</span><br>
         
          
              <br>
             <span>Recibe: {{user.recibeName}} </span> 
             <br>
              <!-- Area: {{user.recibeArea}} -->
          
        
         
          <br>
          <span class="text" > Descripción: {{user.descripcion}}</span><br>
          <span class="text"*ngIf="user.acceso"  > Nivel de acceso: {{user.acceso}}<br></span> 
          <!-- <span class="text" *ngIf="user.empresa" > Empresa: {{user.empresa.name}}<br></span> -->
          <!-- <span class="text" *ngIf="!user.empresa" > Empresa: Independiente<br> </span> -->
          <span class="text" > Número de personas: {{user.personas.length}}<br> </span>
          <div class="d-flex justify-content-end">

              <button *ngIf="!user.notasopen" class="btn btn-primary btn-2" (click)="user.notasopen=true" >ver notas</button><br>
          </div>
          <div *ngIf="user.notasopen" style="border:solid 2px black" >
              <h2 >Notas: </h2>
              <span *ngFor="let nota of user.notas" >
              - {{nota.txt}} 
              <br>
              </span>
              <button  (click)="newnota(user._id)" class="btn btn-primary m-1 btn-2" >Agregar nota</button>
              <button  class="btn btn-primary btn-2" (click)="user.notasopen=false" >ocultar notas</button>
                      </div>
          <!-- <div *ngIf="user.empresa">
              {{user.empresa.nombre}}
              <span class="text"> NIT: {{user.empresa.nit}}</span>
        
              <br>
        
              <span class="text" *ngIf="user.placa"> Vehiculo: {{user.placa}}</span>
           <br>
                          
        
        
            </div> -->
            <span class="text" style="font-size: 22px;" > Personas:</span><br>
          <span [ngClass]="{'verde':persona.aprobe3}" *ngFor="let persona of user.personasObj" >
              {{persona.name}} {{persona.apellido}}    <br> 
              <span *ngIf="persona.ingreso" > Ya registro ingreso {{persona.ingreso.hora | date:'medium'}} <br> </span>
              <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
         
          <hr>
          </span>
      
     
         </div>
         <div class="d-flex justify-content-center">
             <button class="btn-orange"  (click)="eliminar(user._id)" >Eliminar</button>
         </div>
      </div>
    
          </div>
          
                  </div>
      
  </div>

  <div class="col-md-12" *ngIf="vproximas"  >
             
    <div class="row justify-content-center" >
        <div class="col-md-4" *ngFor="let user of proximas" >
            <div class="mt-2  " [ngClass]="{'verde':user.aprobada==user.personas.length && !user.admin,'verde3':user.aprobada==user.personas.length && user.porteria, 'verde2':user.aprobada==user.personas.length && user.admin, 'amarillo':user.aprobada != user.personas.length && user.aprobada!=0,'caja-datos':user.aprobada==0}"   style="border-radius: 15px; padding: 5px;" >
                <!-- <div *ngIf="user.open" >
                    <app-visita [visita]="user"  ></app-visita>
                    <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
                </div>
                <a href="/aprobar4/{{user._id}}" target="_blank" rel="noopener noreferrer">
                    <button class="btn btn-primary" >Aprobar</button>
                    </a> -->
               <div *ngIf="!user.open" >
               
                <span  >{{user.fechat}}</span><br>
                
  
                Recibe: {{user.recibeName}} <br>
                <span class="text" > Descripción: {{user.descripcion}}</span><br>
         
    
                <span class="text" > Número de personas: {{user.personas.length}}<br> </span>
                <div style="border:solid 2px black" >
                    <h2 >Notas de la visita</h2>
                    <span *ngFor="let nota of user.notas" >
                    - {{nota.txt}} 
                    <br>
                    </span>
                    <div class="d-flex justify-content-end">
                        <button  (click)="newnota(user._id)" class="btn btn-botonWarning m-1" >Agregar nota</button>
                    </div>
                            </div>
                <!-- <div *ngIf="user.empresa">
                    {{user.empresa.name}}
                    <span class="text"> NIT: {{user.empresa.nit}}</span>
              
                    <br>
              
                    <span class="text" *ngIf="user.placa"> Vehiculo: {{user.placa}}</span>
         <br>
                                
              
              
                  </div> -->
               
                <span *ngFor="let persona of user.personasObj" >
                    {{persona.name}} {{persona.apellido}}    <br> 
          
                <span *ngIf="persona.ingreso" > Ya registro ingreso <br> </span>
                <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
               
                <hr>
                </span>
            
       
               </div>
               <div class="d-flex justify-content-end">
                   <button class="btn-botonAdmin"  (click)="eliminar(user._id)" >Eliminar</button>
               </div>
            </div>
          
        </div>
        
                </div>
   
</div>
<div  class="popFull" *ngIf="pop" >
    <div class="row m-1 justify-content-center">
        <div class="col-md-4">
            <div class="card">
                <div class="card-header" style="padding: 1px;" >
                    <button style="float: right;" class="btn btn-danger" (click)="pop=false" >X</button>
                </div>
                <div class="card-body"  >
                    <div *ngIf="!newRegister"  >
                        <div *ngIf="!showGuest" >
                            Beneficiarios <br>
                            <div class="caja-form2"  *ngFor="let beneficiario of beneficiarios" >
                                Nombre: {{beneficiario.name}} {{beneficiario.apellido}}
                                <br>
                                Documento: {{beneficiario.id}}
                                <br>
                                <label for="">Selecciona Beneficiarios a Agregar
                                    <input type="checkbox" (click)="dataAgregar(beneficiario)" value="false" name="" id="">
                                </label>
<!--                                 <button class="btn btn-primary" (click)="agregar(beneficiario)" >Agregar</button>
 -->                                
                                 <hr style="border: 1px solid navy; background-color:navy;  ">
                             </div>
                             <div class="d-flex justify-content-end">

                                 <button class="btn btn-primary" (click)="agregar()" >Agregar</button>
                             </div>

                        </div>
                        <div *ngIf="showGuest" >
                            <div style="background-color: white; color: black;"   >
                                Nombre: {{guest.name}} {{guest.apellido}}
                                <br>
                                Documento: {{guest.id}}
                                <br>
                            <!--     <label for="">Selecciona Beneficiarios a Agregar
                                    <input type="checkbox" (click)="dataAgregar(beneficiario)" value="false" name="" id="">
                                </label> -->
                                <button class="btn btn-primary" (click)="agregar2()" >Agregar</button>
                                
                                 <hr style="border: 1px solid navy; background-color:navy;  ">
                             </div>
                        </div>
                         <form  *ngIf="!showGuest" class="form" [formGroup]="ingresoForm" (ngSubmit)="findPerson()">
                    
                            Buscar invitado
                            <div class="form-floating form-group m-2">
                                <input placeholder="" class="form-control mb-2" formControlName="doc"
                                        [ngClass]="{ 'is-invalid': submitted && f.doc.errors }"  type="text" name="">
                                    <div *ngIf="submitted && f.doc.errors" class="invalid-feedback ">
                                        <div *ngIf="f.doc.errors.required">ingrese su número de documento</div>
                                    
                                    </div>
                                <label for="floatingInput">Número de documento*</label>
                              </div>
                              <div  style="display: inline;" >
                                <input type="submit" value="Buscar" class="btn btn-primary">
                                
                              </div>
                            
                        </form>
                    </div>
                    
                    <div  *ngIf="newRegister" >
                        <main class="form-signin w-100 m-auto" >
                       
                            <h3>Ingresa los datos del invitado </h3>
                            <form  class="form" [formGroup]="registerForm" (ngSubmit)="register()">
                                <div class="form-floating form-group m-2">
                                    
                                    
                                    <input type="text" class="form-control" placeholder="" formControlName="name"
                                        [ngClass]="{ 'is-invalid': submitted && f2.name.errors }">
                                    <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
                                        <div *ngIf="f2.name.errors.required">Ingrese su nombre</div>
                                        <div *ngIf="f2.name.errors.minlength">El nombre debe tener al menos 3 caracteres</div>
                                    </div>
                                    <label for="floatingInput">Nombre Completo*</label>
                                </div>
                                <div class="form-floating form-group m-2">
                                 
                                    <input class="form-control" type="email" placeholder="" formControlName="correo"
                                        [ngClass]="{ 'is-invalid': submitted && f2.correo.errors }">
                                    <div *ngIf="submitted && f2.correo.errors" class="invalid-feedback ">
                                        <div *ngIf="f2.correo.errors.required">ingrese su email</div>
                                        <div *ngIf="f2.correo.errors.email">ingrese un correo valido</div>
                                    </div>
                                    <label for="floatingInput">Correo electronico*</label>
                                </div>
                                <div class="form-floating form-group m-2">
                                    <input placeholder="" class="form-control mb-2" formControlName="id"
                                            [ngClass]="{ 'is-invalid': submitted && f2.id.errors }"  type="text" 
                                            name="">
                                        <div *ngIf="submitted && f2.id.errors" class="invalid-feedback ">
                                            <div *ngIf="f2.id.errors.required">ingrese su número de documento</div>
                                        
                                        </div>
                                    <label for="floatingInput">Número de documento*</label>
                                  </div>
                                  <div class="d-flex justify-content-between">
                                    
                                      <input type="submit" value="Enviar" class="btn btn-primary">
                                        <button  type="submit" class="btn btn-botonAdmin" (click)="newRegister=false">Regresar</button>
                                    </div>
                            </form>
                            </main>     
                    </div>
                   

                </div>
            </div>
        </div>
    </div>
</div>