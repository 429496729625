import { Component, OnInit,ElementRef, Renderer2,ViewChild, HostListener  } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 
@Component({
  selector: 'app-ingreso',
  templateUrl: './ingreso.component.html',
  styleUrls: ['./ingreso.component.css']
})
export class IngresoComponent implements OnInit {
rperson;
rcompany;
icompanyForm;
ipersonForm;
rpersonForm;
rcompanyForm;
submitted;
  foto1Url: any;
  fotop: boolean;
  videoWidth = 0;
videoHeight = 0;
loading: boolean = false;

@ViewChild('video', { static: false }) videoElement: ElementRef;
@ViewChild('canvas', { static: false }) canvas: ElementRef;
  stream: any;
  id: any;
  fotovalidate: boolean;
  user: any;
href;
cargaRegistroMo: boolean = true;
estadoRegistro: boolean = true;

deferredPrompt: any;

@HostListener('window:beforeinstallprompt', ['$event'])
onBeforeInstallPrompt(event: Event) {
  console.log('beforeinstallprompt Event fired');
  event.preventDefault();
  this.deferredPrompt = event;
}


addToHomeScreen() {
  if (this.deferredPrompt) {
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice
      .then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Usuario aceptó la instalación de la aplicación');
        } else {
          console.log('Usuario canceló la instalación de la aplicación');
        }
        this.deferredPrompt = undefined;
      });
  }
}
  constructor(
    private authService: AuthService,
    private router:Router,
    private renderer: Renderer2

  ) { }
  showAddToHomeScreenButton = false;
  ngOnInit(): void {
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (isAndroid && !window.matchMedia('(display-mode: standalone)').matches) {
      // Mostrar el botón de instalación solo si es Android y no está en el modo "standalone"
      this.showAddToHomeScreenButton = true;
    }
    
    window.addEventListener('beforeinstallprompt', (e) => {
      try {
        console.log("¡Evento 'beforeinstallprompt' capturado!");
        this.deferredPrompt = e;
        // Aquí puedes agregar cualquier otra lógica que desees ejecutar cuando se dispare el evento
      } catch (error) {
        console.log("Error al manejar el evento 'beforeinstallprompt':", error);
      }
    });
    this.href = this.router.url;
    if(!this.href.includes("login")){
      this.loadingreso();
    }
 
    this.ipersonForm = new FormGroup({
 
      'id': new FormControl('', [
        Validators.required,
          ]),    
    });

    this.icompanyForm = new FormGroup({
 
      'nit': new FormControl('', [
        Validators.required,
          ]),    
    });


this.rcompanyForm=new FormGroup({
  'name': new FormControl('', [
    Validators.required,
    Validators.minLength(4)
  ]),
  'nit': new FormControl('', [
    Validators.required,
  ]),

  'clave': new FormControl('', [
    Validators.required,
  ]),

  //para representante
  'rname': new FormControl('', [
    Validators.required,
    Validators.minLength(4)
  ]),
  'rcorreo': new FormControl('', [
    Validators.required,
    Validators.email
  ]),
  'rtelefono': new FormControl('', [
    Validators.required,
    Validators.min(1111)
  ]),
 
 

})


    this.rpersonForm = new FormGroup({
      'name': new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      'apellido': new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      'correo': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'tipoid': new FormControl('', [
        Validators.required,
     
      ]),
      'nacimiento': new FormControl('', [
        Validators.required,     
      ]),
      'sexo': new FormControl('', [
        Validators.required,     
      ]),
      'id': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
      'empresa': new FormControl('', [
   
  
      ]),
      'cargo': new FormControl('', [
      
  
      ]),

      'telefono': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
       'arl': new FormControl('', [
     
  
      ]),
      'eps': new FormControl('', [
  
  
      ]),
   
      'rol': new FormControl('usuario', [
        Validators.required,
    
      ]),
      'tipo': new FormControl('persona', [
        Validators.required,
    
      ]),
      'categoria': new FormControl('socio', [
        Validators.required,
    
      ]),
      'aprobe': new FormControl(false, [
        Validators.required,
    
      ]),
    
    

    });
 
  }
  get f() { return this.rpersonForm.controls; }
  get f2() { return this.rcompanyForm.controls; }
  get f3() { return this.icompanyForm.controls; }
  get f4() { return this.ipersonForm.controls; }



loadingreso(){
  //leer usuario thi.user
  if(localStorage.getItem('userInfo')){
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    if(this.user.rol=="admin"){
      
      this.router.navigate(['administracion']);
      return
    }
    else if(this.user.rol=="areas"){
  
      this.router.navigate(['areas']);
      return
    }
    else if(this.user.rol=="porteria"){
     
      this.router.navigate(['porteria']);
      return
    }
    else {
     
      this.router.navigate(['agenda']);
      return
    }
  }
 
}
  regresarLogin(){
    this.rperson = false;
    this.estadoRegistro = true;
    this.rcompany = false;
  }
  registercompany(){
    this.estadoRegistro = false;
    this.rcompany=true;
  }

  registerperson(){
    this.estadoRegistro = false;
    this.rperson=true;
    this.fotop=false;
    this.activeVideo();
  }

  registerp(){
    this.submitted = true;
    this.rpersonForm.patchValue({
      rol:"usuario",
      tipo:"persona",
      categoria:"socio",
      aprobe:false
    })
    console.log(this.rpersonForm.controls);
    if (this.rpersonForm.invalid) {
      console.log("no enviado ");
      return;
  }
  console.log("enviado ");
    this.authService.createPerson(this.rpersonForm.value,this.foto1Url)
    .then((response) => {
      console.log(response);
if(response["user"].aprobe){
  this.authService.setUserInfo({'user' : response['user']});
  this.router.navigate(['agenda']);
}
else{
  Swal.fire("Usuario cargado correctamente")
  this.rperson=false;
}


 
     // this.completo=true;
    })
    .catch((err)=>{
      // this.router.navigate(['registerp']);
      Swal.fire("Error registradno usuario")
  })
  
  }
  

  registerc(){
    this.submitted = true;
    console.log(this.rcompanyForm.controls);
    if (this.rcompanyForm.invalid) {
      console.log("no enviado ");
      return;
  }
  console.log("enviado ");
    this.authService.createCompany(this.rcompanyForm.value)
    .then((response) => {
      console.log(response);
      this.authService.setUserInfo({'user' : response['user']});
     // this.completo=true;
     this.router.navigate(['empresa']);
    })
    .catch((err)=>{
      this.router.navigate(['registerp']);
  })
  
  }
  
  ingresoc(){
    this.submitted = true;
    if (this.icompanyForm.invalid) {
     console.log("invalido");
     return;
  }
    this.authService.validatec(this.icompanyForm.get("nit").value)
    .then(async (response) => {
      console.log(response);
    
      //validar clave

  const { value: pass } = await Swal.fire({
      title: 'Ingresa la contraseña',
      input: 'text',
      background:"#F0F8FF",
      inputPlaceholder: 'Contraseña',
      confirmButtonColor:"#696969",
      footer: '<a href="/olvido/'+response["user"]["_id"]+'">Olvide mi contraseña</a>'
    })
     
    if (pass) {
      if(pass==response["user"]["clave"]){
    
  this.authService.setUserInfo({'user' : response['user']});
  this.router.navigate(['empresa']);
      }
      else{
        Swal.fire("Contraseña incorrecta");
      }
    }


    })
    .catch((err)=>{

      Swal.fire(     {
        title: "No encontramos tu NIT ",
        text:"Si no te has registrado, por favor registrate",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#89c9b8',
        confirmButtonText: 'Registrarme',
        cancelButtonText: "Reintentar"
        
    }).then((result)=>{
    if(result.value){
    this.registercompany();
    
      
    }
    else{}
    });

      
  })
  }



  ingresop(){
    this.submitted = true;
    if (this.ipersonForm.invalid) {
     console.log("invalido");
     return;
  }
    this.authService.validatep(this.ipersonForm.get("id").value)
    .then(async (response) => {
      console.log(response);


        // this.authService.validateArea(response["user"].id)
        //         .then((res)=>{
        //           if(res["token"]&& res["user"].aprobe){
        //             localStorage.setItem('access_token', res["token"]);
        //             this.authService.setUserInfo({'user' : res['user']});
        //           }
        //           if(res["user"]["rol"]=="areas"){
        //             this.authService.setUserInfo({'user' : res['user']});
        //             this.router.navigate(['areas']);
        //             return
        //           }
        //           else if(res["user"]["rol"]=="porteria"){
        //             this.authService.setUserInfo({'user' : response['user']});
        //             this.router.navigate(['porteria']);
        //             return
        //           }
        //         })
        //         return
    
        
        if(response["user"]["photo1"]!="nofoto" ){
          if( response["user"].aprobe &&  response["user"].rol!="areas" &&  response["user"].rol!="porteria"){
            this.id=this.ipersonForm.get("id").value;
            this.fotop=false;
            this.fotovalidate=true;
            this.rperson=true;
            this.activeVideo();
          }
          else if ((response["user"].aprobe &&  response["user"].rol=="areas")|| (response["user"].aprobe &&  response["user"].rol=="porteria")){


            //pedir token
            const { value: pass } = await Swal.fire({
              title: 'Ingresa la contraseña',
              input: 'password',
              background:"#F0F8FF",
              inputPlaceholder: 'Contraseña',
              confirmButtonColor:"#696969",
            
            })
             
            if (pass) {
              if(pass=="serrezuela2023"){
                this.authService.validateArea(response["user"].id)
                .then((res)=>{
                  if(res["token"]&& res["user"].aprobe){
                    localStorage.setItem('access_token', res["token"]);
                  }
                  if(res["user"]["rol"]=="areas"){
                    this.authService.setUserInfo({'user' : res['user']});
                    this.router.navigate(['areas']);
                    return
                  }
                  else if(res["user"]["rol"]=="porteria"){
                    this.authService.setUserInfo({'user' : response['user']});
                    this.router.navigate(['porteria']);
                    return
                  }
                })
                .catch((err)=>{
                  console.log(err);
                  alert("El número de documento no esta registrado");
                
              
                    location.reload();
                })
              }
              else{
                Swal.fire("Contraseña incorrecta");
              }
            }


            

          }
        
          else{
            Swal.fire("Su registro esta en proceso de aprobación")
          }

   
    }
     else{
         this.authService.setUserInfo({'user' : response['user']});
   this.router.navigate(['agenda']);
     }
      
     
     
//   this.authService.setUserInfo({'user' : response['user']});
// this.router.navigate(['agenda']);



     
    })
    .catch((err)=>{

      Swal.fire(     {
        title: "No encontramos tu número de identificación ",
        text:"Si no te has registrado, por favor registrate",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#89c9b8',
        confirmButtonText: 'Registrarme',
        cancelButtonText: "Reintentar"
        
    }).then((result)=>{
    if(result.value){
    // this.registerperson();
    this.router.navigate(["register"])
      
    }
    else{}
    });

      
  })
  }



//control camara
handleError(error) {
  console.log('Error: ', error);
}

attachVideo(stream) {
  this.stream=stream;
  this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
  this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
    this.videoHeight = this.videoElement.nativeElement.videoHeight;
    this.videoWidth = this.videoElement.nativeElement.videoWidth;
});

}

activeVideo(){
     
      var  constraints = {
        video: {
            
            width: { ideal: 1280 },
            height: { ideal: 720 }
        }
    };
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
      navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
         } else {
             alert('Sorry, camera not available.');
         }



}

tomar(){
  this.loading = true;
  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
  this.authService.sendfoto(data)
  .then((response)=>{
    console.log(response["url"]);
    this.foto1Url=response["url"];
    this.loading=false;
    this.fotop=true;
  })
  .catch((err)=>{
alert("no encontramos tu rostro en la foto");
this.loading=false;

  })

}

tomarregistro(){
  this.loading=true;

  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');

  this.authService.validateP(data,this.id)
  .then((response)=>{
    console.log(response);
if(response["token"]&& response["user"].aprobe){
  localStorage.setItem('access_token', response["token"]);
}

    if(response["user"]["rol"]=="admin"){
      this.authService.setUserInfo({'user' : response['user']});
      this.router.navigate(['administracion']);
      return
    }
    else if(response["user"]["rol"]=="areas"){
      this.authService.setUserInfo({'user' : response['user']});
      this.router.navigate(['areas']);
      return
    }
    else if(response["user"]["rol"]=="porteria"){
      this.authService.setUserInfo({'user' : response['user']});
      this.router.navigate(['porteria']);
      return
    }
    else if(response["user"]["rol"]=="ocupacional"){
      this.authService.setUserInfo({'user' : response['user']});
      this.router.navigate(['aprobacion2']);
      return
    }

else{
  if(response["user"].aprobe){
    this.authService.setUserInfo({'user' : response['user']});
    this.router.navigate(['agenda']);
  }
  else{
    Swal.fire("Su registro esta en proceso de aprobación")
  }


}
    this.stream.getTracks()[0].stop();
  }) 
  .catch((err)=>{
    console.log(err);
    alert("los rostros no coinciden");
    this.loading=false;

      location.reload();
  })

}


}
