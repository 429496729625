import { Component, OnInit,Input, ChangeDetectorRef,ElementRef,ViewChild,Renderer2 } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-vinculacion',
  templateUrl: './vinculacion.component.html',
  styleUrls: ['./vinculacion.component.css']
})
export class VinculacionComponent implements OnInit {
seeRegister=true;
registerForm;
submitted; 
log; 
 foto1Url: any;
fotop: boolean;
videoWidth = 0;
videoHeight = 0;
loading: boolean = false;

@ViewChild('video', { static: false }) videoElement: ElementRef;
@ViewChild('canvas', { static: false }) canvas: ElementRef;
stream: any;
  user: any;
  id;
  visita: any;
  ingresoForm: FormGroup;
  constructor( private usersService: AuthService,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.paramMap.get("id")
    console.log("auth",this.isAuthenticated())
    if(this.isAuthenticated()){
this.seeRegister=false

    }
    else{
     
    }
    this.ingresoForm = new FormGroup({
   
      'doc': new FormControl('', [
        Validators.required,

      ]),

        });
    this.registerForm = new FormGroup({
      'name': new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),
     
      'correo': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'id': new FormControl("", [
        Validators.required,
      ]),
      'empresa': new FormControl("independiente", [
        Validators.required,
    
      ]),
    
      'rol': new FormControl('usuario', [
        Validators.required,
      ]),
   
      'categoria': new FormControl('invitado', [
        Validators.required,
    
      ]),
      'aprobe': new FormControl(false, [
        Validators.required,
    
      ]),

       
    });
  }

  isAuthenticated() : Boolean {
    let userData = localStorage.getItem('userInfo')
    if(userData && JSON.parse(userData)){
      this.user=JSON.parse(userData).user
      this.getVisita();
      return true;
    }
    return false;
  }

getVisita(){
this.usersService.getvisitasbyid(this.id)
.then((r:any)=>{

  this.visita=r.visita;
  console.log("vis",this.visita)
})
.catch((err)=>{
  location.href="/login"  
})
}

findPerson(){
  if(this.ingresoForm.invalid){
    return
  }
  this.usersService.validatep(this.ingresoForm.controls["doc"].value)
  .then((r:any)=>{
    console.log("invi",r)

    this.user=r.user;
    this.getVisita();
    this.seeRegister=false;

  })
  .catch((err)=>{
//    console.log("invi-error",err)
    this.log=true;
    this.registerForm.patchValue({id:this.ingresoForm.controls["doc"].value})
    this.ingresoForm.reset();
    this.activeVideo();
  })
}
get f(){return this.ingresoForm.controls}
  get f2() { return this.registerForm.controls; }

  register(){
  
    this.submitted = true;
    this.registerForm.patchValue({
      rol:"usuario",
      tipo:"persona",
      categoria:"invitado",
      aprobe:true,
      empresa:"independiente"
   
    })
    console.log(this.registerForm.value)
    if (this.registerForm.invalid) {
      return;
    }
    else{
    
       this.usersService.createPerson(this.registerForm.value,this.foto1Url)
       .then((res)=>{
        console.log(res);
        Swal.fire("Registro exitoso ")
        this.usersService.setUserInfo({'user' : res['user']});
        localStorage.setItem('access_token', res["token"]);
        this.user=res['user']
        this.getVisita();
      // this.invitados.push(res["user"])
      this.seeRegister=false;
      
      })
      .catch((err)=>{
        Swal.fire(     {
          title: "El registro no fue exitoso",
          text:"Por favor verifica tus datos, si ya te encuentras registrado pulsa el boton ingresar. Recomendamos el uso del navegador web Chrome. Si estás conectado por VPN por favor desconéctate y vuelve a intentarlo.",
          icon: 'error',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ingresar ',
          cancelButtonText: "Reintentar"
          
      }).then((result)=>{
      if(result.value){
      
      
      }
      else{
        //this.router.navigate(['']); 
      }
      });
       })
  
    }
  
  
  }
  
  //control camara
handleError(error) {
  console.log('Error: ', error);
}

attachVideo(stream) {
  this.stream=stream;
  this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
  this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
    this.videoHeight = this.videoElement.nativeElement.videoHeight;
    this.videoWidth = this.videoElement.nativeElement.videoWidth;
});

}

activeVideo(){
     
      var  constraints = {
        video: {
            
            width: { ideal: 1280 },
            height: { ideal: 720 }
        }
    };
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
      navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
         } else {
             alert('Sorry, camera not available.');
         }



}
tomar(){
  this.loading = true;
  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
  this.usersService.sendfoto(data)
  .then((response)=>{
    console.log(response["url"]);
    this.foto1Url=response["url"];
    this.loading=false;
    this.fotop=true;
  })
  .catch((err)=>{
alert("no encontramos tu rostro en la foto");
this.loading=false;

  })

}
addToVisita(){
  this.usersService.addPersonToVisit(this.visita,this.user._id)
  .then((r)=>{
    location.href="/agenda"
  })
}

}
