<div class="m-1" >




    <div class="row mt-3 justify-content-center "  >
        <div class="col-md-6" *ngIf="!log"  >
            <form  class="form" [formGroup]="ingresoForm" (ngSubmit)="findPerson()">
                    
              
                <div class="form-floating form-group m-2">
                    <input placeholder="" class="form-control mb-2" formControlName="doc"
                            [ngClass]="{ 'is-invalid': submitted && f.doc.errors }"  type="text" name="">
                        <div *ngIf="submitted && f.doc.errors" class="invalid-feedback ">
                            <div *ngIf="f.doc.errors.required">ingrese su número de documento</div>
                        
                        </div>
                    <label for="floatingInput">Número de documento*</label>
                  </div>
                  <div  style="display: inline;" >
                    <input type="submit" value="Buscar" class="btn btn-primary">
                    
                  </div>
                
            </form>
        </div>

        <div class="col-md-6" *ngIf="log" >
          
            <div    >
                <main class="form-signin w-100 m-auto" >
               
                    <h3>Ingresa los datos del invitado </h3>
                    <form  class="form" [formGroup]="registerForm" (ngSubmit)="register()">
                        <div class="form-floating form-group m-2">
                            
                            
                            <input type="text" class="form-control" placeholder="" formControlName="name"
                                [ngClass]="{ 'is-invalid': submitted && f2.name.errors }">
                            <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
                                <div *ngIf="f2.name.errors.required">Ingrese su nombre</div>
                                <div *ngIf="f2.name.errors.minlength">El nombre debe tener al menos 3 caracteres</div>
                            </div>
                            <label for="floatingInput">Nombre Completo*</label>
                        </div>
                        <div class="form-floating form-group m-2">
                         
                            <input class="form-control" type="email" placeholder="" formControlName="correo"
                                [ngClass]="{ 'is-invalid': submitted && f2.correo.errors }">
                            <div *ngIf="submitted && f2.correo.errors" class="invalid-feedback ">
                                <div *ngIf="f2.correo.errors.required">ingrese su email</div>
                                <div *ngIf="f2.correo.errors.email">ingrese un correo valido</div>
                            </div>
                            <label for="floatingInput">Correo electronico*</label>
                        </div>
                        <div class="form-floating form-group m-2">
                            <input placeholder="" class="form-control mb-2" formControlName="id"
                                    [ngClass]="{ 'is-invalid': submitted && f2.id.errors }"  type="text" 
                                    name="">
                                <div *ngIf="submitted && f2.id.errors" class="invalid-feedback ">
                                    <div *ngIf="f2.id.errors.required">ingrese su número de documento</div>
                                
                                </div>
                            <label for="floatingInput">Número de documento*</label>
                          </div>
                        <input type="submit" value="Enviar" class="btn btn-primary">
                    </form>
                    </main>     
            </div>
        </div>
    </div>

 
</div>