<div   *ngIf="!data" class="row mt-2 justify-content-center">
    <div class="col-md-6 col-8" style="text-align: center;" >
        <select #listcameras (change)="selectedCamera(listcameras.value)">
            <option value="">-</option>
            <option *ngFor="let camera of cameras" value="{{camera.id}}">{{camera.label}}</option>
        </select>
        
        <video  playsinline  #video1  width="80%" height="auto" ></video>
    </div>
    </div>
    <div   *ngIf="data" class="row mt-2 justify-content-center">
        <div class="col-md-6 col-10"  > 
            <h1 style="color: white;" >
                Información del ingreso
            </h1>
            <img src="{{manilla.user.photo1}}" width="50%" alt="">
            <h2>Nombre: {{manilla.user.name}} {{manilla.user.apellido}}</h2>
            <h2>EPS: {{manilla.user.eps}}</h2>
            <h2>ARL: {{manilla.user.arl}}</h2>
            <h2>Nivel de acceso: {{manilla.visita.acceso}}</h2>
            <h2>Descripción: {{manilla.visita.descripcion}}</h2>
            <h2 *ngIf="manilla.visita.empresa && manilla.visita.empresa!='independiente'" >Empresa: {{manilla.visita.empresa.name}}</h2>
  <button class="btn btn-primary"  (click)="termina()" >Terminar</button>
                </div>
    </div>