import { Component, OnInit , ElementRef, Renderer2, ViewChild,} from '@angular/core';
import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service";

import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { interval } from 'rxjs';
import  Swal from 'sweetalert2'; 
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {
user;
submitted = false;
registerForm: FormGroup;
rpersonForm;
tomandoFoto;
videoWidth = 0;
videoHeight = 0;
@ViewChild('video', { static: false }) videoElement: ElementRef;
@ViewChild('canvas', { static: false }) canvas: ElementRef;
  stream: any;
  constructor(private authService : AuthService,
    private renderer: Renderer2

    ) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;

    this.rpersonForm = new FormGroup({
      'name': new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      'apellido': new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      'nacimiento': new FormControl('', [
        Validators.required,     
      ]),
      'correo': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'id': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
      'empresa': new FormControl( [
        Validators.required,
    
      ]),
      'cargo': new FormControl('', [
        
    
      ]),
    
      'telefono': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
       'arl': new FormControl('', [
      
    
      ]),
      'eps': new FormControl('', [
       
    
      ]),
      'contacto': new FormControl('', [
        Validators.required,
    
      ]),
      'contactotel': new FormControl('', [
        Validators.required,
    
      ]),
    
    
    
    });
    this.loaduser();
    this.edituser()
  }



  loaduser(){
    this.authService.validatep(this.user.id)
    .then((response) => {
      console.log(response);
      this.authService.setUserInfo({'user' : response['user']});
      let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    })
    
  
     
         
  }
  
  get f2() { return this.rpersonForm.controls; }

  edituser(){
 
    this.rpersonForm.reset(this.user);
  }

  registerp(){
    this.submitted = true;
    console.log(this.rpersonForm.controls);
    if (this.rpersonForm.invalid) {
      console.log("no enviado ");
      return;
  }
  console.log("enviado ");
    this.authService.createPerson(this.rpersonForm.value,"")
    .then((response) => {
      this.authService.setUserInfo({'user' : response['user']});
      location.reload();
    
  })
  .catch((err)=>{
      
  })
     // this.completo=true;
 
  
  }

  
//control camara
handleError(error) {
  console.log('Error: ', error);
}

attachVideo(stream) {
  this.stream=stream;
  this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
  this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
    this.videoHeight = this.videoElement.nativeElement.videoHeight;
    this.videoWidth = this.videoElement.nativeElement.videoWidth;
});

}

activeVideo(){
     
      var  constraints = {
        video: {
            
            width: { ideal: 1280 },
            height: { ideal: 720 }
        }
    };
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
      navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
         } else {
             alert('Sorry, camera not available.');
         }



}

tomar(){
  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
  this.authService.sendfoto(data)
  .then((response)=>{
    console.log(response["url"]);
    var foto1Url=response["url"];
    this.authService.addfoto(this.user._id,foto1Url)
    .then(()=>{
      location.reload();
    })


  })
  .catch((err)=>{
alert("no encontramos tu rostro en la foto");
  })

}

activarcam(){
  this.tomandoFoto=true
  this.activeVideo();
}


}
