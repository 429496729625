import { Component, OnInit,ElementRef } from '@angular/core';
import {AuthService} from "../../../app/services/auth.service"
import {EventsService} from "../../services/events.service"
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { interval } from 'rxjs';
import  Swal from 'sweetalert2'; 
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit {
  usuarios: any;
  empresas: any;
  Add: boolean;
  selected: any;
  personasc=[];
  empresasc: any[];
  usuarios_: any;
  empresas_: any;
  busca: any[];
  visitas: any;
  mensajes: any;
  veventForm;
  vmensajes;
  nuevos: number;
  eventForm: FormGroup;
  submitted2: boolean;
  visitaForm: FormGroup;
  visitapop: boolean;
  agregados: any[];
  csvRecords: any;
  submitted = false;
  file: boolean;
  header: boolean;
  validcionPersonas: boolean;
  validando: boolean;
  ruser: number;
  nnuser: number;
  newNota: boolean;
  notaForm: FormGroup;
  fechaForm2: FormGroup;
  vhoy: boolean;
  vanteriores: boolean;
  vproximas: boolean;
  hoy: any;
  proximas: any;
  fecha: string;
  sDays=[];
  anteriores: any;
  constructor(    private authService : AuthService, 
    private ngxCsvParser: NgxCsvParser,
    private eventService : EventsService, 
    private route: ActivatedRoute, private router : Router, private elRef:ElementRef,) { }
id;
eventos;
area;
user;
  ngOnInit(): void {
    this.notaForm = new FormGroup({
      "id": new FormControl('', ),  
      'question': new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),              
      'time': new FormControl('', ),    
    });
    this.fechaForm2= new FormGroup({
      'fecha':new FormControl("",[Validators.required]),
    });
    this.id=this.route.snapshot.paramMap.get("id");
    let user = localStorage.getItem('userInfo')
    this.user= JSON.parse(user).user;
    console.log(user);
    if(this.user.rol=="admin" || this.user.rol=="areas"|| this.user.rol=="ocupacional"){
      this.getEventsArea();
    this.getArea();
    this.getempresas();
    this.getpersonas();
    this.lookConver();
  //  this.getVisitasAdmin();
  this.verhoy();

    }
    
  

  }
  cambioFecha(event){
console.log(event.target.value)
this.sDays.push(event.target.value);
  }
  verhoy(){
    this.getsolicitudeshoy();
    this.vhoy=true
    this.vanteriores=false;
    this.vproximas=false;
   

  }
  verproxi(){
    this.getsolicitudesproxima();
    this.vproximas=true;
    this.vhoy=false;
    this.vanteriores=false;


  }
  getsolicitudesproxima(){
    var date=new Date();
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    
    this.authService.getvisitasproxima(dia,mes,ano)
    .then((response)=>{
  console.log(response);
     
    this.proximas =response["visitas"];
    this.proximas.sort( this.compare );
    this.proximas=this.proximas.filter((el)=> {return el.admin})
    this.cargarhoras(this.proximas);
    })
  }
verantes(){
//  this.getsolicitudesanterior();
  this.vanteriores=true;
  this.vhoy=false;
  this.vproximas=false
}
newFecha2(){
  console.log(this.fechaForm2.controls)
  var date=new Date(this.fechaForm2.controls["fecha"].value.toString());
  this.fecha= date.getUTCDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear();

  var dia=date.getUTCDate();
  var mes=date.getMonth()+1;
  var ano=date.getFullYear();
  console.log(dia,mes,ano)
  this.authService.getvisitasanterior(dia,mes,ano)
  .then((response)=>{
console.log(response);

  this.anteriores=response["visitas"];
  this.anteriores.sort( this.compare );
  this.anteriores.reverse()
 this.anteriores= this.anteriores.filter((el)=>{return el.admin})
  this.cargarhoras(this.anteriores);
  })
}

compare( a, b ) {
  if ( a.fecha < b.fecha ){
    
    return -1;
  }
  if ( a.fecha > b.fecha ){
    return 1;
  }
  return 0;
}

getsolicitudeshoy(){
  var date=new Date();
  var dia=date.getDate();
  var mes=date.getMonth()+1;
  var ano=date.getFullYear();
  this.authService.getvisitashoy(dia,mes,ano)
  .then((response)=>{
//console.log(response);
  this.hoy=response["visitas"];
  this.hoy.sort( this.compare );
  console.log("antes",this.hoy);
  this.hoy=this.hoy.filter((el)=> {return el.admin})
  console.log("despues",this.hoy);
  this.cargarhoras(this.hoy);
  })
}

  newnota(id){
    this.newNota=true;
    this.notaForm.patchValue({
    id:id  
    });
  
  }
  eliminar(id){
    console.log(id);
    this.authService.eliminarVisita(id)
    .then((response) => {
     this.verhoy();
  })
  }


  onNewConversations(conver){
    console.log("evento: ",conver);
    this.mensajes=conver;
    this.nuevos=0;
    this.mensajes.forEach(element => {
      if(element.new!=""&& element.new!=this.user.nit){
      this.nuevos+=1;
      }
    });
  }

 
lookConver(){
this.authService.lookConver(this.user.id)
.then((response)=>{
console.log(response);
this.mensajes=response["mensajes"];
this.nuevos=0;
this.mensajes.forEach(element => {
if(element.new!=""&& element.new!=this.user.nit){
this.nuevos+=1;
}
});

})
.catch((err)=>{
console.log(err)
})

}
get f2() { return this.eventForm.controls; }
getVisitasAdmin(){
  this.eventService.getVisitasAdmin()
  .then((response)=>{
    this.visitas=response["visitas"];
    this.cargarhoras(this.visitas)
  })
}

cargarhoras(arr){
  arr =arr.filter((el)=>{return el.admin});
  console.log(arr);
  var empresas=[];
  arr.forEach(solicitud => {
  //  console.log("visita: ",solicitud);
    var date= new Date(solicitud.fecha);
     var fecha= date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getUTCFullYear()+" hora: "+date.getHours()+":"+(date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
     solicitud.fechat=fecha;
    solicitud.open=false; 
    var aprobada=0; 
    solicitud.personas.forEach(element => {
    if(element.aprobe3){
      aprobada+=1;
    }  
    });
    console.log(solicitud.empresa);
    if(solicitud.empresa){
      this.authService.getempresa(solicitud.empresa)
.then((response)=>{
console.log(response);


solicitud.empresa=response["empresa"];
})
    }
solicitud.aprobada=aprobada;
  }); 
}
  getArea(){
    this.eventService.getArea(this.id)
    .then((response)=>{
      console.log(response);
      this.area=response["results"][0];
      //this.getvisitasporAprobar();
      this.eventForm=new FormGroup({
     
        'id':new FormControl("0",[Validators.required,]),
        "nombre": new FormControl("",[Validators.required]),
        "fecha":new FormControl("",[Validators.required]),
        "descripcion":new FormControl("",[Validators.required]),      
       
        "areas":new FormControl([{
          "id":this.area._id,
          "name":this.area.nombre,
     
       
          }], [
        ]),
      })
    })
  }
getEventsArea(){
  this.eventService.getAllEvents()
  .then((response)=>{
    console.log(response);
    this.eventos=response["results"];
    this.eventos=this.eventos.filter(el=>{return !el.archivado})
  })

}

add(event){
  console.log(event);
  this.selected=event;
this.Add=true;
this.loadpersonas(event);
this.loadempresas(event);
//filtrar personas y empresas
this.usuarios_=this.usuarios;
this.empresas_=this.empresas;
this.usuarios_=this.usuarios.filter(this.personbyId.bind(this))
this.empresas_=this.empresas.filter(this.empresabyId.bind(this))
}
personbyId(obj){ 
    function check(persona){
      return persona.id==obj.id
    }
    return !this.selected.personas.some(check);

}
empresabyId(obj){

  function check(persona){
    return persona.id==obj.nit
  }
  return !this.selected.empresas.some(check);

}
getpersonas(){
  this.authService.getusers()
  .then((response)=>{
    //console.log(response);
    this.usuarios=response["usuarios"];
    
  })

}
getempresas(){
  this.authService.getempresas()
  .then((response)=>{
   // console.log(response);
    this.empresas=response["empresas"];
  })
}

loadpersonas(event){
  this.personasc=[]
  event.personas.forEach(id => {        
    this.authService.validatep(id.id)
    .then((response)=>{
      this.personasc.push(response["user"])
    })
    .catch((err)=>{
  
    })
    });
  
  }

  loadempresas(event){
    this.empresasc=[]
    event.empresas.forEach(id => {        
      this.authService.validatec(id.id)
      .then((response)=>{
        response["user"].cupo=id.cupo
        this.empresasc.push(response["user"])
      })
      .catch((err)=>{
    
      })
      });
    
    }
  loadempresa(id){

  this.authService.getempresa(id)
  .then((response)=>{
    console.log(response);
    
  })
  
     } 

  
addPersonToEvent(id){
 
  this.eventService.addPersonToEvent(this.selected._id,id,this.id)
  .then(()=>{
    this.eventService.getEvent(this.selected._id)
    .then((response)=>{
      this.add(response["results"][0])
    })
  })
}

removePersonToEvent(id){
 
  this.eventService.removePersonToEvent(this.selected._id,id)
  .then(()=>{
    this.eventService.getEvent(this.selected._id)
    .then((response)=>{
      this.add(response["results"][0])
    })
  })
}

removeEmpresaToEvent(id){
 
  this.eventService.removeempresatoevent(this.selected._id,id)
  .then(()=>{
    this.eventService.getEvent(this.selected._id)
    .then((response)=>{
      this.add(response["results"][0])
    })
  })
}
async addEmpresaToEvent(id,idempresa){
  console.log(this.selected);
  const { value: number } = await Swal.fire({
    title: 'Ingrese el cupo de personas de esta empresa en el evento',
    input: 'number',
    background:"#F0F8FF",
    inputPlaceholder: '',
    confirmButtonColor:"#696969"
  })
   
  if (number && number>0) {
   
    this.eventService.addempresatoevent(this.selected._id,id,this.id,number,idempresa)
    .then(()=>{
      this.eventService.getEvent(this.selected._id)
      .then((response)=>{
        this.add(response["results"][0])
      })
    })
  }

 
  
}
limpiar(or){
  if(or==1){
    this.usuarios_=this.usuarios.filter(this.personbyId.bind(this))
    this.elRef.nativeElement.querySelector('#buscado').value=""
  }
  else{
    this.empresas_=this.empresas.filter(this.empresabyId.bind(this))
    this.elRef.nativeElement.querySelector('#buscado2').value=""
  }
  
  
}

buscar(or){
  if(or==1){
    var buscado=this.elRef.nativeElement.querySelector('#buscado').value;
  console.log("buscando",buscado);
  this.usuarios_=this.usuarios_.filter((usr)=>{ return usr.id.includes(buscado)})
  }
  else{
    var buscado=this.elRef.nativeElement.querySelector('#buscado2').value;
    console.log("buscando",buscado);
    this.empresas_=this.empresas_.filter((usr)=>{ return usr.nit.includes(buscado)})
  }
  

}
atras(){
  this.Add=false;
  this.getEventsArea();
}


getvisitasporAprobar(){
  this.eventService.getVisitasArea(this.area._id)
  .then((response)=>{
    console.log(response);
    this.visitas=response["visitas"]
  })
}

async newEvent(){
  this.submitted2 = true;

  if (this.eventForm.invalid) {
    console.log("no enviado ");
    return;
    
}
//console.log(this.eventForm.value);
this.eventService.createEvent(this.eventForm.value)
.then(()=>{
  Swal.fire("evento creado correctamente")
  .then(()=>{location.reload()})

})
.catch(()=>{
  Swal.fire("hubo en error realizando la acción");
})


}
createFormaray(Inputs) {
  
  const arr = Inputs.map(marca => {
    return new FormControl(marca.selected || false);
  });
  return new FormArray(arr);
}

// marcar(){

//   this.csvRecords.forEach(element => {
//     try{
  
//     //console.log("elemento",element[0]);
//     var pp=this.personasc.map((e)=>{return e.id})
//     var index=pp.indexOf(element[0]);
//    // var index= this.personasc.
//     //console.log("index ",index);
//     //this.visitaForm.controls.personas[0]=true;
  
//     this.visitaForm.controls["personasc"].at(index).patchValue(true);
//     this.calcularPersonas();
  
//    // console.log("controls ", this.visitaForm.get('personas')['controls'])
//   }
//   catch(err){}
//   });
//   }
fileChangeListener($event: any): void {
  //this.visitaForm.get('personas')['controls'][0].value=true;
  
  this.file=true;
        // Select the files from the event
        const files = $event.srcElement.files;
    
        // Parse the file you want to select for the operation along with the configuration
        this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
          .pipe().subscribe((result: Array<any>) => {
    
          console.log('Result', result);
          this.csvRecords=result;
            //this.marcar();
            
          }, (error: NgxCSVParserError) => {
            console.log('Error', error);
          });
    
      }
  marcaruno(){
    var uno=this.elRef.nativeElement.querySelector('#marcauno').value;
   // console.log(uno)
   var uno=uno.split("\n")
  uno.forEach((el)=>{
    el =el.replace(/[,.-\s]/g, '');
    if (this.visitaForm.controls.personasc.value.indexOf(el)==-1 && el!="" ) this.visitaForm.controls.personasc.value.push(el);
    
  })

   //console.log(this.visitaForm.controls.personasc.value);
    this.calcularPersonas();
    this.elRef.nativeElement.querySelector('#marcauno').value="";
    if(this.validcionPersonas){
      this.validarPersonas();
    }
  
  }
  
  calcularPersonas(){
    this.agregados=this.visitaForm.controls.personasc.value
    
  
  }
  get f() { return this.visitaForm.controls; }

  compare2( a, b ) {
    if ( a.name < b.name ){
      
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }

createVisita(event){
this.sDays=[];
  this.validcionPersonas=false;
  this.empresas.map((el)=>{el.name=el.name.toUpperCase()})
  this.empresas.sort(this.compare2)


  this.visitaForm= new FormGroup({
    "personasc": new FormControl([], [
      Validators.required,
     
    ]),
    'eventid': new FormControl(event._id, [
      Validators.required,
     
    ]),
    'eventname': new FormControl(event.nombre, [
      Validators.required,
     
    ]),
    'tipo': new FormControl('prestacion de servicios', [
      Validators.required,
     
    ]),
  
    'descripcion': new FormControl('', [
       
    ]),

    "area":new FormControl(this.id, [
      Validators.required,   
    ]),
   
    'date': new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    'personas': new FormControl([], [
       
    ]),
 
    'dia': new FormControl('', [   ]),
    'mes': new FormControl('', [   ]),
    'ano': new FormControl('', [   ]),

   
  })
 
  this.visitapop=true;
  this.agregados=[];
}

removeDay(i){
  this.sDays.splice(i, 1);
}
async newVisita(){
  // console.log(this.visitaForm.controls.personas['controls']);

  this.submitted=true;
  console.log("controls:",this.f)

  if(this.sDays.length==0){
    Swal.fire("Agregue al menos una fecha a la visita")
    .then(()=>{
      return
     })
  }

//  if(this.agregados.length==0){
//   Swal.fire("No ha agregado personas a esta visita")
//   .then(()=>{
//     return
//    })
// }
else{
  var i=0;
  this.sDays.forEach(sdate => {


    var date= new Date(sdate);
    //console.log(now.getDate()==date.getDate() && now.getMonth()==date.getMonth());
    var dia=date.getDate();
    var mes=date.getMonth()+1;
    var ano=date.getFullYear();
    
    
     this.visitaForm.patchValue({
      ano:ano,
      mes:mes,
      dia:dia,
      date:sdate
       })
      // console.log(this.visitaForm.value);
      // console.log("resultados: ",this.visitaForm.value);
      // return
     
      if(this.visitaForm.invalid){
        console.log("invalido",this.visitaForm.value);
        return
      }
    
      this.authService.newvisitaadmin(this.visitaForm.value)
        .then((response)=>{
      
          //this.loadvisitas();
          i+=1;
          if(i>=this.sDays.length){
            this.getVisitasAdmin();
            this.verhoy()
            this.visitapop=false;
            console.log(response);
          }
        
        })
          .catch((err)=>{
         console.error(err);
        })
    
  });


}
    
 
   }


   validarPersonas(){
    this.validando=true;
     var length=this.visitaForm.controls.personasc.value.length;
     //console.log(this.visitaForm.controls.personasc.value);
     //console.log(this.visitaForm.controls.personas.value);
     var c=0;
     this.ruser=0;
     this.nnuser=0;
     var arr=[];
     const arr_=this.visitaForm.controls.personasc.value;
    this.visitaForm.controls.personasc.value.forEach((element,i) => {     
    
        this.authService.validatep(element)
        .then((response) => {
          c+=1;
          this.ruser+=1;
          var user=response["user"]; 

          arr.push(user._id);
          if(c==length){
            
             this.validando=false;
            this.validcionPersonas=true;

      this.visitaForm.patchValue({
        personas :arr,
        personasc:arr_
         })
          }
        })
        .catch((err)=>{
          c+=1;
          this.nnuser+=1;
        //   arr[i]={
        //     "id":element,
        //     "name":null,
        //  "ingreso":null,
        //  "aprobe1":true,
        //  "aprobe2":true,
        //  "aprobe3":true,
         
        //   }
          if(c==length){
             this.validando=false;
         
            this.validcionPersonas=true;
      
      this.visitaForm.patchValue({
        personas :arr,
        personasc:arr_
         })
          }
        })

   
    });
   }

}
