<div class="row m-1 justify-content-center " >
    <div class="col-md-4"style="text-align:center; ">
        <h1 style="margin-bottom: 5px;" >Administración {{area.nombre}}</h1>
        <h2>  Bienvenido {{user.name}} {{user.apellido}}</h2>
    </div>
</div>
<div style="position: absolute;top: 10px;right: 10px; text-align: end; " >
    
    <button class="btn btn-boton2" (click)="vmensajes=!vmensajes" >Mensajes  <span *ngIf="nuevos>0" >({{nuevos}})</span></button>
    <app-mensajes *ngIf="vmensajes" (newConversaions)="onNewConversations($event)"  [conversations]="mensajes" style="z-index: 99;" >
    
    </app-mensajes>
    </div>

<div *ngIf="!Add" class="row m-1 justify-content-center " >
    
    <div class="col-md-4 col-11"  style="max-height: 600px; overflow-y: auto; " >
        <div  class="row justify-content-center">
<div  class="col-12">
    <button (click)="veventForm=!veventForm" class="btn btn-primary m-1" style="padding: 5px; font-size: 15px; "  >Crear evento</button>
     <button  routerLink="/reservas" class="btn btn-primary m-1" style="padding: 5px; font-size: 15px; "  >Reservas</button>
     <h2>Eventos en curso:  </h2> 
    <div class=" row justify-content-center " >
        <div style="text-align: left; border: 3px solid black; padding: 0px; margin-bottom: 15px;" class="col-md-6 col-12 m-1" *ngFor="let event of eventos" >
            <app-evento [event]="event" style="width: 100%;"></app-evento>
            <a href="/evento/{{event._id}}"><button  class="btn btn-primary" >Ver resumen</button></a>
            <!-- <button class="btn btn-primary" (click)="add(event)" >Vincular personas o empresas</button> -->
            <button  class="btn btn-primary" (click)="createVisita(event)" >Crear visita </button>
                </div> 
    </div>
</div>
        </div>
       
       
        <div *ngIf="veventForm" class="row justify-content-center">

            <div class="col-md-6 caja-form "   >
                <h1>Creación de evento</h1>
            <form [formGroup]="eventForm" (ngSubmit)="newEvent()">
                <div class="form-group" *ngIf="eventForm.controls['id'].value!='0'" > 
                    <h3>Id</h3>
                    <input type="text" class="form-control" formControlName="id" readonly>
                    </div>
                <div class="form-group">
                    <h3>Ingrese  nombre del evento </h3>
                    <input type="text" class="form-control"  formControlName="nombre"  [ngClass]="{ 'is-invalid': submitted2 && f2.nombre.errors }">
                    <div *ngIf="submitted2 && f2.nombre.errors" class="invalid-feedback">
                        <div *ngIf="f2.nombre.errors.required">Ingrese su nombre</div>
                       
                    </div>
                </div> 
                <div class="form-group">
                    <h3>Ingrese una descripcion del evento </h3>
                    <input type="text" class="form-control"  formControlName="descripcion"  [ngClass]="{ 'is-invalid': submitted2 && f2.descripcion.errors }">
                    <div *ngIf="submitted2 && f2.descripcion.errors" class="invalid-feedback">
                        <div *ngIf="f2.descripcion.errors.required">Ingrese su descripcion</div>
                       
                    </div>
                </div> 
            
            
                <div class="form-group">
                    <h3>Ingrese la fecha y hora del evento</h3>     
                    <input class="form-control" type="datetime-local" placeholder="Fecha" formControlName="fecha"
                    min="2021-06-01" max="2023-12-31"
                    required      >
                    </div>
                 
                    <input type="submit" value="Crear" class="btn btn-primary" >
            </form>
            </div>
            </div>
        </div>
        
    
    <div class="col-md-7 col-10"  style="max-height: 600px; overflow-y: auto; " >
        <h2>Visitas creadas: </h2>
        <div class="row justify-content-center" >
            <div class="col-md-4 col-4" >
                <button *ngIf="!vhoy" class="btn btn-primary" (click)="verhoy()"  >Ver visitas de hoy</button>
                <h2 *ngIf="vhoy" >Visitas hoy</h2>
            </div>
        <div class="col-md-4 col-4" >
            <button *ngIf="!vanteriores" class="btn btn-primary"  (click)="verantes()" >Ver visitas anteriores</button>
        <h2 *ngIf="vanteriores" >visitas anteriores</h2>
        </div>
        <div class="col-md-4 col-4" >
            <button *ngIf="!vproximas" class="btn btn-primary"  (click)="verproxi()" >Ver proximas visitas</button>
            <h2 *ngIf="vproximas" >Proximas visitas</h2>
        </div>
      
      
     
        </div>
        <div class="row justify-content-center" >
           
            <div class="col-md-12" *ngIf="vanteriores"  >
             
                <div class="row ">
 <div  class="col-4">
     <form [formGroup]="fechaForm2" (ngSubmit)="newFecha2()">
         <div >
             <h3>Ingrese la fecha:</h3>
             <input class="form-control" type="date"  formControlName="fecha"
                 min="2021-06-01" max="2023-12-31" required>
         </div>
 <button class="btn btn-primary" >Buscar</button>
     </form>
 </div>
                </div>
                 <div class="row justify-content-center" >
                    
         <div class="col-md-4"*ngFor="let user of anteriores"  >
             <div class="mt-2 caja-datos "  style=" border-radius: 15px; padding: 5px;" >
                 <div  *ngIf="user.open" >
                     <app-visita [visita]="user"  ></app-visita>
                     <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
                 </div>
              
                <div *ngIf="!user.open" >
                 <button  class="btn btn-primary"  (click)="user.open=true" >Ver detalles</button> <br>
                 <span  >{{user.fechat}}</span><br>
 
                 <span class="text" *ngIf="user.eventid!='general'"  > Evento: {{user.eventname}}</span>
                 <span class="text" *ngIf="user.eventid=='general'"  > Evento: General</span>
                
                 <br>
                 <span class="text" > Descripción: {{user.descripcion}}</span><br>
                 <span class="text" > Nivel de acceso: {{user.acceso}}</span><br>
                 <span class="text" *ngIf="user.empresa" > Empresa: {{user.empresa.name}}<br></span>
                 <span class="text" *ngIf="!user.empresa" > Empresa: Independiente<br> </span>
                 <span class="text" > Número de personas: {{user.personas.length}}<br> </span>
                 <div style="border:solid 2px black" >
                     <h2 >Notas de la visita</h2>
                     <span *ngFor="let nota of user.notas" >
                     - {{nota.txt}} 
                     <br>
                     </span>
                     <button  (click)="newnota(user._id)" class="btn btn-primary m-1" >Agregar nota</button>
                             </div>
                 <!-- <div *ngIf="user.empresa">
                     {{user.empresa.name}}
                     <span class="text"> NIT: {{user.empresa.nit}}</span>
               
                     <br>
               
                     <span class="text" *ngIf="user.placa"> Vehiculo: {{user.placa}}</span>
                     <br>
                                 
               
               
                   </div> -->
         
                 <span  [ngClass]="{'verde':persona.aprobe3}" *ngFor="let persona of user.personas" >
                     {{persona.name}} {{persona.apellido}}   -- {{persona.id}}  <br> 
                     <span *ngIf="persona.aprobe3 && persona.aprobe2 && persona.aprobe1 " > Aprobado <br> </span>
                     <span *ngIf="!persona.aprobe3 || !persona.aprobe2 || !persona.aprobe1 "  > No aprobado <br> </span>
              
                 <span *ngIf="persona.ingreso" > Ya registro ingreso <br> </span>
                 <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
                 <span *ngIf="persona.salida" > Ya registro salida  <br> </span>
                     <span *ngIf="!persona.salida" > No ha salido <br> </span>
                 <hr>
                 </span>
             
                
                </div>
                 <button class="btn-danger"  (click)="eliminar(user._id)" >Eliminar</button>
             </div>
         </div>
         
                 </div>
                 
             </div>
         
             <div class="col-md-12" *ngIf="vhoy" >
             
         
                 <div class="row justify-content-center" >
                     <div class="col-md-4"  *ngFor="let user of hoy" >
                        
                 <div class="mt-2 " [ngClass]="{'verde':user.aprobada==user.personas.length && !user.admin,'verde3':user.aprobada==user.personas.length && user.porteria, 'verde2':user.aprobada==user.personas.length && user.admin, 'amarillo':user.aprobada != user.personas.length && user.aprobada!=0,'caja-datos':user.aprobada==0}"   style=" border-radius: 15px; padding: 5px;" >
                                            <div   *ngIf="user.open" >
                                                <app-visita [visita]="user"  ></app-visita>
                                                
                                                <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
                                            </div>
                                        
                                            
                                            <div *ngIf="!user.open" >
                                                <button  *ngIf="!user.open" class="btn btn-primary btn-2"  (click)="user.open=true" >Ver detalles</button><br>
                            <span  class="texto-color">{{user.fechat}}</span><br>
                                        
                                            
                            <br>
                        <span>Recibe: {{user.recibeName}} </span> 
                        <br>


                        <br>
                        <span class="text" > Descripción: {{user.descripcion}}</span><br>
                        <span class="text"*ngIf="user.acceso"  > Nivel de acceso: {{user.acceso}}<br></span> 

                        <span class="text" > Número de personas: {{user.personas.length}}<br> </span>
                        <div class="d-flex justify-content-end">

                            <button *ngIf="!user.notasopen" class="btn btn-primary btn-2" (click)="user.notasopen=true" >ver notas</button><br>
                        </div>
                        <div *ngIf="user.notasopen" style="border:solid 2px black" >
                            <h2 >Notas: </h2>
                            <span *ngFor="let nota of user.notas" >
                            - {{nota.txt}} 
                            <br>
                            </span>
                            <button  (click)="newnota(user._id)" class="btn btn-primary m-1 btn-2" >Agregar nota</button>
                            <button  class="btn btn-primary btn-2" (click)="user.notasopen=false" >ocultar notas</button>
                                    </div>

                        <span class="text" style="font-size: 22px;" > Personas:</span><br>
                        <span [ngClass]="{'verde':persona.aprobe3}" *ngFor="let persona of user.personasObj" >
                            {{persona.name}} {{persona.apellido}}    <br> 
                            <span *ngIf="persona.ingreso" > Ya registro ingreso {{persona.ingreso.hora | date:'medium'}} <br> </span>
                            <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>

                        <hr>
                        </span>

                    </div>
                 
                     <button class="btn-danger"  (click)="eliminar(user._id)" >Eliminar</button>
                 </div>
               
                     </div>
                     
                             </div>
                 
             </div>
             </div>
             <div class="col-md-12" *ngIf="vproximas"  >
              
                 <div class="row justify-content-center" >
                     <div class="col-md-4" *ngFor="let user of proximas" >
                        <div class="mt-2 " [ngClass]="{'verde':user.aprobada==user.personas.length && !user.admin,'verde3':user.aprobada==user.personas.length && user.porteria, 'verde2':user.aprobada==user.personas.length && user.admin, 'amarillo':user.aprobada != user.personas.length && user.aprobada!=0,'caja-datos':user.aprobada==0}"   style=" border-radius: 15px; padding: 5px;" >
                            <div   *ngIf="user.open" >
                                <app-visita [visita]="user"  ></app-visita>
                                
                                <button  class="btn btn-primary"  (click)="user.open=false" >Ocultar detalles</button>
                            </div>
                        
                            
                            <div *ngIf="!user.open" >
                                <button  *ngIf="!user.open" class="btn btn-primary btn-2"  (click)="user.open=true" >Ver detalles</button><br>
            <span  class="texto-color">{{user.fechat}}</span><br>
                        
                            
            <br>
        <span>Recibe: {{user.recibeName}} </span> 
        <br>


        <br>
        <span class="text" > Descripción: {{user.descripcion}}</span><br>
        <span class="text"*ngIf="user.acceso"  > Nivel de acceso: {{user.acceso}}<br></span> 

        <span class="text" > Número de personas: {{user.personas.length}}<br> </span>
        <div class="d-flex justify-content-end">

            <button *ngIf="!user.notasopen" class="btn btn-primary btn-2" (click)="user.notasopen=true" >ver notas</button><br>
        </div>
        <div *ngIf="user.notasopen" style="border:solid 2px black" >
            <h2 >Notas: </h2>
            <span *ngFor="let nota of user.notas" >
            - {{nota.txt}} 
            <br>
            </span>
            <button  (click)="newnota(user._id)" class="btn btn-primary m-1 btn-2" >Agregar nota</button>
            <button  class="btn btn-primary btn-2" (click)="user.notasopen=false" >ocultar notas</button>
                    </div>

        <span class="text" style="font-size: 22px;" > Personas:</span><br>
        <span [ngClass]="{'verde':persona.aprobe3}" *ngFor="let persona of user.personasObj" >
            {{persona.name}} {{persona.apellido}}    <br> 
            <span *ngIf="persona.ingreso" > Ya registro ingreso {{persona.ingreso.hora | date:'medium'}} <br> </span>
            <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>

        <hr>
        </span>

    </div>
 
     <button class="btn-danger"  (click)="eliminar(user._id)" >Eliminar</button>
 </div>
                       
                     </div>
                     
                             </div>
                
             </div>
                    </div>
    </div>
  



<div  *ngIf="Add"   >
    <div class="row m-1" >
<div class="col-1">
<button class="btn btn-danger ml-5" (click)="atras()" >Atras</button>
</div>
    </div>
    <div class="row justify-content-center " >
<div class="col-md-4 col-11 caja-add"  style="color: white; font-size: 15px;" >
    <app-evento [event]="selected" ></app-evento>
    <br>
    Empresas agregadas: 
    <br>
    <div class="row ">    
    <div class="col-md-12" *ngFor="let empresa of empresasc">
        <hr>
     {{empresa.name}} -({{empresa.cupo}} cupos) NIT: {{empresa.nit}} <button (click)="removeEmpresaToEvent(empresa.nit)"> borrar</button>
      <hr>
      </div>
    </div>
    <br>
    Personas agregadas: 
    <br>
    <div class="row ">    
    <div class="col-md-6" *ngFor="let persona of personasc">
     
        <app-persona [user]="persona" [show]="false" ></app-persona>
       <button (click)="removePersonToEvent(persona.id)"> borrar</button>
      </div>
    </div>
   

</div>
<div class="col-md-3 col-5 caja-add" style="color: white;"  >
    <h1>Agregar personas</h1>
    <br>
    Buscar por cédula: 
    <br>
    <input type="text" id="buscado" autocomplete="off" > <button class="btn btn-primary" (click)="buscar('1')" >Buscar</button> 
<button  class="btn btn-danger" (click)="limpiar('1')" >limpiar</button>
<div style="color: white; font-size: 15px;" *ngFor="let persona of usuarios_" ><hr> <span style="font-weight: bold;" > Nombre: </span> {{persona.name}} {{persona.apellido}}  <br><span style="font-weight: bold;" > CC: </span> {{persona.id}}  <button  class="btn btn-primary" (click)="addPersonToEvent(persona.id)" >vincular al evento</button> <hr></div>
</div>
<div class="col-md-3 col-5 caja-add" style="color: white;" >
    <h1>Agregar Empresas</h1>
    <br>
    Buscar por NIT: 
    <br>
    <input type="text" id="buscado2" autocomplete="off"> <button class="btn btn-primary" (click)="buscar('2')" >Buscar</button> 
    <button  class="btn btn-danger" (click)="limpiar('2')" >limpiar</button>
<div style="color: white; font-size: 15px;" *ngFor="let empresa of empresas_" > <hr> <span style="font-weight: bold;" > Nombre: </span> {{empresa.name}}  <br> <span style="font-weight: bold;" > NIT: </span> {{empresa.nit}}  <button  class="btn btn-primary" (click)="addEmpresaToEvent(empresa.nit,empresa._id)" >vincular al evento</button> <hr> </div>
</div>
    </div>

</div>



<div *ngIf="visitapop" class="row cajainfo text-white  container mt-5" style="position: fixed; top: 1%; left:8%; padding: 10px; overflow-y: scroll; max-height: 550px;">
   <div class="col-md-10">
    <form [formGroup]="visitaForm" (ngSubmit)="newVisita()" novalidate="">  
     
    

                   
                    <div class="form-group" *ngIf="visitaForm.controls['tipo'].value=='prestacion de servicios'" >
                <h3>Describa el servicio que va a prestar</h3>
                <input type="text" class="form-control"  formControlName="descripcion" [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }" >
                    </div>
                    
                    <div class="form-group">
                        Fechas habilitadas: 
                        <span  *ngIf="sDays.length==0">0</span>
                        <div class="day" *ngFor="let day of sDays ; let i = index" > 
                            {{ day | date:'medium'}}
                             <button class="btn btn-danger p-1"  (click)="removeDay(i)"><i class="bi bi-trash"></i> </button> <br></div>
                        <h3>Agregue fecha y hora de su visita</h3>     
                        <input class="form-control" type="datetime-local" placeholder="Fecha" formControlName="date"
                        min="2021-06-01" max="2023-12-31"
                        required  (change)="cambioFecha($event)"     >
                        <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                            <div *ngIf="f.date.errors.required">Campo requerido</div>
                               </div>
                            
                        </div>
                       Personas agregadas: {{agregados.length}} <br>
                        <div *ngIf="!validcionPersonas" >
                            Ingrese las cédulas de las personas a agregar (puede pegar desde una hoja de calculo) : <textarea  cols="20" rows="3" id="marcauno" autocomplete="off" #marcauno ></textarea> <button (click)="marcaruno()" type="button" > Agregar</button>
                        </div>

                        
                        <ul *ngIf="!validcionPersonas"  style="max-height: 100px; overflow-y: auto;" >
                            <li *ngFor="let id of visitaForm.controls['personasc'].value" >{{id}}</li>
                        </ul>
                        <div *ngIf="validcionPersonas" class="row" >
                            <div class="col-md-6">
                                <h2>Usuarios registrados {{ruser}}</h2>
                                <!-- <ul   style="max-height: 100px; overflow-y: auto;" >
                                    <ng-container *ngFor="let id of visitaForm.controls['personas'].value" >
                                        <li *ngIf="id.name" style="color: rgb(43, 42, 42);" >{{id.name}}- {{id.id}} </li>
                                    </ng-container>
                                    
                                </ul> -->
                            </div>
                            <div class="col-md-6">
                                <h2>Usuarios no registrados {{nnuser}}</h2>
                                <!-- <ul   style="max-height: 100px; overflow-y: auto;" >
                                    <ng-container *ngFor="let id of visitaForm.controls['personas'].value" >
                                        <li *ngIf="!id.name" style="color: rgb(43, 42, 42);;" > {{id.id}} </li>
                                    </ng-container>
                                    
                                </ul> -->
                            </div>
                           
                            
                        
               
           </div>
            <input type="submit" *ngIf="validcionPersonas" value="Crear visita" class="btn btn-primary">
      
        </form>
        <div class="row">
<div class="col-10">
    <button class="btn btn-primary" *ngIf="!validcionPersonas && !validando" (click)="validarPersonas()" >Validar documentos</button>
    <h4 *ngIf="validando" >Cargando..</h4> 
     <button class="btn btn-danger ml-2" (click)="visitapop=false" >Cancelar</button>

</div>
        </div>
   </div>
   
   </div> 