<div style="position: absolute;top: 10px; left: 10px; z-index: 99;" >
    <a href="/tutorial" target="_blank" rel="noopener noreferrer">
<button class="btn btn-boton2">Tutoriales</button></a>
</div>
<div style="position: absolute;top: 10px;right: 10px; text-align: end; " >
    
<button class="btn btn-boton2" (click)="vmensajes=!vmensajes" >Mensajes  <span *ngIf="nuevos>0" >({{nuevos}})</span></button>
<app-mensajes *ngIf="vmensajes" (newConversaions)="onNewConversations($event)"  [conversations]="mensajes" style="z-index: 99;" >

</app-mensajes>
</div>
<div *ngIf="nuser" class="pop" style="overflow-y: auto;" >
    <div class="row mt-4 justify-content-center" >
        <div class="col-5" >
            
            <app-persona [user]="luser" [show]="false" ></app-persona>    
        </div>
    </div>
    <div class="row mt-0  mb-4 justify-content-center" >
        <div class=" cajainfo col-5" >
            <button class="btn btn-primary" (click)="vincular()"  >Vincular</button>
            <button class="btn btn-danger" (click)="nuser=false" >Cancelar</button>
        </div> </div>
   
</div>
<div class="row justify-content-between" *ngIf="user.tipo=='empresa'" >
    <div class="col-11" style="text-align:center" ><h1>Bienvenido {{user.name}}</h1></div>
    <div class="col-md-3 m-5" style="max-height: 450px; overflow-y: scroll;" >
      
        <a href="/perfilempresa"><button  class="btn btn-primary" >Editar Datos</button></a>
    <app-empresa [user]="user" ></app-empresa> 
    
</div>
<div class="col-md-3 m-5" >
<div  *ngIf="cargador" class="pop" >
<div class="row mt-4 justify-content-center" >
<div class="col-9" >
    <button class="btn btn-danger "  (click)="cargador=false" >Cancelar</button>
<app-useruploader (active)="onActive($event)" [empresa]="user._id" ></app-useruploader>
</div>
</div>
</div>








<div *ngIf="!registrar" class="cajainfo  p-2 "style="color: white;font-size: 15px; font-weight: bold;  overflow-x: auto;" >
    Enlace de registro para vincular personas: <br>
    <span style="word-wrap: break-word;" >{{registerurl}}</span> <br>
   <button class="btn btn-danger" style="padding: 1px; font-size: 12px;" (click)="copiar()" >copiar</button> 
   <br>
   <br>
   Agrega personas por numero de cédula:
   <form [formGroup]="idrperson" (ngSubmit)="lookPerson()"   >

     <div class="form-group">
         <h3>Documento de identidad:</h3>
         <input class="form-control" type="number" placeholder="Documento de identidad" formControlName="id" >
         
     </div>
     <input type="submit" class="btn btn-primary" value="Buscar">
 </form>
 </div>
<br>

<button class="btn btn-primary mb-1"  (click)="cargador=true" >Cargar usuarios con archivo CSV</button>

<button *ngIf="!registrar"  class="btn btn-primary"  (click)="registrar=true" >Registrar persona</button>
<button *ngIf="registrar"  class="btn btn-danger"  (click)="registrar=false" >Cancelar</button>
    <h2 *ngIf="registrar" >Agregar persona</h2>
    <form *ngIf="registrar" [formGroup]="rpersonForm" (ngSubmit)="registerp()"  class="caja-form"  >  
     

        <div class="row" >
            <div class="col-11 col-md-6" >
                <div class="form-group">
                    <h3>Nombres:</h3>
                    <input type="text" class="form-control" placeholder="Nombre" formControlName="name"  [ngClass]="{ 'is-invalid': submitted && f2.name.errors }">
                    <div *ngIf="submitted && f2.name.errors" class="invalid-feedback">
                        <div *ngIf="f2.name.errors.required">Ingrese su nombre</div>
                        <div *ngIf="f2.name.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                    </div>
                </div> 
            </div>
            <div class="col-11 col-md-6" >
                <div class="form-group">
                    <h3>Apellidos:</h3>
                    <input type="text" class="form-control" placeholder="Apellido" formControlName="apellido"  [ngClass]="{ 'is-invalid': submitted && f2.apellido.errors }">
                    <div *ngIf="submitted && f2.apellido.errors" class="invalid-feedback">
                        <div *ngIf="f2.apellido.errors.required">Ingrese sus apellidos</div>
                        <div *ngIf="f2.apellido.errors.minlength">El nombre debe tener al menos 1 caracteres</div>
                    </div>
                </div> 
            </div>
                        </div>

     
                        <div class="row" >
                            <div class="col-11 col-md-6" >
                                <div class="form-group">
                                    <h3>Correo electronico:</h3>     
                                    <input  class="form-control" type="email" placeholder="Correo electrónico" formControlName="correo"  [ngClass]="{ 'is-invalid': submitted && f2.correo.errors }">
                                    <div *ngIf="submitted && f2.correo.errors" class="invalid-feedback ">
                                        <div *ngIf="f2.correo.errors.required">ingrese su email</div>
                                        <div *ngIf="f2.correo.errors.email">ingrese un correo valido</div>
                                    </div> 
                                </div> 
                            </div>
                            <div class="col-11 col-md-6" >
                                <div class="form-group">
                                    <h3>Documento de identidad:</h3>
                                    <input class="form-control" type="number" placeholder="Documento de identidad" formControlName="id" [ngClass]="{ 'is-invalid': submitted && f2.id.errors }">
                                    <div *ngIf="submitted && f2.id.errors" class="invalid-feedback ">
                                        <div *ngIf="f2.id.errors.required">ingrese su documento</div>
                                        <div *ngIf="f2.id.errors.min">ingrese un documento valido</div>
                                    </div> 
                                </div>
                            </div>
                                        </div>
    

                                        <div class="row" >
                                            <div class="col-11 col-md-6" >
                                                <div class="form-group">
                                                    <h3>Celular:</h3>
                                                    <input class="form-control" type="number" placeholder="celular" formControlName="telefono" [ngClass]="{ 'is-invalid': submitted && f2.telefono.errors }">
                                                    <div *ngIf="submitted && f2.telefono.errors" class="invalid-feedback ">
                                                        <div *ngIf="f2.telefono.errors.required">ingrese su telefono</div>
                                                        <div *ngIf="f2.telefono.errors.min">ingrese un telefono valido</div>
                                                    </div> 
                                                </div>  
                                            </div>
                                            <div class="col-11 col-md-6" >
                                                <div class="form-group">
                                                    <h3>EPS</h3>
                                                    <input type="text" class="form-control" placeholder="EPS" formControlName="eps"  [ngClass]="{ 'is-invalid': submitted && f2.eps.errors }">
                                                    <div *ngIf="submitted && f2.eps.errors" class="invalid-feedback">
                                                        <div *ngIf="f2.eps.errors.required">Campo requerido</div>
                                                           </div>
                                                </div>
                                            </div>
                                                        </div>
      
   
                                                        <div class="row" >
                                                            <div class="col-11 col-md-6" >
                                                                <div class="form-group">
                                                                    <h3>Cargo</h3>
                                                                    <input type="text" class="form-control" placeholder="Cargo" formControlName="cargo"  [ngClass]="{ 'is-invalid': submitted && f2.cargo.errors }">
                                                                    <div *ngIf="submitted && f2.cargo.errors" class="invalid-feedback">
                                                                        <div *ngIf="f2.cargo.errors.required">Campo requerido</div>
                                                                           </div>
                                                                </div> 
                                                            </div>
                                                            <div class="col-11 col-md-6" >
                                                                <div class="form-group">
                                                                    <h3>ARL</h3>
                                                                    <input type="text" class="form-control" placeholder="ARL" formControlName="arl"  [ngClass]="{ 'is-invalid': submitted && f2.arl.errors }">
                                                                    <div *ngIf="submitted && f2.arl.errors" class="invalid-feedback">
                                                                        <div *ngIf="f2.arl.errors.required">Campo requerido</div>
                                                                           </div>
                                                                </div> 
                                                            </div>
                                                                        </div>
   
     <div class="row" >

<div class="col-11 col-md-6" >
<div class="form-group">
    <h3>Contacto de emergencia</h3>
    <input type="text" class="form-control" placeholder="Contacto" formControlName="contacto"  [ngClass]="{ 'is-invalid': submitted && f2.contacto.errors }">
    <div *ngIf="submitted && f2.contacto.errors" class="invalid-feedback">
        <div *ngIf="f2.contacto.errors.required">Campo requerido</div>
           </div>
</div> 
</div>
<div class="col-11 col-md-6" >
    <div class="form-group">
        <h3>Celular del contacto</h3>
        <input type="number" class="form-control" placeholder="celular" formControlName="contactotel"  [ngClass]="{ 'is-invalid': submitted && f2.contactotel.errors }">
        <div *ngIf="submitted && f2.contactotel.errors" class="invalid-feedback">
            <div *ngIf="f2.contactotel.errors.required">Campo requerido</div>
               </div>
    </div> 
</div>
        </div>
        <div class="row" >
            <div class="col-11 col-md-6" >
               
            </div>
            <div class="col-11 col-md-6" >
            
            </div>
                        </div>
    
 

        <input type="submit" value="Ingresar" class="btn btn-info">
    </form>
</div>
<div class="col-md-3 m-5" >
    <!-- <button  class="btn btn-primary" (click)="visitapopopen()"  >Crear visita</button> -->
<h2>Visitas</h2>

<div style="max-height: 400px; overflow-y: scroll;" >


    <div class="cajainfo mb-1 p-1" style="color: white !important;"  *ngFor="let visita of visitas" >
        <!-- <app-visita [visita]="visita" ></app-visita>
        <button class="btn btn-danger" (click)="eliminar(visita._id)"  >Eliminar</button> -->


        <div   *ngIf="visita.open" >
            <app-visita [visita]="visita" [show]="true"   ></app-visita>
            <!-- <button class="btn btn-danger" *ngIf="!visita.admin" (click)="eliminar(visita._id)"  >Eliminar</button> -->
            <button  class="btn btn-primary"  (click)="visita.open=false" >Ocultar detalles</button>
        </div>
   
       <div *ngIf="!visita.open" >
        <button  class="btn btn-primary"  (click)="visita.open=true" >Ver detalles</button><br>
        <span style="color: white;" >{{visita.fechat}}</span><br>
       
        <span class="text" > Evento: {{visita.eventname}}</span>
       
        <br>
        <span class="text" > Descripción: {{visita.descripcion}}</span><br>
        <span class="text" > Nivel de acceso: {{visita.acceso}}</span><br> 
      
      <h1 style="margin-bottom: 2px;">Personas: </h1>
       
        <div [ngClass]="{'verde':persona.aprobe3}"  style="padding:2px;" *ngFor="let persona of visita.personas" >
           <span style="margin:1px;"> {{persona.name}} {{persona.apellido}}       </span>      
        <hr  style="margin: 1px;">
        </div>
    
   
       </div>







        </div>
</div>
</div>


</div>



<div *ngIf="visitapop" class="row cajainfo text-white  container mt-5" style="position: fixed; top: 1%; left:8%; padding: 10px; overflow-y: scroll; max-height: 550px;">
   <div class="col-10" >

    <form [formGroup]="visitaForm" (ngSubmit)="newVisita()" novalidate="">  
        <div class="form-group">  
            <div class="form-group" >
                <h3>
                    seleccione el evento que va a visitar
                </h3>
                <select formControlName="eventid"  (change)="buscarcupos($event.target.value)" [ngClass]="{'is-invalid': submitted && f.eventid.errors  }">
                
                <!-- <option value="migaperezber@unal.edu.co">101</option>
                <option value="jbaracaldo@ipservices.com.co">102</option> -->
                <option value="">Seleccione</option>
                <option value="general">Visita general</option>
                <option value="{{event._id}}" *ngFor="let event of eventos" >{{event.nombre}}</option>
                </select>
                <div *ngIf="submitted && f.eventid.errors" class="invalid-feedback">
                    <div *ngIf="f.eventid.errors.required">Campo requerido</div>
                       </div>
                <h4 *ngIf="visitaForm.controls['eventid'].value!='general' && visitaForm.controls['eventid'].value!=''"   >Tienes {{cupo}} cupos totales para este evento</h4>
                </div> 
                <div class="form-group" >
                    <h3>
                        seleccione su tipo de visita
                    </h3>
                    <select formControlName="tipo" [ngClass]="{ 'is-invalid': submitted && f.tipo.errors }">
                    <option value="">Seleccione</option>
                 <option *ngIf="visitaForm.controls['eventid'].value=='general'" value="reunion">reunion</option>
                 <option value="prestacion de servicios">prestacion de servicios</option>
                    </select>
                    <div *ngIf="submitted && f.tipo.errors" class="invalid-feedback">
                        <div *ngIf="f.tipo.errors.required">Campo requerido</div>
                           </div>
                    </div>

                    <div class="form-group"  *ngIf="visitaForm.controls['eventid'].value=='general'" >
<h3>Escriba el correo de la persona que va a visitar si no lo sabe escriba el nombre de la persona que va a a visitar</h3>
<input type="text" class="form-control"  formControlName="anfitrion">
                    </div>
                    <div class="form-group" *ngIf="visitaForm.controls['tipo'].value=='prestacion de servicios'" >
                <h3>Describa el servicio que va a prestar</h3>
                <input type="text" class="form-control"  formControlName="descripcion" [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }" >
                    </div>
                    <div class="form-group"  >
                        <h3>Si va a realizar la visita en vehiculo, por favor escriba la placa</h3>
                        <input type="text" class="form-control"  formControlName="placa" [ngClass]="{ 'is-invalid': submitted && f.placa.errors }" >
                            </div>
                    <div class="form-group">
                        <h3>Ingrese la fecha y hora de su visita</h3>     
                        <input class="form-control" type="datetime-local" placeholder="Fecha" formControlName="date"
                        min="2021-06-01" max="2023-12-31"
                        required      >
                        <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                            <div *ngIf="f.date.errors.required">Campo requerido</div>
                               </div>
                        </div>
                        <!-- <label >seleccione las personas que se haran presentes o cargue un archivo csv con los documentos de identidad de los seleccionados </label>
                        <input type="file" #csvReader name="Upload CSV" id="txtFileUpload" (change)="fileChangeListener($event)" accept=".csv" />    
                        <h2>Si desea ver un archivo de ejemplo, por favor <a href="../../../assets/docs/visita.xlsx" download="visita.xlsx" style="color: blueviolet;" > descargue aquí</a>(Recuerde abrirlo como archivo CSV)  </h2>
                        Personas agregadas: {{agregados.length}} <br>
 Ingrese las cédulas de las personas a agregar (puede pegar desde una hoja de calculo) : <input type="text" id="marcauno" #marcauno > <button (click)="marcaruno()" type="button" > Agregar</button>
                        <div style="overflow-y: scroll !important; max-height: 100px;">
                            <div *ngFor="let persona of visitaForm.controls.personasc?.controls; let i=index;"  >
                                <input type="checkbox" (change)="calcularPersonas()"  [formControl]="persona">
                                {{personasc[i].name}} {{personasc[i].apellido}}
                              </div>
                        </div>
                         -->


                         Personas agregadas: {{agregados.length}} <br>
                         <div *ngIf="!validcionPersonas" >
                             Ingrese las cédulas de las personas a agregar (puede pegar desde una hoja de calculo) : <textarea  cols="20" rows="3" id="marcauno" autocomplete="off" #marcauno ></textarea> <button (click)="marcaruno()" type="button" > Agregar</button>
                         </div>
 
                         
                         <ul *ngIf="!validcionPersonas"  style="max-height: 100px; overflow-y: auto;" >
                             <li *ngFor="let id of visitaForm.controls['personasc'].value" >{{id}}</li>
                         </ul>
                         <div *ngIf="validcionPersonas" class="row" >
                            <div class="col-md-6">
                                <h2>Usuarios registrados {{ruser}}</h2>
                                <ul   style="max-height: 100px; overflow-y: auto;" >
                                    <ng-container *ngFor="let id of visitaForm.controls['personas'].value" >
                                        <li *ngIf="id.name" style="color: rgb(43, 42, 42);" >{{id.name}}- {{id.id}} </li>
                                    </ng-container>
                                    
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <h2>Usuarios no registrados {{nnuser}}</h2>
                                <ul   style="max-height: 100px; overflow-y: auto;" >
                                    <ng-container *ngFor="let id of visitaForm.controls['personas'].value" >
                                        <li *ngIf="!id.name" style="color: rgb(43, 42, 42);;" > {{id.id}} </li>
                                    </ng-container>
                                    
                                </ul>
                            </div>
                            
                             
                         
                
            </div>
                 
           </div>
           <input type="submit" *ngIf="validcionPersonas" value="Crear visita" class="btn btn-primary">
           
        </form>
   </div>
<div class="row">
<div class="col-10">
    <button class="btn btn-primary" *ngIf="!validcionPersonas && !validando" (click)="validarPersonas()" >Validar documentos</button>
    <h4 *ngIf="validando" >Cargando..</h4>
    <button class="btn btn-danger ml-2" (click)="visitapop=false" >Cancelar</button>
  
</div>
</div>
        
    </div> 