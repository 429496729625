<div class="row mt-4 justify-content-center " >
    <div class="col-md-2" >
        <button class="btn btn-boton2" *ngIf="!veventos"  (click)="verEventos()" >Administrar eventos </button>
    </div>
    <div class="col-md-2" >
        <button class="btn btn-boton2" *ngIf="veventos"  (click)="veventos=false" >Aprobar visitas </button>
    </div>
    <div style="text-align: left;" class="col-2 " *ngFor="let area of areas" >
        
<div class="card cajainfo" style="cursor: pointer;" (click)="irArea(area)" >
<h2 class="card-title" style="color: black; "  > Administrar {{area.nombre}} </h2>

</div> 

    </div>    

</div>
<div style="position: absolute;top: 10px;right: 10px; text-align: end; " >
    
    <button class="btn btn-boton2" (click)="vmensajes=!vmensajes" >Mensajes  <span *ngIf="nuevos>0" >({{nuevos}})</span></button>
    <app-mensajes *ngIf="vmensajes" (newConversaions)="onNewConversations($event)"  [conversations]="mensajes" style="z-index: 99;" >
    
    </app-mensajes>
    </div>
<div *ngIf="veventos" class="row justify-content-center m-1" >
    <div class="col-12" >
        <div class="row mt-4 justify-content-center " >
            <div class="col-12" >
                <h1>Eventos Creados: </h1>
            </div>
           
            <div style="text-align: left;" class="col-6  col-md-2 " *ngFor="let event of eventos" >
                <app-evento [event]="event" ></app-evento>
                <button  class="btn btn-danger"  (click)="deleteEvent(event._id)" >Archivar</button>
                <button class="btn btn-primary"(click)="editEvent(event)" >Editar</button>
            </div>    
        
        </div>
    
        <div class="row justify-content-center " > 
            
        <div class="col-md-6 caja-form "   >
            <h1>Creación de evento</h1>
        <form [formGroup]="eventForm" (ngSubmit)="newEvent()">
            <div class="form-group" *ngIf="eventForm.controls['id'].value!='0'" > 
                <h3>Id</h3>
                <input type="text" class="form-control" formControlName="id" readonly>
                </div>
            <div class="form-group">
                <h3>Ingrese  nombre del evento </h3>
                <input type="text" class="form-control"  formControlName="nombre"  [ngClass]="{ 'is-invalid': submitted2 && f2.nombre.errors }">
                <div *ngIf="submitted2 && f2.nombre.errors" class="invalid-feedback">
                    <div *ngIf="f2.nombre.errors.required">Ingrese su nombre</div>
                   
                </div>
            </div> 
            <div class="form-group">
                <h3>Ingrese una descripcion del evento </h3>
                <input type="text" class="form-control"  formControlName="descripcion"  [ngClass]="{ 'is-invalid': submitted2 && f2.descripcion.errors }">
                <div *ngIf="submitted2 && f2.descripcion.errors" class="invalid-feedback">
                    <div *ngIf="f2.descripcion.errors.required">Ingrese su descripcion</div>
                   
                </div>
            </div> 
        
        
            <div class="form-group">
                <h3>Ingrese la fecha y hora del evento</h3>     
                <input class="form-control" type="datetime-local" placeholder="Fecha" formControlName="fecha"
                min="2021-06-01" max="2023-12-31"
                required      >
                </div>
                <div class="form-group">
                <h3>seleccione las áreas que se involucraran en el proceso </h3>
                <div *ngFor="let area of eventForm.get('areasc')['controls']; let i=index;"style="color: white;">
                    <input type="checkbox" [formControl]="area"  >
                    {{areascompleta[i].nombre}}
                  </div>
                </div>
                <input type="submit" value="Crear" class="btn btn-primary" >
        </form>
        </div>
        </div>
        
    </div>
    
    </div>
    

<div *ngIf="!veventos" class="row justify-content-center">
    <div class="col-11"   >
        <h2>Visitas por aprobar: </h2>
        <div class=" row justify-content-center " >
            <div class="col-3 m-2 caja-datos" *ngFor="let user of visitas"  >
                <span style="color: black;" >{{user.fechat}}</span><br>
                <span class="text" > Evento: {{user.eventname}}</span>
               
                <br>
                <span class="text" > Descripción: {{user.descripcion}}</span><br>
                <span class="text" >Fecha: {{user.fechat}}</span><br>
                <span *ngFor="let persona of user.personas" >
                    {{persona.name}} {{persona.apellido}}    <br> 
                    <span *ngIf="persona.aprobe2" > Aprobado <br> </span>
                <span *ngIf="!persona.aprobe2" > No aprobado <br> </span>
                <span *ngIf="persona.ingreso" > Ya registro ingreso <br> </span>
                <span *ngIf="!persona.ingreso" > No ha ingresado <br> </span>
               
                <hr>
                </span>
                <a href="/aprobar2/{{user._id}}" target="_blank" rel="noopener noreferrer">
                <button class="btn btn-primary" >Aprobar</button>
                </a>
            </div>
        </div>
    </div>

</div>