import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthService } from '../../services/auth.service';
import {ReservaService} from '../../services/reservas.services'
import { ChangeDetectorRef } from '@angular/core'
import Swal from 'sweetalert2';
import { log } from 'console';
var ageCalculator = require('age-calculator');
let {AgeFromDateString, AgeFromDate} = require('age-calculator');
 
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {
  clubes=[
    "CLUB CAMPESTRE DE  IBAGUÉ",
    "CORPORACION CENTRO MANIZALES",
    "CLUB CAMPESTRE DE  NEIVA",
    "CLUB CAMPESTRE DE  PEREIRA",
    "CLUB CAMPESTRE DE ARMENIA",
    "CLUB CAMPESTRE DE BUCARAMANGA.",
    "CLUB CAMPESTRE DE CARTAGENA",
    "CLUB CAMPESTRE DE  MEDELLIN",
    "CLUB CAMPESTRE DE POPAYÁN",
    "CLUB CAMPESTRE EL RODEO  ",
    "CLUB LAGOS DE CAUJARAL DE BARRANQUILLA",
    "CORPORACION CLUB MONTERIA JARAGUAY GOLF",
    "CORPORACION COUNTRY CLUB DE EJECUTIVOS MEDELLIN",
    "COUNTRY CLUB DE BARRANQUILLA",
    "FARALLONES CLUB CAMPESTRE DE CALI",
    "RUITOQUE GOLF COUNTRY CLUB S.A.",
    "CORPORACIÓN RECREATIVA TENNIS GOLF CLUB ",
   "CLUB EL BOSQUE",
    "CLUB CAMPESTRE POTRERILLO",
    "CLUB COLOMBO LIBANÉS",
    "CLUB DE BANQUEROS Y EMPRESARIOS",
    "CLUB MILITAR DE GOLF",
    "CLUB LOS BÚHOS",
    "CONDOMINIO CAMPESTRE EL PEÑON",
    "LOS PINOS POLO CLUB MOSQUERA",
    "CORPORACIÓN ATHENEUM WORLD TRADE CENTER CLUB",
    "JUNKO GOLF CLUB  ",
    "CLUB CAMPESTRE CUSCATLAN (SAN SALVADOR)",
    "SUMMIT GOLF &  RESORT S.A.",
    "HOROKO GOLF &  RESORT S.A.",
    "CITY UNIVERSITY CLUB LONDRES",
    "CLUB UNIÓN DE PANAMÁ",
    "IZCARAGUA COUNTRY CLUB",
    "LOS INKAS GOLF CLUB",
    "GUAYAQUIL COUNTRY CLUB ",
    "ARRAYANES COUNTRY CLUB",
    "DUBLIN COUNTRY CLUB",
    "CLUB DE GOLF DE PANAMÁ"
  
  ]
  popPQR: boolean = false;
  popCanje: boolean = false;

  beneficiarios;
  pop;
  user;
  subird;
  docForm;
  deletedoc;
  visitapop: boolean = false;
  visitaForm;
  submitted = false;
  visitas: any;
  visitaid;
  foto: boolean = false;
  videoWidth = 0;
  videoHeight = 0;
  popInv;
  @ViewChild('video', { static: false }) videoElement: ElementRef;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  foto1Url: any;
  eventos: any;
  mensajes: any;
  vmensajes;
  nuevos: number;
  canjeForm: FormGroup;
  pqrForm: FormGroup;
  constructor(
    private authService: AuthService,
    private eventService: EventsService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    private Reservas: ReservaService
  ) { }

  ngOnInit(): void {
    let user = localStorage.getItem('userInfo')
    this.user = JSON.parse(user).user;
    this.loaduser();
    this.visitaForm = new FormGroup({

      'descripcion': new FormControl('', [

      ]),
      'fecha': new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      'dia': new FormControl('', []),

      'mes': new FormControl('', []),
      'ano': new FormControl('', []),
      'personas': new FormControl([], [
      ]),
      'anfitrion': new FormControl('', []),
      'recibeName': new FormControl('', []),
    })

    this.canjeForm=new FormGroup({
      "user": new FormControl('',[Validators.required]),
      "inicio": new FormControl('',[Validators.required]),
      "fin": new FormControl('',[Validators.required]),
      "club": new FormControl('',[Validators.required]),
    })
    this.pqrForm=new FormGroup({
      "user": new FormControl('',[Validators.required]),
      "mensaje": new FormControl('',[Validators.required]),
    })
    this.docForm = new FormGroup({
      "doc_pdf": new FormControl(''),
      "id": new FormControl(''),
    })
    this.deletedoc = new FormGroup({

      "url": new FormControl(''),
      "id": new FormControl(''),


    })



   

  }
  abrirPop(){
    console.log("entrooo");
    
    this.visitapop=true;
  }
  get f2(){return this.canjeForm.controls}

  newCanje(){
    this.canjeForm.patchValue({
      user:this.user._id
    })
    if(this.canjeForm.invalid){
      return
    }
    this.authService.newSol(this.canjeForm.value)
    .then((r)=>{
      Swal.fire("solicitud enviada con exito")
      this.canjeForm.reset();
    })
  }
  newPqr(){
    this.pqrForm.patchValue({
      user:this.user._id
    })
    if(this.pqrForm.invalid){
      return
    }
    this.authService.newPqr(this.pqrForm.value)
    .then((r)=>{
      Swal.fire("solicitud enviada con exito")
      this.pqrForm.reset();
    })
  }

  loaduser() {
    console.log("usuaior car", this.user)
    this.authService.validatep(this.user.id)
      .then((response) => {
        console.log(response);
        this.user = response['user']
        var age=new AgeFromDateString(this.user.nacimiento).age;
        this.user.age=age;
        console.log(age,this.user)
        this.authService.setUserInfo({ 'user': response['user'] });
        if (this.user.categoria == "canje") {
          const d = new Date();
          let time = d.getTime();
          console.log(this.user.habilitado.fin, time)
          if (this.user.habilitado.fin > time) {
            this.user.canjeHabil = true
          }
          else {
            this.user.canjeHabil = false
          }
        }

        if (this.user.photo1 != "nofoto") {
          this.foto = false;

        }
        else {
          this.foto=true;
          this.activeVideo();
        }

        this.loadvisitas();
        this.getBenefits();
        this.getEventosVinculados();
        this.lookConver();

      })
      .catch((err) => {
        console.log(err)
        location.href = "/login"
      })



  }
  copiar(){
 
    /* Copy the text inside the text field */
   navigator.clipboard.writeText("https://socios.serrezuela.com/register-beneficiaio/"+this.user._id);
 }
  getBenefits() {
    this.authService.getBenefits(this.user._id)
      .then((r) => {
        this.beneficiarios = r;
        console.log(this.beneficiarios)
      })
  }



  onNewConversations(conver) {
    console.log("evento: ", conver);
    this.mensajes = conver;
    this.nuevos = 0;
    this.mensajes.forEach(element => {
      if (element.new != "" && element.new != this.user.nit) {
        this.nuevos += 1;
      }
    });
  }


  lookConver() {
    this.authService.lookConver(this.user.id)
      .then((response) => {
        console.log(response);
        this.mensajes = response["mensajes"];
        this.nuevos = 0;
        this.mensajes.forEach(element => {
          if (element.new != "" && element.new != this.user.nit) {
            this.nuevos += 1;
          }
        });

      })
      .catch((err) => {
        console.log(err)
      })

  }


  get f() { return this.visitaForm.controls; }

  loadvisitas() {
    var now = new Date();
    var n = now.getUTCHours();
    if (n < 5) {
      n = 24 + n;
    }
    function compare(a, b) {
      if (a.fecha < b.fecha) {

        return -1;
      }
      if (a.fecha > b.fecha) {
        return 1;
      }
      return 0;
    }

    var date = new Date();
    var dia = date.getDate();
    var mes = date.getMonth() + 1;
    var ano = date.getFullYear();
    if (this.user.categoria == "invitado") {
      this.authService.getvisitasInvitadoFecha(this.user._id, dia, mes, ano)
        .then((response) => {
          console.log("visitas", response);
          this.visitas = response["visitas"]
          this.visitas = this.visitas.sort(compare)
        })
        .catch((err) => {

        })
    }
    else {
      this.authService.getvisitasAnfitrionFecha(this.user._id, dia, mes, ano)
        .then((response) => {
          console.log("visitas", response);
          this.visitas = response["visitas"]
          this.visitas = this.visitas.sort(compare)
        })
        .catch((err) => {

        })
    }


  }

  getArea(event) {
    if (event == "general") {
      return "general"
    }
    var select = this.eventos.filter((evento) => { return evento._id == event })

    var personas = select[0].personas;
    var event = select[0].nombre;
    //console.log("select: ",personas);
    var personas = personas.filter(persona => { return persona.id == this.user.id })
    return [personas[0].area, event]
  }
  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  async newVisita() {
    var date = new Date(this.visitaForm.controls["fecha"].value);
    //console.log(now.getDate()==date.getDate() && now.getMonth()==date.getMonth());
    var dia = date.getDate();
    var mes = date.getMonth() + 1;
    var ano = date.getFullYear(); 
    this.visitaForm.patchValue({
      ano: ano,
      mes: mes,
      dia: dia,
      personas: [],
      anfitrion: this.user._id,
      recibeName: this.user.name + " " + this.user.apellido
    })
    
    if (this.visitaForm.invalid) {
      console.log("invalid", this.visitaForm.value);
      return
    }

    this.authService.newvisita(this.visitaForm.value)
      .then((response) => {
        this.visitapop = false;
        console.log(response);
        this.loadvisitas();
        this.visitaForm.reset();
      })
      .catch((err) => {
        console.log(err)

      })



  }


  done(event) {
    console.log(event)
    if (event) {
      this.pop = false;
      this.getBenefits();
    }
  }
  done2(event) {
    console.log(event)
    if (event) {
      this.popInv = false;
      this.loadvisitas()
    }
  }
  //control camara
  handleError(error) {
    console.log('Error: ', error);
  }

  attachVideo(stream) {

    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
      this.videoHeight = this.videoElement.nativeElement.videoHeight;
      this.videoWidth = this.videoElement.nativeElement.videoWidth;
    });

  }

  activeVideo() {

    var constraints = {
      video: {

        width: { ideal: 1280 },
        height: { ideal: 720 }
      }
    };
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
    } else {
      alert('Sorry, camera not available.');
    }



  }

  tomar() {
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
    var data = this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
    this.authService.sendfoto(data)
      .then((response) => {
        console.log(response["url"]);
        this.foto1Url = response["url"];
        this.foto = true;
        this.authService.addfoto(this.user._id, this.foto1Url)
          .then((response) => {
            this.authService.validatep(this.user.id)
              .then((response) => {
                //console.log(response);
                this.authService.setUserInfo({ 'user': response['user'] });
                let user = localStorage.getItem('userInfo')
                this.user = JSON.parse(user).user;

              })

          })


      })
      .catch((err) => {
        alert("no encontramos tu rostro en la foto");
      })

  }


  getEventosVinculados() {
    this.eventService.getEventosPersona(this.user.id)
      .then((response) => {
        console.log(response)
        this.eventos = response["eventos"];
      })
  }

  eliminar(id) {
    console.log(id);
    this.authService.eliminarVisita(id)
      .then((response) => {
        this.ngOnInit();
      })
  }






}
