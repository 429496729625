import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import QrScanner from 'qr-scanner';
import { ActivatedRoute } from '@angular/router';
import { ReservaService } from '../../services/reservas.services'
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lector-reservas',
  templateUrl: './lector-reservas.component.html',
  styleUrls: ['./lector-reservas.component.css']
})
export class LectorReservasComponent implements OnInit {

  //idr:any
  infouser:any
  infouserpop = false
  data: boolean
  inter = null;
  cedula:any
  cameras: any;
  codigoid:any
  qrScanner: any;
  desiredDevice: any = "";
  comentario;
  @ViewChild('video1', { static: false }) videoElement: ElementRef;

  @Input() idr;
  constructor(private router: Router,private api: ReservaService, private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    
    this.data = false
    this.changeDetectorRef.detectChanges();
   // this.idr=this.route.snapshot.paramMap.get("idr")
    console.log("ID", this.idr)


    this.inter = setInterval(() => {
      console.log("i ")
      if (this.videoElement.nativeElement) {
        this.iniciar();
      }
    }, 100)
  }

  onComentario(event) {
     const inputValue = event.target.value;
     console.log(inputValue)
     this.comentario = inputValue
  }

  iniciar() {
    clearInterval(this.inter);
    this.qrScanner = new QrScanner(this.videoElement.nativeElement, (result) => {
      //console.log("RES", this.result.data)
      if (result) {

        if (!this.data) {
          console.log("NO DATA")
          this.scanSuccessHandler(result.data);
          this.qrScanner.stop()
          this.qrScanner.destroy()
        }
      }

    }, {
      highlightScanRegion: true,
      highlightCodeOutline: true
    });

    this.qrScanner.start()
    QrScanner.listCameras().then((r) => {
      console.log(r)
      this.cameras = r
    })
  }


  selectedCamera(id){
    console.log(id)
    this.qrScanner.setCamera(id);
  }

  getcedula(e){
    this.changeDetectorRef.detectChanges();
    console.log("iii",e.target.value)
    this.cedula = e.target.value
    this.changeDetectorRef.detectChanges();
    
  }

  validarcedula(){
    console.log("CEDULA", this.cedula)
    this.api.infousercedula(this.cedula).then((r:any)=>{
      console.log("USERC", r)
      if(r != null){
        this.infouser = r;
      this.infouserpop = true;
      this.changeDetectorRef.detectChanges();
      this.codigoid = r._id
      }else{
        Swal.fire("El usuario no existe")
        .then(()=>{
          
        })
      }
      

    })
  }


  scanSuccessHandler(e: string) {
    
    this.codigoid = e
    console.log("lectura: ", e)
    this.data = true;

    this.api.infouser(e).then((r)=>{
      console.log("USER", r)
      this.infouser = r;
      this.infouserpop = true;
      this.changeDetectorRef.detectChanges();
    })

   
  }

  ingreso(){
    this.api.codigoreserva(this.codigoid, this.idr, this.comentario).then((r)=>{
      console.log(r)
      Swal.fire("Validación exitosa")
      this.infouserpop = false
      this.ngOnInit()
      //location.reload()
     
      //this.router.navigate(['/reservalector',  {'idr': this.idr}] );
    })
  }


  seleccionarCamara(deviceValue: any) {
    console.log(deviceValue);
    if (deviceValue != "") {
      this.desiredDevice = deviceValue
    }
  }

}
