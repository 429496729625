<div  class="row justify-content-center">
    <div class="col-10 " style="text-align: center;" >
        <select class="form-control mb-2" #listcameras (change)="selectedCamera(listcameras.value)">
            <option value="">-</option>
            <option *ngFor="let camera of cameras" value="{{camera.id}}">{{camera.label}}</option>
        </select>
        
        <video  playsinline  #video1  width="80%" height="auto" ></video>
    </div>

    </div>