import { Component, OnInit,Input,Output,EventEmitter, ElementRef} from '@angular/core';
import { rejects } from 'assert';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-useruploader',
  templateUrl: './useruploader.component.html',
  styleUrls: ['./useruploader.component.css']
})
export class UseruploaderComponent implements OnInit {
  csvRecords: any[] = [];
  header = false;
  delimiter=",";
  file;
  foto1Url="nofoto";
  cargando;
  cargados;
  @Input() empresa="";
  @Output() active = new EventEmitter<boolean>();
  constructor(private ngxCsvParser: NgxCsvParser, private authService : AuthService,
    private elRef:ElementRef) { }

  ngOnInit(): void {
    
  }

  onChange(value){
    this.delimiter=value;
    this.file=false;
  }
    // Your applications input change listener for the CSV File
    fileChangeListener($event: any): void {
this.file=true;
      // Select the files from the event
      const files = $event.srcElement.files;
  
      // Parse the file you want to select for the operation along with the configuration
      this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: this.delimiter })
        .pipe().subscribe((result: Array<any>) => {
  
        //  console.log('Result', result);
        result.forEach(element => {
          this.csvRecords.push({"datos":element,"estado":""})
        });
          
          
        }, (error: NgxCSVParserError) => {
          console.log('Error', error);
        });
  
    }

cargarre(){
  this.cargando=true;
  this.csvRecords.forEach((usr,i) => {
    if(usr.datos.length==6){
      var usrObj={
        "name":usr.datos[0]+" "+usr.datos[1],
        "id":usr.datos[2],
        "correo":usr.datos[3],
        "telefono":usr.datos[5],
        "empresa":this.empresa
      }
  
  
  
    }
    else{
      if(this.csvRecords.length==i+1){
        this.cargados=true;
        this.cargando=false;
           }
      this.csvRecords[i].estado="error"
    }
 
});
}
promise(usrObj,i){
  return new Promise((resolve,reject)=>{
    this.authService.createPerson(usrObj,this.foto1Url)
    .then((response) => {
      if(this.csvRecords.length==i+1){
        this.cargados=true;
        this.cargando=false;
           }
     this.csvRecords[i].estado="cargado"
     resolve("cargado")
  })
  .catch(()=>{
    if(this.csvRecords.length==i+1){
      this.cargados=true;
      this.cargando=false;
         }
    this.csvRecords[i].estado="error"
    resolve("error")
  })
  })
}


async cargar(){
  for (let i = 0; i < this.csvRecords.length; i++) {
    var usr=this.csvRecords[i];
    console.log(this.csvRecords[i])
    if(usr.datos.length==10 && !isNaN(usr.datos[3])){
      console.log();
      var usrObj={
        "name":usr.datos[0],
        "apellido":usr.datos[1],        
        "correo":usr.datos[2],
        "id":usr.datos[3],
        "telefono":usr.datos[4],
        "cargo":usr.datos[5],
        "arl":usr.datos[6],
        "eps":usr.datos[7],
        "contacto":usr.datos[8],
        "contactotel":usr.datos[9],
        "empresa":this.empresa
      }
  
   var resutl= await this.promise(usrObj,i);
    console.log(resutl)
}
else{
  console.log("error");
  if(this.csvRecords.length==i+1){
    this.cargados=true;
    this.cargando=false;
       }
  this.csvRecords[i].estado="error"
}
  }

}
exit() {
  this.active.emit(false);

}

marcaruno(){
  this.file=true;
  var result=this.elRef.nativeElement.querySelector('#marcauno').value;

 var result=result.split("\n")
result.forEach(el=>{
  el=el.split("\t");
  el=el.slice(0,10)
  this.csvRecords.push({"datos":el,"estado":""})
})
//  console.log(result)
//  return




}

}
