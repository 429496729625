import { Component, OnInit,ElementRef, Renderer2,ViewChild, ChangeDetectorRef  } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  Swal from 'sweetalert2'; 

@Component({
  selector: 'app-validacion',
  templateUrl: './validacion.component.html',
  styleUrls: ['./validacion.component.css']
})
export class ValidacionComponent implements OnInit {
  rperson;
vis;
  ipersonForm;
visitas=[];
  submitted;
    foto1Url: any;
    fotop: boolean;
    videoWidth = 0;
  videoHeight = 0;
  @ViewChild('video', { static: false }) videoElement: ElementRef;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
    stream: any;
    id: any;
    fotovalidate: boolean;
  visita: any;
  user: any;
  foto: any;
  finish: boolean;
  yaingreso: boolean;
  empresa: any;
  yamanilla: boolean;
  validatemanilla:boolean;
  bitaForm: FormGroup;
  rpersonForm: FormGroup;
  load:boolean=false;
  loading: boolean;
  constructor(
    private authService: AuthService,
    private router:Router,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void { 
    this.rpersonForm = new FormGroup({
      'name': new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      'apellido': new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      'correo': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'id': new FormControl('', [
        Validators.required,
        Validators.min(100)
      ]),
      'empresa': new FormControl('independiente', [
        Validators.required,
  
      ]),
      'cargo': new FormControl('', [
        Validators.required,
  
      ]),

      'telefono': new FormControl('', [
        Validators.required,
        Validators.min(1111)
      ]),
       'arl': new FormControl('', [
        Validators.required,
  
      ]),
      'eps': new FormControl('', [
        Validators.required,
  
      ]),
      'contacto': new FormControl('', [
        Validators.required,
  
      ]),
      'contactotel': new FormControl('', [
        Validators.required,
  
      ]),
    
    

    });
    this.bitaForm = new FormGroup({
 
      'question': new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),              
      'time': new FormControl('', ),    
    });
    this.ipersonForm = new FormGroup({
 
      'id': new FormControl('', [
        Validators.required,
          ]),    
    });
    // this.activeVideo()
    // this.fotop=false;
    // this.finish=false;
    // this.vis=true;
    // this.yaingreso=false;

  }

  get f() { return this.rpersonForm.controls; }
  get f4() { return this.ipersonForm.controls; }

changeInput(event){
  
  console.log( this.ipersonForm.controls['id'].value)
  //this.ipersonForm.controls['id'].value= this.ipersonForm.controls['id'].value.replace(/^0+/, '');
  var value=this.ipersonForm.controls['id'].value.replace(/\s/g, '');
  value=value.replace(/^0+/, '');
  this.ipersonForm.controls['id'].patchValue(value)
}

  ingresop(){
    if(this.load){
      return 
    }
    this.load=true;
    this.submitted = true;
    if (this.ipersonForm.invalid) {
     console.log("invalido");
     return
  }

  this.authService.validatep(this.ipersonForm.get("id").value)
    .then((response) => {
     this.load=false;
      console.log(response);  
    //   this.authService.setUserInfo({'user' : response['user']});
       this.user=response["user"];  

if(this.user.bloqueado){
  Swal.fire("El usuario no se encuentra habilitado para ingresar", "Contacte a la seguridad del centro de eventos ")
  .then(()=>{
    location.reload();
  })

}
else{
  if(this.user.funcionario){
    //verificar si no ha ingresado
    
    
    this.authService.getIngresosUser(this.user.id)
    .then((result)=>{
       //console.log(result["result"]);
       
       var ingreso=result["result"];
               Swal.fire("No se ha registrado salida de su anterior ingreso.")
              .then(()=>{
                //si hay ingreso, marcar salida
               this.salidaFuncionario();
              })
    
    })
    .catch((err)=>{
      console.log(err)
      //validar cod error no ha ingresado.
      if(err.error.statusCode==100){
        var now= new Date();
                        var n = now.getUTCHours();
                        if(n<5){
                          n=24+n; 
                        } 
                       now.setHours(n-5);
                       
                        this.authService.newingresoFuncionario("funcionario",this.user.photo1,now,this.user)
                        .then((response)=>{
                          Swal.fire("Se registro el ingreso",)
                          .then(()=>{
                           // this.finish=true;
                            this.yaingreso=true;
                            this.visita="funcionario";
                            this.vis=true;
                           // this.terminar()
                          })
                        }) 
                        .catch((err)=>{
                       
                            //location.reload();
                        })
    
      }
    
    })
    
                   
    }
    
    else{
    //   this.authService.getIngresosUser(this.user.id)
    //   .then((result)=>{
    // //ya registro ingreso.
    
    //   })
    //   .catch((err)=>{
    // //no ha registrado 
    
    //   })
    
    
     this.authService.getvisitas(this.ipersonForm.get("id").value)
     .then((response) => {
      console.log(response); 
    this.visitas=response["visitas"];
    
    //valida ingreso antes de revisar visita.
    
      this.ordenar();
     })
     .catch((err)=>{
    
       Swal.fire(     {
         title: "No encotramos visitas asociadas a tu número de identificacion ",
             icon: 'warning',
         showCancelButton: false,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#89c9b8',
         confirmButtonText: 'ok',
    
         
     }).then((result)=>{
     if(result.value){
    
     location.reload();
       
     }
     else{}
     });
    
       
    })
    
    
    }  
}

    })
    .catch(()=>{
      this.load=false;
      Swal.fire("El usuario no se encuentra registrado aún en nuestra plataforma")
      .then(()=>{
        
        this.rpersonForm.patchValue({
          id:this.ipersonForm.get("id").value
           })
        this.rperson=true;
        this.fotop=false;
        this.activeVideo();
      })
    })
    //deberemos pedirle registro.


  
  }

ordenar(){
  if(this.visitas.length>0){
var events=this.visitas;
  function compare( a, b ) {
    var datea=Date.parse(a.fecha);
    var dateb= Date.parse(b.fecha);
    
    // console.log("fecha a",);
    // console.log("fecha b",dateb);
    if ( datea > dateb ){      
      return 1;   }
    if ( datea < dateb ){
            return -1;  }
    return 0; 
  }
  function checkHoy(visita){
    //return false
    var now =new Date();
  var date= new Date(visita.fecha);
 // console.log(now.getDate()==date.getDate() && now.getMonth()==date.getMonth());
    return now.getDate()==date.getDate() && now.getMonth()==date.getMonth()
  }

  events.sort(compare);
  console.log("ordenadas ",events);
var events=events.filter(checkHoy);
console.log("filtrada",events);
if(events.length==0){
  Swal.fire("No tienes citas para hoy");
}
else{
  var visit=events[0];
  console.log(visit);
var aprobado=true;
      this.visita=visit
      this.vis=true;
      this.loadempresa();
      this.visita.personas.forEach((element,i) => {
        if (element.ingreso && element.id==this.user.id ){
          console.log("encontrado: ",i)
this.yaingreso=true;
this.authService.getManillaUser(this.user._id,this.visita._id)
.then((result)=>{
  console.log(result);
  if(result["result"]!=null && result["result"].manilla){
    this.yamanilla=true;
  }
})
// this.salida();
return
        }
         if (!element.aprobe3 || !element.aprobe2 || !element.aprobe1 && element.id==this.user.id ){
          
           Swal.fire("la visita no ha sido aprobada")
           .then(()=>{location.reload()})
           return
                 }
                 else if(element.aprobe3 && element.aprobe2 && element.aprobe1 && element.id==this.user.id ){
                  if(this.user.photo1=="nofoto"){
                    this.activeVideo()
                  }
                  else if(this.user.photo1!="nofoto" && !this.yaingreso){
                    //verificar si la foto sirve
                    this.authService.verificarFoto(this.user.photo1)
                    .then((result)=>{
                      var now= new Date();
                      var n = now.getUTCHours();
                      if(n<5){
                        n=24+n; 
                      } 
                     now.setHours(n-5);
                     //ingreso descontar de capacidades.
                      this.authService.newingreso(this.visita._id,this.user.photo1,now,this.user)
                      .then((response)=>{
                        Swal.fire("Se registro el ingreso","por favor muestre este resultado para ingresar.")
                        .then(()=>{
                         // this.finish=true;
                          this.yaingreso=true;
                         // this.terminar()
                        })
                      }) 
                      .catch((err)=>{
                        console.log(err);
                    if(err.error.statusCode==100){
                      Swal.fire("la empresa ha excedido el cupo de personas para este evento")
                      .then(()=> location.reload())
                     
                    }
                    else{
                      location.reload();
                    }
                          //location.reload();
                      })
                    })
                    .catch((err)=>{
                      this.activeVideo()
                    })

                   
                  
            
                  }
                 }
      });
   

 
    

}

  
}
else{
  Swal.fire("No tienes citas para hoy");
}

}



//control camara
handleError(error) {
  console.log('Error: ', error);
}
loadempresa(){
  if(this.user.empresa){
  if(this.user.empresa!="independiente"){
this.authService.getempresa(this.user.empresa)
.then((response)=>{
  console.log(response);
  this.empresa=response["empresa"]; 
})

   } }
}
attachVideo(stream) {
  this.stream=stream;
  this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
  this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
    this.videoHeight = this.videoElement.nativeElement.videoHeight;
    this.videoWidth = this.videoElement.nativeElement.videoWidth;
});

}

activeVideo(){
     
      var  constraints = {
        video: {
            
            width: { ideal: 1280 },
            height: { ideal: 720 }
        }
    };
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
      navigator.mediaDevices.getUserMedia(constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
         } else {
             alert('Sorry, camera not available.');
         }



}

tomar(){
  this.loading=true;
  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');
  this.authService.sendfoto(data)
  .then((response)=>{
    this.loading=false;
    console.log("url",response["url"]);
    this.foto1Url=response["url"];

this.fotop=true;

}) 

  .catch((err)=>{
    console.log(err)
alert("no encontramos tu rostro en la foto");
  })

}

tomarregistro(){
  this.loading=true;
  this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  var data= this.canvas.nativeElement.toDataURL().replace(/\s/g, '+').replace(/^data:image\/png;base64,/, '');

  this.authService.validatePingreso(data,this.user.id)
  .then((response)=>{
    this.loading=false;
    this.foto=response["foto"];
    this.user.photo1=this.foto;
    this.ingreso();
  }) 
  .catch((err)=>{
    this.loading=false;
    console.log(err);
    if(err.error.message=="No rostro"){
      Swal.fire("No encontramos tu rostro en la foto")
      .then(()=>{return})
    }
else{
  location.reload();
}
      
  })

}

ingreso(){
  var now= new Date();
  var n = now.getUTCHours();
  if(n<5){
    n=24+n; 
  } 
  now.setHours(n-5);
  //ingreso descontar de capacidades.
  this.authService.newingreso(this.visita._id,this.user.photo1,now,this.user)
  .then((response)=>{
    Swal.fire("Se registro el ingreso","por favor muestre este resultado para ingresar.")
    .then(()=>{
     // this.finish=true;
      this.yaingreso=true;
     // this.terminar()
    })
  }) 
  .catch((err)=>{
    console.log(err);
  if(err.error.statusCode==100){
  Swal.fire("la empresa ha excedido el cupo de personas para este evento")
  .then(()=> location.reload())
  
  }
  else{
  location.reload();
  }
      //location.reload();
  })

}


salida(){
  var now= new Date();
  var n = now.getUTCHours();
  if(n<5){
    n=24+n; 
  } 
 now.setHours(n-5);
  this.authService.newsalida(this.visita._id,now,this.user)
  .then((response)=>{
    Swal.fire("Se registro la salida")
    .then(()=>{
      this.finish=true
      this.terminar()
    })
   
  }) 
  .catch((err)=>{
    console.log(err);

     // location.reload();
  })


}
terminar(){
  location.reload();
}
validate(){
  this.validatemanilla=true
  this.changeDetectorRef.detectChanges();
}

onNewManilla(event){ 
  this.validatemanilla=false;
  this.changeDetectorRef.detectChanges();
  console.log(isNaN(event));
  if(event.length==28 && !isNaN(event)){
    this.authService.newManilla(this.user,this.visita,event)
    .then((response)=>{
      console.log(response);
      this.yamanilla=true     
      this.validatemanilla=false;
      this.changeDetectorRef.detectChanges();
    })
    .catch((err)=>{
      console.log(err);    
      Swal.fire("Error al leer manilla", "Asegurese que la manilla no ha sido usada e intente de nuevo ")
      this.validatemanilla=false;
      
    })
  }
  else{
       Swal.fire("codigo de manilla no valido", "Asegurese de estar leyendo una manilla de ingreso valida")
       this.validatemanilla=false;
    
    
  }

}


salidaFuncionario(){
  //todo: ponebr en bitacora la salida forzosa 
      //proceso de salida para funcionarios
       //1-buscar ingreso
       this.authService.getIngresosUser(this.user.id)
       .then((result)=>{
          //console.log(result["result"]);
          
          var ingreso=result["result"];
          var now= new Date();
            var n = now.getUTCHours();
            if(n<5){
              n=24+n; 
            } 
            now.setHours(n-5);
            this.authService.newsalidaFuncionario(now,this.user,ingreso._id)
            .then((response)=>{
              Swal.fire("Se registro la salida")
              .then(()=>{
                this.newitem("El sistema marco de forma automatica la salida de: "+this.user.name+" "+this.user.apillido+" - CC: "+this.user.id+" debido a que esta intentando ingresar nuevamente")
                var now= new Date();
                var n = now.getUTCHours();
                if(n<5){
                  n=24+n; 
                } 
               now.setHours(n-5);
               
                this.authService.newingresoFuncionario("funcionario",this.user.photo1,now,this.user)
                .then((response)=>{
                  Swal.fire("Se registro el ingreso",)
                  .then(()=>{
                   // this.finish=true;
                    this.yaingreso=true;
                    this.visita="funcionario";
                    this.vis=true;
                   // this.terminar()
                  })
                }) 
                .catch((err)=>{
               
                    //location.reload();
                })
              })
            
            }) 
            .catch((err)=>{
              console.log(err);

              // location.reload();
            })

       })
       .catch((err)=>{
         console.log(err)
         //validar cod error no ha ingresado.
        //  if(err.error.statusCode==100){
        //   Swal.fire("No se ha registrado su ingreso en la plataforma")
        //   .then(()=>{
        //     this.finish=true
        //     this.terminar()
        //   })
        //  }

       })


}

newitem(message){
  var now= new Date();
  var n = now.getUTCHours();
  if(n<5){
    n=24+n; 
  } 
 now.setHours(n-5);  
  this.bitaForm.patchValue({
    time: now,
    question:message
  });

  this.authService.newitem(this.bitaForm.value,this.user)
  .then((response)=>{
  
  })


}

registerp(){
  this.submitted = true;
  console.log(this.rpersonForm.controls);
  if (this.rpersonForm.invalid) {
    console.log("no enviado ");
    return;
}
console.log("enviado ");
  this.authService.createPerson(this.rpersonForm.value,this.foto1Url)
  .then((response) => {
    console.log(response);
    var id= response['user'].id;
    this.ipersonForm.patchValue({
      id:id
       })
       this.rperson=false;
       this.ingresop();
   // this.completo=true;
  })
  .catch((err)=>{
    location.reload()
})

}




}
